import React, { useState } from 'react'
import { TimezoneSelect } from './TimezoneSelect'
import { MDBRow, MDBCol, MDBCard, MDBIcon, MDBCardBody, MDBBtn } from 'mdbreact'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setChangesToBeSavedAction } from '../../../store/CompanySlice'

const GeneralSettings = ({
  selectedTimezone,
  handleTimezoneChange,
  leaderboardURLGenerator,
  changesToBeSaved,
  saveGeneralSettings,
  ipIgnoreListEnabled
}) => {
  const dispatch = useDispatch()

  const [ipIgnoreListEnabledState, setIpIgnoreListEnabledState] = useState(ipIgnoreListEnabled)

  const handleChange = (event) => {
    setIpIgnoreListEnabledState(event.target.checked)
    dispatch(setChangesToBeSavedAction(true))
  }
  return (
    <MDBRow className='d-flex justify-content-center'>

      <MDBCol xl='12' md='12' className='mb-5'>
        <MDBCard cascade className='cascading-admin-card hud-stats'>
          <div className='admin-up'>
            <MDBIcon icon='cogs' className='primary-color mr-3 z-depth-2' />
            <div className='data'>
              <h4><strong>General Settings</strong></h4>
            </div>
          </div>
          <MDBCardBody className='mt-4'>
            <MDBRow className='my-2 d-flex'>
              <MDBCol size='12'>
                <TimezoneSelect className='browser-default custom-select' selectedTimezone={selectedTimezone} onChange={handleTimezoneChange} />
              </MDBCol>
            </MDBRow>
            {/* To-do: Add this setting back when we add the Report-A-Phish feature */}
            {/* <MDBRow className='my-2 d-flex'>
              <MDBCol size='12'>
                <MDBInput getValue={(value) => handleReportingInboxAddress(value)} value={newReportingInboxAddress} icon='envelope' label='Report-A-Phish Inbox Address' />
              </MDBCol>
            </MDBRow> */}

            {leaderboardURLGenerator}

            <FormGroup className='margin-left:0 margin-top:2 margin-bottom:2'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ipIgnoreListEnabledState}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color='warning'
                  />
}
                label={(<div className='phin-h5'>Ignore Third Party Service IP Addresses</div>)}
              />
              <p className='padding-left:2'>Enabling this feature will help prevent third party services such as Microsoft Defender from producing false positive phishing results. Visit our <a href='https://www.phinsec.io/knowledge/ignore-third-party-service-ip-addresses' target='_blank' rel='noreferrer'>Knowledge Base</a> to learn more.</p>
            </FormGroup>

            <MDBCol size='12' className=' d-flex justify-content-end'>
              <MDBBtn tag='a' size='lg' title='Save Changes' onClick={() => saveGeneralSettings(ipIgnoreListEnabledState)} style={changesToBeSaved ? {} : { display: 'none' }} rounded color='primary'>
                Save Changes
              </MDBBtn>
            </MDBCol>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  )
}

export default GeneralSettings
