import { MDBIcon, MDBTooltip } from 'mdbreact'
import { orgLevels } from '../../../frontendConsts.js'

function WelcomeEmailInheritanceBadges ({ welcomeEmailOrgLevel, orgLevel }) {
  return (
    <div className='inheritanceBadgeGroup'>
      <MDBTooltip domElement placement='top'>
        <div className={welcomeEmailOrgLevel === orgLevels.DEFAULT ? 'phinBadge activeBadge' : 'phinBadge'}>
          <MDBIcon className='badgeIcon' fas icon='globe' />
        </div>
        <div>Default Template</div>
      </MDBTooltip>
      <MDBTooltip domElement placement='top'>
        <div className={welcomeEmailOrgLevel === orgLevels.PARTNER ? 'phinBadge activeBadge' : 'phinBadge'}>
          <MDBIcon className='badgeIcon' far icon='handshake' />
        </div>
        <div>Partner Template</div>
      </MDBTooltip>
      {orgLevel === orgLevels.COMPANY &&
        <MDBTooltip domElement placement='top'>
          <div className={welcomeEmailOrgLevel === orgLevels.COMPANY ? 'phinBadge activeBadge' : 'phinBadge'}>
            <MDBIcon className='badgeIcon' far icon='building' />
          </div>
          <div>Company Template</div>
        </MDBTooltip>}
    </div>
  )
}

export default WelcomeEmailInheritanceBadges
