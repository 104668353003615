import React, { useState, useEffect } from 'react'
import { Grid, Button, Skeleton, Box } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoAdd, IoTrash } from 'react-icons/io5'
import { useSelector, useDispatch } from 'react-redux'

import NotificationWrapper from './components/notifications/notificationWrapper'
import SharedBasicModal from './components/SharedBasicModal'

import AdminCreator from './components/partnersPage/adminCreator'
import { createAdminThunk, deleteAdminThunk } from '../store/PartnerSlice'

import CompaniesTable from './components/companies/CompaniesTable'
import { MDBContainer } from 'mdbreact'

export const PartnersPage = ({ id }) => {
  const { partner, loaders } = useSelector((state) => state.partner)
  const { authorization } = useSelector((state) => state.auth)

  const isBillingAdmin = authorization.billingAdminPartnerIds.includes(id)

  const {
    isLoadingPartner
  } = loaders
  const dispatch = useDispatch()

  const [adminCreatorModal, setAdminCreatorModal] = useState(false)
  const [adminToDeleteUid, setAdminToDeleteUid] = useState('')
  const [deleteAdminConfirm, setDeleteAdminConfirm] = useState(false)
  const [tablePageSize, setTablePageSize] = useState(10)
  const [adminTableRows, setAdminTableRows] = useState([])

  useEffect(() => {
    if (partner) {
      const partnerAdminRows = []
      partner.admins.forEach((admin) => {
        partnerAdminRows.push({
          ...admin
        })
      })
      setAdminTableRows(partnerAdminRows)
    }
  }, [partner])

  const adminTableColumns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Email Address',
      field: 'email',
      flex: 1
    },
    {
      headerName: 'Action',
      field: 'uid',
      renderCell: (params) => generateAdminDeleteButton(params.id, params.row.email),
      width: 200,
      sortable: false,
      filterable: false
    }
  ]

  const generateAdminDeleteButton = (id, email) => {
    return (
      <Button
        endIcon={<IoTrash />}
        id={`delete-${email}`}
        style={{ color: 'red' }}
        onClick={() => { setAdminToDeleteUid(id); setDeleteAdminConfirm(true) }}
      />
    )
  }

  const createAdmin = async (adminFirst, adminLast, adminEmail, billingPermission) => {
    dispatch(createAdminThunk({
      adminEmail,
      adminFirst,
      adminLast,
      id,
      partnerName: partner.name,
      billingPermission
    }))
    setAdminCreatorModal(false)
  }

  const deleteAdmin = async (uid) => {
    /**
     * uid -> The auth0 id of the admin
     * id -> The partner id of the partner to delete the admin from
     */
    dispatch(deleteAdminThunk({ id, uid }))
    setDeleteAdminConfirm(false)
  }

  return (
    <MDBContainer>
      <NotificationWrapper />

      {/* Admin Creator Modal */}
      <AdminCreator
        isOpen={adminCreatorModal}
        isBillingAdmin={isBillingAdmin}
        closeModal={() => { setAdminCreatorModal(false) }}
        createAdmin={createAdmin}
      />

      <SharedBasicModal
        open={deleteAdminConfirm}
        onClose={() => setDeleteAdminConfirm(!deleteAdminConfirm)}
        Content={<p>Are you sure you want to delete this admin? <strong>This can not be undone!</strong></p>}
        confirmText='Delete'
        confirmAction={() => deleteAdmin(adminToDeleteUid)}
      />

      <Box sx={{ marginBottom: 4 }}>
        <h1 style={{ fontWeight: 500 }}>Company Management</h1>
      </Box>

      <div className='main-container-centered'>

        <CompaniesTable />

        <Grid container sx={{ paddingBottom: '1em' }}>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'Left' }}>
              <h4 className='phin-h4' style={{ textAlign: 'left' }}>Administrators</h4>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <span>These are admins who can access all companies in your partner.</span>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant='contained'
                startIcon={<IoAdd />}
                id='create-partner-admin-button'
                style={{ height: '3em' }}
                disabled={!partner || isLoadingPartner}
                onClick={() => {
                  setAdminCreatorModal(true)
                }}
              >
                ADD AN ADMIN
              </Button>
            </Grid>
          </Grid>

          {(isLoadingPartner || !partner) && (
            <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1110} height={400} />
          )}

          {(!isLoadingPartner && partner) && (
            <div style={{ width: '100%' }}>
              <DataGrid
                getRowId={(row) => row.uid}
                style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                className='DataGrid'
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }]
                  }
                }}
                autoHeight
                rows={adminTableRows}
                columns={adminTableColumns}
                pageSize={tablePageSize}
                onPageSizeChange={(newSize) => setTablePageSize(newSize)}
                rowsPerPageOptions={[10, 25, 50]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500, id: 'partner-admin-table-search', ariaLabel: 'Search' },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                disableSelectionOnClick
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
              />
            </div>
          )}
        </Grid>

      </div>
    </MDBContainer>
  )
}

export default PartnersPage
