import React from 'react'
import { useHistory } from 'react-router-dom'
import { MDBRow, MDBContainer } from 'mdbreact'
import { Box } from '@mui/material'
import NotificationWrapper from './components/notifications/notificationWrapper'
import { useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import PartnerAdminManagementTable from './components/partnerManagement/PartnerAdminManagementTable'
import DistributorAdminManagementTable from './components/partnerManagement/DistributorAdminManagementTable'

const PartnerOverviewPage = ({ id }) => {
  const history = useHistory()
  const { isLoadingDeleteAdmin, isLoadingCreateAdmin, isLoadingDistributor, isLoadingAddPartner } = useSelector((state) => state.distributor.loaders)

  const distributor = useSelector((state) => state.distributor.distributor)

  return (
    <MDBContainer>
      <NotificationWrapper />

      <Box sx={{ marginBottom: 4 }}>
        <h1 style={{ fontWeight: 500 }}>Partner Overview</h1>
      </Box>

      <MDBRow size='12' className='d-flex justify-content-center'>
        <MDBContainer fluid>
          {(!distributor || isLoadingDistributor || isLoadingAddPartner) && (
            <Spinner message='Loading Distributor Data' />
          )}
          {(distributor && !isLoadingDistributor && !isLoadingAddPartner) && (
            <PartnerAdminManagementTable
              distributor={distributor}
              history={history}
            />
          )}
        </MDBContainer>
      </MDBRow>
      <br />
      <MDBRow size='12' className='d-flex justify-content-center'>
        <MDBContainer fluid style={{ marginBottom: '4em' }}>
          {distributor && !isLoadingDistributor && !isLoadingDeleteAdmin && !isLoadingCreateAdmin && (
            <DistributorAdminManagementTable
              distributor={distributor}
              history={history}
            />
          )}
        </MDBContainer>
      </MDBRow>
    </MDBContainer>
  )
}

export default PartnerOverviewPage
