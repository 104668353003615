import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const stagedGroupsSlice = createSlice({
  name: 'stagedGroups',
  initialState: {
    loaders: {
      loadingStagedGroups: false
    },
    stagedGroups: null
  },
  reducers: {
    loadingStagedGroupsUpdated: (state, action) => {
      state.loaders.loadingStagedGroups = action.payload
    },
    getStagedGroups: (state, action) => {
      state.stagedGroups = action.payload
    },
    deleteStagedGroups: (state) => {
      state.stagedGroups = null
    },
    updateStagedGroupStatus: (state, action) => {
      const updatedStagedGroups = state.stagedGroups

      for (const updatedStagedGroup of updatedStagedGroups) {
        if (updatedStagedGroup.id === action.payload.stagedGroupId) {
          updatedStagedGroup.status = action.payload.status
        }
      }
    }
  }
})

export const {
  loadingStagedGroupsUpdated: loadingStagedGroupsUpdatedAction,
  getStagedGroups: getStagedGroupsAction,
  deleteStagedGroups: deleteStagedGroupsAction,
  updateStagedGroupStatus: updateStagedGroupStatusAction
} = stagedGroupsSlice.actions

export const getStagedGroupsByCompanyThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingStagedGroupsUpdatedAction(true))

    try {
      const response = await api.get(`/api/companies/${companyId}/stagedGroups`)
      if (response.status === 200) {
        const { stagedGroups } = await response.json()
        dispatch(getStagedGroupsAction(stagedGroups))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to get stagedGroups.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to get stagedGroups.')
      }, 0)
    }

    dispatch(loadingStagedGroupsUpdatedAction(false))
  }
}

export default stagedGroupsSlice.reducer

export const updateStagedGroupStatusThunk = ({ companyId, stagedGroupId, updatedFields }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/companies/${companyId}/stagedGroups/${stagedGroupId}/ignore`, updatedFields)

      if (response.status === 200) {
        const { status } = await response.json()
        dispatch(updateStagedGroupStatusAction({ stagedGroupId, status }))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update stagedGroup.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update stagedGroup.')
      }, 0)
    }
  }
}
