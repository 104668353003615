import { useAuth0 } from '@auth0/auth0-react'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { ClickAwayListener, Box, Grow, MenuItem, MenuList, Paper, Popper, Typography, Stack, Chip } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useRef, useEffect } from 'react'
import SharedBasicModal from './SharedBasicModal'
import { getAccessibleDistributorsThunk } from '../../store/DistributorSlice'
import Spinner from './Spinner'
import { useHistory } from 'react-router-dom'

function UserProfileButton ({ distributorId }) {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const history = useHistory()
  const { authorization } = useSelector((state) => state.auth)
  const { accessibleDistributors } = useSelector((state) => state.distributor)
  const { isLoadingAccessibleDistributors } = useSelector((state) => state.distributor.loaders)
  const distributors = authorization?.roles?.distributors
  const [open, setOpen] = useState(false)
  const [openDistributorsModal, setOpenDistributorsModal] = useState(false)
  const [selectedDistributor, setSelectedDistributor] = useState(null)
  const anchorRef = useRef(null)

  useEffect(() => {
    if (authorization?.uid) {
      dispatch(getAccessibleDistributorsThunk())
    }
  }, [authorization])

  useEffect(() => {
    if (distributorId) {
      setSelectedDistributor(distributorId)
    }
  }, [distributorId])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className='nav-link'>
      <>
        <div className='user-info'>
          <span
            ref={anchorRef}
            onClick={handleToggle}
            id='profile-btn'
            aria-haspopup='true'
            style={{
              borderRadius: '50%',
              width: '1.15em',
              height: '1.15em',
              color: '#FFF',
              marginTop: '-3px',
              textAlign: 'center',
              font: '1.6em Arial, sans-serif'
            }}
          >{authorization && authorization.name && authorization.name.toUpperCase()[0]}
          </span>
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement='bottom-start'
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    sx={{ minWidth: '200px' }}
                    autoFocusItem={open}
                    id='composition-menu'
                    aria-labelledby='composition-button'
                  >
                    <MenuItem
                      id='logout-button'
                      aria-label='Logout Button'
                      onClick={() => logout({ returnTo: window.location.origin })}
                    >
                      <LogoutIcon sx={{ mr: '.5rem' }} />
                      Logout
                    </MenuItem>
                    {distributors && Object.keys(distributors).length > 1 &&
                      <MenuItem
                        id='switch-distributors-button'
                        aria-label='Switch Distributors Button'
                        onClick={() => setOpenDistributorsModal(true)}
                      >
                        <ManageAccountsIcon sx={{ mr: '.5rem' }} />
                        Switch Distributors
                      </MenuItem>}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <SharedBasicModal
          width='40%'
          onClose={() => {
            setOpenDistributorsModal(false)
            setSelectedDistributor(distributorId)
          }}
          confirmAction={() => {
            history.push(`/distributors/${selectedDistributor}`)
          }}
          open={openDistributorsModal}
          // confirmAction={submitAddUsersToOnboarding}
          confirmText='submit'
          Content={
            <Box>
              <Typography variant='h6' component='h6'>Change Distributor</Typography>
              <Stack sx={{ mt: '.5rem' }}>
                {
                  isLoadingAccessibleDistributors || !accessibleDistributors
                    ? <Spinner />
                    : accessibleDistributors.map((dist) => (
                      <Box
                        key={dist.id}
                        sx={{ display: 'flex', justifyContent: 'space-between', border: selectedDistributor === dist.id ? '2px solid #2e7d32' : 'none', cursor: 'pointer', padding: '.5rem', borderRadius: '5px' }}
                        onClick={() => setSelectedDistributor(dist.id)}
                      >
                        <Box>{dist.name}</Box>
                        <Box>
                          {dist.id === distributorId && <Chip label='Current' size='small' color='success' />}
                        </Box>
                      </Box>
                    ))
                }
              </Stack>
            </Box>
          }
        />
      </>
    </div>
  )
}

export default UserProfileButton
