import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBRow,
  MDBInput, MDBIcon, MDBCol, MDBTooltip, MDBContainer, MDBAlert
} from 'mdbreact'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import ValidationUtils from '../utils/validationUtils'
import { useDispatch, useSelector } from 'react-redux'
import { addPartnerThunk } from '../store/DistributorSlice'
import Spinner from './components/Spinner'
import { useAuth0 } from '@auth0/auth0-react'
import { SharedStepper } from '../shared/components/SharedStepper'
import { IoPersonAdd, IoCashOutline, IoPencil } from 'react-icons/io5'
import HubSpotPartnerSelector from './components/partnersPage/HubSpotPartnerSelector'
import { updateHubSpotPartnerErrorMessageAction, updateHubSpotPartnerNameAction, updateIsValidHubSpotPartnerAction, updateMinimumBillableUsersAction, updatePricePerUserOptionAction } from '../store/HubSpotPartnersSlice'
import { Alert } from '@mui/material'

const totalSteps = 3

function cancelCreation (history) {
  history.goBack()
}

const CreatePartner = ({ id }) => {
  const [adminFirst, setAdminFirstName] = useState('')
  const [adminLast, setAdminLastName] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [partnerFreeTrial, setPartnerFreeTrial] = useState(false)
  const [showDuplicateAdminAlert, setDuplicateAdminAlert] = useState(false)
  const [hubSpotCompanyId, setHubSpotCompanyId] = useState()

  const { loaders, distributor } = useSelector((state) => state.distributor)
  const { isLoadingAddPartner } = loaders
  const { isValidHubSpotPartner } = useSelector((state) => state.hubSpotPartners)

  const history = useHistory()
  const { user } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateHubSpotPartnerNameAction())
    dispatch(updateMinimumBillableUsersAction())
    dispatch(updatePricePerUserOptionAction())
    dispatch(updateHubSpotPartnerErrorMessageAction())
    dispatch(updateIsValidHubSpotPartnerAction(false))
  }, [])

  function validate () {
    if (!ValidationUtils.isNotWhiteSpace(partnerName)) {
      throw Error('Please input a name for your partner')
    }
    if (!ValidationUtils.isNotWhiteSpace(adminFirst)) {
      throw Error('Please input a first name')
    }
    if (!ValidationUtils.isNotWhiteSpace(adminLast)) {
      throw Error('Please input a last name')
    }
    if (!ValidationUtils.isValidEmail(adminEmail)) {
      throw Error('Please use a valid email')
    }
    if (distributor && distributor.supportsAutomatedBilling && !isValidHubSpotPartner) {
      throw Error('Please enter a valid HubSpot Company ID for this Partner.')
    }
  }

  function validateName () {
    if (!ValidationUtils.isNotWhiteSpace(partnerName)) {
      throw Error('Please input a name for your partner')
    }
  }

  function nextStep (finishCreation) {
    try {
      switch (activeTab) {
        // Getting started
        case (totalSteps - 1):
          finishCreation()
          break
        case 1:
          validateName()
          setActiveTab(activeTab + 1)
          break
        default:
          setActiveTab(activeTab + 1)
          break
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  async function finishCreation () {
    try {
      validate()

      const { success, status } = await dispatch(addPartnerThunk(
        {
          id,
          adminFirst,
          adminLast,
          adminEmail,
          partnerName,
          partnerFreeTrial,
          authId: user.sub,
          history,
          hubSpotCompanyId
        }
      ))

      if (!success && status === 409) {
        setDuplicateAdminAlert(true)
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  const renderStepper = () => {
    const stepsArray = [
      {
        tab: 0,
        label: 'Account Status',
        icon: IoCashOutline
      },
      {
        tab: 1,
        label: 'Partner Info',
        icon: IoPencil
      },
      {
        tab: 2,
        label: 'Admin Information',
        icon: IoPersonAdd
      }]
    return (<div className='pb-4'><SharedStepper steps={stepsArray} activeTab={activeTab} setActiveTab={setActiveTab} /></div>)
  }

  function handleUpdateAdminEmail (email) {
    setAdminEmail(email)

    if (showDuplicateAdminAlert) {
      setDuplicateAdminAlert(false)
    }
  }

  return (
    <MDBContainer>
      <NotificationWrapper />
      <MDBRow>
        <MDBCol className='d-flex flex-column flex-center mb-3'>
          <h1 id='partner-page-heading'>Create A New Partner</h1>
        </MDBCol>
      </MDBRow>

      {isLoadingAddPartner && (
        <Spinner />
      )}

      {!isLoadingAddPartner && (
        <>

          {renderStepper()}
          {activeTab === 0 &&
            <MDBCard>
              <MDBCardHeader>
                <MDBRow className='d-flex'>
                  <MDBCol className='d-flex justify-content-start align-items-center'>
                    <h5>Free Trial Account</h5>
                  </MDBCol>
                  <MDBCol className='d-flex justify-content-end'>
                    <MDBTooltip
                      placement='left'
                      id='campaignBuilderstep0'
                      material
                    >
                      <MDBBtn
                        color='elegant'
                        className='my-0'
                        floating
                        tag='a'
                        size='sm'
                      >
                        <MDBIcon icon='question' />
                      </MDBBtn>
                      <div>
                        Is this new partner starting a free trial account?
                      </div>
                    </MDBTooltip>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody className='p-3'>
                <MDBRow className='d-flex justify-content-center'>
                  <MDBCol size='4'>
                    <MDBInput
                      id='trialTrue' checked={partnerFreeTrial} type='radio' size='lg' onChange={() => {
                        setPartnerFreeTrial(true)
                      }} name='trial status' label='Yes'
                    />
                    <MDBInput
                      id='trialfalse' checked={!partnerFreeTrial} type='radio' size='lg' onChange={() => {
                        setPartnerFreeTrial(false)
                      }} name='trial status' label='No'
                    />
                  </MDBCol>
                </MDBRow>

              </MDBCardBody>
            </MDBCard>}

          {activeTab === 1 &&
            <MDBCard>
              <MDBCardHeader>
                <MDBRow className='d-flex'>
                  <MDBCol className='d-flex justify-content-start align-items-center'>
                    <h5>Name</h5>
                  </MDBCol>
                  <MDBCol className='d-flex justify-content-end'>
                    <MDBTooltip
                      placement='left'
                      id='campaignBuilderstep0'
                      material
                    >
                      <MDBBtn
                        color='elegant'
                        className='my-0'
                        floating
                        tag='a'
                        size='sm'
                      >
                        <MDBIcon icon='question' />
                      </MDBBtn>
                      <div>
                        Give this new client a name to identify it
                      </div>
                    </MDBTooltip>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody className='p-3'>
                <MDBRow className='d-flex justify-content-center'>
                  <MDBCol size='4'>
                    <MDBInput
                      outline value={partnerName} id='new-partner-name' size='lg' onChange={(e) => {
                        setPartnerName(e.target.value)
                      }} name='partner name' label='Partner Name'
                    />
                  </MDBCol>
                </MDBRow>

                {(distributor && distributor.supportsAutomatedBilling) && (
                  <HubSpotPartnerSelector
                    hubSpotCompanyId={hubSpotCompanyId}
                    setHubSpotCompanyId={setHubSpotCompanyId}
                    distributorId={id}
                  />
                )}

              </MDBCardBody>
            </MDBCard>}
          {activeTab === 2 &&
            <MDBCard>
              <MDBCardHeader>
                <MDBRow className='d-flex'>
                  <MDBCol className='d-flex justify-content-start align-items-center'>
                    <h5>Add An Administrator</h5>
                  </MDBCol>
                  <MDBCol className='d-flex justify-content-end'>
                  </MDBCol>
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody className='p-3'>

                {showDuplicateAdminAlert &&
                  <MDBRow center>
                    <MDBCol size='10'>
                      <MDBAlert color='warning'>
                        <MDBRow>
                          <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
                            <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='exclamation-triangle' />
                          </MDBCol>
                          <MDBCol className='d-flex align-items-center'>
                            An admin with the email {adminEmail} already exists in another partner. Please use a different email or contact Phin Support (support@phinsecurity.com) for assistance.
                          </MDBCol>
                        </MDBRow>
                      </MDBAlert>
                    </MDBCol>
                  </MDBRow>}
                  <Alert severity="info">Give this partner a new initial admin. This will be the account used to sign in and will allow adding other users. This will also enable the admin to have permissions to view billing details and give others permissions for billing access.</Alert>
                <MDBRow center>
                  <MDBCol size='4'>
                    <MDBInput
                      outline value={adminFirst} id='new-admin-first-input' size='lg' onChange={(e) => {
                        setAdminFirstName(e.target.value)
                      }} name='admin first name' label='Admin First Name'
                    />
                  </MDBCol>
                  <MDBCol size='4'>
                    <MDBInput
                      outline value={adminLast} id='new-admin-last-input' size='lg' onChange={(e) => {
                        setAdminLastName(e.target.value)
                      }} name='admin last name' label='Admin Last Name'
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow className='d-flex justify-content-center'>
                  <MDBCol size='4'>
                    <MDBInput
                      outline value={adminEmail} id='new-admin-email-input' size='lg' onChange={(e) => {
                        handleUpdateAdminEmail(e.target.value)
                      }} name='admin email' label='Admin Email'
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>}
          <MDBBtn
            id='cancel-partner-creation-button'
            aria-label='Cancel Partner Creation Button'
            className='mt-4'
            color='red'
            onClick={() => cancelCreation(history)}
          >Cancel
          </MDBBtn>
          {activeTab > 0 &&
            <MDBBtn
              id='previous-button'
              aria-label='Previous Button'
              className='mt-4'
              onClick={() => setActiveTab(activeTab - 1)}
              color='secondary'
            >Previous
            </MDBBtn>}
          <MDBBtn
            className='mt-4'
            id='create-partner-next-button'
            aria-label='Next/Finish Button'
            onClick={() => nextStep(finishCreation)}
            color='primary'
          >{activeTab < totalSteps - 1 ? 'Next' : 'Finish'}
          </MDBBtn>
        </>)}
    </MDBContainer>
  )
}

export default CreatePartner
