import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

export const AudienceDetailsTable = ({ audience, audienceColumns }) => {
  return (
    <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
      <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Audience</h3>
      <DataGrid
        autoHeight
        style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
        className='DataGrid'
        initialState={{
          sorting: {
            sortModel: [{ field: 'creationDate', sort: 'desc' }]
          }
        }}
        rows={audience?.users || []}
        columns={audienceColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 250, id: 'audience-details-table-search', ariaLabel: 'Search' },
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true }
          }
        }}
        disableColumnSelector
        disableDensitySelector
      />
    </Box>
  )
}
