import {
  MDBCol, MDBContainer, MDBBtn, MDBRow, MDBCard, MDBIcon, MDBCardBody, MDBInput, MDBCardHeader, MDBTooltip
} from 'mdbreact'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ValidationUtils from '../utils/validationUtils'
import CampaignTypeSelector from './components/Campaigns/CampaignTypeSelector'
import NotificationUtilities from './components/notifications/notificationUtils'
import NotificationWrapper from './components/notifications/notificationWrapper'

import { navigationRoutes } from '../frontendConsts.js'
import { resetAllCampaignFrequencyChoicesAction } from '../store/CampaignFrequencySlice'
import { useDispatch } from 'react-redux'

function CampaignCreator ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [campaignType, setCampaignType] = useState('')

  useEffect(() => {
    document.title = 'Phin Security | Campaign Creator'
    window.scrollTo(0, 0)
  }, [])

  async function bundleCampaign () {
    // Validate inputs
    if (!ValidationUtils.isNotWhiteSpace(name)) {
      NotificationUtilities.sendWarningMessage('Campaign must have a name')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(campaignType)) {
      NotificationUtilities.sendWarningMessage('Campaign must have a type')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(description)) {
      NotificationUtilities.sendWarningMessage('Campaign must have a description')
      return
    }

    await dispatch(resetAllCampaignFrequencyChoicesAction())

    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNEDITOR}`, { campaignName: name, campaignType, campaignDescription: description })
  }

  return (
    <MDBContainer>
      <NotificationWrapper />
      <MDBRow>
        <h1>Campaign Creator</h1>
        <p>Here we'll set up the basic information of the campaign as well as it's type. Then we'll move to the editor to finish creation! Hover over the question bubbles for
          specific information about each step.
        </p>
      </MDBRow>
      <hr />
      <MDBCard className='w-100 h-100'>
        <MDBCardHeader>
          <MDBRow className='d-flex'>
            <MDBCol className='d-flex justify-content-start align-items-center'>
              <h5>Campaign Info</h5>
            </MDBCol>
            <MDBCol className='d-flex justify-content-end'>
              <MDBTooltip
                placement='left'
                id='description-popover'
                material
              >
                <MDBBtn
                  color='elegant'
                  className='my-0'
                  floating
                  tag='a'
                  size='sm'
                >
                  <MDBIcon icon='question' />
                </MDBBtn>
                <div>
                  Here you can add a name for this campaign. This is how we will identify it later on. You also can add a description to better describe this campaign.
                </div>
              </MDBTooltip>
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody>
          <div className='form-group w-100'>
            <MDBInput
              outline
              value={name}
              id='campaign-name-field'
              size='lg'
              className='w-100 h-100'
              onChange={(e) => { setName(e.target.value) }}
              name='campName'
              label='Campaign Name'
            />
          </div>
          <MDBRow className='d-flex justify-content-center'>
            <MDBCol>
              <MDBInput
                type='textarea'
                label='Campaign Description'
                rows='5'
                outline
                value={description}
                id='campaign-description-field'
                onChange={(e) => { setDescription(e.target.value) }}
                name='campDesc'
              />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <hr />
      <CampaignTypeSelector campaignType={campaignType} setCampaignType={setCampaignType} />
      <hr />
      <MDBRow className='d-flex justify-content-end align-items-center'>
        <MDBBtn onClick={() => { history.goBack() }} color='danger'>Cancel</MDBBtn>
        <MDBBtn onClick={bundleCampaign} color='primary'>Next</MDBBtn>
      </MDBRow>
    </MDBContainer>
  )
}

export default CampaignCreator
