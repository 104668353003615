import React, { useRef, useState } from 'react'
import { useMountEffect } from '../../../utils/SettingsUtils'
import ErrorPanel from '../errorPanel'
import { shareLevels } from '../../../frontendConsts.js'

function ImageSettings ({
  logoURL,
  logoShareLevel,
  handleLogoImageChange,
  shouldFocusImageSettings
}) {
  const [logoFileName, setLogoFileName] = useState('No File Selected')
  const imageRef = useRef(null)
  const executeScroll = () => {
    if (shouldFocusImageSettings) {
      imageRef.current.scrollIntoView()
    }
  }

  useMountEffect(executeScroll)

  const logoUpload = (logo) => {
    handleLogoImageChange(logo)
    const [file] = logo
    // Get the file name and size
    const { name: fileName, size } = file
    // Convert size in bytes to kilo bytes
    let fileSuffix = 'KB'
    let fileSize = (size / 1000).toFixed(2)
    if (fileSize > 1000) {
      fileSize = (fileSize / 1000).toFixed(2)
      fileSuffix = 'MB'
    }
    // Set the text content
    const fileNameAndSize = `${fileName} - ${fileSize}${fileSuffix}`
    setLogoFileName(fileNameAndSize)
  }

  return (
    <div className='pageContainer' ref={imageRef}>
      <div id='logoEditCard'>
        <div>
          <p className='phinCardHeader'>Logo Customization</p>
          <p>
            Set an image to be this company's logo.
            The logo will be used on all communications for this
            company besides phishing emails and will appear
            on the Learning Management System.
            <strong>
                  &nbsp; Logo uploader supports PNG and JPG only. Updates to the logo can take up to an hour to appear.
            </strong>
          </p>
          <div className='pl-2' style={{ zIndex: 10 }} />
        </div>
        <div className='logoCardContent'>
          {logoShareLevel === shareLevels.PREMIUM && (
            <>
              <ErrorPanel color='warning' error='This is our default logo. If you upload your own it will be shown here. Note that it may take up to an hour for your logo to be displayed.' />
            </>
          )}
          <div style={{ height: '400px' }} className='logoDisplayGroup'>
            <div className='bg-white h-100 w-100 d-flex justify-content-center align-items-center logoDisplayRadiusBlack'>
              <img
                id='logo-white-back-image'
                aria-label='Logo White Background Image'
                style={{ maxHeight: '25%', maxWidth: '25%' }}
                src={logoURL}
              />
            </div>
            <div className='bg-dark h-100 w-100 d-flex justify-content-center align-items-center logoDisplayRadiusWhite'>
              <img style={{ maxHeight: '25%', maxWidth: '25%' }} src={logoURL} />
            </div>

          </div>
          <div className='w-100'>
            <input
              id='logo-upload-button'
              aria-label='Logo Upload Button'
              type='file'
              placeholder='Upload your file'
              className='file'
              onChange={(e) => logoUpload(e.target.files)}
            />
            <label className='logoUpload' for='logo-upload-button'>
              <span className='saveContentsBtn p-3'>Upload</span>
              <p className='textInputBox'>{logoFileName}</p>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageSettings
