import {
  MDBSideNavLink,
  MDBIcon,
  MDBSideNavNav
} from 'mdbreact'

function DistributorNavRoutes ({ id }) {
  return (
    <MDBSideNavNav>
      <MDBSideNavLink
        to={`/distributors/${id}`}
        id='distributorsPageNavLink'
        className='nav-icon'
      >
        <MDBIcon fixed icon='handshake' className='mr-2' />
        Partner Overview
      </MDBSideNavLink>

    </MDBSideNavNav>
  )
}

export default DistributorNavRoutes
