import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBRow,
  MDBCol
} from 'mdbreact'
import './css/integrationCard.css'
import { integrationStatuses, integrationTypes, partnerIntegrationStatuses } from '../frontendConsts.js'
import { useSelector } from 'react-redux'
import Spinner from './components/Spinner'

const integrationsCatalog = {
  phinApi: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Phin API',
    provider: 'Phin',
    integrationType: integrationTypes.PHIN_API,
    id: integrationTypes.PHIN_API,
    description: 'Enable the Phin API. Use the API Client ID and Client Secret to make requests for partner and company specific data from an external application.',
    logo: '/logoBlueNoTextSmall.png'
  },
  gradient: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Gradient MSP',
    provider: 'Gradient',
    integrationType: integrationTypes.GRADIENT,
    id: integrationTypes.GRADIENT,
    description: 'Enable the Gradient MSP - Phin integration. Automatically send usage data for your clients\' companies directly to Gradient MSP.',
    logo: '/gradientLogoGSmall.png'
  }
}

export function PartnerIntegrationsPage ({ id }) {
  const history = useHistory()
  const { partnerIntegrationsMap, loaders } = useSelector((state) => state.partnerIntegrations)
  const { isLoadingPartnerIntegrations } = loaders

  const [integrations, setIntegrations] = useState(integrationsCatalog)

  async function validatePartnerIntegrations () {
    const updatedIntegrationsMap = { ...integrations }

    for (const partnerIntegration of Object.values(updatedIntegrationsMap)) {
      if (partnerIntegrationsMap && partnerIntegrationsMap[partnerIntegration.id]) {
        partnerIntegration.integrationStatus = partnerIntegrationsMap[partnerIntegration.id].integrationStatus
      } else {
        partnerIntegration.integrationStatus = integrationStatuses.NOT_CONNECTED
      }
    }

    setIntegrations(updatedIntegrationsMap)
  }

  useEffect(() => {
    validatePartnerIntegrations()
  }, [partnerIntegrationsMap])

  const generateStatus = (integrationStatus, integrationId) => {
    if (integrationStatus === partnerIntegrationStatuses.ENABLED) {
      return <p className='statusBadge connected'>connected</p>
    } else {
      return <p className='statusBadge connect'>connect</p>
    }
  }

  const generateOnClickFunction = (integrationId, integrationType) => {
    return () => history.push(`integrations/${integrationId}`)
  }

  function generateIntegrationCard ({ key, integrationId, name, integrationStatus, integrationType, description, logo }) {
    const onclickFunction = generateOnClickFunction(integrationId, integrationType)
    return (
      <a key={key} onClick={onclickFunction}>
        <div className='interCardPadding'>
          <div
            id={`${name}-integration-card`}
            aria-label={`${name} Integration Card`}
            className='cardBody'
          >
            <div className='cardContents'>
              <div className='cardInfoQuick'>
                <img src={logo} className='cardLogo' />
                <p className='cardStatus'>{generateStatus(integrationStatus, integrationId)}</p>
              </div>
              <div className='cardTitle'>{name}</div>
              <p className='cardDesc'>{description}</p>
            </div>
          </div>
        </div>
      </a>
    )
  }

  function generateIntegrationsList () {
    return Object.entries(integrations)
      .map(([id, integration], i) => {
        let { name, integrationStatus, description, logo } = integration
        if (!name) {
          name = 'Unknown Integration'
          integrationStatus = integrationStatuses.CONNECTED
        }
        return (
          generateIntegrationCard({
            key: i,
            name,
            integrationId: integration.id,
            integrationStatus,
            integrationType: integration.integrationType,
            description,
            logo
          })
        )
      })
  }

  return (
    <div>
      <MDBRow className=''>
        <MDBCol className=''>
          <div className='phin-h2 screen-pad'>Phin Integrations</div>
        </MDBCol>
      </MDBRow>

      <MDBRow className='d-flex justify-content-start align-items-center screen-pad'>
        {(!partnerIntegrationsMap || isLoadingPartnerIntegrations) && (
          <Spinner message='Loading Partner Integrations' />
        )}

        {(partnerIntegrationsMap && !isLoadingPartnerIntegrations) && (
          generateIntegrationsList()
        )}
      </MDBRow>
    </div>
  )
}
