import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { updateSendRecordAction } from './AnalyticsSlice'

export const sendRecordSlice = createSlice({
  name: 'sendRecords',
  initialState: {
    loaders: {
      isLoadingSendRecord: false
    }
  },
  reducers: {
    setIsLoadingSendRecord: (state, action) => {
      state.loaders.isLoadingSendRecord = action.payload
    }
  }
})

export const {
  setIsLoadingSendRecord: setIsLoadingSendRecordAction
} = sendRecordSlice.actions

export default sendRecordSlice.reducer

export function markSendRecordFalsePositiveThunk ({ companyId, sendRecordId, sendRecord }) {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingSendRecordAction(true))
    try {
      const res = await api.put(`/api/companies/${companyId}/sendRecords/${sendRecordId}/mark-false-positive`,
        { sendRecord })

      if (res.status === 200) {
        const { updatedSendRecord } = await res.json()
        dispatch(updateSendRecordAction(updatedSendRecord))
        dispatch(setIsLoadingSendRecordAction(false))
        return true
      } else {
        NotificationUtilities.sendErrorMessage('Failed to mark record as a False Positive. Please try again or contact Phin Support if issue persists.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to mark record as a False Positive. Please try again or contact Phin Support if issue persists.')
    }
    dispatch(setIsLoadingSendRecordAction(false))
    return false
  }
}
