import {
  MDBContainer, MDBRow, MDBBtn
} from 'mdbreact'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationWrapper from './components/notifications/notificationWrapper'
import ConfigQuestion from './components/resourceConfig/ConfigQuestion'
import NotificationUtilities from './components/notifications/notificationUtils'
import ContinuousCampaignViewer from './components/partnersPage/ContinuousCampaignViewer'
import ContinuousCampaignLauncher from './components/partnersPage/ContinuousCampaignLauncher'
import { SharedStepper } from '../shared/components/SharedStepper'
import { IoBook, IoHelp, IoTime } from 'react-icons/io5'

import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { getConfigQuestionsThunk, updateOnboardingChecklistDataThunk } from '../store/CompanySlice'
import { createContinuousCampaignsThunk, getActiveCampaignsThunk, getBaseTrainingTopicsForCampaignThunk } from '../store/CampaignsSlice'
import { Step } from '@mui/material'

function ClientAutoConfig ({ companyId }) {
  const dispatch = useDispatch()
  const { company, configQuestions, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany, isLoadingConfigQuestions } = loaders
  const { activeCampaigns, baseTrainingCampaignTopics, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingActiveCampaigns, isLoadingCreateContinuousCampaigns } = campaignLoaders

  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)
  const phishingDescription = 'Phin generated continuous phishing campaign'
  const trainingDescription = 'Phin generated continuous training campaign'

  const [phishingCampaignName, setPhishingCampaignName] = useState('')
  const [trainingCampaignName, setTrainingCampaignName] = useState('')
  const [phishingStartDate, setPhishingStartDate] = useState(new Moment().add(1, 'days'))
  const [phishingCategories, setPhishingCategories] = useState(['notification', 'current event', 'healthcare', 'unsubscribe', 'giveaway'])
  const [trainingStartDate, setTrainingStartDate] = useState(new Moment().add(1, 'days'))
  const [trainingTopics, setTrainingTopics] = useState([])
  const [trainingStartTimeHours, setTrainingStartTimeHours] = useState(12)
  const [trainingStartTimeMinutes, setTrainingStartTimeMinutes] = useState(0)
  const [questionsCollection, setQuestionsCollection] = useState([])

  useEffect(() => {
    dispatch(getConfigQuestionsThunk(companyId))
    dispatch(getBaseTrainingTopicsForCampaignThunk(companyId))
    if (!activeCampaigns) {
      dispatch(getActiveCampaignsThunk(companyId))
    }
  }, [])

  useEffect(() => {
    if (baseTrainingCampaignTopics.length > 0) {
      setTrainingTopics([...baseTrainingCampaignTopics])
    }
  }, [baseTrainingCampaignTopics])

  useEffect(() => {
    if (company) {
      setPhishingCampaignName(company.name + ' Continuous Phishing Campaign')
      setTrainingCampaignName(company.name + ' Continuous Training Campaign')
    }
  }, [company])

  useEffect(() => {
    if (configQuestions) {
      setQuestionsCollection(JSON.parse(JSON.stringify(configQuestions)))
    }
  }, [configQuestions])

  const NEXT = 'next'
  const PREVIOUS = 'previous'

  const changeTab = (direction) => {
    if (activeTab === (questionsCollection.length - 1) + 2) {
      if (direction === NEXT) {
        submit()
      } else {
        setActiveTab(activeTab - 1)
      }
    } else if (activeTab === 0) {
      if (direction === NEXT) {
        setActiveTab(activeTab + 1)
      } else {
        history.goBack()
      }
    } else {
      if (direction === NEXT) {
        setActiveTab(activeTab + 1)
      } else {
        setActiveTab(activeTab - 1)
      }
    }
  }

  const handleTrainingStartDateChange = (date) => {
    setTrainingStartDate(Moment(date))
  }

  const handlePhishingStartDateChange = (date) => {
    setPhishingStartDate(Moment(date))
  }

  const validateSurvey = () => {
    let isValid = true
    questionsCollection.forEach((question, index) => {
      if (question.answer === undefined) {
        if (isValid) {
          setActiveTab(index)
        }
        isValid = false
      }
    })
    if (!isValid) {
      NotificationUtilities.sendErrorMessage('please fill out all the questions')
    }
    return isValid
  }

  const submit = async () => {
    if (validateSurvey()) {
      const phishingCampaign = {
        campaignType: 'continuous',
        phishingCategories,
        learningTypes: {
          custom: false,
          lm: true,
          video: false
        },
        name: phishingCampaignName,
        description: phishingDescription,
        parameters: [{
          field: 'first',
          type: '!=',
          value: '~~~'
        }],
        audienceFilterType: 'first',
        sendInterval: 'monthly',
        sendingChannels: {
          email: true,
          sms: false
        },
        startTime: Moment(phishingStartDate.format('YYYY-MM-DD')),
        isContinuous: true,
        isAutoGenerated: true
      }

      let minutesString = '' + trainingStartTimeMinutes
      let hoursString = '' + trainingStartTimeHours
      if (minutesString.length === 1) {
        minutesString = '0' + minutesString
      }
      if (hoursString.length === 1) {
        hoursString = '0' + hoursString
      }

      const trainingSendTime = `${hoursString}:${minutesString}`
      const trainingCampaign = {
        name: trainingCampaignName,
        description: trainingDescription,
        curriculum: {
          description: 'Phin generated curriculum',
          name: `${company.name} custom curriculum`,
          modules: []
        },
        startTime: Moment(trainingStartDate.format('YYYY-MM-DD') + ' ' + trainingSendTime, 'YYYY-MM-DD h:ma'),
        audienceFilterType: 'first',
        audienceFilters: [{
          field: 'first',
          type: '!=',
          value: '~~~'
        }],
        campaignType: 'training',
        isContinuous: true,
        trainingTopics,
        isAutoGenerated: true
      }

      await dispatch(createContinuousCampaignsThunk({ companyId, questionsCollection, trainingCampaign, trainingSendTime, phishingCampaign, history }))
      await dispatch(updateOnboardingChecklistDataThunk(companyId, { hasActiveCampaigns: true }))
    }
  }

  const renderStepper = (questionsCollection) => {
    const stepsArray = []
    for (const [index, value] of questionsCollection.entries()) {
      value.tab = index
      value.label = `Question ${index + 1}`
      value.icon = IoHelp
      stepsArray.push(value)
    }

    // adding the steps for continous campaign review

    stepsArray.push({
      tab: questionsCollection.length,
      label: 'Review',
      icon: IoBook
    },
    {
      tab: questionsCollection.length + 1,
      label: 'Scheduling',
      icon: IoTime
    })

    return (<><SharedStepper steps={stepsArray} activeTab={activeTab} setActiveTab={setActiveTab} /></>)
  }

  const addPhishingCategories = (newPhishingCategories) => {
    setPhishingCategories([...new Set(phishingCategories.concat(newPhishingCategories))])
  }

  const removePhishingCategories = (newPhishingCategories) => {
    newPhishingCategories.forEach(category => {
      const index = phishingCategories.indexOf(category)
      if (index !== -1) {
        phishingCategories.splice(index, 1)
      }
    })
    setPhishingCategories(phishingCategories)
  }

  const addTrainingTopic = (trainingTopic) => {
    if (!trainingTopics.includes(trainingTopic)) {
      setTrainingTopics([...trainingTopics, trainingTopic])
    }
  }

  const removeTrainingTopic = (trainingTopic) => {
    const index = trainingTopics.indexOf(trainingTopic)
    if (index !== -1) {
      const newTrainingTopics = [...trainingTopics]
      newTrainingTopics.splice(index, 1)
      setTrainingTopics(newTrainingTopics)
    }
  }

  return (
    <MDBContainer>
      <NotificationWrapper />
      <MDBRow className='d-flex justify-content-center'>
        <h1>Continuous Campaign Creator</h1>
      </MDBRow>

      {(!company || !configQuestions || !activeCampaigns ||
      isLoadingCompany || isLoadingConfigQuestions ||
      isLoadingActiveCampaigns ||
      isLoadingCreateContinuousCampaigns) && (
        <Spinner />
      )}

      {(company && configQuestions && activeCampaigns &&
      !isLoadingCompany && !isLoadingConfigQuestions &&
      !isLoadingActiveCampaigns &&
      !isLoadingCreateContinuousCampaigns) && (
        <>
          <MDBRow className='d-flex justify-content-center pt-5'>
            {renderStepper(questionsCollection)}
            <Step icon='boxes' stepName='Resource Viewer' onClick={() => setActiveTab(questionsCollection.length)} />
            <Step icon='clock' stepName='Campaigns Launcher' onClick={() => setActiveTab(questionsCollection.length + 1)} />
          </MDBRow>

          {(questionsCollection.length !== 0) &&

            <div>
              {(activeTab <= (questionsCollection.length - 1)) &&
                <ConfigQuestion
                  index={activeTab}
                  questionsCollection={questionsCollection}
                  setQuestionsCollection={setQuestionsCollection}
                  addPhishingCategories={addPhishingCategories}
                  removePhishingCategories={removePhishingCategories}
                  addTrainingTopic={addTrainingTopic}
                  removeTrainingTopic={removeTrainingTopic}
                />}

              {(activeTab === questionsCollection.length) &&
                <ContinuousCampaignViewer
                  phishingDescription={phishingDescription}
                  phishingCampaignName={phishingCampaignName}
                  phishingCategories={phishingCategories}
                  trainingCampaignName={trainingCampaignName}
                  trainingDescription={trainingDescription}
                  trainingTopics={trainingTopics}
                />}

              {(activeTab === (questionsCollection.length + 1)) &&
                <ContinuousCampaignLauncher
                  phishingStartDate={phishingStartDate}
                  setPhishingStartDate={handlePhishingStartDateChange}
                  trainingStartDate={trainingStartDate}
                  setTrainingStartDate={handleTrainingStartDateChange}
                  trainingStartTimeHours={trainingStartTimeHours}
                  setTrainingStartTimeHours={setTrainingStartTimeHours}
                  trainingstartTimeMinutes={trainingStartTimeMinutes}
                  setTrainingStartTimeMinutes={setTrainingStartTimeMinutes}
                />}
              <MDBRow className='d-flex justify-content-between align-items-center mt-4'>
                <MDBBtn
                  id='cancel-previous-button'
                  aria-label='Cancel or Previous Button'
                  onClick={() => changeTab(PREVIOUS)}
                  color='danger'
                >{(activeTab === 0) ? 'Cancel' : 'Previous'}
                </MDBBtn>
                <MDBBtn
                  id='next-submit-button'
                  aria-label='Next or Submit Button'
                  onClick={() => changeTab(NEXT)}
                  color='primary'
                >{(activeTab === (questionsCollection.length - 1) + 2) ? 'Submit' : 'Next'}
                </MDBBtn>
              </MDBRow>
            </div>}
        </>
      )}
    </MDBContainer>
  )
}

export default ClientAutoConfig
