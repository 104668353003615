import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React from 'react'
import '.././TrainingAnalytics.css'
import NotificationWrapper from '../../notifications/notificationWrapper'
import { useSelector } from 'react-redux'
import Spinner from '../../Spinner'
import { DateTime } from 'luxon'

export const PhishingClicksTable = ({ companyId }) => {
  const { phishingClicksStats, loaders } = useSelector((state) => state.analytics)

  const columns = [
    { headerName: 'First', field: 'first' },
    { headerName: 'Last', field: 'last' },
    { headerName: 'Email', field: 'userEmailAddress', width: 200 },
    { headerName: 'Id', field: 'userId', width: 200 },
    { headerName: 'Campaign Id', field: 'campaignId', width: 200 },
    { headerName: 'Campaign', field: 'campaignName', width: 200 },
    { headerName: 'Send Time', field: 'sendDateTime', width: 200, renderCell: (field) => { return DateTime.fromSeconds(field.value._seconds).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) } },
    { headerName: 'Template Name', field: 'templateName', width: 200 },
    { headerName: 'Template Subject', field: 'templateSubject', width: 200 },
    { headerName: 'From Address', field: 'fromAddress', width: 200 },
    { headerName: 'From Name', field: 'fromName', width: 200 },
    { headerName: 'Clicked Time', field: 'clickDateTime', width: 200, renderCell: (field) => { return DateTime.fromSeconds(field.value._seconds).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) } },
    { headerName: 'Click IP', field: 'userIp', width: 150 },
    { headerName: 'User Agent', field: 'userAgent', width: 200 }
  ]
  if (loaders.isLoadingPhishingClicksStats) {
    return (
      <Spinner />
    )
  }
  return (
    <>
      <NotificationWrapper />
      <>
        <div style={{ height: '65vh', width: '100%' }} className='p-m-t-1'>
          {/* TODO: Allow users to change the pageSize */}
          <DataGrid
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            className='DataGrid'
            rows={phishingClicksStats || []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      </>
    </>
  )
}
