
import React from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardHeader
} from 'mdbreact'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'

function ResourcesPage ({ props }) {
  const saveToClipBoard = (ip) => {
    navigator.clipboard.writeText(ip).then(() => {
      NotificationUtilities.sendSuccessMessage('Copied!')
    })
  }

  return (
    <MDBContainer>
      <NotificationWrapper />

      <MDBRow className='mb-4 d-flex flex-row justify-content-center'>
        <MDBCard className='w-100' narrow>
          <MDBCardHeader className='view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3 text-center'>
            Docs
          </MDBCardHeader>
          <MDBCardBody cascade>
            <div className='d-flex justify-content-center'>
              <a
                id='phin-knowledge-base-link'
                aria-label='Phin Knowledge Base Link'
                target='_blank'
                href='https://www.phinsec.io/knowledge'
                rel='noreferrer'
              ><h5>Phin Knowledge Base</h5>
              </a>
            </div>
          </MDBCardBody>
          <MDBCardBody cascade>
            <div className='d-flex justify-content-center'>
              <a
                id='learning-moment-customization-pdf-link'
                aria-label='Learning Moment Customization PDF Link'
                target='_blank'
                href='https://pub.phinsec.io/TUTORIAL.pdf'
                rel='noreferrer'
              ><h5>Learning Moment Customization PDF</h5>
              </a>
            </div>
          </MDBCardBody>
          <MDBCardBody cascade>
            <div className='d-flex justify-content-center'>
              <a
                id='google-allowlisting-guide-link'
                aria-label='Google Allowlisting Guide Link'
                target='_blank'
                href='https://docs.google.com/document/d/11AMQmlUDJ24kYfatWiBMyVJ6zyTtJoYaCcPeb_dbm4M'
                rel='noreferrer'
              ><h5>Google Allowlisting Guide</h5>
              </a>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBRow>

      <MDBRow className='mb-4 d-flex flex-row justify-content-center'>
        <MDBCard className='w-100' narrow>
          <MDBCardHeader className='view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3 text-center'>
            IP Allow Listing Guide
          </MDBCardHeader>
          <MDBCardBody cascade>
            <MDBRow className='mb-4 d-flex flex-row justify-content-center align-items-center'>
              <p className='px-3 mb-1'>
                Sending IP:
              </p>
              <p style={{ backgroundColor: '#eeeeee', borderRadius: '.5rem', padding: '0rem .5rem', marginBottom: '.25rem' }}>198.2.177.227</p> <MDBBtn onClick={() => saveToClipBoard('198.2.177.227')} className='blue-gradient mx-4 mb-2'>copy</MDBBtn>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBRow>

      <MDBRow className='mb-4 d-flex flex-row justify-content-center'>
        <MDBCard className='w-100' narrow>
          <MDBCardHeader className='view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3 text-center'>
            <div className='white-text mx-3'>Videos</div>
          </MDBCardHeader>
          <MDBCardBody cascade>
            <div className='d-flex justify-content-center mt-2'>
              <a
                id='phin-youtube-channel-link'
                aria-label='Phin Youtube Channel Link'
                target='_blank'
                href='https://www.youtube.com/channel/UCg3LU45c_egmUZYO43OHMdQ/videos'
                rel='noreferrer'
              ><h5>Phin Youtube Channel</h5>
              </a>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBRow>

    </MDBContainer>
  )
}

export default ResourcesPage
