import { MDBBtn, MDBIcon, MDBTooltip } from 'mdbreact'

export const TrainingTableRow = ({ courseInfo, togglePreviewModal, toggleDetailModal, handleArrayChange, modules, lock, stripe }) => {
  const checkedCourseIds = modules.map(course => course.id)
  return (
    <tr className={(stripe) ? '' : 'stripe'}>
      <th scope='row' style={(lock) ? { pointerEvents: 'none', opacity: '0.6' } : {}}>
        <div className='form-check'>
          <input onChange={() => handleArrayChange(courseInfo)} className='form-check-input' type='checkbox' checked={checkedCourseIds.includes(courseInfo.id)} id={`checkbox-${courseInfo.id}`} />
          <label className='form-check-label' for={`checkbox-${courseInfo.id}`} />
        </div>
      </th>
      <td>{courseInfo.provider}</td>
      <td>{courseInfo.name}</td>
      <td>
        <MDBTooltip style={{ zIndex: '2' }} className='action-button' placement='top'>
          <MDBBtn
            rounded
            size='sm'
            color='blue'
            onClick={() => { toggleDetailModal(courseInfo, true) }}
          >
            <MDBIcon
              size='lg'
              icon='info'
            />
          </MDBBtn>
          <div>
            {`Show additional details for "${courseInfo.name}"`}
          </div>
        </MDBTooltip>
      </td>
      <td>
        <MDBTooltip style={{ zIndex: '2' }} className='action-button' placement='top'>
          <MDBBtn
            rounded
            type='submit'
            size='sm'
            color='default'
            onClick={() => { togglePreviewModal(courseInfo, true) }}
          >
            <MDBIcon
              size='lg'
              color='blue'
              far
              icon='eye'
            />
          </MDBBtn>
          <div>
            Preview Training Module
          </div>
        </MDBTooltip>
      </td>
    </tr>
  )
}
