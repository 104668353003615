import { roleTypes } from '../frontendConsts.js'
import { readLocalRole, writeLocalRole } from '../utils/localStorageUtils'

function getFirstRole (roles, roleType) {
  const roleArray = Object.entries(roles || {})
  if (roleArray.length > 0) {
    const [id, role] = roleArray[0]
    return { role, id, roleType }
  }
  return null
}

// This will try to get an updated role for the same client or
// will get a new role from the map if an existing roleObj doesn't exist
export const getRoleFromMap = (loginMapData, roleObj) => {
  const roles = loginMapData.roles
  // if there is an existing role, check if there is simply an update to the role
  if (roleObj !== null && roleObj !== undefined) {
    if (loginMapData[roleObj.roleType] &&
      loginMapData[roleObj.roleType][roleObj.id] !== undefined) {
      return {
        roleType: roleObj.roleType,
        id: roleObj.id,
        role: loginMapData[roleObj.roleType][roleObj.id]
      }
    }
  }
  // other wise we get whatever is first in the list
  const firstDistributor = getFirstRole(roles.distributors, 'distributors')
  const firstPartner = getFirstRole(roles.partners, 'partners')
  const firstCompany = getFirstRole(roles.companies, 'companies')
  if (firstDistributor !== null) {
    return firstDistributor
  } else if (firstPartner !== null) {
    return firstPartner
  } else if (firstCompany !== null) {
    return firstCompany
  } else {
    // no access
    return null
  }
}

function getPartnerRoleByID (id, loginMapData) {
  return loginMapData[roleTypes.PARTNER][id]
}

function getClientRoleByID (id, loginMapData) {
  return loginMapData[roleTypes.COMPANY][id]
}

function getDistributorRoleByID (id, loginMapData) {
  return loginMapData[roleTypes.DISTRIBUTOR][id]
}

function hasRole (roleObj, loginMapData) {
  return (loginMapData.roles &&
    loginMapData.roles[roleObj.roleType] &&
    loginMapData.roles[roleObj.roleType][roleObj.id] &&
    loginMapData.roles[roleObj.roleType][roleObj.id] === roleObj.role)
}

export const switchCredentialsToPartnerRole = (id, roles) => {
  const role = getPartnerRoleByID(id, roles)
  const roleObj = { role, id, roleType: roleTypes.PARTNER }
  writeLocalRole(roleObj)
  return roleObj
}

export const switchCredentialsToCompanyRole = (id, roles) => {
  const role = getClientRoleByID(id, roles)
  const roleObj = { role, id, roleType: roleTypes.COMPANY }
  writeLocalRole(roleObj)
  return roleObj
}

export const switchCredentialsToDistributorRole = (id, roles) => {
  const role = getDistributorRoleByID(id, roles)
  const roleObj = { role, id, roleType: roleTypes.DISTRIBUTOR }
  writeLocalRole(roleObj)
  return roleObj
}

export const getCurrentFocusedRole = (loginMapData) => {
  let roleObj = readLocalRole()
  if (roleObj !== null) {
    // there is already a cached role
    if (hasRole(roleObj, loginMapData)) {
      // this role still exists on the user
      return roleObj
    }
  }
  // if the user didn't have a role cached or if the role is no
  // longer available
  roleObj = getRoleFromMap(loginMapData, roleObj)
  if (roleObj !== null) {
    // a role was found
    writeLocalRole(roleObj)
    return roleObj
  } else {
    // the user has access to no roles
    return null
  }
}

export const getBaseRole = (loginMapData) => {
  return getRoleFromMap(loginMapData)
}
