import React from 'react'
import {
  MDBSpinner,
  MDBBtn,
  MDBIcon
} from 'mdbreact'

const DataExportButtons = ({ exporting, downloadOrgData }) => {
  let exportButtonContent
  if (exporting) {
    exportButtonContent = (<MDBSpinner multicolor />)
  } else {
    exportButtonContent = (
      <MDBBtn
        floating
        size='lg'
        color='blue'
        icon='file-download'
        title='Download CSV based on selected filters'
        onClick={downloadOrgData}
      >
        <MDBIcon size='2x' icon='file-download' />
      </MDBBtn>
    )
  }
  return (
    <div className='mb-5 mr-5 phin-fixed-bottom-right'>
      {exportButtonContent}
    </div>
  )
}

export default DataExportButtons
