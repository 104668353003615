import { useEffect, useState } from 'react'
import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSelect
} from 'mdbreact'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../Spinner'
import { enrollableUsersUpdatedAction, enrollUsersThunk, getEnrollableUsersThunk } from '../../../store/TrainingSlice'
import NotificationUtilities from '../notifications/notificationUtils'
import ValidationModal from '../ValidationModal'

export function EnrollUserModal ({
  isOpen,
  companyId, selectedCourse,
  toggleEnrollUserModal
}) {
  const dispatch = useDispatch()
  const { enrollableUsers, loaders } = useSelector((state) => state.trainings)
  const { isLoadingGetEnrollableUsers } = loaders

  const [userOptions, setUserOptions] = useState([])
  const [selectedUsers, selectUsers] = useState()
  const [disableComponent, setDisableComponent] = useState(false)
  const [isConfirmModalOpen, setisConfirmModalOpen] = useState(false)
  const [shouldSendReminders, toggleSendReminders] = useState(false)

  useEffect(() => {
    const getEnrollableUsers = async () => {
      const apiSucceeded = await dispatch(getEnrollableUsersThunk({ companyId, courseId: selectedCourse.id }))
      if (!apiSucceeded) {
        toggleEnrollUserModal({ isOpen: false, course: null })
      }
      return (
        () => {
          dispatch(enrollableUsersUpdatedAction(null))
        }
      )
    }

    getEnrollableUsers()
  }, [])

  useEffect(() => {
    if (enrollableUsers && Array.isArray(enrollableUsers)) {
      const enrollableUserOptions = []
      for (const enrollableUser of enrollableUsers) {
        enrollableUserOptions.push({
          text: `${enrollableUser.first} ${enrollableUser.last}`,
          value: enrollableUser
        })
      }
      setUserOptions(enrollableUserOptions)
    }
  }, [enrollableUsers])

  const toggleConfirmationModal = () => {
    if (isConfirmModalOpen) {
      setDisableComponent(false)
    } else {
      setDisableComponent(true)
    }
    setisConfirmModalOpen(!isConfirmModalOpen)
  }

  const submitEnrollUser = () => {
    if (selectedUsers && selectedUsers.length > 0) {
      toggleConfirmationModal()
    } else {
      NotificationUtilities.sendWarningMessage('Please select at least 1 user to enroll before submitting.')
    }
  }

  const confirmCourseEnrollment = async () => {
    setisConfirmModalOpen(!isConfirmModalOpen)
    const wereUsersEnrolled = await dispatch(enrollUsersThunk({ companyId, courseId: selectedCourse.id, selectedUsers, shouldSendReminders }))

    setDisableComponent(false)
    if (wereUsersEnrolled) {
      toggleEnrollUserModal({ isOpen: false, course: null })
    }
  }

  const renderSelectedUsersNames = () => {
    const selectedUserNames = []

    if (selectedUsers) {
      for (const selectedUser of selectedUsers) {
        selectedUserNames.push(
          <li>{selectedUser.first} {selectedUser.last}</li>
        )
      }
    }

    return selectedUserNames
  }

  return (
    <>

      <MDBModal
        isOpen={isOpen}
        toggle={() => toggleEnrollUserModal({ isOpen: false, course: null })}
        disabled={disableComponent}
      >
        <MDBModalHeader>Enroll User in Course</MDBModalHeader>
        <MDBModalBody>
          <>
            {(isLoadingGetEnrollableUsers || !enrollableUsers) && (
              <Spinner />
            )}

            {(!isLoadingGetEnrollableUsers && enrollableUsers) && (
              <>
                <MDBSelect
                  multiple
                  options={userOptions}
                  selected='Select one or more users'
                  selectAll
                  search
                  getValue={selectUsers}
                />
                <MDBInput
                  label='Send Training Notification Now'
                  type='checkbox'
                  id='shouldSendReminders'
                  getValue={toggleSendReminders}
                />
              </>
            )}
          </>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color='red'
            onClick={() => toggleEnrollUserModal({ isOpen: false, course: null })}
            disabled={disableComponent}
          >
            Cancel
          </MDBBtn>
          <MDBBtn
            disabled={disableComponent}
            onClick={() => {
              submitEnrollUser()
            }}
            color='primary'
          >
            Enroll
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <ValidationModal
        isOpen={isConfirmModalOpen}
        toggleFunction={() => toggleConfirmationModal()}
        handlerFunction={() => confirmCourseEnrollment()}
        messageJSX={
          <div style={{ textAlign: 'left' }}>
            <p>You are about to enroll {selectedUsers ? selectedUsers.length : 0} {selectedUsers && selectedUsers.length > 1 ? 'users' : 'user'} in this course: '{selectedCourse.name}'. </p>
            {shouldSendReminders && (
              <p>Reminder emails will be sent immediately.</p>
            )}
            <p>{selectedUsers && selectedUsers.length > 1 ? 'These users' : 'This user'} will be enrolled:
              <ul>
                {renderSelectedUsersNames()}
              </ul>
            </p>
            <p> Are you sure you would like to do this?</p>
          </div>
                    }
        falseOptionText='Cancel'
        trueOptionText={`Enroll ${selectedUsers && selectedUsers.length > 1 ? 'Users' : 'User'}`}
        falseOptionColor='red'
        trueOptionColor='primary'
      />
    </>
  )
}
