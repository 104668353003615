import React, { Component } from 'react'
import TemplatesPreviewer from './TemplatesPreviewer'
import {
  MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCardHeader,
  MDBIcon, MDBCol, MDBDataTable, MDBTooltip
} from 'mdbreact'
import CampaignSendingChannelEditor from './CampaignSendingChannelEditor'
import CampaignLearningTypesEditor from './CampaignLearningTypesEditor'
import ActionButton from './ActionButton'

class CampaignTemplateCategoryEditor extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      previewModal: false,
      previewTemplates: []
    }
    this.state = this.initialState

    // The callback method where all the parameter updates will be sent
    this.editCallback = this.props.editCallback
    this.handleCategorySelectAll = this.handleCategorySelectAll.bind(this)
    this.handleCategoryRemoveAll = this.handleCategoryRemoveAll.bind(this)
    this.handleCategorySelection = this.handleCategorySelection.bind(this)
    this.handleCategoryRemoval = this.handleCategoryRemoval.bind(this)
    this.closePreviewModal = this.closePreviewModal.bind(this)
  }

  closePreviewModal () {
    const previewModal = this.state.previewModal
    this.setState({ previewModal: !previewModal, previewTemplates: [] })
  }

  generateCategoryOption (category, selectedCampaignCategories) {
    if (selectedCampaignCategories.includes(category)) {
      return ({
        category,
        action: (
          <MDBRow>
            <ActionButton title='Remove' color='success' icon='check' onClick={() => { this.handleCategoryRemoval(category) }} />
            <ActionButton title='View Templates' outline onClick={() => { this.previewTemplates(category) }} icon='eye' />
          </MDBRow>
        )
      })
    } else {
      return ({
        category,
        action: (
          <MDBRow>
            <ActionButton title='Add' color='info' onClick={() => { this.handleCategorySelection(category) }} icon='plus' />
            <ActionButton title='View Templates' outline onClick={() => { this.previewTemplates(category) }} icon='eye' />
          </MDBRow>
        )
      })
    }
  }

  generateCategoryOptions (campaignCategories, selectedCampaignCategories) {
    const campaignCategoryOptions = []

    for (let i = 0; i < campaignCategories.length; i++) {
      const category = campaignCategories[i]

      campaignCategoryOptions.push(this.generateCategoryOption(category, selectedCampaignCategories))
    }
    return campaignCategoryOptions
  }

  previewTemplates (category) {
    const templates = [...this.props.defaultTemplates, ...this.props.userTemplates, ...this.props.sharedTemplates]
    const previewTemplates = []

    templates.forEach((template) => {
      if (template.tags !== undefined && template.tags.includes(category)) {
        previewTemplates.push(template)
      }
    })

    this.setState({ previewTemplates, previewModal: true })
  }

  previewTemplate (template) {
    if (template.channel === 'email') {
      const win = window.open('')
      win.document.body.innerHTML = template.html
      win.document.title = template.name
    } else {
      this.setState({ previewModal: true, previewTemplate: template })
    }
  }

  /**
  * Loops through all the category options, selects them and updates state accordingly
  */
  handleCategorySelectAll () {
    this.editCallback(this.props.campaignCategories)
  }

  /**
     * Loops through all the category options, deselects them and updates state accordingly
     */
  handleCategoryRemoveAll () {
    this.editCallback([])
  }

  /**
     * Adds the specified category to the list of those selected for the campaigns
     *
     * @param {number} index The index of the selected item in the campaignC list
     */
  handleCategorySelection (category) {
    this.editCallback([...this.props.selectedCampaignCategories, category])
  }

  /**
     * Removes the specified template from the ones selected for this campaign
     *
     * @param {number} index The index of the removed item in the campaignTemplates list
     */
  handleCategoryRemoval (category) {
    this.editCallback([...this.props.selectedCampaignCategories.filter(selectedCategory => selectedCategory !== category)])
  }

  render () {
    const { campaignCategories, selectedCampaignCategories, sendingChannels, learningTypes } = this.props

    const categorySelectionColumns = [
      {
        label: 'Category',
        field: 'category',
        sort: 'asc'
      },
      {
        label: 'Actions',
        field: 'action'
      }
    ]

    const categorySelectionData = {
      columns: categorySelectionColumns,
      rows: this.generateCategoryOptions(campaignCategories, selectedCampaignCategories)
    }

    return (
      <>
        <TemplatesPreviewer toggleModal={() => this.closePreviewModal()} isOpen={this.state.previewModal} templates={this.state.previewTemplates} />
        <CampaignSendingChannelEditor disableUpdating={this.props.disableUpdating || false} sendingChannels={sendingChannels} updateSendingChannels={this.props.updateSendingChannels} />
        <hr />
        <CampaignLearningTypesEditor disableUpdating={this.props.disableUpdating || false} learningTypes={learningTypes} toggleLearningTypes={this.props.toggleLearningTypes} />
        <hr />
        <MDBCard>
          <MDBCardHeader>
            <MDBRow className='d-flex'>
              <MDBCol className='d-flex justify-content-start align-items-center'>
                <h5>Template Categories</h5>
              </MDBCol>
              <MDBCol className='d-flex justify-content-end'>
                <MDBTooltip
                  placement='left'
                  id='templates'
                  material
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                  <div>
                    Here you can select the templates sent during the campaign. You can select from any of the Phin Security default templates or any of your own.
                  </div>
                </MDBTooltip>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBRow className='mt-2 d-flex justify-content-center'>
              <MDBBtn onClick={this.handleCategoryRemoveAll} color='red'><MDBIcon icon='times' /> Clear All</MDBBtn>
              <MDBBtn onClick={this.handleCategorySelectAll} color='primary'><MDBIcon icon='check-double' /> Select All</MDBBtn>
            </MDBRow>
            <hr />
            <MDBRow className='d-flex justify-content-center mb-4'>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <MDBDataTable disableRetreatAfterSorting sortable={false} small fixed btn striped data={categorySelectionData} />
              </div>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </>
    )
  }
}

export default CampaignTemplateCategoryEditor
