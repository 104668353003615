import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import SideNav from './pages/components/SideNav'
import TopNav from './pages/topNav.js'
import { MDBContainer } from 'mdbreact'
import { CompanyRouter } from './routers/CompanyRouter'
import { PartnerRouter } from './routers/PartnerRouter'
import { DistributorRouter } from './routers/DistributorRouter'
import { ExternalIntegrationPage } from './pages/components/integrations/ExternalIntegrationPage'
import Spinner from './pages/components/Spinner'
import { useSelector } from 'react-redux'

function RootRouter () {
  const { authorization, isLoadingAuthorization } = useSelector((state) => state.auth)

  let id
  if (authorization) {
    const [routeNothing, routeRoleType, routeRoleId, ...pages] = window.location.pathname.split('/')
    const { roles } = authorization
    if (roles[routeRoleType] && roles[routeRoleType][routeRoleId] !== undefined) {
      id = routeRoleId
    }
  }

  return (
    <>
      {(!authorization || isLoadingAuthorization) &&
      (<Spinner message='Authorizing' />)}

      {(!isLoadingAuthorization && authorization) &&
        <MDBContainer fluid className='mt-2'>
          <TopNav />
          <SideNav
            id={id}
          />
          <Switch>
            <main
              style={{
                margin: '5rem 3% 2rem',
                height: '88vh'
              }}
            >
              <Route
                path='/integrations'
                render={() => (<ExternalIntegrationPage />)}
              />
              <Route
                path='/companies/:id'
                render={() => {
                  return (
                    <CompanyRouter
                      id={id}
                    />
                  )
                }}
              />
              <Route
                path='/partners/:id'
                render={() => (
                  <PartnerRouter
                    id={id}
                  />
                )}
              />
              <Route
                path='/distributors/:id'
                render={() => (
                  <DistributorRouter
                    id={id}
                  />
                )}
              />
            </main>
          </Switch>
        </MDBContainer>}
    </>
  )
}

export default RootRouter
