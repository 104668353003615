import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Box, Grid, Button } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoArrowForwardSharp, IoAdd, IoEllipseSharp, IoCaretUp, IoCaretDown } from 'react-icons/io5'
import { roleTypes, roles } from '../../../frontendConsts.js'
import PhinBadge from '../PhinBadge.js'
import { DateTime } from 'luxon'

function PartnerAdminManagementTable ({ distributor, history }) {
  const { authorization } = useSelector((state) => state.auth)
  const [partnerTableRows, setPartnerTableRows] = useState([])
  const [tablePageSize, setTablePageSize] = useState(10)

  useEffect(() => {
    if (distributor) {
      const partnerRows = []
      distributor.partners.forEach((partner) => {
        const analytic = distributor.partnerAnalytics.find(item => item.id === partner.id)

        // Default these values to zero
        let totalEnrolledUsers = 0
        let changeFromLastMonth = 0
        let percentChangeFromLastMonth = 0
        let absoluteChangeFromLastMonth = 0
        let absolutePercentChangeFromLastMonth = 0

        let isNew = true

        if (analytic) {
          if (analytic.auditCreationTimestamp) {
            const auditCreationDateTime = DateTime.fromSeconds(analytic.auditCreationTimestamp._seconds)

            const now = DateTime.now()

            isNew = auditCreationDateTime.month === now.month && auditCreationDateTime.year === now.year
          }

          if (analytic.totalEnrolledUsers || analytic.totalEnrolledUsers === 0) {
            totalEnrolledUsers = analytic.totalEnrolledUsers

            if (analytic.lastMonthEnrolledUsers || analytic.lastMonthEnrolledUsers === 0) {
              changeFromLastMonth = totalEnrolledUsers - analytic.lastMonthEnrolledUsers
              absoluteChangeFromLastMonth = Math.abs(changeFromLastMonth)

              if (analytic.lastMonthEnrolledUsers !== 0) {
                percentChangeFromLastMonth = changeFromLastMonth / analytic.lastMonthEnrolledUsers
              } else {
                if (analytic.changeFromLastMonth > 0) {
                  percentChangeFromLastMonth = 1
                } else if (analytic.changeFromLastMonth < 0) {
                  percentChangeFromLastMonth = -1
                } else {
                  percentChangeFromLastMonth = 0
                }
              }
              absolutePercentChangeFromLastMonth = Math.abs(percentChangeFromLastMonth)

              isNew = false
            }
          }
        }

        partnerRows.push({
          ...partner,
          ...analytic,
          totalEnrolledUsers,
          changeFromLastMonth,
          absoluteChangeFromLastMonth,
          percentChangeFromLastMonth,
          absolutePercentChangeFromLastMonth,
          isNew
        })
      })
      setPartnerTableRows(partnerRows)
    }
  }, [distributor])

  function hasViewerAccessToPartner (partnerId, userAuthorizationData) {
    // at least viewer access
    return userAuthorizationData.roles[roleTypes.PARTNER][partnerId] === roles.VIEWER || userAuthorizationData.roles[roleTypes.PARTNER][partnerId] === roles.ADMIN
  }

  const simpleGenerateArrowNumber = (value, absoluteValue, isPercentage) => {
    const sign = Math.sign(value)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {((sign === 1) && (<IoCaretUp style={{ color: 'green' }} />))}
        {((sign === -1) && (<IoCaretDown style={{ color: 'red' }} />))}
        {((sign === 0) && '')}
        {isPercentage
          ? Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 }).format(absoluteValue)
          : absoluteValue}

      </Box>
    )
  }

  const partnerTableColumns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.name}{params.row.isNew && (<span style={{ fontSize: '0.5em' }}><PhinBadge displayString='New' icon={<IoEllipseSharp />} color='var(--phin-blue)' /></span>)}
          </>
        )
      }
    },
    {
      headerName: 'Total Enrolled in Campaigns',
      field: 'totalEnrolledUsers',
      flex: 1,
      renderCell: (params) => params.row.totalEnrolledUsers
    },
    {
      headerName: 'Change in Campaign Enrollment',
      field: 'changeFromLastMonth',
      flex: 1,
      valueGetter: (params) => { return params.row.changeFromLastMonth },
      renderCell: (params) => simpleGenerateArrowNumber(params.row.changeFromLastMonth, params.row.absoluteChangeFromLastMonth)
    },
    {
      headerName: 'Percent Change in Campaign Enrollment',
      field: 'percentChangeFromLastMonth',
      flex: 1,
      valueGetter: (params) => { return params.row.percentChangeFromLastMonth },
      renderCell: (params) => simpleGenerateArrowNumber(params.row.percentChangeFromLastMonth, params.row.absolutePercentChangeFromLastMonth, true)
    },
    {
      headerName: '',
      field: 'id',
      renderCell: (params) => generateViewButton(params.id, params.row.name, authorization, history),
      width: 200,
      sortable: false,
      filterable: false,
      disableExport: true
    }
  ]

  const generateViewButton = (partnerId, partnerName, userAuthorizationData, history) => {
    return (
      <Button
        id={`distributor-dashboard-view-partner-button-${partnerName}`}
        aria-label='View Partner Button'
        disabled={!hasViewerAccessToPartner(partnerId, userAuthorizationData)}
        endIcon={<IoArrowForwardSharp />} onClick={() => { history.push(`/partners/${partnerId}`) }}
      >
        View Partner
      </Button>
    )
  }

  return (
    <Grid container>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <h4 className='phin-h4' style={{ textAlign: 'left' }}>Partners</h4>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            id='add-partner-button'
            aria-label='Add a Partner Button'
            variant='contained'
            startIcon={<IoAdd />}
            onClick={() => {
              history.push(`/distributors/${distributor.distributorId}/create-partner`)
            }}
          >
            ADD A PARTNER
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        <DataGrid
          style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
          className='DataGrid'
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }]
            }
          }}
          autoHeight
          rows={partnerTableRows}
          columns={partnerTableColumns}
          pageSize={tablePageSize}
          onPageSizeChange={(newSize) => setTablePageSize(newSize)}
          rowsPerPageOptions={[10, 25, 50]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                debounceMs: 500,
                id: 'partner-management-table-search',
                ariaLabel: 'Search'
              },
              printOptions: { disableToolbarButton: true },
              csvOptions: {
                fileName: `${distributor.name}_Partners_Breakdown.csv`,
                delimiter: ','
              }
            }
          }}
          disableSelectionOnClick
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          disableColumnMenu
        />
      </div>
    </Grid>
  )
}

export default PartnerAdminManagementTable
