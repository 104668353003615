import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBCol, MDBRow, MDBBtn,
  MDBInput
} from 'mdbreact'

import NotificationUtilities from '../components/notifications/notificationUtils'
import NotificationWrapper from '../components/notifications/notificationWrapper'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import { createPolicyThunk } from '../../store/PoliciesSlice'
import './UploadPolicy.css'
function cancelCreation (history) {
  history.goBack()
}

const UploadPolicy = ({ id }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { policies, policyCatalog, loaders: policyLoaders } = useSelector((state) => state.policies)
  const { isSideNavOpen } = useSelector((state) => state.sideNav)
  const { isLoadingPolicies, isLoadingPolicyCatalog, isLoadingCreatePolicy } = policyLoaders

  const [policyPDF, setPolicyPDF] = useState()
  const [policyName, setPolicyName] = useState()
  const [policyDescription, setPolicyDescription] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [dragActive, setDragActive] = React.useState(false)
  // ref
  const inputRef = React.useRef(null)

  function handleFile (file) {
    setPolicyPDF(file)
  }

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0])
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0])
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click()
  }

  function handlePDFUpload () {
    if (!policyPDF || !policyName) {
      NotificationUtilities.sendErrorMessage('Please Upload a PDF, or Add a Policy Name')
    } else {
      if (policyPDF.type.indexOf('pdf') === -1) {
        NotificationUtilities.sendErrorMessage('Please Upload a PDF')
        setIsSubmitting(false)
        return
      }

      const reader = new FileReader()
      try {
        reader.readAsDataURL(policyPDF)
        reader.onload = async (e) => {
          const formData = new FormData()
          formData.append('policy', policyPDF, policyPDF.name)
          formData.append('name', policyName)
          formData.append('description', policyDescription)

          dispatch(createPolicyThunk({ id, formData, history }))
        }
      } catch (err) {
        console.log(err)
        NotificationUtilities.sendErrorMessage('There was an error reading your file, please try again later.')
        setIsSubmitting(false)
      }
    }
  }

  return (
    <div
      className='editor-container' style={isSideNavOpen
        ? { marginLeft: '320px', marginRight: '200px' }
        : { marginLeft: '128px', marginRight: '128px' }}
    >
      <NotificationWrapper />
      <MDBRow>
        <MDBCol className='d-flex flex-column flex-center mb-3'>
          <h1 id='partner-page-heading'>Upload a Policy</h1>
        </MDBCol>
      </MDBRow>

      {(!company || isLoadingCompany ||
        !policies || isLoadingPolicies ||
        !policyCatalog || isLoadingPolicyCatalog ||
        isLoadingCreatePolicy) && (
          <Spinner />
      )}

      {(company && !isLoadingCompany &&
        policies && !isLoadingPolicies &&
        policyCatalog && !isLoadingPolicyCatalog &&
        !isLoadingCreatePolicy) && (
          <>

            <div className='curriculum-card-container'>
              <div>
                <h4 className='phin-h4'>Policy Information</h4>
                <p className='description'>Configure policy metadata.</p>
              </div>
              <div className='form-group w-100'>
                <div>
                  <MDBInput
                    style={{ borderRadius: '4px' }}
                    outline value={policyName}
                    id='policyName'
                    size='lg'
                    className='w-100 h-100'
                    onChange={(e) => {
                      setPolicyName(e.target.value)
                    }} required name='policyName' label='Policy Name'
                  />
                </div>
                <div>
                  <MDBInput
                    style={{ borderRadius: '4px' }}
                    type='textarea'
                    label='Policy Description'
                    rows='5'
                    outline
                    value={policyDescription}
                    id='policyDescription'
                    onChange={(e) => {
                      setPolicyDescription(e.target.value)
                    }}
                    name='policyDescription'
                  />
                </div>

                <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                  <input ref={inputRef} type='file' id='input-file-upload' onChange={handleChange} />
                  <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? 'drag-active' : ''}>
                    {
                    !policyPDF
                      ? (
                        <div>
                          <p>Drag and drop your file here or</p>
                          <button className='upload-button' onClick={onButtonClick}>Upload a file</button>
                        </div>
                        )
                      : (
                        <div>
                          <p>{policyPDF.name}</p>
                          <button className='upload-button' onClick={onButtonClick}>Replace file</button>
                        </div>
                        )
                      }
                  </label>
                  {dragActive && <div id='drag-file-element' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} />}
                </form>

              </div>
            </div>
            <MDBBtn className='mt-4' color='red' onClick={() => cancelCreation(history)}>Cancel</MDBBtn>
            <MDBBtn disabled={isSubmitting} className='mt-4' id='create-partner-next-button' onClick={() => { setIsSubmitting(true); handlePDFUpload() }} color='primary'>{isSubmitting ? 'Loading...' : 'Finish'}</MDBBtn>
          </>
      )}
    </div>
  )
}

export default UploadPolicy
