import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

import Spinner from '../Spinner'
import { getUserTrainingStatsThunk, resetTrainingForUserThunk, getUserSendRecordsThunk } from '../../../store/AnalyticsSlice'
import { capitalizeFirstLetter } from '../../../utils/FormattingUtils'
import { DateTime } from 'luxon'
import { IoArrowBackOutline, IoSendSharp, IoReloadSharp, IoEyeSharp, IoCloseCircle, IoCheckmarkCircle } from 'react-icons/io5'
import { Box, Button, IconButton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import './TrainingAnalytics.css'
import NotificationWrapper from '../notifications/notificationWrapper'
import NotificationUtilities from '../notifications/notificationUtils'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { userStatuses } from '../../../frontendConsts.js'
import CompleteTrainingModal from '../training/CompleteTrainingModal'
import SharedBasicModal from '../SharedBasicModal'
import PhishingEmailViewer from '../PhishingEmailViewer'
import { markSendRecordFalsePositiveThunk } from '../../../store/SendRecordSlice'

function UserAnalyticsPage ({ companyId }) {
  const history = useHistory()
  const { userId } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()

  const { userInteractionDataStats, userSendRecords, loaders: analyticsLoaders } = useSelector((state) => state.analytics)
  const { isLoadingUserInteractionDataStats } = analyticsLoaders

  const { users, loaders: userLoaders } = useSelector((state) => state.users)
  const { isLoadingUsers } = userLoaders

  const [userCourseRows, setUserCourseRows] = useState([])
  const [userTemplateRows, setUserTemplateRows] = useState([])
  const [incompleteCourseCount, setIncompleteCourseCount] = useState(0)

  const [phishingTablePageSize, setPhishingTablePageSize] = useState(10)
  const [trainingTablePageSize, setTrainingTablePageSize] = useState(10)

  const [user, setUser] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [viewerModal, setViewerModal] = useState(false)
  const [previewSendRecord, setPreviewSendRecord] = useState({})

  const [confirmationModalHandlerFunction, setConfirmationModalHandlerFunction] = useState()
  const [confirmationModalBody, setConfirmationModalBody] = useState()
  const [confirmationModalAffirmText, setConfirmationModalAffirmText] = useState()

  const [course, setCourse] = useState()

  const [isCompleteTrainingModalOpen, setIsCompleteTrainingModalOpen] = useState(false)

  const courseReminderText = (<><p>You are about to remind this user to complete training. </p> <p> Are you sure you would like to do this?</p></>)

  useEffect(() => {
    dispatch(getUserTrainingStatsThunk({ companyId, userId }))
    dispatch(getUserSendRecordsThunk({ companyId, userId }))

    // Make sure we start at the top of the page
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setUserCourseRows(userInteractionDataStats)
    if (userCourseRows) {
      setIncompleteCourseCount(userCourseRows.reduce((accumulator, currentValue) => accumulator + (currentValue.lesson_status === 'incomplete' ? 1 : 0), 0))
    }
  }, [userInteractionDataStats, userCourseRows])

  useEffect(() => {
    if (userSendRecords) {
      setUserTemplateRows(userSendRecords)
    }
  }, [userSendRecords])

  useEffect(() => {
    if (users) {
      const selectedUser = users.find(obj => obj.id === userId)

      if (selectedUser) {
        setUser(selectedUser)
      } else {
        // Likely was deleted and we're coming from the Phishing Analytics. Attempt to pull from the passed location state
        setUser(location.state.user)
      }
    }
  }, [users])

  function toggleCompleteTrainingModalOpen ({ userCourse }) {
    if (isCompleteTrainingModalOpen) {
      setCourse()
    } else {
      setCourse(userCourse)
    }
    setIsCompleteTrainingModalOpen(!isCompleteTrainingModalOpen)
  }

  function renderCourseStatus (isArchived) {
    if (isArchived) {
      return 'Archived'
    } else {
      return 'Active'
    }
  }

  function renderDateAssigned (userCourse) {
    if (userCourse.dateAssigned) {
      return DateTime.fromSeconds(userCourse.dateAssigned._seconds).toJSDate()
    } else {
      return DateTime.fromSeconds(userCourse.dateStarted._seconds).toJSDate()
    }
  }

  function renderDateCompleted (userCourse) {
    if (userCourse.dateCompleted) {
      return DateTime.fromSeconds(userCourse.dateCompleted._seconds).toLocaleString()
    } else {
      return 'N/A'
    }
  }

  function renderDateClicked (clicked) {
    if (clicked) {
      return DateTime.fromSeconds(clicked._seconds).toLocaleString()
    } else {
      return 'N/A'
    }
  }

  function renderLearningMomentCompleted ({ learningMomentFinished, clicked }) {
    if (clicked && learningMomentFinished) {
      return DateTime.fromSeconds(learningMomentFinished._seconds).toLocaleString()
    } else {
      return 'N/A'
    }
  }

  function handleToggleTemplateViewerModal (sendRecord) {
    setPreviewSendRecord(sendRecord)
    setViewerModal(true)
  }

  function renderPhishingActionButtons (sendRecord) {
    return (
      <Grid container alignContent='center' justifyContent='end'>
        <Grid item>
          <IconButton
            aria-label='View Template'
            id={sendRecord.id}
            onClick={() => {
              handleToggleTemplateViewerModal(sendRecord)
            }}
          >
            <IoEyeSharp />
          </IconButton>
        </Grid>
        <Grid item>
          <Button
            id={`False-Positive-Button-${sendRecord.id}`}
            aria-label={`Mark ${sendRecord.templateName} as a false positive`}
            sx={{ mt: 0.5 }}
            variant='outlined'
            color='primary'
            disabled={!sendRecord.clicked}
            size='small'
            onClick={() =>
              toggleConfirmModal({
                shouldOpen: true,
                confirmationModalHandlerFunction: () => { handleMarkAsFalsePositive(sendRecord) },
                confirmationModalBody: (<><p>You are about to mark a click on "{sendRecord.templateName}" for user {sendRecord.first} {sendRecord.last} as a false positive. </p> <p> Are you sure you would like to do this?</p></>),
                confirmationModalAffirmText: 'Mark False Positive'
              })}
            startIcon={<IoCloseCircle />}
          >Mark False Positive
          </Button>
        </Grid>
      </Grid>
    )
  }

  async function handleMarkAsFalsePositive (sendRecord) {
    await dispatch(markSendRecordFalsePositiveThunk({ companyId, sendRecordId: sendRecord.id, sendRecord }))
    toggleConfirmModal({ shouldOpen: false })
  }

  function renderCourseStatusUpdateButton (userCourse) {
    if (userCourse.lesson_status === 'incomplete') {
      return (
        <Button
          id={`Mark-${userCourse.name}-Course-Complete-button`}
          aria-label={`Mark ${userCourse.name} Course Complete Button`}
          sx={{ mb: 1 }}
          disabled={user.status === userStatuses.DELETED}
          variant='outlined'
          color='primary'
          size='small'
          onClick={() =>
            toggleCompleteTrainingModalOpen({
              userCourse: { ...userCourse }
            })}
          startIcon={<IoCheckmarkCircle />}
        >Mark Complete
        </Button>
      )
    } else {
      return (
        <Button
          id={`Reset-${userCourse.name}-Training`}
          sx={{ mb: 1 }}
          variant='outlined'
          color='primary'
          size='small'
          disabled={user.status === userStatuses.DELETED}
          onClick={() =>
            toggleConfirmModal({
              shouldOpen: true,
              confirmationModalHandlerFunction: () => resetTraining(userCourse),
              confirmationModalBody: (<><p>You are about to reset training course: "{userCourse.name}" for user {user.first} {user.last}. </p> <p> Are you sure you would like to do this?</p></>),
              confirmationModalAffirmText: 'Reset Course',
              userCourse: { ...userCourse }
            })}
          startIcon={<IoReloadSharp />}
        >Reset Training
        </Button>
      )
    }
  }

  function toggleConfirmModal ({
    shouldOpen, confirmationModalHandlerFunction, confirmationModalBody,
    confirmationModalAffirmText, userCourse
  }) {
    if (shouldOpen) {
      setConfirmationModalHandlerFunction(() => confirmationModalHandlerFunction)
      setConfirmationModalBody(confirmationModalBody)
      setConfirmationModalAffirmText(confirmationModalAffirmText)
      setCourse({ ...userCourse })
      setConfirmModal(shouldOpen)
      setIsCompleteTrainingModalOpen(false)
    } else {
      setConfirmModal(shouldOpen)
      setConfirmationModalHandlerFunction()
      setConfirmationModalBody()
      setConfirmationModalAffirmText()
      setCourse()
    }
  }

  async function remindUser (user) {
    try {
      const res = await apiRequestUtils.post(`/api/companies/${companyId}/training/remind-user`, {
        userId
      })
      await res.json()
      if (res.status === 200) {
        NotificationUtilities.sendSuccessMessage('Reminder Sent!')
      } else {
        NotificationUtilities.sendErrorMessage('Error Sending Reminder, please contact Phin if problem persists!')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Error Sending Reminder, please contact Phin if problem persists!')
    }
  }

  async function resetTraining (userCourse) {
    const status = await dispatch(resetTrainingForUserThunk({ user, companyId, courseId: userCourse.id, isSingleUserView: true }))

    if (status) {
      setCourse()
      setConfirmModal(false)
    }
  }

  function sendUserReminder () {
    remindUser(user)
    toggleConfirmModal({ shouldOpen: false })
  }

  function goBackToPreviousPage () {
    history.goBack()
  }

  const userInteractionDataStatsColumns = [
    { field: 'name', headerName: 'Course Name', minWidth: 100, flex: 1.5 },
    { field: 'lesson_status', headerName: 'User Course Status', minWidth: 100, flex: 1, valueGetter: (params) => capitalizeFirstLetter(params.row.lesson_status) },
    { field: 'dateAssigned', type: 'date', headerName: 'Date Assigned', minWidth: 100, flex: 1, valueGetter: (params) => renderDateAssigned(params.row) },
    { field: 'dateCompleted', headerName: 'Date Completed', minWidth: 150, flex: 1, valueGetter: (params) => renderDateCompleted(params.row) },
    { field: 'archived', headerName: 'Course Status', minWidth: 100, flex: 1, valueGetter: (params) => renderCourseStatus(params.row.archived) },
    { headerName: '', minWidth: 200, flex: 1, renderCell: (params) => renderCourseStatusUpdateButton(params.row) }
  ]

  const userPhishingStatsColumns = [
    { field: 'templateName', headerName: 'Template Name', minWidth: 150, flex: 1 },
    { field: 'sendAt', type: 'date', headerName: 'Sent', minWidth: 150, flex: 0.5, valueGetter: (params) => DateTime.fromSeconds(params.row.sendAt._seconds).toJSDate() },
    { field: 'clicked', headerName: 'Clicked', minWidth: 100, flex: 0.5, valueGetter: (params) => renderDateClicked(params.row.clicked) },
    { field: 'learningMomentFinished', headerName: 'Learning Moment Completed', minWidth: 100, flex: 0.5, valueGetter: (params) => renderLearningMomentCompleted(params.row) },
    { field: 'actions', headerName: '', flex: 1, minWidth: 250, renderCell: (params) => renderPhishingActionButtons(params.row) }
  ]

  return (
    <>
      <div className='screen-pad d-flex flex-column'>
        <div className='mb-4' style={{ alignSelf: 'left' }}>
          <NotificationWrapper />
          <PhishingEmailViewer
            sendRecordInfo={previewSendRecord}
            setSendRecordInfo={setPreviewSendRecord}
            isOpen={viewerModal}
            toggleViewerModal={setViewerModal}
          />
          <SharedBasicModal
            onClose={() => toggleConfirmModal({ shouldOpen: false })}
            open={confirmModal}
            Content={confirmationModalBody}
            confirmAction={confirmationModalHandlerFunction}
            confirmText={confirmationModalAffirmText}
            disableSubmit={isLoadingUserInteractionDataStats}
          />

          {isCompleteTrainingModalOpen && (

            <CompleteTrainingModal
              companyId={companyId}
              isOpen={isCompleteTrainingModalOpen}
              onClose={() => toggleCompleteTrainingModalOpen({})}
              confirmAction={() => toggleCompleteTrainingModalOpen({})}
              user={user}
              course={course}
              isSingleUserView
            />
          )}

          {(isLoadingUserInteractionDataStats || isLoadingUsers || !userInteractionDataStats || !userSendRecords || !users) && (
            <Spinner message='Loading User Analytics' />
          )}

          {(!isLoadingUserInteractionDataStats && !isLoadingUsers && userInteractionDataStats && userSendRecords && users) && (

            <>
              <Button
                id='return-to-user-list-button'
                aria-label='Go Back Button'
                sx={{ mb: 1 }}
                variant='text'
                color='primary'
                size='small'
                onClick={() => goBackToPreviousPage()}
                startIcon={<IoArrowBackOutline />}
              >{`Return to ${location.state?.previousPageTitle}`}
              </Button>

              <Grid container sx={{ p: 2, border: '2px solid', borderColor: 'var(--phin-light-gray)', borderRadius: '8px', fontFamily: 'inter' }}>
                <Grid item alignContent='center' className='detailColumn' xs={6} md={4}>
                  <Box component='div' style={{ textOverflow: 'ellipsis' }}>
                    <Typography style={{ marginBottom: '0rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.email}</Typography>
                  </Box>
                  <Box component='div'>
                    <Typography>{user.first} {user.last}</Typography>
                  </Box>
                </Grid>
                <Grid item justifyContent='center' className='detailColumn' xs={6} md={4}>
                  <div>
                    <p style={{ marginBottom: '0rem' }}>Status</p>
                    <p>{user.status}</p>
                  </div>
                </Grid>
                <Grid item className='detailColumn' xs={6} md={4}>
                  <div>
                    <p>Incomplete Course Count: {incompleteCourseCount}</p>
                    <p>
                      <Button
                        id='send-reminder-button'
                        aria-label='Send Reminder Button'
                        variant='contained'
                        disabled={incompleteCourseCount === 0 || user.status === userStatuses.DELETED}
                        endIcon={<IoSendSharp />}
                        onClick={() => {
                          toggleConfirmModal({
                            shouldOpen: true,
                            confirmationModalHandlerFunction: sendUserReminder,
                            confirmationModalBody: courseReminderText,
                            confirmationModalAffirmText: 'Remind User'
                          })
                        }}
                      >
                        Send Reminder
                      </Button>
                    </p>
                  </div>
                </Grid>

              </Grid>
              <h3 className='phin-h4' style={{ paddingTop: '1em' }}>Seen Phishing</h3>
              <div style={{ height: '65vh', width: '100%' }} className='p-m-t-1'>
                <DataGrid
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  rows={userTemplateRows}
                  columns={userPhishingStatsColumns}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                      csvOptions: {
                        fields: ['templateName', 'clicked', 'sendAt', 'learningMomentFinished'],
                        fileName: `${user.first} ${user.last} Phishing Analytics ${DateTime.now().toLocaleString()}`
                      },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'sendAt', sort: 'desc' }]
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableSelectionOnClick
                  pageSize={phishingTablePageSize}
                  onPageSizeChange={(newPageSize) => setPhishingTablePageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50]}
                  pagination
                />
              </div>

              <h3 className='phin-h4' style={{ paddingTop: '1em' }}>Seen Training</h3>
              <div style={{ height: '65vh', width: '100%', marginBottom: '3rem' }} className='p-m-t-1'>
                <DataGrid
                  style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                  className='DataGrid'
                  rows={userCourseRows}
                  columns={userInteractionDataStatsColumns}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                      csvOptions: {
                        fields: ['name', 'lesson_status', 'dateAssigned', 'dateCompleted', 'archived'],
                        fileName: `${user.first} ${user.last} Training Analytics ${DateTime.now().toLocaleString()}`
                      },
                      printOptions: { disableToolbarButton: true }
                    }
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'dateAssigned', sort: 'desc' }]
                    }
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableSelectionOnClick
                  pageSize={trainingTablePageSize}
                  onPageSizeChange={(newPageSize) => setTrainingTablePageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50]}
                  pagination
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default UserAnalyticsPage
