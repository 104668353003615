import { createSlice } from '@reduxjs/toolkit'
import { integrationTypes } from '../frontendConsts.js'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { integrationDisconnectedAction, integrationStatusUpdatedAction, integrationUpdatedAction } from './IntegrationsSlice'
import { deleteStagedGroupsAction } from './StagedGroupsSlice'
import { deleteStagedUsersAction } from './StagedUsersSlice'

export const userSyncSlice = createSlice({
  name: integrationTypes.USER_SYNC,
  initialState: {
    loaders: {
      loadingUserSyncStatus: false
    }
  },
  reducers: {
    loadingUserSyncStatusUpdated: (state, action) => {
      state.loaders.loadingUserSyncStatus = action.payload
    }
  }
})

const {
  loadingUserSyncStatusUpdated: loadingUserSyncStatusUpdatedAction
} = userSyncSlice.actions

export default userSyncSlice.reducer

export const startStagingThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingUserSyncStatusUpdatedAction(true))

    try {
      const response = await api.put(`/api/companies/${companyId}/integrations/userSync/startStaging`)

      if (response.status === 200) {
        const { integrationStatus } = await response.json()
        dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.USER_SYNC, integrationStatus: integrationStatus }))
      } else if (response.status === 409) {
        // Ignore duplicate send of start staging error
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to start staging for User Sync.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to start staging for User Sync.')
      }, 0)
    }

    dispatch(loadingUserSyncStatusUpdatedAction(false))
  }
}

export const commitStagingThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingUserSyncStatusUpdatedAction(true))

    try {
      const response = await api.put(`/api/companies/${companyId}/integrations/userSync/commitStaging`)

      if (response.status === 200) {
        const { integrationStatus } = await response.json()
        dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.USER_SYNC, integrationStatus: integrationStatus }))
      } else if (response.status === 409) {
        NotificationUtilities.sendWarningMessage('Failed to commit users and groups for User Sync. Committing is already in progress.')
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to commit users and groups for User Sync. Please try again or contact Phin support if issue persists.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to commit users and groups for User Sync. Please try again or contact Phin support if issue persists.')
      }, 0)
    }

    dispatch(loadingUserSyncStatusUpdatedAction(false))
  }
}

export const syncUsersThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingUserSyncStatusUpdatedAction(true))
    try {
      const response = await api.put(`/api/companies/${companyId}/integrations/userSync/sync`)

      if (response.status === 200) {
        const { isSyncing } = await response.json()
        dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { isSyncing } }))
      } else if (response.status === 409) {
        NotificationUtilities.sendWarningMessage('Failed to sync users and groups. Sync is already in progress.')
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to sync users and groups. Please try again or contact Phin support if issue persists.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to sync users and groups. Please try again or contact Phin support if issue persists.')
      }, 0)
    }
    dispatch(loadingUserSyncStatusUpdatedAction(false))
  }
}

export const disconnectUserSyncThunk = ({ companyId, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingUserSyncStatusUpdatedAction(true))

    try {
      const response = await api.delete(`/api/companies/${companyId}/integrations/userSync/`)

      if (response.status === 200) {
        const { removedIntegrationId } = await response.json()
        dispatch(integrationDisconnectedAction(removedIntegrationId))
        dispatch(deleteStagedUsersAction())
        dispatch(deleteStagedGroupsAction())

        history.push(`/companies/${companyId}/integrations`)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to disconnect User Sync. Please try again or contact Phin Support if issue persists.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to disconnect User Sync. Please try again or contact Phin Support if issue persists.')
      }, 0)
    }

    dispatch(loadingUserSyncStatusUpdatedAction(false))
  }
}
