import React from 'react'
import { Box, Button, Grid, Modal } from '@mui/material'

const SharedBasicModal = ({
  onClose, open, Content,
  confirmAction = null, confirmText = null,
  disableSubmit = null, styleOverrides = null,
  width = null
}) => {
  const defaultStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    ...styleOverrides
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={defaultStyle}>
        {Content}
        <Grid container justifyContent='flex-end'>
          <Box sx={{ mt: 2 }}>
            <Button
              id='close-button'
              aria-label='Close Button'
              sx={{ mr: 3 }}
              variant='contained'
              onClick={onClose}
            >Close
            </Button>
            {confirmAction && <Button id='confirm-button' aria-label={`${confirmText} Button`} disabled={disableSubmit} color='success' variant='contained' onClick={confirmAction}>{confirmText || 'confirm'}</Button>}
          </Box>
        </Grid>
      </Box>
    </Modal>
  )
}

export default SharedBasicModal
