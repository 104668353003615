import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

const initialBrandingSettings = {
  isAutoWelcomeEnabled: false,
  audienceFilterType: 'first',
  audienceParameters: [{ field: 'first', type: '!=', value: '~~~' }],
  autoWelcomeSendTime: '12:00',
  whiteLabelName: null
}

export const brandingSlice = createSlice({
  name: 'BrandingSlice',
  initialState: {
    brandingSettings: {
      ...initialBrandingSettings
    }
  },
  reducers: {
    setBrandingSettings: (state, action) => {
      state.brandingSettings = action.payload
    }
  }
})

export const {
  setBrandingSettings: setBrandingSettingsAction
} = brandingSlice.actions

export default brandingSlice.reducer

export const getBrandingSettingsThunk = (companyId, partnerId) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.get(`/api/companies/${companyId}/branding/settings?partnerId=${partnerId}`)
      if (res.status === 200) {
        const { brandingSettings } = await res.json()

        if (brandingSettings !== undefined) {
          if (!brandingSettings.whiteLabelName) {
            const name = getState().company.company.partnerName
            brandingSettings.whiteLabelName = name
          }
          dispatch(setBrandingSettingsAction(brandingSettings))
        } else {
          const name = getState().company.company.partnerName
          const brandingSettings = { ...initialBrandingSettings }
          brandingSettings.whiteLabelName = name
          dispatch(setBrandingSettingsAction(brandingSettings))
        }
      } else {
        NotificationUtilities.sendErrorMessage('Failed to get branding settings. Please try again or contact Phin support.')
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to get branding settings. Please try again or contact Phin support.')
      console.error(err)
    }
  }
}

export const toggleWelcomeEmailAutomationThunk = (companyId, isAutoWelcomeEnabled) => {
  return async (dispatch, getState, api) => {
    try {
      const brandingSettings = { ...getState().branding.brandingSettings }

      const res = await api.put(`/api/companies/${companyId}/branding/settings`, { isAutoWelcomeEnabled })
      if (res.status === 200) {
        brandingSettings.isAutoWelcomeEnabled = isAutoWelcomeEnabled
        dispatch(setBrandingSettingsAction(brandingSettings))
        return true
      } else {
        NotificationUtilities.sendErrorMessage('Failed to toggle automatic welcome email sending. Please try again or contact Phin support.')
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to toggle automatic welcome email sending. Please try again or contact Phin support.')
      console.error(err)
    }

    return false
  }
}

export const updateBrandingSettingsThunk = (companyId, updatedFields) => {
  return async (dispatch, getState, api) => {
    try {
      const brandingSettings = { ...getState().branding.brandingSettings }

      const res = await api.put(`/api/companies/${companyId}/branding/settings`, { ...updatedFields })
      if (res.status === 200) {
        for (const [updateFieldName, updateField] of Object.entries(updatedFields)) {
          brandingSettings[updateFieldName] = updateField
        }
        dispatch(setBrandingSettingsAction(brandingSettings))
        return true
      } else {
        NotificationUtilities.sendErrorMessage('Failed to toggle automatic welcome email sending. Please try again or contact Phin support.')
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to toggle automatic welcome email sending. Please try again or contact Phin support.')
      console.error(err)
    }

    return false
  }
}
