import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { shareLevels } from '../frontendConsts.js'
import { setOnboardingChecklistAction } from './CompanySlice'

export const logoSlice = createSlice({
  name: 'logo',
  initialState: {
    loaders: {
      isLoadingLogoUpload: false,
      isLoadingLogo: false
    },
    logoUrl: null,
    logoShareLevel: null
  },
  reducers: {
    setIsLoadingLogoUpload: (state, action) => {
      state.loaders.isLoadingLogoUpload = action.payload
    },
    setIsLoadingLogo: (state, action) => {
      state.loaders.isLoadingLogo = action.payload
    },
    setLogoUrl: (state, action) => {
      state.logoUrl = action.payload.logoUrl
      state.logoShareLevel = action.payload.shareLevel
    }
  }
})

export const {
  setIsLoadingLogoUpload: setIsLoadingUploadLogoAction,
  setIsLoadingLogo: setIsLoadingLogoAction,
  setLogoUrl: setLogoUrlAction

} = logoSlice.actions

export default logoSlice.reducer

export const uploadNewLogoThunk = ({ formData, id, shareLevel }) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(setIsLoadingUploadLogoAction(true))
      let url = ''
      if (shareLevel === shareLevels.CLIENT) {
        url = `/api/companies/${id}/settings/images/logo`
      } else if (shareLevel === shareLevels.PARTNER) {
        url = `/api/partners/${id}/defaults/logo-image`
      } else {
        throw new Error('Invalid share level')
      }
      const response = await api.postForm(url, formData)
      if (response.status === 200) {
        const imageBucketName = (process.env.REACT_APP_DEV_MODE === 'true') ? 'company-logo-image' : 'company-logo-image-prod'
        const imageLocation = (id = 'anchor-image') => `https://storage.googleapis.com/${imageBucketName}/${id}/logo.png`
        dispatch(setLogoUrlAction({ logoUrl: imageLocation(id), shareLevel }))
        const onboardingChecklist = { ...getState().company.onboardingChecklist }
        onboardingChecklist.hasPersonalizations = true
        dispatch(setOnboardingChecklistAction(onboardingChecklist))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Image successfully uploaded. Please allow up to an hour for the portal and customer communications to clear the cache and show the new image.')
        })
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('There was an error uploading your image, only jpeg, jpg, and png are supported.')
        })
        return 'failure'
      }
    } catch (error) {
      console.error(error)
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('There was an error uploading your image, please try again later.')
      })
    }
  }
}

export const getLogoLocationThunk = ({ companyId, partnerId }) => {
  return async (dispatch, getState, api) => {
    const imageExists = (imageUrl) => {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = function () {
          resolve(imageUrl)
        }
        image.onerror = function () {
          resolve(false)
        }
        image.src = imageUrl
      })
    }

    const getImageLocation = async ({ partnerId, companyId }, fileName) => {
      const imageBucketName = (process.env.REACT_APP_DEV_MODE === 'true') ? 'company-logo-image' : 'company-logo-image-prod'
      const imageLocation = (id = 'anchor-image') => `https://storage.googleapis.com/${imageBucketName}/${id}/${fileName}`
      const promises = []
      if (!partnerId && !companyId) {
        throw new Error('No partner or companyId provided')
      } else {
        if (companyId) {
          if (companyId && partnerId) {
            promises.push(imageExists(imageLocation(companyId)))
            promises.push(imageExists(imageLocation(partnerId)))
          } else {
            throw new Error('If fetching a companyId, must provide the partnerId as well')
          }
        } else {
          promises.push(imageExists(imageLocation(partnerId)))
        }
      }

      const responses = await Promise.all(promises)

      if (responses.length === 1) {
        if (responses[0]) {
          dispatch(setLogoUrlAction({ logoUrl: imageLocation(partnerId), shareLevel: shareLevels.PARTNER }))
        } else {
          dispatch(setLogoUrlAction({ logoUrl: imageLocation(), shareLevel: shareLevels.PREMIUM }))
        }
      } else {
        if (responses[0]) {
          dispatch(setLogoUrlAction({ logoUrl: imageLocation(companyId), shareLevel: shareLevels.CLIENT }))
        } else if (responses[1]) {
          dispatch(setLogoUrlAction({ logoUrl: imageLocation(partnerId), shareLevel: shareLevels.PARTNER }))
        } else {
          dispatch(setLogoUrlAction({ logoUrl: imageLocation(), shareLevel: shareLevels.PREMIUM }))
        }
      }
    }

    dispatch(setIsLoadingLogoAction(true))
    await getImageLocation({ companyId, partnerId }, 'logo.png')
    dispatch(setIsLoadingLogoAction(false))
  }
}
