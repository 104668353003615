import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBBtn, MDBCard, MDBCardHeader, MDBCardBody, MDBRow,
  MDBInput, MDBIcon, MDBCol, MDBTooltip, MDBContainer
} from 'mdbreact'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import ValidationUtils from '../utils/validationUtils'
import './EditUser.css'
import { useDispatch, useSelector } from 'react-redux'
import { resetEditingUserAction, editUserThunk } from '../store/UsersSlice'
import Spinner from './components/Spinner'

const totalSteps = 1

function previousStep (currentStep, setStep) {
  setStep(currentStep - 1)
}

function cancelCreation (history, resetEditingUser) {
  history.goBack()
  resetEditingUser()
}

const EditUser = ({ id }) => {
  const [currentStep, setStep] = useState(0)
  const [editedUserFields, setEditedUserFields] = useState({})
  const history = useHistory()
  const dispatch = useDispatch()
  const { editingUser: user } = useSelector(state => state.users)
  const { company } = useSelector(state => state.company)
  const { hasUserSync } = useSelector(state => state.integrations)
  if (!company) {
    return <Spinner />
  }

  let { deviceStructure: userStructure } = company
  userStructure = JSON.parse(userStructure)

  function validate () {
    if (editedUserFields.first !== undefined && !ValidationUtils.isNotWhiteSpace(editedUserFields.first)) {
      throw Error('Please input a first name')
    }
    if (editedUserFields.last !== undefined && !ValidationUtils.isNotWhiteSpace(editedUserFields.last)) {
      throw Error('Please input a last name')
    }
    if (editedUserFields.email !== undefined && !ValidationUtils.isValidEmail(editedUserFields.email)) {
      throw Error('Please enter a valid email')
    }
  }

  function nextStep (currentStep, setStep, finishCreation) {
    try {
      switch (currentStep) {
        // Getting started
        case (totalSteps - 1):
          finishCreation()
          break
        case 0:
          validate()
          setStep(currentStep + 1)
          break
        default:
          setStep(currentStep + 1)
          break
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  async function finishCreation () {
    try {
      validate()
      await dispatch(editUserThunk(id, user.id, editedUserFields))
      history.goBack()
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  const renderSyncIcon = () => {
    return (
      <div className='sync-icon'>
        <MDBTooltip
          placement='right'
          id='sync-user-icon'
          material
        >
          <MDBBtn>
            <MDBIcon className='text-primary' icon='sync-alt' />
          </MDBBtn>
          <div>
            This user has been synced from an external system and must be edited from there
          </div>
        </MDBTooltip>
      </div>
    )
  }
  return (
    <MDBContainer>
      <NotificationWrapper />
      <MDBRow>
        <MDBCol className='d-flex flex-column flex-center mb-3'>
          <h1 id='partner-page-heading'>Edit A User {(user.externalId && hasUserSync) ? renderSyncIcon() : ''}</h1>
        </MDBCol>
      </MDBRow>
      {currentStep === 0 &&
        <MDBCard>
          <MDBCardHeader>
            <MDBRow className='d-flex'>
              <MDBCol className='d-flex justify-content-start align-items-center'>
                <h5>User Information</h5>
              </MDBCol>
              <MDBCol className='d-flex justify-content-end'>
                <MDBTooltip
                  placement='left'
                  id='campaignBuilderstep0'
                  material
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                  <div>
                    Edit this user's information
                  </div>
                </MDBTooltip>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody className='p-3'>
            <MDBRow className='d-flex justify-content-center'>
              {userStructure.map(userField => {
                return (
                  <MDBCol key={userField.field} size='4'>
                    <MDBInput
                      data-lpignore='true'
                      outline disabled={(user.externalId && hasUserSync)}
                      value={editedUserFields[userField.field] !== undefined ? editedUserFields[userField.field] : user[userField.field]}
                      id={`edit-user-${userField.field}-field`}
                      aria-label={`Edit User ${userField.field} Field`}
                      size='lg'
                      onChange={(e) => {
                        setEditedUserFields({ ...editedUserFields, [userField.field]: e.target.value })
                      }} name={userField.field} label={userField.label}
                    />
                  </MDBCol>
                )
              })}
            </MDBRow>

          </MDBCardBody>
        </MDBCard>}
      <MDBBtn
        id='cancel-user-changes-button'
        aria-label='Cancel User Changes Button'
        className='mt-4'
        color='red' onClick={() => cancelCreation(history, () => dispatch(resetEditingUserAction()))}
      >Cancel
      </MDBBtn>
      {currentStep > 0 &&
        <MDBBtn className='mt-4' onClick={() => previousStep(currentStep, setStep)} color='secondary'>Previous</MDBBtn>}
      {user.email && (user.externalId === undefined || !hasUserSync) &&
        <MDBBtn
          className='mt-4'
          id='save-user-changes-button'
          aria-label='Save User Changes Button'
          onClick={() => nextStep(currentStep, setStep, finishCreation)}
          color='primary'
        >{currentStep < totalSteps - 1 ? 'Next' : 'Save'}
        </MDBBtn>}
    </MDBContainer>
  )
}

export default EditUser
