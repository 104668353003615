import {
  MDBSideNav
} from 'mdbreact'
import '../css/sideNav.css'

import { orgTypes } from '../../frontendConsts'
import CompanyNavRoutes from './sideNav/CompanyNavRoutes'
import PartnerNavRoutes from './sideNav/PartnerNavRoutes'
import DistributorNavRoutes from './sideNav/DistributorNavRoutes'
import { useSelector } from 'react-redux'
import Spinner from './Spinner'

function SideNav ({ id }) {
  const {
    isSideNavOpen,
    sideNavOrgId,
    sideNavOrgType,
    sideNavIsFreeTrial
  } = useSelector((state) => state.sideNav)
  const { sideNavOrgName } = useSelector((state) => state.sideNav)

  const { logoUrl, loaders } = useSelector((state) => state.logo)
  const { isLoadingLogo } = loaders

  function renderRoutes () {
    switch (sideNavOrgType) {
      case orgTypes.COMPANY:
        return <CompanyNavRoutes id={sideNavOrgId || id} />
      case orgTypes.PARTNER:
        return <PartnerNavRoutes id={sideNavOrgId || id} />
      case orgTypes.DISTRIBUTOR:
        return <DistributorNavRoutes id={sideNavOrgId || id} />
      default:
        return <></>
    }
  }
  return (
    <MDBSideNav
      className='side-nav'
      triggerOpening={isSideNavOpen}
      slim={false}
      mask='rgba-blue-strong'
      showOverlay={false}
    >
      <div style={{ paddingTop: '48px' }}>
        <li>
          <div className='logo-wrapper sn-ad-avatar-wrapper mdb-side-nav-override'>
            {isLoadingLogo && (
              <Spinner size='2em' />
            )}
            {!isLoadingLogo && (
              <img
                alt=''
                src={logoUrl}
                width='60rem'
              />
            )}
            <span style={{ margin: 0, fontSize: '120%' }}>{sideNavOrgName}</span>

          </div>
        </li>

        {sideNavOrgType !== orgTypes.DISTRIBUTOR && sideNavIsFreeTrial &&
          <span style={{ backgroundColor: 'white', color: '#44a1e3', fontWeight: '500' }} className='d-flex flex-row justify-content-center'>
            free tier
          </span>}

        {renderRoutes()}
      </div>
    </MDBSideNav>
  )
}

export default SideNav
