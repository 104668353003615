import { MDBRow, MDBCard, MDBCardBody, MDBCardHeader, MDBDataTable, MDBIcon } from 'mdbreact'

import { computeCampaignStatus } from './statusUtils'
import ActionButton from './ActionButton'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { navigationRoutes } from '../../../frontendConsts'

function renderStatus (campaign, i, tz) {
  const statusObj = computeCampaignStatus(new Date(campaign.startTime), new Date(campaign.endTime), new Date(), campaign, tz)
  return (<h4 key={i} searchValue={statusObj.message}><span className={`badge badge-${statusObj.color}`}>{statusObj.message}</span></h4>)
}

function CampaignTable ({
  title, campaigns, columns, pastCampaigns = false, actionButtons = [], focusedCampaign,
  focusCallback = undefined, csvDownloadHandler = undefined, handleCancelCampaign = undefined, handleEditCampaign = undefined,
  tz
}) {
  if (campaigns.length === 0) {
    return <></>
  }

  const history = useHistory()
  const { company, loaders } = useSelector((state) => state.company)
  const campaignRows = renderCampaignRows(tz)
  const currentCampaignTable = {
    columns: columns,
    rows: campaignRows
  }

  function handleSelection (campaign, i) {
    focusCallback(campaign, i)
  }

  function handleViewActiveCampaign (campaignId) {
    history.push(`/companies/${company.id}${navigationRoutes.PHISHING_VIEWER}/${campaignId}`)
  }

  function renderCampaignRows (tz) {
    const rows = []
    if (!pastCampaigns) {
      campaigns.forEach((campaign, i) => {
        rows.push(
          {
            name: i === focusedCampaign ? <div key={`${campaign.name} + ${i}`} searchValue={campaign.name}><MDBIcon size='sm' icon='search' />  {campaign.name}</div> : campaign.name,
            clickEvent: () => {
              handleSelection(campaign, i)
            },
            status: renderStatus(campaign, i, tz),
            action: <MDBRow searchValue='' className='d-flex justify-content-center'>{renderActionButtons(actionButtons, campaign)}</MDBRow>
          }
        )
      })
    } else {
      for (const campaign of campaigns) {
        const st = new Date(campaign.startTime)
        let endString = ''

        if (campaign.endTime !== undefined) {
          const et = new Date(campaign.endTime)
          endString = et.toLocaleDateString('en-us', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })
        } else {
          endString = 'Continuous'
        }

        rows.push(
          {
            name: campaign.name,
            startTime: st.toLocaleDateString('en-us', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }),
            endTime: endString,
            action: <MDBRow searchValue='' className='d-flex justify-content-center'>{renderActionButtons(actionButtons, campaign)}</MDBRow>
          }
        )
      }
    }

    return rows
  }

  function renderActionButtons (actionButtons, campaign) {
    const buttons = []
    for (const btn of actionButtons) {
      let onClick
      switch (btn.onClick) {
        case 'csv':
          onClick = () => { csvDownloadHandler(campaign.id, campaign.name, pastCampaigns ? 'pastCampaigns' : 'activeCampaigns') }
          break
        case 'stop':
          onClick = () => { handleCancelCampaign(campaign.id) }
          break
        case 'edit':
          onClick = () => { handleEditCampaign(campaign.id) }
          break
        case 'view':
          onClick = () => { handleViewActiveCampaign(campaign.id) }
          break
        default:
          onClick = ''
      }

      buttons.push(
        <ActionButton title={btn.title} onClick={onClick} color={btn.color} icon={btn.icon} />
      )
    }

    return buttons
  }

  return (
    <MDBRow className='d-flex justify-content-center mb-4 text-center'>
      <MDBCard id='active-campaigns-table' narrow style={{ width: '100%' }}>
        <MDBCardHeader className='view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-campaigns-center py-2 mx-4 mb-3 text-center'>
          <a className='white-text mx-3'>{title}</a>
        </MDBCardHeader>
        <MDBCardBody cascade>
          <MDBDataTable disableRetreatAfterSorting sortRows={['name']} btn fixed hover data={currentCampaignTable} />
        </MDBCardBody>
      </MDBCard>
    </MDBRow>
  )
}

export default CampaignTable
