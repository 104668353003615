import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const trainingSlice = createSlice({
  name: 'trainings',
  initialState: {
    trainings: null,
    courseCatalog: null,
    curriculumCatalog: null,
    enrollableUsers: null,
    loaders: {
      isLoadingTrainings: false,
      isLoadingDeleteTrainingCourse: false,
      isLoadingCourseCatalog: false,
      isLoadingLaunchTrainingCourse: false,
      isLoadingCurriculumCatalog: false,
      isLoadingCreateTrainingCourse: false,
      isLoadingGetEnrollableUsers: false
    }
  },
  reducers: {
    setIsLoadingTrainings: (state, action) => {
      state.loaders.isLoadingTrainings = action.payload
    },
    getTrainings: (state, action) => {
      state.trainings = action.payload
    },
    deleteTrainingCourse: (state, action) => {
      const updatedTrainings = [...state.trainings]

      const deletedTrainingIndex = updatedTrainings.findIndex(course => course.trainingId === action.payload)
      updatedTrainings.splice(deletedTrainingIndex, 1)

      state.trainings = updatedTrainings
    },
    setIsLoadingCourseCatalog: (state, action) => {
      state.loaders.isLoadingCourseCatalog = action.payload
    },
    getCourseCatalog: (state, action) => {
      state.courseCatalog = action.payload
    },
    launchTrainingCourse: (state, action) => {
      const updatedTrainings = [...state.trainings]
      updatedTrainings.push(action.payload)
      state.trainings = updatedTrainings
    },
    setIsLoadingCurriculumCatalog: (state, action) => {
      state.loaders.isLoadingCourseCatalog = action.payload
    },
    getCurriculumCatalog: (state, action) => {
      state.curriculumCatalog = action.payload
    },
    setIsLoadingCreateTrainingCourse: (state, action) => {
      state.loaders.isLoadingCreateTrainingCourse = action.payload
    },
    createTrainingCourse: (state, action) => {
      const updatedCourseCatalog = [...state.courseCatalog]
      updatedCourseCatalog.unshift(action.payload)
      state.courseCatalog = updatedCourseCatalog
    },
    setIsLoadingGetEnrollableUsers: (state, action) => {
      state.loaders.isLoadingGetEnrollableUsers = action.payload
    },
    enrollableUsersUpdated: (state, action) => {
      state.enrollableUsers = action.payload
    },
    enrollmentCountUpdated: (state, action) => {
      const updatedTrainings = [...state.trainings]

      const index = updatedTrainings.findIndex(object => {
        return object.id === action.payload.courseId
      })

      updatedTrainings[index].totalEnrolled = updatedTrainings[index].totalEnrolled + action.payload.successCount

      state.trainings = updatedTrainings
    }
  }
})

export const {
  setIsLoadingTrainings: setIsLoadingTrainingsAction,
  getTrainings: getTrainingsAction,
  deleteTrainingCourse: deleteTrainingCourseAction,
  setIsLoadingCourseCatalog: setIsLoadingCourseCatalogAction,
  getCourseCatalog: getCourseCatalogAction,
  launchTrainingCourse: launchTrainingCourseAction,
  setIsLoadingCurriculumCatalog: setIsLoadingCurriculumCatalogAction,
  getCurriculumCatalog: getCurriculumCatalogAction,
  setIsLoadingCreateTrainingCourse: setIsLoadingCreateTrainingCourseAction,
  createTrainingCourse: createTrainingCourseAction,
  setIsLoadingGetEnrollableUsers: setIsLoadingGetEnrollableUsersAction,
  enrollableUsersUpdated: enrollableUsersUpdatedAction,
  enrollmentCountUpdated: enrollmentCountUpdatedAction
} = trainingSlice.actions

export default trainingSlice.reducer

export const getTrainingsThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingTrainingsAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/training`)
      if (res.status === 200) {
        const trainings = await res.json()
        dispatch(getTrainingsAction(trainings))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingTrainingsAction(false))
  }
}

export const deleteTrainingCourseThunk = ({ id, course }) => {
  return async (dispatch, getState, api) => {
    const deletedTrainingId = course.trainingId
    NotificationUtilities.sendInfoMessage('Received Request to Cancel Course.')
    try {
      const res = await api.post(`/api/companies/${id}/training/cancel/${course.trainingId}`)
      if (res.status === 200) {
        dispatch(deleteTrainingCourseAction(deletedTrainingId))
        NotificationUtilities.sendSuccessMessage('Course Cancelled.')
      } else {
        NotificationUtilities.sendErrorMessage('Error Cancelling Course, please contact Phin if problem persists.')
      }
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage('Error Cancelling Course, please contact Phin if problem persists.')
    }
  }
}

export const getCourseCatalogThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCourseCatalogAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/training/catalog`)
      if (res.status === 200) {
        const catalog = await res.json()
        // Note: splicing in properties that will be used in generating a user curriculum
        // path: `course-definitions/${course.courseDefinitionId}`

        dispatch(getCourseCatalogAction(catalog))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load Course Catalog. Please try again or contact Phin Support if problem persists.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Course Catalog. Please try again or contact Phin Support if problem persists.')
        console.error(err)
      })
    }
    dispatch(setIsLoadingCourseCatalogAction(false))
  }
}

export const launchTrainingCourseThunk = ({ companyId, selectedCourse, history }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.post(`/api/companies/${companyId}/training`,
        { course: selectedCourse }
      )

      if (response.status !== 200) {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to start course. Please try again or contact Phin Support if problem persists.')
        })
      } else {
        const newTraining = await response.json()

        dispatch(launchTrainingCourseAction(newTraining))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Successfully started course.')
        })
        history.push(`/companies/${companyId}/training`)
      }
    } catch (err) {
      console.error('Failed to start course', err)
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to start course.')
      })
    }
  }
}

export const getCurriculumCatalogThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCurriculumCatalogAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/training/curriculum`)
      if (res.status === 200) {
        const curriculumCatalog = await res.json()
        dispatch(getCurriculumCatalogAction(curriculumCatalog))
      } else {
        NotificationUtilities.sendErrorMessage('Failed to load Curriculum Catalog. Please try again or contact Phin Support if problem persists.')
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to load Curriculum Catalog. Please try again or contact Phin Support if problem persists.')
    }
    dispatch(setIsLoadingCurriculumCatalogAction(false))
  }
}

export const createTrainingCourseThunk = ({ companyId, formData, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateTrainingCourseAction(true))

    try {
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Creating course...')
      })
      const res = await api.postForm(`/api/companies/${companyId}/training/create`, formData)

      if (res.status === 200) {
        const courseInfo = await res.json()

        dispatch(createTrainingCourseAction(courseInfo))

        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Course Successfully created. Redirecting you to course Catalog.')
        }, 0)
        history.push(`/companies/${companyId}/training/catalog`)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error creating course. Please ensure to include a Course Thumbnail (PNG) and Video (MP4) with a combined file size of 32MB or less. Please try again or contact Phin Support if problem persists.')
        })
      }
    } catch (error) {
      console.error(error)

      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Error creating course. Please try again or contact Phin support if issue persists.')
      })
    }
    dispatch(setIsLoadingCreateTrainingCourseAction(false))
  }
}

export const getEnrollableUsersThunk = ({ companyId, courseId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingGetEnrollableUsersAction(true))
    let fetchedEnrollableUsers = false
    try {
      const response = await api.get(`/api/companies/${companyId}/training/enrollableUsers/${courseId}`)
      if (response.status === 200) {
        const enrollableUsers = await response.json()
        fetchedEnrollableUsers = true
        dispatch(enrollableUsersUpdatedAction(enrollableUsers))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error loading users to enroll, please try again or contact Phin if problem persists.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Error loading users to enroll, please try again or contact Phin if problem persists.')
      }, 0)
    }
    dispatch(setIsLoadingGetEnrollableUsersAction(false))
    return Promise.resolve(fetchedEnrollableUsers)
  }
}

export const enrollUsersThunk = ({ companyId, courseId, selectedUsers, shouldSendReminders }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingGetEnrollableUsersAction(true))
    let wereUsersEnrolled = false
    try {
      const response = await api.post(`/api/companies/${companyId}/training/enrollUsers/${courseId}`, { selectedUsers, shouldSendReminders })
      if (response.status === 200) {
        const { successCount } = await response.json()
        let successMesage = `Successfully enrolled ${successCount} ${successCount && successCount > 1 ? 'users' : 'user'} to course.`
        if (shouldSendReminders) {
          if (successCount > 1) {
            successMesage += ` Sent ${successCount} reminders.`
          } else {
            successMesage += ` Sent ${successCount} reminder.`
          }
        }
        const errorCount = selectedUsers.length - successCount
        if (errorCount !== 0) {
          successMesage += ` Failed to enroll ${errorCount} users. Please try to enroll these users again.`
        }
        wereUsersEnrolled = true
        dispatch(enrollmentCountUpdatedAction({ courseId, successCount }))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage(successMesage)
        }, 0)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error enrolling users, please try again or contact Phin if problem persists.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Error enrolling users, please try again or contact Phin if problem persists.')
      }, 0)
    }
    dispatch(setIsLoadingGetEnrollableUsersAction(false))
    return Promise.resolve(wereUsersEnrolled)
  }
}
