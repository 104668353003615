import { createSlice } from '@reduxjs/toolkit'
import { integrationTypes } from '../frontendConsts.js'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    integrationsMap: null,
    hasUserSync: false,
    hasSpamFilterBypass: false,
    loaders: {
      isLoadingCompanyIntegrations: false,
      isLoadingDisableSpamBypass: false
    }
  },
  reducers: {
    setIsLoadingCompanyIntegrations: (state, action) => {
      state.loaders.isLoadingCompanyIntegrations = action.payload
    },
    getCompanyIntegrations: (state, action) => {
      state.integrationsMap = action.payload.integrationsMap
      state.hasUserSync = action.payload.hasUserSync
      state.hasSpamFilterBypass = action.payload.hasSpamFilterBypass
    },
    installMicrosoftSpamBypass: (state, action) => {
      const updatedIntegrationsMap = { ...state.integrationsMap }
      updatedIntegrationsMap.spamBypass = action.payload
      state.integrationsMap = updatedIntegrationsMap
    },
    setIsLoadingDisableSpamBypass: (state, action) => {
      state.loaders.isLoadingDisableSpamBypass = action.payload
    },
    disableSpamBypass: (state, action) => {
      const updatedIntegrationsMap = { ...state.integrationsMap }
      delete updatedIntegrationsMap.spamBypass
      state.integrationsMap = updatedIntegrationsMap
    },
    integrationStatusUpdated: (state, action) => {
      const updatedIntegrationsMap = { ...state.integrationsMap }
      if (!updatedIntegrationsMap[action.payload.integrationId]) {
        updatedIntegrationsMap[action.payload.integrationId] = {}
      }
      updatedIntegrationsMap[action.payload.integrationId].integrationStatus = action.payload.integrationStatus
      updatedIntegrationsMap[action.payload.integrationId].lastSyncDateTime = action.payload.lastSyncDateTime
      updatedIntegrationsMap[action.payload.integrationId].lastSyncSuccessDateTime = action.payload.lastSyncSuccessDateTime
      updatedIntegrationsMap[action.payload.integrationId].syncErrorMessage = action.payload.syncErrorMessage
      state.integrationsMap = updatedIntegrationsMap
    },
    integrationDisconnected: (state, action) => {
      const updatedIntegrationsMap = { ...state.integrationsMap }

      delete updatedIntegrationsMap[action.payload]

      state.integrationsMap = updatedIntegrationsMap
    },
    integrationUpdated: (state, action) => {
      const updatedIntegrationsMap = { ...state.integrationsMap }
      if (!updatedIntegrationsMap[action.payload.integrationId]) {
        updatedIntegrationsMap[action.payload.integrationId] = {}
      }
      for (const [updatedFieldName, updatedFieldValue] of Object.entries(action.payload.updatedFields)) {
        updatedIntegrationsMap[action.payload.integrationId][updatedFieldName] = updatedFieldValue
      }

      state.integrationsMap = updatedIntegrationsMap
    }
  }
})

export const {
  setIsLoadingCompanyIntegrations: setIsLoadingCompanyIntegrationsAction,
  getCompanyIntegrations: getCompanyIntegrationsAction,
  installMicrosoftSpamBypass: installMicrosoftSpamBypassAction,
  setIsLoadingDisableSpamBypass: setIsLoadingDisableSpamBypassAction,
  disableSpamBypass: disableSpamBypassAction,
  integrationStatusUpdated: integrationStatusUpdatedAction,
  integrationDisconnected: integrationDisconnectedAction,
  integrationUpdated: integrationUpdatedAction
} = integrationsSlice.actions

export default integrationsSlice.reducer

export const getCompanyIntegrationsThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCompanyIntegrationsAction(true))

    try {
      const res = await api.get(`/api/companies/${id}/integrations`)
      if (res.status === 200) {
        const integrations = await res.json()

        const integrationsMap = {}
        let hasUserSync = false
        let hasSpamFilterBypass = false
        for (const integration of integrations) {
          const { id: integrationId } = integration
          if (integrationsMap[integrationId] === undefined) {
            integrationsMap[integrationId] = {}
          }
          if (integrationId === integrationTypes.USER_SYNC) {
            hasUserSync = true
          }
          if (integrationId === 'spamFilterBypass') {
            hasSpamFilterBypass = true
          }
          integrationsMap[integrationId] = integration
        }

        dispatch(getCompanyIntegrationsAction({ integrationsMap, hasUserSync, hasSpamFilterBypass }))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load company integrations. Please try again or contact Phin suport if the issue persists.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load company integrations. Please try again or contact Phin suport if the issue persists.')
      })
      console.error(err)
    }
    dispatch(setIsLoadingCompanyIntegrationsAction(false))
  }
}

export const installMicrosoftSpamBypassThunk = ({ id, microsoftTenantId, history }) => {
  return async (dispatch, getState, api) => {
    const res = await api.post(`/api/companies/${id}/settings/integrations/microsoft/spamBypass`, {
      tenant: microsoftTenantId
    })
    if (res.status !== 200) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('error integrating')
      }, 0)
    } else {
      const { status: integration } = await res.json()
      integration.id = integrationTypes.LEGACY_SPAM_BYPASS
      dispatch(installMicrosoftSpamBypassAction(integration))

      history.push(`/companies/${id}/integrations/spamBypass`)
    }
  }
}

export const disableSpamBypassThunk = ({ id, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingDisableSpamBypassAction(true))
    const res = await api.delete(`/api/companies/${id}/settings/integrations/microsoft/spamBypass`)
    if (res.status !== 200) {
      NotificationUtilities.sendErrorMessage('Error disabling plugin')
    } else {
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Successfully disabled plugin')
      }, 0)

      dispatch(disableSpamBypassAction())

      history.push(`/companies/${id}/integrations`)
    }
    dispatch(setIsLoadingDisableSpamBypassAction(false))
  }
}
