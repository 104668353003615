import React from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBInput } from 'mdbreact'

function SMSPreviewModal ({ template, isOpen, toggleModal }) {
  return (
    <MDBContainer>
      <MDBModal size='md' isOpen={isOpen} toggle={toggleModal}>
        <MDBModalBody>
          <MDBInput
            outline
            name='sms-message'
            label='Message'
            labelClass='text-dark'
            type='textarea'
            valueDefault={template.message}
            rows='10'
            disabled
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color='danger' onClick={toggleModal}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  )
}

export default SMSPreviewModal
