import {
  MDBContainer, MDBRow
} from 'mdbreact'
import CompanyOnboardingChecklist from './CompanyOnboardingChecklist'
import NotificationWrapper from './components/notifications/notificationWrapper'
import Spinner from './components/Spinner'
import { useSelector } from 'react-redux'

function CompanyHome ({
  id
}) {
  const { company, loaders } = useSelector((state) => state.company)
  const { activeCampaigns, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingActiveCampaigns } = campaignLoaders
  const { isLoadingCompany, isLoadingOnboardingChecklist } = loaders

  return (
    <MDBContainer>
      <NotificationWrapper />

      {(!company || !activeCampaigns || isLoadingCompany ||
      isLoadingActiveCampaigns || isLoadingOnboardingChecklist) && (
        <Spinner message='Loading Company Data' />
      )}

      {company && activeCampaigns && !isLoadingCompany &&
      !isLoadingActiveCampaigns && !isLoadingOnboardingChecklist && (
        <>
          <MDBRow className='d-flex justify-content-center'>
            <h1>{company.name}</h1>
          </MDBRow>
          <CompanyOnboardingChecklist
            id={id}
            companyName={company.name}
          />
        </>
      )}
    </MDBContainer>
  )
}

export default CompanyHome
