import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const reportViewersSlice = createSlice({
  name: 'reportViewers',
  initialState: {
    reportViewers: null,
    loaders: {
      isLoadingreportViewers: false,
      isLoadingCreateReportViewer: false,
      isLoadingUpdateReportViewer: false
    }
  },
  reducers: {
    setIsLoadingReportViewers: (state, action) => {
      state.loaders.isLoadingreportViewers = action.payload
    },
    getReportViewers: (state, action) => {
      state.reportViewers = action.payload
    },
    setIsLoadingCreateReportViewer: (state, action) => {
      state.loaders.isLoadingCreateReportViewer = action.payload
    },
    createReportViewer: (state, action) => {
      const updatedReportViewers = [...state.reportViewers]
      updatedReportViewers.push(action.payload)
      state.reportViewers = updatedReportViewers
    },
    deleteReportViewer: (state, action) => {
      let updatedReportViewers = [...state.reportViewers]
      updatedReportViewers = updatedReportViewers.filter(reportViewer => reportViewer.id !== action.payload)
      state.reportViewers = updatedReportViewers
    },
    setIsLoadingUpdateReportViewer: (state, action) => {
      state.loaders.isLoadingUpdateReportViewer = action.payload
    },
    updateReportViewer: (state, action) => {
      const updatedReportViewers = [...state.reportViewers]
      const updatedReportViewersIndex = updatedReportViewers.findIndex(reportViewer => reportViewer.id === action.payload.id)
      updatedReportViewers[updatedReportViewersIndex] = action.payload
      state.reportViewers = updatedReportViewers
    }
  }
})

export const {
  setIsLoadingReportViewers: setIsLoadingReportViewersAction,
  getReportViewers: getReportViewersAction,
  setIsLoadingCreateReportViewer: setIsLoadingCreateReportViewerAction,
  createReportViewer: createReportViewerAction,
  deleteReportViewer: deleteReportViewerAction,
  setIsLoadingUpdateReportViewer: setIsLoadingUpdateReportViewerAction,
  updateReportViewer: updateReportViewerAction
} = reportViewersSlice.actions

export default reportViewersSlice.reducer

export const getReportViewersThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingReportViewersAction(true))
    try {
      const response = await api.get(`/api/companies/${companyId}/reports/settings/viewers`)

      if (response.status === 200) {
        const reportViewers = await response.json()

        dispatch(getReportViewersAction(reportViewers))
      } else {
        NotificationUtilities.sendErrorMessage('Failed to get report viewers.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to get report viewers.')
    }
    dispatch(setIsLoadingReportViewersAction(false))
  }
}

export const createReportViewerThunk = ({ companyId, reportViewerEmail }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateReportViewerAction(true))
    try {
      const response = await api.post(`/api/companies/${companyId}/reports/settings/viewers`, { reportViewerEmail })

      if (response.status === 200) {
        const { reportViewer } = await response.json()
        dispatch(createReportViewerAction(reportViewer))
        return true
      }
    } catch (error) {
      console.error(error)
    }
    NotificationUtilities.sendErrorMessage('Failed to add new report viewer. Please try again or contact Phin Support if issue persists.')
    dispatch(setIsLoadingCreateReportViewerAction(false))
    return false
  }
}

export const deleteReportViewerThunk = ({ companyId, id }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.delete(`/api/companies/${companyId}/reports/settings/viewers/${id}`)
      if (response.status === 200) {
        dispatch(deleteReportViewerAction(id))
      } else {
        NotificationUtilities.sendErrorMessage('Failed to delete report viewer. Please try again or contact Phin Support if issue persists.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to delete report viewer. Please try again or contact Phin Support if issue persists.')
    }
  }
}

export const updateReportViewerThunk = ({ companyId, id, reportViewerEmail }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingUpdateReportViewerAction(true))
    try {
      const response = await api.put(`/api/companies/${companyId}/reports/settings/viewers/${id}`, { reportViewerEmail })
      if (response.status === 200) {
        const { reportViewer } = await response.json()
        await dispatch(updateReportViewerAction(reportViewer))
        dispatch(setIsLoadingUpdateReportViewerAction(false))
        return true
      }
    } catch (error) {
      console.error(error)
    }
    setTimeout(() => {
      NotificationUtilities.sendErrorMessage('Failed to update report viewer. Please try again or contact Phin Support if issue persists.')
    })
    dispatch(setIsLoadingUpdateReportViewerAction(false))
    return false
  }
}
