import { MDBBtn, MDBTooltip, MDBIcon, MDBBadge } from 'mdbreact'

function TableBadge ({ iconName, description, color, onClick, cardName, searchValue = '===' }) {
  return (
    <div searchValue={searchValue}>
      {description !== '' &&
        <MDBTooltip placement='top'>
          <MDBBtn
            id={`${cardName}-override-button`}
            size='md'
            rounded color={color || 'primary'}
            onClick={onClick}
          >
            <MDBIcon size='lg' icon={iconName} />
          </MDBBtn>
          <div>{description}</div>
        </MDBTooltip>}

      {description === '' &&
        <MDBBadge
          id={`${cardName}-badge`}
          style={{ margin: '0.375rem' }}
          pill
          color={color}
        >
          <MDBIcon
            style={{ padding: '0.4rem 0.8rem' }}
            className='white-text'
            icon={iconName}
            size='md'
          />
        </MDBBadge>}
    </div>
  )
}

export default TableBadge
