import { Skeleton } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { orgLevels, shareLevels } from '../frontendConsts.js'
import { uploadNewLogoThunk } from '../store/LogoSlice.js'
import { getWelcomeEmailThunk } from '../store/WelcomeEmailSlice.js'
import WelcomeEmail from './components/branding/WelcomeEmail'
import NotificationUtilities from './components/notifications/notificationUtils.js'
import NotificationWrapper from './components/notifications/notificationWrapper.js'
import ImageSettings from './components/settingsPage/ImageSettings.js'
import EmailBranding from './components/branding/EmailBranding'
import ChangeCompanyName from './components/branding/ChangeCompanyName.js'

function CompanyBrandingPage ({ id }) {
  const { logoUrl } = useSelector(state => state.logo)
  const { isLoadingLogo } = useSelector((state) => state.logo.loaders)
  const { loaders } = useSelector((state) => state.welcomeEmail)
  const { isLoadingWelcomeEmail } = loaders
  const { isLoadingGroups } = useSelector((state) => state.users.loaders)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWelcomeEmailThunk(id, orgLevels.COMPANY))
  }, [])

  async function handleLogoImageUpload (fileInput) {
    if (fileInput[0].type.indexOf('image') === -1) {
      NotificationUtilities.sendErrorMessage('Please Upload an Image')
      return
    }

    const reader = new FileReader()

    try {
      reader.readAsDataURL(fileInput[0])
      reader.onload = async (e) => {
        const formData = new FormData()
        formData.append('image', fileInput[0], fileInput[0].name)
        dispatch(uploadNewLogoThunk({ formData, id, shareLevel: shareLevels.CLIENT }))
      }
      reader.onerror = (err) => {
        console.error(err)
        throw new Error('Error while reading file')
      }
    } catch (err) {
      console.error(err)
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('There was an error reading your file, please try again')
      })
    }
  }

  return (
    <>
      <NotificationWrapper />
      {(isLoadingLogo || isLoadingWelcomeEmail || isLoadingGroups) && (
        <>
          <div className='pageContainer'>
            <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1190} height={725} />
          </div>
          <div className='pageContainer'>
            <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1190} height={1400} />
          </div>
        </>
      )}

      {(!isLoadingLogo && !isLoadingWelcomeEmail && !isLoadingGroups) && (
        <>
          <ChangeCompanyName id={id} />
          <ImageSettings
            id='imageSettings'
            logoURL={logoUrl}
            logoShareLevel={shareLevels.CLIENT}
            handleLogoImageChange={handleLogoImageUpload}
            shouldFocusImageSettings={false}
          />
          <WelcomeEmail id={id} orgLevel={orgLevels.COMPANY} />
          <EmailBranding id={id} />
        </>
      )}

    </>
  )
}

export default CompanyBrandingPage
