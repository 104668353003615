import React, { Component } from 'react'
import SelectOptions from '../SelectOptions'
import NotificationUtilities from '../notifications/notificationUtils'
import { sendTestEmail, parseMustacheTemplate, validateSubmittedTemplate } from '../../../utils/templateUtils'
import remove from 'lodash/remove'
import {
  MDBCol,
  MDBRow,
  MDBInput,
  MDBModalFooter,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBIcon,
  MDBAlert,
  MDBBadge,
  MDBTooltip
} from 'mdbreact'

import HTMLTemplateOptions from './HTMLTemplateOptions'
import SMSTemplateOptions from './SMSTemplateOptions'
import BaseTemplateOptions from './BaseTemplateOptions'
import ValidationUtils from '../../../utils/validationUtils'
import './TemplateBuilder.css'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { PHISHING_TEMPLATE_INJECTED_FIELDS } from '../../../frontendConsts'

// weird global reference to editor to escape all react management
let templateEditor

let updateTemplateTimer
const updateTemplateTimerTimeout = 2000

class TemplateBuilder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedTemplate: this.generateInitialTemplateState(),
      testEmail: '',
      displayTestEmailButton: false,
      loading: false,
      displayHTMLUpload: false,
      hasActionURLField: false,
      tagInput: ''
    }

    if (this.props.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      this.state.selectedTemplate = this.props.editTemplate
      this.appendTagsIfMissing(this.state.selectedTemplate)
    }

    this.templateUpdateCallback = this.props.templateUpdateCallback
  }

  componentDidUpdate (prevProps) {
    if (prevProps && this.props.editTemplate !== prevProps.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      const templateCopy = Object.assign({}, this.props.editTemplate)
      this.appendTagsIfMissing(templateCopy)
      this.setState({ selectedTemplate: templateCopy, displayHTMLUpload: false })
    }
  }

  generateInitialTemplateState () {
    return {
      name: '',
      type: '',
      channel: '',
      fromName: '',
      fromEmail: '',
      injectedFields: [],
      shared: false,
      tags: [],
      appeals: [],
      learningUrl: '',
      learningType: 'lm'
    }
  }

  appendTagsIfMissing (selectedTemplate) {
    if (selectedTemplate.tags === undefined) {
      selectedTemplate.tags = []
    }
  }

  async sendTemplateTestEmail () {
    try {
      if (this.validateTestEmail()) {
        const emailObj = { templateName: this.state.selectedTemplate.name, content: this.state.selectedTemplate.html, email: this.state.testEmail }
        await sendTestEmail(this.props.id, emailObj, apiRequestUtils)
        this.setState({
          testEmail: ''
        })
      }
    } catch (err) {
      console.log(err)
      NotificationUtilities.sendErrorMessage('Test email failed to send! Please try again.')
    }
  }

  validateTestEmail () {
    if (!ValidationUtils.isNotWhiteSpace(this.state.selectedTemplate.html)) {
      NotificationUtilities.sendWarningMessage('You must have a template uploaded before sending a test email')
      return false
    }

    if (!ValidationUtils.isValidEmail(this.state.testEmail)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid email to send a test email')
      return false
    }

    return true
  }

  filterSystemInjectedFields (injectedFields) {
    const filteredFields = injectedFields.filter(field => { return field !== 'action_url' })
    return filteredFields
  }

  parseSMSInjectedFields (text) {
    try {
      const injectedFields = parseMustacheTemplate(text)
      return injectedFields
    } catch (err) {
      // Errors are acceptable here (still finishing a tag for example)
      return []
    }
  }

  loadFromTemplateObject (editor, templateObj, type = 'full') {
    editor.DomComponents.clear()
    editor.CssComposer.clear()
    editor.UndoManager.clear()
    if (type === 'full') {
      editor.setStyle(JSON.parse(templateObj.styles))
      editor.setComponents(JSON.parse(templateObj.components))
    } else if (type === 'html-string') {
      editor.setComponents(templateObj.html)
    }
  }

  clearEditor (editor) {
    editor.DomComponents.clear()
    editor.CssComposer.clear()
    editor.UndoManager.clear()
    editor.setStyle([])
    editor.setComponents([])
  }

  loadGrapesTemplates (editor, templateObj) {
    this.loadFromTemplateObject(editor, templateObj, 'full')
  }

  loadLegacyTemplate (editor, templateObj) {
    this.loadFromTemplateObject(editor, templateObj, 'html-string')
  }

  setTemplateEditor (editor) {
    templateEditor = editor
    templateEditor.on('storage:store', (obj) => {
      for (const [key, value] of Object.entries(obj)) {
        this.state.selectedTemplate[key] = value
      }
      this.setState({ selectedTemplate: this.state.selectedTemplate })
    })
    const viewComponentsButton = templateEditor.Panels.getButton('options', 'sw-visibility')
    // update broken icon. MDBReact must override the Font Awesome version or something
    viewComponentsButton.attributes.className = 'fa fa-eye'

    templateEditor.on('storage:load', (obj) => {
      const isGrapesTemplate = this.state.selectedTemplate && this.state.selectedTemplate.components !== undefined
      const isLegacyTemplate = this.state.selectedTemplate.components === undefined && this.state.selectedTemplate.html !== undefined

      if (isGrapesTemplate) {
        this.loadGrapesTemplates(templateEditor, this.state.selectedTemplate)
      } else if (isLegacyTemplate) {
        this.loadLegacyTemplate(templateEditor, this.state.selectedTemplate)
      } else {
        // reset the editor when we call load with no selected template (i.e. new templates)
        this.clearEditor(templateEditor)
      }
    })

    function updateInjectedFields (newFields, oldFields) {
      const newFieldNames = newFields.map(newField => newField.name)
      const results = oldFields.filter(oldField => newFieldNames.includes(oldField.name))
      newFields.forEach((newField, i) => {
        const existingInjectedFieldIndex = oldFields.findIndex(oldField => oldField.name === newField.name)
        if (existingInjectedFieldIndex < 0) {
          results.push(newFields[i])
        }
      })
      return results
    }

    const updateTemplate = () => {
      clearTimeout(updateTemplateTimer)
      this.setState({ loading: true })

      // editor gets destroyed on cancel. This makes sure we don't try to update when 'remove' events
      // are fired when the editor is unmounted. Definitely not ideal implementation. App crashes if it
      // attempts to update selectedTemplate when editor is unmounted
      if (this.props.display) {
        updateTemplateTimer = setTimeout(() => {
          const updatedTemplate = Object.assign({}, this.state.selectedTemplate)
          updatedTemplate.css = templateEditor.getCss()
          updatedTemplate.html = templateEditor.getHtml()
          updatedTemplate.components = templateEditor.getComponents()
          updatedTemplate.styles = templateEditor.getStyle()

          const injectedFields = parseMustacheTemplate(updatedTemplate.html)
          updatedTemplate.injectedFields = updateInjectedFields(injectedFields, updatedTemplate.injectedFields)
          this.setState({ loading: false, selectedTemplate: updatedTemplate })
        }, updateTemplateTimerTimeout)
      }
    }

    templateEditor.on('component:add', (model) => {
      updateTemplate(templateEditor)
    })
    templateEditor.on('component:update', (model) => {
      updateTemplate(templateEditor)
    })
    templateEditor.on('component:remove', (model) => {
      updateTemplate(templateEditor)
    })
  }

  handleFileUpload (event) {
    // Don't expend resources reading a file that is empty
    if (!event.length) {
      NotificationUtilities.sendWarningMessage('The file you uploaded appears to be empty!')
      return
    }

    if (!event[0].name.includes('.html') || !event[0].name.includes('.htm')) {
      NotificationUtilities.sendWarningMessage('Please upload an html file')
      return
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      const template = this.state.selectedTemplate
      template.html = reader.result

      try {
        const injectedFields = parseMustacheTemplate(template.html)
        template.injectedFields = injectedFields

        this.setState({ selectedTemplate: template, displayTestEmailButton: true })
        this.props.toggle('email')
      } catch (error) {
        NotificationUtilities.sendErrorMessage('There was an error in your template. Please make sure the syntax is correct.')
      }
    }

    // Start the read
    reader.readAsText(event[0])
  }

  handleInjectedFieldEdit (event) {
    const injectedFields = this.state.selectedTemplate.injectedFields

    injectedFields.forEach((injectedField, index) => {
      if (injectedField.name === event.info.name) {
        injectedFields[index] = {
          value: event.info.value,
          name: injectedField.name
        }
      }
    })

    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.injectedFields = injectedFields

    this.setState({ selectedTemplate })
  }

  cancelEdit () {
    clearTimeout(updateTemplateTimer)

    const { toggle } = this.props
    toggle('create')

    const template = this.generateInitialTemplateState()
    this.setState({ loading: false, selectedTemplate: template })
  }

  handleNameEdit (event) {
    const template = this.state.selectedTemplate
    template.name = event.target.value
    this.setState({ selectedTemplate: template })
  }

  handleChannelEdit (event) {
    const template = this.generateInitialTemplateState()
    template.channel = event.target.value
    template.name = this.state.selectedTemplate.name

    this.setState({ selectedTemplate: template })
  }

  handleTestEmailEdit (event) {
    this.setState({ testEmail: event.target.value })
  }

  handleSubjectEdit (event) {
    const template = this.state.selectedTemplate
    template.subject = event.target.value
    this.setState({ selectedTemplate: template })
  }

  handleFromNameEdit (event) {
    const template = this.state.selectedTemplate
    template.fromName = event.target.value
    this.setState({ selectedTemplate: template })
  }

  handleEmailNameEdit (event) {
    const template = this.state.selectedTemplate
    const domain = template.fromEmail.split('@')[1]
    template.fromEmail = `${event.target.value}@${domain}`
    this.setState({ selectedTemplate: template })
  }

  handleEmailDomainEdit (event) {
    const template = this.state.selectedTemplate
    const name = template.fromEmail.split('@')[0]
    template.fromEmail = `${name}@${event.target.value}`
    this.setState({ selectedTemplate: template })
  }

  handleSMSMessageEdit (event) {
    const template = this.state.selectedTemplate
    template.message = event.target.value

    const injectedFields = this.parseSMSInjectedFields(template.message)
    template.injectedFields = injectedFields

    this.setState({ selectedTemplate: template })
  }

  tagEnterListener (event) {
    const ENTER_KEY = 13
    if (event.charCode === ENTER_KEY || !event.charCode) {
      this.handleTagAdd()
    }
  }

  handleTagEdit (event) {
    event.preventDefault()
    this.setState({ tagInput: event.target.value })
  }

  handleTagAdd () {
    if (ValidationUtils.isNotWhiteSpace(this.state.tagInput)) {
      this.addTagToSelectedTemplate(this.state.tagInput)
    } else {
      NotificationUtilities.sendWarningMessage('You must have a title for this tag to add it')
    }
  }

  updateTags (tags) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.tags = tags
    this.setState({
      selectedTemplate
    })
  }

  updateLearningType (type) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.learningType = type
    this.setState({
      selectedTemplate
    })
  }

  updateLearningUrl (val) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.learningUrl = val
    this.setState({
      selectedTemplate
    })
  }

  handleTagRemoval (tag) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.tags = remove(selectedTemplate.tags, (val) => { return val !== tag })
    this.setState({ selectedTemplate })
  }

  addTagToSelectedTemplate (tag) {
    const selectedTemplate = this.state.selectedTemplate
    selectedTemplate.tags.push(tag)
    this.setState({ selectedTemplate, tagInput: '' })
  }

  submitUpdatedTemplate () {
    const template = this.state.selectedTemplate
    if (template.channel === 'email') {
      templateEditor.store()
    }

    if (!validateSubmittedTemplate(template)) {
      return
    }

    // If we were passed a template to edit, handle this as an edit if not create new
    if (this.props.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      this.templateUpdateCallback(template, true)
      this.cancelEdit()
    } else {
      this.templateUpdateCallback(template)
      this.cancelEdit()
    }
    this.setState({ loading: false })
  }

  renderSpecialTemplateOptions () {
    const { sendingDomains, toggle } = this.props
    if (this.state.selectedTemplate.channel === '') {
      return
    }

    if (this.state.selectedTemplate.channel === 'email') {
      return (
        <HTMLTemplateOptions
          sendingDomains={sendingDomains}
          selectedTemplate={this.state.selectedTemplate}
          displayTestEmailButton={this.state.displayTestEmailButton}
          displayHTMLUpload={this.state.displayHTMLUpload}
          handleEmailDomainEdit={this.handleEmailDomainEdit.bind(this)}
          handleEmailNameEdit={this.handleEmailNameEdit.bind(this)}
          handleFileUpload={this.handleFileUpload.bind(this)}
          handleTestEmailEdit={this.handleTestEmailEdit.bind(this)}
          setTemplateEditor={this.setTemplateEditor.bind(this)}
          templateEditor={templateEditor}
          toggle={toggle}
          sendTemplateTestEmail={this.sendTemplateTestEmail.bind(this)}
          handleSubjectEdit={this.handleSubjectEdit.bind(this)}
          handleFromNameEdit={this.handleFromNameEdit.bind(this)}
        />
      )
    } else {
      return (
        <SMSTemplateOptions
          handleSMSMessageEdit={this.handleSMSMessageEdit.bind(this)}
          selectedTemplate={this.state.selectedTemplate}
        />
      )
    }
  }

  renderTemplateTags () {
    const tags = this.state.selectedTemplate.tags
    const renderedTags = []

    for (const tag of tags) {
      renderedTags.push(
        <a onClick={() => this.handleTagRemoval(tag)}>
          <h4 id={tag} className='m-2'><MDBBadge color='secondary'>{tag} <MDBIcon className='ml-1' size='sm' icon='times' /></MDBBadge></h4>
        </a>
      )
    }

    return renderedTags
  }

  renderTemplateOptions (tableData) {
    const specialTemplateOptions = this.renderSpecialTemplateOptions()
    const renderedTags = this.renderTemplateTags()

    if (specialTemplateOptions) {
      return (
        <>
          {specialTemplateOptions}
          <BaseTemplateOptions
            updateTags={this.updateTags.bind(this)}
            updateLearningType={this.updateLearningType.bind(this)}
            updateLearningUrl={this.updateLearningUrl.bind(this)}
            tags={this.state.selectedTemplate.tags}
            categories={this.props.categories}
            learningType={this.state.selectedTemplate.learningType}
            learningUrl={this.state.selectedTemplate.learningUrl}
            tableData={tableData}
            id={this.props.id}
          />
        </>
      )
    } else {
      return (
        <MDBRow className='d-flex justify-content-center mr-2 ml-2 mt-4'>
          <MDBAlert color='secondary'>
            <MDBRow>
              <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
                <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='question-circle' />
              </MDBCol>
              <MDBCol>
                Select a template type to change additional options here.
              </MDBCol>
            </MDBRow>
          </MDBAlert>
        </MDBRow>
      )
    }
  }

  renderInjectedFieldsOptions (injectedFields, options) {
    const renderedInjectedRows = []

    for (const field of injectedFields) {
      renderedInjectedRows.push({
        name: field.name,
        options: (
          <SelectOptions
            name='templateFields'
            key={`${field.name}`}
            options={options}
            value={field.value}
            onChange={e =>
              this.handleInjectedFieldEdit({
                target: { name: e.target.name },
                info: {
                  name: field.name,
                  value: e.target.value
                }
              })}
          />
        )
      })
    }

    return renderedInjectedRows
  }

  renderSubmitButtonText () {
    if (this.state.loading) {
      return (
        <div
          className='spinner-border'
        />
      )
    } else if (this.props.editTemplate && Object.keys(this.props.editTemplate).length > 0) {
      return 'Save'
    } else {
      return 'Create'
    }
  }

  render () {
    const {
      display,
      typeOptions,      
    } = this.props

    const { injectedFields } = this.state.selectedTemplate

    const injectedOptions = [
      {
        text: 'Select a Value',
        value: '',
        disabled: true,
        selected: true
      }
    ]

    for (const injectedField in PHISHING_TEMPLATE_INJECTED_FIELDS) {
      injectedOptions.push({
        text: PHISHING_TEMPLATE_INJECTED_FIELDS[injectedField].label,
        value: PHISHING_TEMPLATE_INJECTED_FIELDS[injectedField].value
      })
    }

    const renderedInjectedRows = this.renderInjectedFieldsOptions(injectedFields, injectedOptions)

    const tableData = {
      columns: [
        {
          label: 'Field Name'
        },
        {
          label: 'Selected Value'
        }
      ],
      rows: renderedInjectedRows
    }

    const renderedTemplateOptions = this.renderTemplateOptions(tableData)

    const submitButtonText = this.renderSubmitButtonText()

    return (
      <MDBModal
        size='lg'
        isOpen={display}
        className='template-builder-size'
        modalStyle='info'
        // toggle must be defined due to a bug in MDBModal
        toggle={() => { }}
      >
        <MDBModalHeader className='text-center'>
          Template Builder
        </MDBModalHeader>

        <MDBModalBody>
          <MDBCard>
            <MDBCardHeader>
              <MDBRow className='d-flex'>
                <MDBCol className='d-flex justify-content-start align-items-center'>
                  <h5>Template Name and Type</h5>
                </MDBCol>
                <MDBCol className='d-flex justify-content-end'>
                  <MDBTooltip
                    placement='left'
                    id='popover23'
                    material
                  >
                    <MDBBtn
                      color='elegant'
                      className='my-0'
                      floating
                      tag='a'
                      size='sm'
                    >
                      <MDBIcon icon='question' />
                    </MDBBtn>
                    <div>
                      Name your template to keep track
                      of it. Then, select if it will
                      be an email template or a text (sms) template.
                    </div>
                  </MDBTooltip>
                </MDBCol>
              </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow className='d-flex justify-content-center'>
                <MDBCol
                  className='d-flex justify-content-center align-items-center'
                  size='6'
                >
                  <MDBInput
                    id='template-name-input'
                    outline
                    name='templateName'
                    label='Template Name'
                    labelClass='text-dark'
                    value={this.state.selectedTemplate.name}
                    onChange={e => this.handleNameEdit(e)}
                  />
                </MDBCol>

                <MDBCol
                  className='d-flex justify-content-center align-items-center'
                  size='6'
                >
                  <SelectOptions
                    name='templateChannel'
                    options={typeOptions}
                    value={this.state.selectedTemplate.channel}
                    onChange={e => this.handleChannelEdit(e)}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {renderedTemplateOptions}

        </MDBModalBody>

        <MDBModalFooter>
          <MDBBtn
            color='red'
            value='cancel'
            disabled={this.state.loading}
            onClick={() => this.cancelEdit()}
          >
            Cancel
          </MDBBtn>
          <MDBBtn
            id='template-builder-submit-button'
            onClick={e => {
              this.submitUpdatedTemplate()
            }}
            disabled={this.state.loading}
            type='submit'
            color='primary'
          >
            {submitButtonText}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    )
  }
}

export default TemplateBuilder
