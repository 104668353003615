import { Alert, Button, Card, Grid, Skeleton, TextField } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { enableAutomatedBillingThunk } from '../../../store/PartnerBillingSlice'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../../components/notifications/notificationUtils'

export function EnableAutomatedBillingWidget () {
  const dispatch = useDispatch()
  const { partner } = useSelector((state) => state.partner)
  const { isAutomaticBillingReady, loaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingIsAutomaticBillingReady } = loaders

  const [billingCompanyName, setBillingCompanyName] = useState('')
  const [billingEmail, setBillingEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitAutomatedBillingForm = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      if (!isLoadingIsAutomaticBillingReady) {
        if (!ValidationUtils.isNotWhiteSpace(billingCompanyName)) {
          NotificationUtilities.sendWarningMessage('Please provide a valid company billing name.')
          setIsSubmitting(false)
          return
        }

        if (!ValidationUtils.isValidEmail(billingEmail)) {
          NotificationUtilities.sendWarningMessage('Please provide a valid billing email address.')
          setIsSubmitting(false)
          return
        }

        await dispatch(enableAutomatedBillingThunk({ partnerId: partner.id, billingCompanyName, billingEmail }))
        setIsSubmitting(false)
      }
    }
  }

  return (
    <>
      {(partner && !partner.automaticBillingEnabled) && (
        <>
          {(isLoadingIsAutomaticBillingReady) && (
            <Skeleton width={750} height={400} />
          )}

          {(!isLoadingIsAutomaticBillingReady && !isAutomaticBillingReady) && (
            <div className='margin-bottom:2'>
              <Grid
                container
                s={4}
              >
                <Alert severity='warning' variant='outlined' sx={{ background: 'var(--phin-light-yellow)' }}>
                  <div className='phin-h4 padding:0'>Unable to configure Automated Billing. Please reach out to <a href='https://www.phinsec.io/knowledge/kb-tickets/new'>Phin Support.</a></div>
                </Alert>
              </Grid>
            </div>
          )}

          {(!isLoadingIsAutomaticBillingReady && isAutomaticBillingReady && (partner && !partner.automaticBillingEnabled)) && (
            <Card
              variant='outlined'
              sx={{ width: 500 }}
            >
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                sx={{ padding: 'var(--phin-s2)' }}
              >
                <div className='phin-h4 padding:0'>Enable Automated Billing</div>

                <Grid sx={{ marginBottom: 'var(--phin-s-1)' }}>
                  <Alert severity='info' variant='outlined' sx={{ background: 'var(--phin-light-blue)' }}>
                    Phin uses ChargeBee to handle our Automated Billing. Create an account to get started.
                  </Alert>
                </Grid>

                <TextField
                  id='billing-company-name'
                  label='Company Name'
                  value={billingCompanyName}
                  fullWidth
                  onChange={(e) => {
                    setBillingCompanyName(e.target.value)
                  }}
                  sx={{ marginBottom: 'var(--phin-s-1)' }}
                />

                <TextField
                  id='billing-email-address'
                  label='Billing Email'
                  value={billingEmail}
                  fullWidth
                  onChange={(e) => {
                    setBillingEmail(e.target.value)
                  }}
                  sx={{ marginBottom: 'var(--phin-s-1)' }}
                />

                <Button
                  id='enable-automated-billing-button'
                  aria-label='Enroll In Automated Billing Button'
                  variant='contained'
                  disabled={isSubmitting}
                  onClick={() => { submitAutomatedBillingForm() }}
                  sx={{ margin: 'var(--phin-s0)' }}
                >
                  Enable
                </Button>

              </Grid>
            </Card>
          )}
        </>
      )}
    </>
  )
}
