import {
  MDBCol, MDBBtn, MDBRow, MDBCard, MDBIcon, MDBCardBody, MDBCardHeader,
  MDBNav, MDBNavItem, MDBTooltip
} from 'mdbreact'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { campaignTypes } from '../../../frontendConsts.js'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

function AudienceFilters ({
  groups, departmentNames, campaignType,
  parameters, setParameters, audienceFilterType,
  setAudienceFilterType, supervisorEmails, filterText,
  description, users = null, showHeader = true
}) {
  function generatePresetCustomization () {
    const autoCompleteProps = {
      options: groups,
      getOptionLabel: (option) => option.displayName
    }
    switch (audienceFilterType) {
      case 'group':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.group ? filterText.group : 'This filter will include every active user within the specified groups.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  <Autocomplete
                    {...autoCompleteProps}
                    disablePortal
                    multiple
                    id='groups-audience-dropdown-autocomplete'
                    sx={{ width: 500 }}
                    value={parameters}
                    onChange={handleGroupChange}
                    autoComplete
                    renderInput={(params) => <TextField {...params} label='Group' />}
                  />
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'first':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.all ? filterText.all : 'This filter will include every active user.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'supvemail':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.supervisor ? filterText.supervisor : 'This filter will include every active user with the specified supervisor.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-2'>
              <MDBCol>
                <FormControl style={{ width: '35%', marginTop: '.5rem', marginLeft: '35%' }}>
                  <InputLabel id='supervisor-email'>Supervisor Email</InputLabel>
                  <Select
                    id='supervisor-email-audience-select'
                    labelId='supervisor-email'
                    value={parameters[0].value}
                    label='Supervisor Email'
                    onChange={handleChange}
                  >
                    {supervisorEmails.map((email) => (
                      <MenuItem value={email} key={email}>{email}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBCol>
            </MDBRow>
          </>
        )
      case 'deptname':
        return (
          <>
            <MDBRow className='mt-2'>
              <MDBCol>
                <MDBRow className='d-flex justify-content-center'>
                  {filterText?.department ? filterText.department : 'This filter will include every active user within the specified department.'}
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-2'>
              <MDBCol>
                <FormControl style={{ width: '35%', marginTop: '.5rem', marginLeft: '35%' }}>
                  <InputLabel id='demo-simple-select-label'>Department Name</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='dept-name-audience-select'
                    value={parameters[0].value}
                    label='Department Name'
                    onChange={handleChange}
                  >
                    {departmentNames.map((deptName) => (
                      <MenuItem value={deptName} key={deptName}>{deptName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBCol>
            </MDBRow>
          </>
        )
      default:
        console.log('No Case Found')
    }
  }

  function handleChange (e) {
    const value = e.target.value
    e.preventDefault()
    parameters[0].value = value
    setParameters([...parameters])
  }

  const handleGroupChange = (e, newValue) => {
    // MUI already packs the group objects into an array, no need to do a transformation here!
    const value = newValue
    setParameters(value)
  }

  const selectDifferentAudienceFilter = (newAudienceFilterType) => {
    const parameters = []
    switch (newAudienceFilterType) {
      case 'group':
        setAudienceFilterType('group')
        setParameters(parameters)
        break
      case 'first':
        setAudienceFilterType('first')
        parameters.push({
          field: 'first',
          type: '!=',
          value: '~~~'
        })
        setParameters(parameters)
        break
      case 'supvemail':
        setAudienceFilterType('supvemail')
        parameters.push({
          field: 'supvemail',
          type: '==',
          value: null
        })
        setParameters(parameters)
        break
      case 'deptname':
        setAudienceFilterType('deptname')
        parameters.push({
          field: 'deptname',
          type: '==',
          value: null
        })
        setParameters(parameters)
        break
    }
  }

  const userColumns = [
    { field: 'last', headerName: 'Last name', flex: 1 },
    { field: 'first', headerName: 'First name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 }
  ]

  return (
    <MDBCard className='w-100'>
      {showHeader &&
        <MDBCardHeader>
          <MDBRow className='d-flex'>
            <MDBCol className='d-flex justify-content-start align-items-center'>
              <h5>Audience Filters</h5>
            </MDBCol>
            <MDBCol className='d-flex justify-content-end'>
              <MDBTooltip
                placement='left'
                material
                id='popover23'
              >
                <MDBBtn
                  color='elegant'
                  className='my-0'
                  floating
                  tag='a'
                  size='sm'
                >
                  <MDBIcon icon='question' />
                </MDBBtn>
                <div>
                  Select one of the below filters to choose who will be involved in this campaign.
                </div>
              </MDBTooltip>
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>}
      <MDBCardBody>
        {description && <MDBRow className='ml-2 mb-2'>{description}</MDBRow>}
        <MDBNav pills color='primary' className='nav-justified'>
          <MDBNavItem>
            <a
              id='company-campaign-audience-tab-icon'
              onClick={() => selectDifferentAudienceFilter('first')}
              className={audienceFilterType === 'first' ? 'nav-link Ripple-parent active h-100' : 'nav-link Ripple-parent h-100'}
              aria-current='page'
              style={{ pointerEvents: 'auto' }}
            >
              <MDBIcon size='lg' icon='globe' /> <br /> Full Company
            </a>
          </MDBNavItem>
          <MDBNavItem>
            <a
              id='dept-name-audience-tab-icon'
              onClick={() => selectDifferentAudienceFilter('deptname')}
              className={audienceFilterType === 'deptname' ? 'nav-link Ripple-parent active h-100' : 'nav-link Ripple-parent h-100'}
              aria-current='page'
            >
              <MDBIcon size='lg' icon='address-book' /> <br /> Department
            </a>
          </MDBNavItem>
          <MDBNavItem>
            <a
              id='supvemail-audience-tab-icon'
              onClick={() => selectDifferentAudienceFilter('supvemail')}
              className={audienceFilterType === 'supvemail' ? 'nav-link Ripple-parent active h-100' : 'nav-link Ripple-parent h-100'}
              aria-current='page'
            >
              <MDBIcon size='lg' icon='user' /> <br /> Supervisor
            </a>
          </MDBNavItem>
          {groups && groups.length > 0 &&
            <MDBNavItem>
              <a
                id='group-audience-tab-icon'
                onClick={() => selectDifferentAudienceFilter('group')}
                className={audienceFilterType === 'group' ? 'nav-link Ripple-parent active h-100' : 'nav-link Ripple-parent h-100'}
                aria-current='page'
              >
                <MDBIcon size='lg' icon='users' /> <br /> Group Campaign
              </a>
            </MDBNavItem>}
        </MDBNav>

        {generatePresetCustomization()}
        {(campaignType === campaignTypes.TRAINING || campaignType === campaignTypes.POLICY || campaignType === campaignTypes.CONTINUOUS_TRAINING) && (
          <Box style={{ width: '100%', paddingTop: '2em' }}>
            <DataGrid
              autoHeight
              rows={users || []}
              columns={userColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableExport
              disableColumnMenu
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 }
                }
              }}
            />
          </Box>
        )}
      </MDBCardBody>
    </MDBCard>
  )
}

export default AudienceFilters
