import { Alert } from '@mui/material'
import {
  MDBRow, MDBCol, MDBCard,
  MDBCardHeader, MDBCardBody
} from 'mdbreact'

function renderTopics (topics) {
  const topicsArray = []

  topics.forEach(topic => {
    topicsArray.push(<li key={topic}>{topic}</li>)
  })

  return topicsArray
}

function TrainingCampaignResource ({ trainingTopics, trainingCampaignName, trainingDescription }) {
  return (
    <MDBCard>
      {/* Training Campaign Name */}
      <MDBCardHeader><h5>{trainingCampaignName}</h5></MDBCardHeader>
      <MDBCardBody>
        {/* Training Campaign Description */}
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol>
            <div className='h5'>Description:</div>
          </MDBCol>
          <MDBCol>
            <div className='h6'>{trainingDescription}</div>
          </MDBCol>
        </MDBRow>

        <br />

        {/* Training Topics */}
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol>
            <div className='h5'>Topics:</div>
          </MDBCol>
          <MDBCol>
            <ul className='h6'>
              {renderTopics(trainingTopics)}
            </ul>
          </MDBCol>
        </MDBRow>

        <div className='margin-top:1'>
          <Alert variant='outlined' severity='info'>
            {'Continuous Training Campaigns will run continuously with a finite amount of training courses. If a campaign uses' +
              ' all of the training courses from a specific topic, the campaign will start re-using courses.'}
          </Alert>
        </div>
      </MDBCardBody>
    </MDBCard>
  )
}

export default TrainingCampaignResource
