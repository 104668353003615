import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import '../../../muiOverride.css'
import { DateTime } from 'luxon'

import { getFileThunk } from '../../../store/ReportingSettingsSlice'

import { capitalizeFirstLetter } from '../../../utils/FormattingUtils'
import { CloudDownloadButton } from '../buttons/CloudDownloadButton'
import ReportGenerator from './ReportGenerator'

function ReportingHistory ({ rows, companyId }) {
  const dispatch = useDispatch()
  const { loaders } = useSelector(state => state.reportingSettings)
  const { isDownloadingFile } = loaders

  const reportColumns = [
    { field: 'downloadFileName', headerName: 'Report Name', flex: 1 },
    { field: 'reportType', headerName: 'Report Type', renderCell: (params) => capitalizeFirstLetter(params.row.reportType), flex: 1 },
    { field: 'reportScope', headerName: 'Scope', sortable: false, filterable: false, flex: 1 },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      type: 'date',
      flex: 1,
      renderCell: (params) => DateTime.fromISO(params.row.creationDate).toFormat('LLL d, yyyy')
    },
    {
      field: 'download',
      headerName: 'Download',
      renderCell: (params) => <CloudDownloadButton id={params.row.id} isDownloading={isDownloadingFile[params.row.id]} downloadAction={() => { dispatch(getFileThunk({ fileInfo: params.row })) }} />,
      sortable: false,
      filterable: false,
      flex: 1
    }
  ]

  return (
    <Grid sx={{ mt: 6 }}>
      <div className='' style={{ alignSelf: 'start', display: 'flex', justifyContent: 'space-between' }}>
        <h4 className='phin-h4 margin-bottom:-1 margin-right:1' style={{ textAlign: 'left', display: 'inline-block' }}>Reporting History</h4>
        <ReportGenerator
          companyId={companyId}
        />
      </div>
      <div style={{ height: '65vh', width: '100%' }}>
        <DataGrid
          style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
          className='DataGrid'
          initialState={{
            sorting: {
              sortModel: [{ field: 'creationDate', sort: 'desc' }]
            }
          }}
          rows={rows}
          columns={reportColumns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true }
            }
          }}
          disableColumnSelector
          disableDensitySelector
        />
      </div>
    </Grid>
  )
}

export default ReportingHistory
