import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { setSideNavOrgDataAction, setSideNavOrgNameAction } from './SideNavSlice'
import { setTopNavPartnerDataAction, setTopNavPartnerNameAction } from './TopNavSlice'
import { orgTypes } from '../frontendConsts.js'
import { getAuthorizationSilentlyThunk } from './AuthSlice'
import { createGenericDownload } from '../utils/fileUtils'

export const partnerSlice = createSlice({
  name: 'partner',
  initialState: {
    partner: null,
    partnerAnalytics: null,
    loaders: {
      isLoadingPartner: false,
      isLoadingCreateAdmin: false,
      isLoadingDeleteAdmin: false,
      isLoadingCreateCompany: false,
      isLoadingDeleteCompany: false,
      isUploadingLogo: false,
      isDownloadingPartnerFile: {}
    },
    logoUrl: null,
    logoShareLevel: null
  },
  reducers: {
    setIsLoadingPartner: (state, action) => {
      state.loaders.isLoadingPartner = action.payload
    },
    getPartner: (state, action) => {
      state.partner = action.payload
      state.loaders.isLoadingPartner = false
    },
    createCompany: (state, action) => {
      const updatedPartner = { ...state.partner }
      updatedPartner.clients.push(action.payload)
      state.partner = updatedPartner
      state.loaders.isLoadingCreateCompany = false
    },
    setIsLoadingCreateCompany: (state, action) => {
      state.loaders.isLoadingCreateCompany = action.payload
    },
    setIsLoadingDeleteCompany: (state, action) => {
      state.loaders.isLoadingDeleteCompany = action.payload
    },
    deleteCompany: (state, action) => {
      const updatedPartner = { ...state.partner }
      const updatedCompanies = updatedPartner.clients.filter(client => client.id !== action.payload)
      updatedPartner.clients = updatedCompanies
      state.partner = updatedPartner
    },

    createAdmin: (state, action) => {
      const updatedPartner = { ...state.partner }
      updatedPartner.admins.push(action.payload)
      state.partner = updatedPartner
      state.loaders.isLoadingCreateAdmin = false
    },
    setIsLoadingCreateAdmin: (state, action) => {
      state.loaders.isLoadingCreateAdmin = action.payload
    },
    setIsLoadingDeleteAdmin: (state, action) => {
      state.loaders.isLoadingDeleteAdmin = action.payload
    },
    deleteAdmin: (state, action) => {
      const updatedPartner = { ...state.partner }
      const updatedAdmins = updatedPartner.admins.filter(({ uid: adminUid }) => adminUid !== action.payload)
      updatedPartner.admins = updatedAdmins
      state.partner = updatedPartner
      state.loaders.isLoadingDeleteAdmin = false
    },
    setIsLoadingUploadLogo: (state, action) => {
      state.loaders.isUploadingLogo = action.payload
    },
    setLogoUrl: (state, action) => {
      state.logoUrl = action.payload.logoUrl
      state.logoShareLevel = action.payload.shareLevel
      state.loaders.isUploadingLogo = false
    },
    setPartnerName: (state, action) => {
      state.partner.name = action.payload
    },
    setPartnerAutomaticBillingEnabled: (state, action) => {
      state.partner.automaticBillingEnabled = action.payload
    },
    setIsDownloadingPartnerFile: (state, action) => {
      state.loaders.isDownloadingPartnerFile[action.payload.id] = action.payload.value
    }
  }
})

export const {
  setIsLoadingPartner: setIsLoadingPartnerAction,
  getPartner: getPartnerAction,
  setIsLoadingCreateAdmin: setIsLoadingCreateAdminAction,
  createAdmin: createAdminAction,
  setIsLoadingDeleteAdmin: setIsLoadingDeleteAdminAction,
  deleteAdmin: deleteAdminAction,
  createCompany: createCompanyAction,
  setIsLoadingCreateCompany: setIsLoadingCreateCompanyAction,
  deleteCompany: deleteCompanyAction,
  setIsLoadingDeleteCompany: setIsLoadingDeleteCompanyAction,
  setLogoUrl: setLogoUrlAction,
  setIsLoadingUploadLogo: setIsLoadingUploadLogoAction,
  setPartnerName: setPartnerNameAction,
  setPartnerAutomaticBillingEnabled: setPartnerAutomaticBillingEnabledAction,
  setIsDownloadingPartnerFile: setIsDownloadingPartnerFileAction
} = partnerSlice.actions

export default partnerSlice.reducer

export const createAdminThunk = ({
  adminEmail, adminFirst, adminLast,
  id, partnerName, billingPermission
}) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateAdminAction(true))

    const billingPermissionObj = {
      admin: billingPermission === 'full-access',
      readOnly: false,
      noAccess: billingPermission === 'standard'
    }
    const res = await api.post('/api/auth/users', { email: adminEmail, displayName: `${adminFirst} ${adminLast}`, partners: { [id]: 'admin' }, whiteLabelName: partnerName, billing: { [id]: billingPermissionObj} })
    if (res.status === 200) {
      const { uid } = await res.json()
      dispatch(createAdminAction({ uid, name: `${adminFirst} ${adminLast}`, email: adminEmail }))
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Successfully created admin.')
      })
    } else {
      const { error } = await res.json()
      dispatch(setIsLoadingCreateAdminAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage(error)
      })
    }
  }
}

export const createCompanyThunk = ({
  name,
  partnerId, authId
}) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCreateCompanyAction(true))
    const res = await api.post(`/api/partners/${partnerId}/companies`, { name })
    if (res.status === 200) {
      const company = await res.json()
      await dispatch(getAuthorizationSilentlyThunk(authId))
      dispatch(createCompanyAction(company))
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Successfully created company.')
      })
      return company.id
    } else {
      const { error } = await res.json()
      dispatch(setIsLoadingCreateCompanyAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage(error)
      })
      throw new Error(error)
    }
  }
}

export const deleteAdminThunk = ({ id, uid }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingDeleteAdminAction(true))
    const res = await api.delete(`/api/auth/users/${uid}`, { partners: { [id]: 'admin' } })
    if (res.status === 200) {
      const uid = await res.json()
      dispatch(deleteAdminAction(uid))
      setTimeout(() => {
        NotificationUtilities.sendSuccessMessage('Successfully deleted admin.')
      })
    } else {
      dispatch(setIsLoadingDeleteAdminAction(false))
      const result = await res.json()
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage(`Failed to deleted admin: ${result?.error}`)
      })
    }
  }
}

export const getPartnerThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerAction(true))
    try {
      const res = await api.get(`/api/partners/${id}`)
      if (res.status === 200) {
        const partner = await res.json()
        await dispatch(getPartnerAction(partner))

        const { name, isFreeTrial, distributorId, distributorName } = partner

        dispatch(setSideNavOrgDataAction({ name, id, isFreeTrial, orgType: orgTypes.PARTNER }))

        dispatch(setTopNavPartnerDataAction({ name, id, distributorId, distributorName }))
        dispatch(setIsLoadingPartnerAction(false))
      } else {
        dispatch(setIsLoadingPartnerAction(false))
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load partner.')
        })
      }
    } catch (err) {
      dispatch(setIsLoadingPartnerAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load partner.')
      })
      console.error(err)
    }
  }
}

export const changePartnerNameThunk = (id, nameToChange) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.put(`/api/partners/${id}/update-name`, { nameToChange })
      if (res.status === 200) {
        NotificationUtilities.sendSuccessMessage('Successfully Changed Partner Name.')
        dispatch(setPartnerNameAction(nameToChange))
        dispatch(setTopNavPartnerNameAction({ name: nameToChange }))
        dispatch(setSideNavOrgNameAction({ name: nameToChange }))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update partner name.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update partner name.')
      })
      console.error(err)
    }
  }
}

export const deleteCompanyThunk = ({ partnerId, companyId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingDeleteCompanyAction(true))
    try {
      const response = await api.delete(`/api/partners/${partnerId}/companies/${companyId}`)

      if (response.status === 200) {
        const { companyId } = await response.json()
        dispatch(deleteCompanyAction(companyId))
        dispatch(setIsLoadingDeleteCompanyAction(false))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Successfully Removed Company.')
        })
      } else {
        setTimeout(() => {
          NotificationUtilities.sendWarningMessage('Error Deleting Company.')
        })
        dispatch(setIsLoadingDeleteCompanyAction(false))
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendWarningMessage('Error Deleting Company.')
      })
      dispatch(setIsLoadingDeleteCompanyAction(false))
    }
  }
}

export const getPartnerFileThunk = ({ partnerId, fileInfo, fileName }) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(setIsDownloadingPartnerFileAction({ id: fileInfo.id, value: true }))

      const res = await api.get(`/api/partners/${partnerId}/reports/download?cloudFilePath=${fileInfo.cloudFilePath}&fileType=${fileInfo.fileType}&fileName=${fileName}`)

      if (res.status === 200) {
        const reportBlob = await res.blob()

        if (reportBlob.size === 0) {
          setTimeout(() => {
            NotificationUtilities.sendErrorMessage('Error downloading report, as it is empty, please contact Phin if problem persists.')
          })
        }

        createGenericDownload({ fileBlob: reportBlob, fileName: fileInfo.reportName, fileType: fileInfo.fileType })
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error downloading report, please try again or contact Phin support for assistance')
        })
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Error downloading report, , please try again or contact Phin support for assistance')
      })
    }
    dispatch(setIsDownloadingPartnerFileAction({ id: fileInfo.id, value: false }))
  }
}
