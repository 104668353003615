import { DateTime } from 'luxon'
import { campaignTypes } from '../../../frontendConsts.js'
import { buildCRONscheduleDBObject, getNextCampaignFrequencyIntervalDBObject } from '../../../utils/dateUtils.js'
import parser from 'cron-parser'
import { useState } from 'react'
import SharedBasicModal from '../SharedBasicModal.js'
import { AddOnboardingUsersTable } from '../Campaigns/AddOnboardingUsersTable.js'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBMask, MDBTooltip, MDBView } from 'mdbreact'
import { Chip } from '@mui/material'
import { formatDateForCard } from './utils.js'
import { useDispatch, useSelector } from 'react-redux'
import { enrollUsersInOnboardingCampaignThunk } from '../../../store/CampaignsSlice.js'

export default function ActiveTrainingCampaignCard ({ campaign, openCancelCampaignModal, editTrainingCampaign, viewTrainingCampaign, company }) {
  const tz = (company && company.timezone) ? company.timezone.value : 'America/New_York'
  const currentDate = new Date()
  const dispatch = useDispatch()
  const { isLoadingOnboardingCampaignEnrollment } = useSelector((state) => state.campaigns.loaders)

  let nextFireTime
  let startDateTime

  if (campaign.campaignType !== campaignTypes.ONBOARDING) {
    startDateTime = new Date(campaign.startTime)
    const cron = buildCRONscheduleDBObject(campaign.campaignFrequency, DateTime.fromJSDate(startDateTime))
    const dateForInterval = (startDateTime > currentDate) ? startDateTime : currentDate
    const interval = parser.parseExpression(cron, { currentDate: dateForInterval, tz })
    nextFireTime = getNextCampaignFrequencyIntervalDBObject(campaign.campaignFrequency, interval, startDateTime)

    if (campaign.campaignFrequency.iterations === 'All At Once' && (startDateTime < currentDate)) {
      nextFireTime = null
    }
  }

  const [addUserModalOpen, setAddUserModalOpen] = useState(false)
  const handleOpenAddUserModal = () => setAddUserModalOpen(true)
  const handleCloseUserModal = () => setAddUserModalOpen(false)
  const [fixedAudienceList, setFixedAudienceList] = useState([])
  const [initialFixedAudienceList, setInitialFixedAudienceList] = useState([])

  const newChanges = fixedAudienceList.filter((user) => !initialFixedAudienceList.includes(user.id)).map((user) => user.id)

  const submitAddUsersToOnboarding = async () => {
    const enrollResult = await dispatch(enrollUsersInOnboardingCampaignThunk({ companyId: company.id, campaignId: campaign.id, usersList: newChanges }))

    if (enrollResult.success) {
      handleCloseUserModal()
    }
  }

  return (
    <>
      <SharedBasicModal
        width='80%'
        onClose={handleCloseUserModal}
        open={addUserModalOpen}
        confirmAction={submitAddUsersToOnboarding}
        confirmText='submit'
        disableSubmit={!newChanges.length || isLoadingOnboardingCampaignEnrollment}
        Content={<AddOnboardingUsersTable fixedAudienceList={fixedAudienceList} initialFixedAudienceList={initialFixedAudienceList} setInitialFixedAudienceList={setInitialFixedAudienceList} setFixedAudienceList={setFixedAudienceList} companyId={company.id} campaignId={campaign.id} />}
      />
      <MDBCol key={campaign.id} className='d-flex justify-content-center mt-4'>
        <MDBView style={{ overflow: 'visible' }} rounded zoom className='h-100 preset-overlay' hover>

          <MDBCard style={{ height: '100%', width: '22rem' }}>
            <MDBCardImage
              top
              waves={false}
              src={campaign.thumbnail || 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg'}
              style={{ height: '12rem' }}
            />
            <MDBCardBody>

              <MDBCardTitle>{campaign.name}</MDBCardTitle>
              {(campaign.campaignType === campaignTypes.ONBOARDING) && (
                <Chip sx={{ marginBottom: '.5rem' }} label='Onboarding Campaign' />
              )}
              {campaign.description &&
                <MDBCardText>
                  {campaign.description}
                </MDBCardText>}
              <MDBCardText />
            </MDBCardBody>
            <MDBCardFooter className='text-center rounded-bottom training-card-bottom text-center card-footer-height'>
              <ul className='list-unstyled list-inline font-small'>
                {(nextFireTime && new Date(nextFireTime) > new Date()) && (
                  <li className='list-inline-item pr-2'>
                    Next Fire Time: {formatDateForCard(new Date(nextFireTime))}
                  </li>
                )}
                {((campaign.campaignFrequency?.iterations === 'All At Once' && (startDateTime < currentDate))) && (
                  <li className='list-inline-item pr-2'>
                    Campaign finished on {formatDateForCard(new Date(startDateTime))}
                  </li>
                )}
                {((campaign.campaignType === campaignTypes.ONBOARDING)) && (
                  <li className='pr-2'>
                    Onboarding Training will be delivered automatically to matching users.
                  </li>
                )}
              </ul>
            </MDBCardFooter>
          </MDBCard>
          <MDBMask style={{ overflow: 'auto' }} className='flex-center' overlay='blue-strong' pattern={5}>
            {campaign.campaignType === campaignTypes.ONBOARDING && (
              <MDBTooltip style={{ zIndex: '2' }} className='action-button' placement='top'>
                <MDBBtn
                  floating
                  tag='a'
                  size='lg'
                  color='success'
                  action
                  onClick={() => {
                    handleOpenAddUserModal()
                  }}
                >
                  <MDBIcon size='2x' icon='plus' />
                </MDBBtn>
                <div>
                  Add New User
                </div>
              </MDBTooltip>
            )}
            <MDBTooltip style={{ zIndex: '2' }} className='action-button' placement='top'>
              <MDBBtn
                floating
                tag='a'
                size='lg'
                color='info'
                action
                onClick={() => {
                  viewTrainingCampaign(campaign)
                }}
              >
                <MDBIcon size='2x' icon='eye' />
              </MDBBtn>
              <div>
                View Campaign
              </div>
            </MDBTooltip>

            <MDBTooltip style={{ zIndex: '2' }} className='action-button' placement='top'>
              <MDBBtn
                floating
                tag='a'
                size='lg'
                color='warning'
                action
                onClick={() => {
                  editTrainingCampaign(campaign)
                }}
              >
                <MDBIcon size='2x' icon='pencil-alt' />
              </MDBBtn>
              <div>
                Edit Campaign
              </div>
            </MDBTooltip>

            <MDBTooltip placement='top'>
              <MDBBtn
                floating
                tag='a'
                color='danger'
                size='lg'
                action
                onClick={() => {
                  openCancelCampaignModal(campaign)
                }}
              >
                <MDBIcon size='2x' icon='trash-alt' className='primary' />
              </MDBBtn>
              <div>
                Cancel Campaign
              </div>
            </MDBTooltip>
            {/* feature locked down for the future
            <MDBTooltip placement='top'>
              <MDBBtn
                floating
                tag='a'
                color='success'
                size='lg'
                action
                onClick={() => {
                  openCampaignLaunchModal(campaign)
                }}
              >
                <MDBIcon fas icon='rocket' className='primary' />
              </MDBBtn>
              <div>
                Launch Campaign
              </div>
            </MDBTooltip> */}

          </MDBMask>
        </MDBView>
      </MDBCol>
    </>
  )
}
