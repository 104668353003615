import { useState } from 'react'
import { useDispatch } from 'react-redux'
import './CampaignFrequencySelector.css'
import SelectOptions from '../../SelectOptions'
import InlineMultiSelect from '../../InlineMultiSelect'
import { setIterationsAction, setFrequencyAction, setMonthAction, setWeekAction, setWeekdayAction, setCampaignFireTimeAction } from '../../../../store/CampaignFrequencySlice'
import TextField from '@mui/material/TextField'
import MomentUtils from '@date-io/moment'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { MDBRow, DatePicker, MDBTooltip, MDBBtn, MDBIcon } from 'mdbreact'
import CampaignScheduleDisplay from './CampaignScheduleDisplay'
import moment from 'moment'
import { campaignTypes } from '../../../../frontendConsts.js'

const CampaignFrequencySelector = ({
  startTime, setStartTime,
  campaignFrequency, tz, campaignType,
  isRunning, nextFireTimes, firstFireDate,
  isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid,
  nextFireTimesErrorMessage, modules // modules is optional
}) => {
  const {
    iterations, iterationsOptions, frequency,
    frequencyOptions, month, monthOptions,
    week, weekOptions, weekday, weekdayOptions
  } = campaignFrequency

  const dispatch = useDispatch()

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  const renderOptions = (activeValue, setActiveValue, options) => {
    return (activeValue
      ? (
        <SelectOptions
          value={activeValue.length ? activeValue[0].value : activeValue.value}
          label=''
          outerClassName='d-inline-block'
          innerClassName='mad-lib-picker'
          onChange={
          (e) => setActiveValue(activeValue.length
            ? [{ text: e.target.value, value: e.target.options[e.target.selectedIndex].value }]
            : { text: e.target.value, value: e.target.options[e.target.selectedIndex].value })
        }
          options={options}
        />
        )
      : (<></>)
    )
  }

  const shouldBeDisabled = (option, selectedOptions) => {
    if (frequency.text === 'Once' ? selectedOptions.length === 1 : selectedOptions.length === 2) {
      const disabledStatus = selectedOptions.map(option => option.value).includes(option.value) ? '' : 'disabled'
      return disabledStatus
    }
  }

  const [collapsed, setCollapsed] = useState(true)

  const [hours, minutes] = campaignFrequency.time.split(':')

  return (
    <div className='curriculum-card-container'>
      <div className='d-flex justify-content-between'>
        <h4 className='phin-h4'>Time and Sending Settings</h4>
        {campaignType === campaignTypes.PHISHING && (
          <div style={{ zIndex: '10' }}>
            <MDBTooltip
              placement='right'
              id='description-popover'
              material
            >
              <MDBBtn
                color='elegant'
                className='my-0'
                floating
                tag='a'
                size='sm'
              >
                <MDBIcon icon='question' />
              </MDBBtn>
              <div>
                When the campaign runs, it schedules phishing emails for
                every user in the audience. These emails have their send date and send time
                randomly generated to be within the next three business days.
              </div>
            </MDBTooltip>
          </div>
        )}
      </div>

      <div>
        <div className='d-flex justify-content-center'>
          <div className='p-5'>
            <MDBRow className='d-flex justify-content-start align-items-center'>
              <p>
                <h4 style={{ width: '100%' }}>
                  <span> Send {renderOptions(iterations, (d) => { setIsCampaignFrequencyWeeksValid(true); dispatch(setIterationsAction(d)) }, iterationsOptions)} </span>
                  {campaignFrequency.iterations.value !== 'All At Once' &&
                  (
                    <>
                      <span> a {renderOptions(frequency, (d) => dispatch(setFrequencyAction(d)), frequencyOptions)} </span>
                      <span>
                        {frequency.text === 'Quarter' &&
                  (
                    <span> in the {renderOptions(month, (d) => dispatch(setMonthAction(d)), monthOptions)} month </span>
                  )}
                      </span>
                      <span>
                        {frequency.text !== 'Week' &&
                  (iterations.text === 'Once'
                    ? (<span> on the {renderOptions(week, (d) => dispatch(setWeekAction(d)), weekOptions)} week </span>)
                    : (
                      <span> on the <InlineMultiSelect
                        shouldBeDisabled={shouldBeDisabled}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        selectedOptions={week}
                        options={weekOptions}
                        setSelectedOptions={(d) => dispatch(setWeekAction(d))}
                        numberOfRequiredChoices={frequency === 'Once' ? 1 : 2}
                        isValid={isCampaignFrequencyWeeksValid}
                        setIsValid={setIsCampaignFrequencyWeeksValid}
                                    /> week
                      </span>
                      ))}
                      </span>
                      <span> on {renderOptions(weekday, (d) => dispatch(setWeekdayAction(d)), weekdayOptions)} </span>
                    </>
                  )}
                  <span> at
                    <span className='pl-3'>
                      {/* TODO: Prevent users from choosing a Date time pair that's within 2 hours of now */}
                      <LocalizationProvider dateAdapter={MomentUtils}>
                        <TimePicker
                          label='Weekly Send Time'
                          value={moment().hours(+hours).minutes(+minutes)}
                          onChange={(newValue) => {
                            if (newValue && newValue._isValid) {
                              dispatch(setCampaignFireTimeAction({ minutes: newValue.minutes(), hours: newValue.hours() }))
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </span>
                  </span>
                </h4>
              </p>
            </MDBRow>
            <MDBRow className='pt-4 pb-4 d-flex flex-column justify-content-start align-items-start'>
              <div className='mr-3'><h5> {campaignFrequency.iterations.value === 'All At Once' ? 'On' : 'Start scheduling no earlier than'}: </h5></div>
              <DatePicker
                className='mt-1 mad-lib-blue'
                labelClass='mad-lib-blue'
                containerClass='mad-lib-blue'
                valueDefault={startTime}
                disablePast={!isRunning}
                disabled={isRunning}
                minDate={isRunning ? startTime : minDate}
                name='startDateTime'
                getValue={(value) => setStartTime(moment(value))}
              />
            </MDBRow>
            {campaignFrequency.iterations.value !== 'All At Once' &&
              <CampaignScheduleDisplay
                tz={tz}
                modules={modules}
                nextFireTimes={nextFireTimes}
                firstFireDate={firstFireDate}
                nextFireTimesErrorMessage={nextFireTimesErrorMessage}
                isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
              />}
          </div>
        </div>
      </div>

    </div>
  )
}

export default CampaignFrequencySelector
