import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBRow,
  MDBCol
} from 'mdbreact'
import '../../css/integrationCard.css'
import NotificationWrapper from '../notifications/notificationWrapper'
import Spinner from './../Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { integrationStatuses, integrationTypes } from '../../../frontendConsts.js'
import ValidationModal from '../ValidationModal'
import { disableSpamBypassThunk } from '../../../store/IntegrationsSlice'
import NotificationUtilities from '../notifications/notificationUtils'

const integrationsCatalog = {
  userSync: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'User Sync',
    provider: 'Microsoft',
    integrationType: integrationTypes.USER_SYNC,
    id: integrationTypes.USER_SYNC,
    description: 'Available in Azure AD (global service) and ' +
    'Azure AD for US Government. Automatically sync users from a company\'s Microsoft Azure instance. ' +
    'You will be able to choose which users to continually sync updates for.'
  },
  spamFilterBypass: {
    integrationStatus: integrationStatuses.NOT_CONNECTED,
    name: 'Graph API Mail Delivery',
    provider: 'Microsoft',
    integrationType: integrationTypes.SPAM_FILTER_BYPASS,
    id: integrationTypes.SPAM_FILTER_BYPASS,
    description: 'Have emails directly inserted into end users\' email mailboxes. ' +
    'Uses Microsoft\'s Graph API to avoid sending email messages using Simple Mail Transfer Protocol (SMTP).'
  }
}

export function IntegrationsPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { integrationsMap, loaders: integrationLoaders } = useSelector((state) => state.integrations)
  const { isLoadingIntegrations } = integrationLoaders

  const [integrations, setIntegrations] = useState(integrationsCatalog)
  const [showDisconnectModal, setShowDisconnectModal] = useState(false)

  useEffect(() => {
    if (integrationsMap) {
      const newIntegrations = JSON.parse(JSON.stringify(integrations))
      for (const integration of Object.values(integrationsMap)) {
        const oldIntegration = integrations[integration.id]
        newIntegrations[integration.id] = Object.assign({}, oldIntegration, integration)
      }

      setIntegrations(newIntegrations)
    }
  }, [integrationsMap])

  const generateStatus = (integrationStatus, integrationId) => {
    if (integrationStatus === integrationStatuses.CONNECTED) {
      if (integrationId === integrationTypes.LEGACY_USER_SYNC || integrationId === integrationTypes.LEGACY_SPAM_BYPASS) {
        return <p className='statusBadge connected-legacy'>connected</p>
      } else {
        return <p className='statusBadge connected'>connected</p>
      }
    } else if (integrationStatus === integrationStatuses.NOT_CONNECTED) {
      return <p className='statusBadge connect'>connect</p>
    } else {
      return <p className='statusBadge connecting'>connecting</p>
    }
  }

  const disconnectLegacySpamBypass = () => {
    dispatch(disableSpamBypassThunk({ id, history }))
    toggleDisconnectModal()
  }

  const toggleDisconnectModal = (integrationType) => {
    setShowDisconnectModal(!showDisconnectModal)
  }

  const generateOnClickFunction = (integrationId, integrationType) => {
    if ((integrationId === integrationTypes.LEGACY_SPAM_BYPASS) ||
      (integrationId === integrationTypes.LEGACY_USER_SYNC)) {
      return () => toggleDisconnectModal(integrationId)
    } else if ((integrationType === integrationTypes.SPAM_FILTER_BYPASS) ||
    (integrationType === integrationTypes.USER_SYNC)) {
      return () => history.push(`integrations/${integrationId}`)
    } else {
      return () => NotificationUtilities.sendWarningMessage('This integration is no longer supported. Please contact Phin Support for assistance.')
    }
  }

  function generateIntegrationCard ({ key, integrationId, name, integrationStatus, integrationType, description }) {
    const onclickFunction = generateOnClickFunction(integrationId, integrationType)
    return (
      <a key={key} id={`integration-card-${integrationType}`} aria-label={`${integrationType} card`} onClick={onclickFunction}>
        <div className='interCardPadding'>
          <div className='cardBody'>
            <div className='cardContents'>
              <div className='cardInfoQuick'>
                <img src='/azureSmall.png' className='cardLogo' />
                <p className='cardStatus'>{generateStatus(integrationStatus, integrationId)}</p>
              </div>
              <div className='cardTitle'>{name}</div>
              <p className='cardDesc'>{description}</p>
            </div>
          </div>
        </div>
      </a>
    )
  }

  function generateIntegrationsList () {
    return Object.entries(integrations)
      .map(([id, integration], i) => {
        let { name, integrationStatus, description } = integration

        if (integration.id === integrationTypes.LEGACY_USER_SYNC) {
          name = 'LEGACY User Sync'
          integrationStatus = integrationStatuses.CONNECTED
          description = 'This integration has limited support and will be decommissioned in early June. ' +
            'Please upgrade to the new version to continue getting the benefits of this integration.'
        } else if (integration.id === integrationTypes.LEGACY_SPAM_BYPASS) {
          name = 'LEGACY Spam Bypass'
          integrationStatus = integrationStatuses.CONNECTED
          description = 'This integration has limited support and will be decommissioned in early June. ' +
            'Please upgrade to the new version to continue getting the benefits of this integration.'
        }

        if (!name) {
          name = 'Unknown Integration'
          integrationStatus = integrationStatuses.CONNECTED
        }

        return (
          generateIntegrationCard({
            key: i,
            name,
            integrationId: integration.id,
            integrationStatus,
            integrationType: integration.integrationType,
            description
          })
        )
      })
  }

  return (
    <div>
      <NotificationWrapper />

      <ValidationModal
        isOpen={showDisconnectModal}
        toggleFunction={toggleDisconnectModal}
        messageJSX={<p>Are you sure you want to disconnect this integraton? You can then begin use of the new integrations.</p>}
        falseOptionText='Cancel'
        trueOptionText='Disconnect'
        falseOptionColor='primary'
        trueOptionColor='red'
        handlerFunction={disconnectLegacySpamBypass}
      />

      <MDBRow className=''>
        <MDBCol className=''>
          <div className='phin-page-heading screen-pad'>Microsoft Integrations</div>
        </MDBCol>
      </MDBRow>

      {(!company || !integrationsMap ||
      isLoadingCompany || isLoadingIntegrations) && (
        <Spinner />
      )}

      {(company && integrationsMap &&
      !isLoadingCompany && !isLoadingIntegrations) &&
        <MDBRow className='d-flex justify-content-start align-items-center screen-pad'>
          {generateIntegrationsList()}
        </MDBRow>}
    </div>
  )
}
