import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBMask, MDBTooltip, MDBView } from 'mdbreact'
import { formatDateForCard, getCampaignNameFromId } from '../../pages/components/training/utils'

export const TrainingCourseCard = ({ course, campaigns, openCancelModal, openConfirmModal, toggleEnrollUserModal, showmask = true }) => {
  return course
    ? (
      <MDBCol key={course.id} className='d-flex justify-content-center mt-4'>
        <MDBView rounded zoom className='h-100 preset-overlay' hover>

          <MDBCard style={{ height: '100%', width: '22rem' }}>
            <MDBCardImage
              top
              waves={false}
              src={course.thumbnail || 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg'}
              style={{ height: '12rem' }}
            />
            <MDBCardBody>
              <MDBCardTitle>{course.name}
              </MDBCardTitle>
              <MDBCardText>
                Topic: {course.topic ? course.topic : 'N/A'}
              </MDBCardText>
              <MDBCardText>
                Provider: {course.provider ? course.provider : 'N/A'}
              </MDBCardText>
              <MDBCardText>
                {course.description}
              </MDBCardText>
              <MDBCardText>
                Estimated Duration: {course.durationInMinutes ? course.durationInMinutes : '15 minutes'}
              </MDBCardText>
            </MDBCardBody>
            {(course.campaignId && course.campaignId !== 'adhoc') && campaigns &&
              <MDBCardFooter className='campaign-footer partner'>
                <div className='flex-center'>
                  <p>Linked to: "{getCampaignNameFromId(course.campaignId, campaigns)}"</p>
                </div>
              </MDBCardFooter>}
            {course.dateStarted && course.totalEnrolled &&
              <MDBCardFooter className='text-center rounded-bottom training-card-bottom text-center card-footer-height'>

                <ul className='list-unstyled list-inline font-small'>
                  <li className='list-inline-item pr-2'>
                    {formatDateForCard(new Date(course.dateStarted))}
                  </li>
                  <li className='list-inline-item pr-2'>
                    <MDBIcon icon='users' className='mr-1' />
                    {course.totalEnrolled}
                  </li>
                  <li className='list-inline-item pr-2'>
                    <MDBIcon icon='check-circle' className='mr-1' />
                    {course.totalCompleted}
                  </li>
                  {course.campaignId !== 'adhoc' &&
                    <li className='list-inline-item pr-2'>
                      <MDBIcon icon='link' className='mr-1' />
                    </li>}
                </ul>

              </MDBCardFooter>}
          </MDBCard>
          {showmask &&
            <MDBMask className='flex-center' overlay='blue-strong' pattern={5}>
              <MDBTooltip style={{ zIndex: '2' }} className='action-button' placement='top'>
                <MDBBtn
                  floating
                  tag='a'
                  size='lg'
                  color='info'
                  action
                  onClick={() => {
                    openConfirmModal(course)
                  }}
                >
                  <MDBIcon size='2x' icon='comment' />
                </MDBBtn>
                <div>
                  Send Course Notification
                </div>
              </MDBTooltip>
              <MDBTooltip placement='top'>
                <MDBBtn
                  floating
                  tag='a'
                  color='danger'
                  size='lg'
                  action
                  onClick={() => {
                    openCancelModal(course)
                  }}
                >
                  <MDBIcon size='2x' icon='trash-alt' className='primary' />
                </MDBBtn>
                <div>
                  Cancel Course
                </div>
              </MDBTooltip>
              <MDBTooltip placement='top'>
                <MDBBtn
                  floating
                  tag='a'
                  color='primary'
                  size='lg'
                  action
                  onClick={() => {
                    toggleEnrollUserModal({ isOpen: true, course })
                  }}
                >
                  <MDBIcon size='2x' icon='plus' className='primary' />
                </MDBBtn>
                <div>
                  Enroll User in Course
                </div>
              </MDBTooltip>

            </MDBMask>}
        </MDBView>
      </MDBCol>
      )
    : <></>
}
