import {
  MDBBtn, MDBCol, MDBIcon, MDBRow, MDBContainer
} from 'mdbreact'
import ValidationModal from './components/ValidationModal'
import React, { useEffect, useState } from 'react'
import { navigationRoutes } from '../frontendConsts.js'
import { useHistory } from 'react-router'
import './css/training.css'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import { apiRequestUtils } from '../utils/apiRequestUtils'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { deleteTrainingCourseThunk } from '../store/TrainingSlice'
import { deleteTrainingCampaignThunk } from '../store/CampaignsSlice'
import { resetAllCampaignFrequencyChoicesAction } from '../store/CampaignFrequencySlice'
import { EnrollUserModal } from './components/training/EnrollUserModal'
import { shouldCourseBeArchived } from './components/training/utils'
import ActiveTrainingCourses from './components/training/ActiveTrainingCourses'
import PastTrainingCourses from './components/training/PastTrainingCourses'
import ActiveTrainingCampaigns from './components/training/ActiveTrainingCampaigns'

function TrainingPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { activeCampaigns, pastCampaigns, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingCampaigns } = campaignLoaders
  const { trainings, loaders: trainingLoaders } = useSelector((state) => state.trainings)
  const { isLoadingTrainings } = trainingLoaders

  const [cancelModal, showCancelModal] = useState(false)
  const [confirmModal, showConfirmModal] = useState(false)
  const [selectedCourse, selectCourse] = useState(null)
  const [selectedDeleteCourse, selectCourseForDelete] = useState(null)
  const [cancelCampaignModal, showCampaignCancelModal] = useState(false)
  const [showLaunchModal, setShowLaunchModal] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const [pastTrainingCourses, setPastTrainingCourses] = useState([])
  const [activeTrainingCourses, setActiveTrainingCourses] = useState([])

  const [isEnrollUserModalOpen, setEnrollUserModalOpen] = useState(false)

  const toggleEnrollUserModal = ({ isOpen, course }) => {
    setEnrollUserModalOpen(isOpen)
    selectCourse(course)
  }

  useEffect(() => {
    if (pastCampaigns && trainings) {
      const coursesLinkedToPastTrainings = []
      const coursesLinkedToActiveTrainings = []
      for (const training of trainings) {
        if (shouldCourseBeArchived(training.campaignId, pastCampaigns)) {
          coursesLinkedToPastTrainings.push(training)
        } else {
          coursesLinkedToActiveTrainings.push(training)
        }
      }

      setPastTrainingCourses(coursesLinkedToPastTrainings)
      setActiveTrainingCourses(coursesLinkedToActiveTrainings)
    }
  }, [pastCampaigns, trainings])

  const handleCreateCurriculum = async () => {
    await dispatch(resetAllCampaignFrequencyChoicesAction())
    history.push(`/companies/${id}${navigationRoutes.TRAINING_CURRICULUM_FORM}/new`)
  }

  function openConfirmModal (course) {
    selectCourse(course)
    showConfirmModal(true)
  }

  function closeConfirmModal () {
    selectCourse(null)
    showConfirmModal(false)
  }

  function openCancelModal (course) {
    selectCourseForDelete(course)
    showCancelModal(true)
  }

  function closeCancelModal () {
    selectCourseForDelete(null)
    showCancelModal(false)
  }

  // NOTE: campaign modification modals, keeping them seperate in hopes of dropping adhoc courses in the future
  function closeCancelCampaignModal () {
    showCampaignCancelModal(false)
  }

  function editTrainingCampaign (campaign) {
    history.push(`/companies/${id}${navigationRoutes.TRAINING_CURRICULUM_FORM}/${campaign.id}`)
  }

  function viewTrainingCampaign (campaign) {
    history.push(`/companies/${id}${navigationRoutes.TRAINING_VIEWER}/${campaign.id}`)
  }

  function openCancelCampaignModal (campaignId) {
    setSelectedCampaign(campaignId)
    showCampaignCancelModal(true)
  }

  async function confirmCourseDeletion (course) {
    showCancelModal(false)
    dispatch(deleteTrainingCourseThunk({ id, course }))
    selectCourseForDelete(null)
  }

  async function confirmTrainingCampaignDeletion (campaign) {
    showCampaignCancelModal(false)
    dispatch(deleteTrainingCampaignThunk({ id, campaignId: campaign.id }))
    setSelectedCampaign(null)
  }

  async function confirmLaunchCampaign (campaign) {
    setShowLaunchModal(false)
    // dispatch(launchTrainingCampaignThunk({ companyId: id, campaignId: campaign.id }))

    setSelectedCampaign(null)
  }

  async function confirmCourseNotification (course) {
    showConfirmModal(false)
    try {
      const res = await apiRequestUtils.post(`/api/companies/${id}/training/training-reminder`, {
        activeTrainingCourseId: course.id,
        activeTrainingCourseName: course.name,
        userNotificationType: 'reminder'
      })
      await res.json()
      if (res.status === 200) {
        NotificationUtilities.sendSuccessMessage('Reminders Sent!')
      } else {
        NotificationUtilities.sendErrorMessage('Error Sending Reminders, please contact Phin if problem persists!')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Error Sending Reminders, please contact Phin if problem persists!')
    }
    selectCourse(null)
  }

  return (
    <MDBContainer>
      <NotificationWrapper />
      <ValidationModal
        isOpen={confirmModal}
        toggleFunction={() => closeConfirmModal()}
        handlerFunction={() => confirmCourseNotification(selectedCourse)}
        messageJSX={<><p>You are about to notify all users who have not yet completed this course. </p> <p> Are you sure you would like to do this?</p></>}
        falseOptionText='Cancel'
        trueOptionText='Remind Users'
        falseOptionColor='red'
        trueOptionColor='primary'
      />

      <ValidationModal
        isOpen={cancelModal}
        toggleFunction={() => closeCancelModal()}
        handlerFunction={() => confirmCourseDeletion(selectedDeleteCourse)}
        messageJSX={
          <p>Are you sure you would like to cancel this course? <strong>This can not be undone!</strong>
            <br /> Your data will be saved and viewable from the Analytics page.
          </p>
          }
        falseOptionText='Continue Course'
        trueOptionText='Cancel Course'
        falseOptionColor='primary'
        trueOptionColor='red'
      />

      <ValidationModal
        isOpen={cancelCampaignModal}
        toggleFunction={() => closeCancelCampaignModal()}
        handlerFunction={() => confirmTrainingCampaignDeletion(selectedCampaign)}
        messageJSX={
          <p>Are you sure you would like to cancel this training campaign? <strong>This can not be undone!</strong>
            <br /> Your data will be saved and viewable from the Analytics page.
          </p>
          }
        falseOptionText='cancel'
        trueOptionText='delete campaign'
        falseOptionColor='red'
        trueOptionColor='primary'
      />

      <ValidationModal
        isOpen={showLaunchModal}
        toggleFunction={() => setShowLaunchModal(false)}
        handlerFunction={() => confirmLaunchCampaign(selectedCampaign)}
        messageJSX={
          <p>Are you sure you would like to launch this training campaign now?
            <br /> This will send the campaign to all users in the campaign immediately
          </p>
          }
        falseOptionText='Cancel'
        trueOptionText='Launch'
        falseOptionColor='red'
        trueOptionColor='primary'
      />

      {isEnrollUserModalOpen && (

        <EnrollUserModal
          isOpen={isEnrollUserModalOpen}
          companyId={id}
          selectedCourse={selectedCourse}
          toggleEnrollUserModal={toggleEnrollUserModal}
        />
      )}

      <MDBRow className='d-flex justify-content-center'>
        <h1>Training Dashboard</h1>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center'>
        <p>All active trainings are shown here at a glance as well as info on their progress</p>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center mb-5'>
        <MDBCol xl='4' md='4' className='d-flex justify-content-center align-items-center'>
          <MDBBtn onClick={handleCreateCurriculum} color='primary' size='lg'>
            <MDBRow className='d-flex justify-content-center align-items-center'>
              <MDBCol size='2' className='pr-1 pl-0'>
                <MDBIcon size='3x' icon='plus-square' />
              </MDBCol>
              <MDBCol className='pr-0 pl-0' id='startNewCurriculumBtn' size='9'>
                Launch a Training Campaign
              </MDBCol>
            </MDBRow>
          </MDBBtn>
        </MDBCol>
      </MDBRow>
      <h1>Active Training Campaigns</h1>
      <p>Manage your active scheduled training campaigns here.</p>
      <br />

      {(!activeCampaigns || isLoadingCompany || isLoadingCampaigns) && (
        <Spinner />
      )}

      {activeCampaigns && !isLoadingCompany && !isLoadingCampaigns && (
        <ActiveTrainingCampaigns
          editTrainingCampaign={editTrainingCampaign}
          openCancelCampaignModal={openCancelCampaignModal}
          viewTrainingCampaign={viewTrainingCampaign}
          company={company}
        />
      )}
      <br />
      <hr />
      <br />
      <h1>Active Courses</h1>
      <p>Manage all your active courses here.</p>
      <br />

      {(!trainings || !activeCampaigns || isLoadingTrainings || isLoadingCompany) && (
        <Spinner />
      )}

      {trainings && activeCampaigns && !isLoadingTrainings && !isLoadingCompany && (
        <ActiveTrainingCourses
          openConfirmModal={openConfirmModal}
          openCancelModal={openCancelModal}
          toggleEnrollUserModal={toggleEnrollUserModal}
          trainings={activeTrainingCourses}
          activeCampaigns={activeCampaigns}
        />
      )}

      <br />
      <hr />
      <br />
      <h1>Completed Training Campaign Courses</h1>
      <p>View Active Courses from Training Campaigns that have finished sending Training Courses here. Note that manually launched Training Courses when completed will not appear here.
        Info for those can be found in the Training Analytics.
      </p>
      <br />

      {(!pastTrainingCourses || !pastCampaigns || isLoadingTrainings || isLoadingCompany) && (
        <Spinner />
      )}

      {(pastTrainingCourses && pastCampaigns && !isLoadingTrainings && !isLoadingCompany) && (
        <PastTrainingCourses
          pastCampaigns={pastCampaigns}
          pastTrainings={pastTrainingCourses}
          openConfirmModal={openConfirmModal}
          openCancelModal={openCancelModal}
          toggleEnrollUserModal={toggleEnrollUserModal}
        />
      )}

    </MDBContainer>
  )
}

export default TrainingPage
