import React from 'react'
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBFileInput,
  MDBContainer,
  MDBTooltip,
  MDBPopover,
  MDBPopoverBody
} from 'mdbreact'

import SelectOptions from '../SelectOptions'
import TemplateEditor from './TemplateEditor'

class HTMLTemplateOptions extends React.Component {
  render () {
    const {
      handleEmailDomainEdit,
      handleEmailNameEdit,
      handleFileUpload,
      handleTestEmailEdit,
      toggle,
      sendTemplateTestEmail,
      handleFromNameEdit,
      handleSubjectEdit,
      sendingDomains,
      displayTestEmailButton,
      displayHTMLUpload,
      selectedTemplate,
      setTemplateEditor
    } = this.props

    const fromName = selectedTemplate.fromEmail.split('@')[0]
    const fromDomain = selectedTemplate.fromEmail.split('@')[1]

    return (
      <>
        <MDBCard className='mt-4'>
          <MDBCardHeader>
            <MDBRow className='d-flex'>
              <MDBCol className='d-flex justify-content-start align-items-center'>
                <h5>Edit Template</h5>
              </MDBCol>
              <MDBCol className='d-flex justify-content-end'>
                <MDBTooltip
                  placement='left'
                  id='templatefile'
                  material
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                  <div>
                    Edit the contents of the template.
                  </div>
                </MDBTooltip>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol size='12'>
                <TemplateEditor setTemplateEditor={setTemplateEditor} />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        {displayHTMLUpload &&
          <MDBCard className='mt-4'>
            <MDBCardHeader>
              <MDBRow className='d-flex'>
                <MDBCol className='d-flex justify-content-start align-items-center'>
                  <h5>Template File</h5>
                </MDBCol>
                <MDBCol className='d-flex justify-content-end'>
                  <MDBTooltip
                    placement='left'
                    id='templatefile'
                    material
                  >
                    <MDBBtn
                      color='elegant'
                      className='my-0'
                      floating
                      tag='a'
                      size='sm'
                    >
                      <MDBIcon icon='question' />
                    </MDBBtn>
                    <div>
                      Upload the HTML template file here. The file must be valid HTML and not have any errors in the markup.
                    </div>
                  </MDBTooltip>
                </MDBCol>
              </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow className='d-flex justify-content-center'>
                <MDBCol size='8'>
                  <MDBFileInput
                    getValue={e => handleFileUpload(e)}
                    name='templateFile'
                    textFieldTitle='Upload your template'
                  />
                </MDBCol>

                <MDBCol
                  size='4'
                  // need to pass in dynamic classes to change display
                  className='d-flex justify-content-center align-items-center'
                >
                  <MDBPopover
                    popover
                    clickable
                    placement='right'
                    isVisible={!displayTestEmailButton}
                  >
                    <MDBBtn
                      onClick={() => toggle('emailInput')}
                      color='primary'
                      value='cancel'
                      disabled={!displayTestEmailButton}
                      className='h-50'
                    >
                      Send Test Email
                    </MDBBtn>
                    <MDBContainer>
                      <MDBPopoverBody>
                        <form>
                          <MDBRow className='d-flex justify-content-center align-items-center'>
                            <MDBInput
                              outline
                              name='email'
                              onChange={e => handleTestEmailEdit(e)}
                              label='Email'
                              type='email'
                            />
                          </MDBRow>
                          <MDBRow className='d-flex justify-content-center align-items-center'>
                            <MDBBtn
                              onClick={() =>
                                sendTemplateTestEmail()}
                              color='primary'
                              className='h-50 w-50'
                            >
                              Send
                            </MDBBtn>
                          </MDBRow>
                        </form>
                      </MDBPopoverBody>
                    </MDBContainer>
                  </MDBPopover>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>}

        <MDBCard className='mt-4'>
          <MDBCardHeader>
            <MDBRow className='d-flex'>
              <MDBCol className='d-flex justify-content-start align-items-center'>
                <h5>Email Address</h5>
              </MDBCol>
              <MDBCol className='d-flex justify-content-end'>
                <MDBTooltip
                  placement='right'
                  id='emailaddress'
                  material
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                  <div>
                    Email metadata to add on send.
                  </div>
                </MDBTooltip>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol size='3'>
                <MDBInput
                  name='senderSubject'
                  label='Email Subject'
                  labelClass='text-dark'
                  value={selectedTemplate.subject}
                  onChange={e => handleSubjectEdit(e)}
                />
              </MDBCol>

              <MDBCol size='3'>
                <MDBInput
                  name='senderName'
                  label='Display Name'
                  labelClass='text-dark'
                  value={selectedTemplate.fromName}
                  onChange={e => handleFromNameEdit(e)}
                />
              </MDBCol>

              <MDBCol
                className='d-flex justify-content-center'
                size='5'
              >
                <MDBInput
                  className='text-right'
                  name='sendingName'
                  label='Sending Name'
                  labelClass='text-dark'
                  value={fromName}
                  onChange={e => handleEmailNameEdit(e)}
                />
                <h4 className='d-flex text-bold my-auto'>
                  @
                </h4>

                <SelectOptions
                  className='my-auto'
                  name='templateDomain'
                  options={sendingDomains}
                  value={fromDomain}
                  onChange={e => handleEmailDomainEdit(e)}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </>
    )
  }
}

export default HTMLTemplateOptions
