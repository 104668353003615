import React, { useEffect, useRef, useState } from 'react'
import Spinner from '../Spinner'
import NotificationWrapper from '../notifications/notificationWrapper'
import NotificationUtilities from '../notifications/notificationUtils'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { useDispatch, useSelector } from 'react-redux'
import { microsoftIntegrationStatuses, integrationStatuses, integrationTypes } from '../../../frontendConsts.js'
import { startStagingThunk } from '../../../store/UserSyncSlice'
import { getStagedUsersByCompanyThunk } from '../../../store/StagedUsersSlice'
import { getStagedGroupsByCompanyThunk } from '../../../store/StagedGroupsSlice'
import { integrationStatusUpdatedAction, integrationUpdatedAction } from '../../../store/IntegrationsSlice'
import { StagedUserSync } from './StagedUserSync'
import { useHistory } from 'react-router-dom'
import { SyncedUsers } from './SyncedUsers'
import { SyncSummary } from './SyncSummary'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import '../../css/integrationSyncSummary.css'
import { MicrosoftConsentCard } from './MicrosoftConsentCard'
import { getGroupsThunk, getUsersThunk } from '../../../store/UsersSlice'

export function MicrosoftUserSyncPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const intervalRef = useRef()

  const [isAzureGovTenant, setIsAzureGovTenant] = useState(false)

  const { stagedUsers } = useSelector((state) => state.stagedUsers)
  const { stagedGroups } = useSelector((state) => state.stagedGroups)

  const { company } = useSelector((state) => state.company)
  const { integrationsMap } = useSelector((state) => state.integrations)

  function toggleIsAzureGovTenant () {
    setIsAzureGovTenant(!isAzureGovTenant)
  }

  const renderStatusBreadCrumbs = () => {
    const breadCrumbArray = []
    const userSyncStatus = integrationsMap.userSync ? integrationsMap.userSync.integrationStatus : microsoftIntegrationStatuses.NOT_CONNECTED
    for (const [stage] of Object.entries(microsoftIntegrationStatuses)) {
      if (microsoftIntegrationStatuses[stage] === microsoftIntegrationStatuses.CONNECTED) {
        breadCrumbArray.push(
          <>
            <div className={(microsoftIntegrationStatuses[stage] === userSyncStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{stage}</div>
          </>
        )
      } else {
        breadCrumbArray.push(
          <>
            <div className={(microsoftIntegrationStatuses[stage] === userSyncStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{stage}</div><hr className='crumbHR' style={{ display: 'inline-block' }} />
          </>
        )
      }
    }
    return breadCrumbArray
  }

  const consentToIntegration = () => {
    const redirectURL = encodeURIComponent(`${window.location.protocol}//${window.location.host}/integrations`)
    let adminConsentLink = 'https://login.microsoftonline.com/common/adminconsent'
    let integrationType = 'userSync'
    let applicationId = process.env.REACT_APP_MICROSOFT_USER_SYNC_APPLICATION_ID

    if (isAzureGovTenant) {
      adminConsentLink = 'https://login.microsoftonline.us/common/adminconsent'
      integrationType = 'userSync%7Ctrue'
      applicationId = process.env.REACT_APP_AZURE_GOV_USER_SYNC_APPLICATION_ID
    }
    window.open(`${adminConsentLink}?client_id=${applicationId}&redirect_uri=${redirectURL}&state=${id}%7C${integrationType}`, '_self')
  }

  const validateConsent = () => {
    apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.USER_SYNC }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json()
        if (data.integrationStatus === microsoftIntegrationStatuses.CONSENTED ||
          data.integrationStatus === microsoftIntegrationStatuses.STAGING) {
          dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { integrationStatus: data.integrationStatus } }))
        } else {
          clearInterval(intervalRef.current)
          intervalRef.current = setInterval(() => {
            apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.USER_SYNC }).then(async (response) => {
              if (response.status === 200) {
                const data = await response.json()
                if (data.integrationStatus === microsoftIntegrationStatuses.CONSENTED ||
                  data.integrationStatus === microsoftIntegrationStatuses.STAGING) {
                  clearInterval(intervalRef.current)
                  dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { integrationStatus: data.integrationStatus } }))
                  // Call staging/firstSync here?
                }
              }
            })
          }, 10000)
        }
      } else {
        clearInterval(intervalRef.current)
        intervalRef.current = setInterval(() => {
          apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.USER_SYNC }).then(async (response) => {
            if (response.status === 200) {
              const data = await response.json()
              if (data.integrationStatus === microsoftIntegrationStatuses.CONSENTED ||
                data.integrationStatus === microsoftIntegrationStatuses.STAGING) {
                clearInterval(intervalRef.current)
                dispatch(integrationUpdatedAction({ integrationId: integrationTypes.USER_SYNC, updatedFields: { integrationStatus: data.integrationStatus } }))
                // Call staging/firstSync here?
              }
            }
          })
        }, 10000)
      }
    })
  }

  const pollForIntegrationStatus = async (statusToPollFor) => {
    const response = await apiRequestUtils.get(`/api/companies/${id}/integrations/userSync`)
    if (response.status === 200) {
      const integration = await response.json()
      if (integration.integrationStatus === statusToPollFor) {
        dispatch(integrationUpdatedAction({ integrationId: integration.id, updatedFields: integration }))
        if (statusToPollFor === integrationStatuses.CONNECTED) {
          dispatch(getUsersThunk(id))
          dispatch(getGroupsThunk(id))
        }
      } else {
        clearInterval(intervalRef.current)
        intervalRef.current = setInterval(async () => {
          const response = await apiRequestUtils.get(`/api/companies/${id}/integrations/userSync`)
          if (response.status === 200) {
            const integration = await response.json()
            if (integration.integrationStatus === statusToPollFor) {
              clearInterval(intervalRef.current)
              dispatch(integrationUpdatedAction({ integrationId: integration.id, updatedFields: integration }))
              if (statusToPollFor === integrationStatuses.CONNECTED) {
                dispatch(getUsersThunk(id))
                dispatch(getGroupsThunk(id))
              }
            }
          } else {
            clearInterval(intervalRef.current)
            setTimeout(() => {
              NotificationUtilities.sendErrorMessage('Failed to load User Sync data. Please try again or contact Phin Support for assistance.')
            }, 0)
          }
        }, 10000)
      }
    } else {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load User Sync data. Please try again or contact Phin Support for assistance.')
      }, 0)
      history.push(`/companies/${id}/integrations`)
    }
  }

  useEffect(() => {
    return function cleanUpInterval () {
      clearInterval(intervalRef.current)
    }
  }, [])

  useEffect(() => {
    if (integrationsMap && integrationsMap.userSync) {
      if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGED) {
        if (!stagedUsers) {
          dispatch(getStagedUsersByCompanyThunk({ companyId: id }))
        }
        if (!stagedGroups) {
          dispatch(getStagedGroupsByCompanyThunk({ companyId: id }))
        }
      }
    }

    clearInterval(intervalRef.current)

    if (integrationsMap && integrationsMap.userSync) {
      if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) {
        validateConsent()
        return function cleanUpInterval () {
          clearInterval(intervalRef.current)
        }
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENTED) {
        dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.USER_SYNC, integrationStatus: microsoftIntegrationStatuses.STAGING }))
        dispatch(startStagingThunk({ companyId: id }))
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGING) {
        pollForIntegrationStatus(microsoftIntegrationStatuses.STAGED)
        return function cleanUpInterval () {
          clearInterval(intervalRef.current)
        }
      } else if (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.COMMITTING) {
        pollForIntegrationStatus(microsoftIntegrationStatuses.CONNECTED)
        return function cleanUpInterval () {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [integrationsMap])

  return (
    <>
      <NotificationWrapper />
      <div className='screen-pad'>
        <div className='detailsPageHeadingGroup'>
          <img src='/azureMed.png' />
          <div>
            <div className='detailsTitleGroup'>
              <h2 className='phin-page-heading'>Azure User Sync</h2>
            </div>
            {company && integrationsMap && (
              <div className='crumbContainer'>
                {renderStatusBreadCrumbs()}
              </div>
            )}
          </div>
        </div>

        {(!company || !integrationsMap) && (
          <Spinner />
        )}

        {(company && integrationsMap) && (
          <>
            <div className='details'>
              {(!integrationsMap.userSync || integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.NOT_CONNECTED) && (
                <>
                  <div className='left'>
                    <MicrosoftConsentCard
                      consentFunction={consentToIntegration}
                      isAzureGovTenant={isAzureGovTenant}
                      toggleIsAzureGovTenant={toggleIsAzureGovTenant}
                    />
                    <SyncSummary />
                  </div>
                  <StagedUserSync
                    companyId={id}
                    haveConsented={false}
                    isStagingMode
                    isSampleData
                  />
                </>
              )}

              {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGED && (
                <>
                  <SyncSummary
                    stagedGroups={stagedGroups}
                    stagedUsers={stagedUsers}
                  />
                  <StagedUserSync
                    companyId={id}
                    haveConsented
                    isStagingMode
                  />
                </>)}

              {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED &&
                <>
                  <Spinner message='Verifying Microsoft Consent Status' secondaryMessage='This may take a few minutes' />
                </>}

              {(integrationsMap.userSync && (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONSENTED ||
              integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGING)) && (
                <>
                  <Spinner message='Preparing First Sync' />
                </>)}

              {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.COMMITTING && (
                <>
                  <Spinner message='Commiting First Sync' />
                </>)}
              {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONNECTED && (
                <>
                  <SyncSummary />
                  <SyncedUsers
                    companyId={id}
                    integration={integrationsMap.userSync}
                    users={[]}
                  />
                </>
              )}
            </div>
          </>)}

      </div>
    </>
  )
}
