import React from 'react'
import { MDBIcon, MDBTooltip, MDBBtn } from 'mdbreact'

function ActionButton ({ title, onClick, color = 'default', icon = 'pencil-alt', className = '', outline = false }) {
  return (
    <MDBTooltip placement='top'>
      <MDBBtn
        rounded
        type='submit'
        size='sm'
        color={color}
        outline={outline}
        onClick={onClick}
        className={className}
      >
        <MDBIcon
          size='lg'
          icon={icon}
        />
      </MDBBtn>
      <div>{title}</div>
    </MDBTooltip>
  )
}

export default ActionButton
