import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { MDBBtn, MDBRow, MDBCol, MDBContainer, MDBIcon, MDBAlert } from 'mdbreact'

import { campaignTypes } from '../frontendConsts.js'
import CampaignName from './components/Campaigns/modules/CampaignName'
import { buildCRONschedule, calculateStartTime, calculateEndTime, getNextCampaignFrequencyInterval } from '../utils/dateUtils'
import ContentViewer from './components/Campaigns/modules/ContentViewer'
import PresetLibrary from './components/Campaigns/PresetLibrary'
import parser from 'cron-parser'

import './css/presetLibrary.css'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner.js'
import { createPhishingCampaignThunk } from '../store/CampaignsSlice.js'
import CampaignFrequencySelector from './components/Campaigns/modules/CampaignFrequencySelector.js'
import { updateOnboardingChecklistDataThunk } from '../store/CompanySlice.js'
import NotificationUtilities from './components/notifications/notificationUtils.js'
import NotificationWrapper from './components/notifications/notificationWrapper.js'
import { validatePhishingCampaign } from '../validation/PhishingCampaigns.js'

function CampaignLauncher ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const { company, loaders, onboardingChecklist } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { sharedTemplates, userTemplates, defaultTemplates, campaignCategories, loaders: templateLoaders } = useSelector((state) => state.templates)
  const { isLoadingTemplates, isLoadingCampaignCategories, isLoadingSendingDomains } = templateLoaders
  const { phishingCampaignPresets, loaders: campaignLoaders } = useSelector((state) => state.campaigns)
  const campaignFrequency = useSelector(state => state.campaignFrequency)
  const { isLoadingActiveCampaigns, isLoadingPhishingCampaignPresets } = campaignLoaders

  const locationState = useLocation().state || {}
  const { campaign, preset } = locationState

  useEffect(() => {
    window.scrollTo(0, 0)

    let load = null
    if (campaign) {
      load = campaign
    }

    setSelectedCampaign(load)
  }, [])

  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const [name, setName] = useState('')
  const [isPreset, setIsPreset] = useState(preset)
  const [startTime, setStartTime] = useState(calculateStartTime())
  const [nextFireTimes, setNextFireTimes] = useState([])
  const [firstFireDate, setFirstFireDate] = useState()
  const [nextFireTimesErrorMessage, setNextFireTimesErrorMessage] = useState()

  const [isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid] = useState(true)

  useEffect(() => {
    // update scheduling dates on component load
    let firstDateToFire
    const numberOfItems = (selectedCampaign?.templateNames ? selectedCampaign.templateNames.length : 5)
    const tempArr = []
    const startingDate = startTime.toDate()
    const currentDateTime = new Date()
    const tz = company ? company.timezone.value : 'America/New_York'
    const cron = buildCRONschedule(campaignFrequency)
    const interval = parser.parseExpression(cron, { currentDate: startingDate, tz })
    let datesRendered = 0

    try {
      while (datesRendered < numberOfItems) {
        const nextFireTime = getNextCampaignFrequencyInterval(campaignFrequency, interval, tz)

        if (nextFireTime > startingDate) {
          if (!firstDateToFire) {
            firstDateToFire = nextFireTime
          }

          if (nextFireTime > currentDateTime) {
            tempArr.push(nextFireTime)
            datesRendered += 1
          }
        }
      }
      setNextFireTimesErrorMessage()
      setNextFireTimes(tempArr)
      setFirstFireDate(firstDateToFire)
    } catch (error) {
      setNextFireTimesErrorMessage(error.message)
    }
  }, [selectedCampaign, startTime, campaignFrequency])

  useEffect(() => {
    if (selectedCampaign && isPreset) {
      setName(`${selectedCampaign.name}, ${calculateStartTime().format('MMMM Do YYYY')}`)
    } else if (selectedCampaign) {
      setName(selectedCampaign.name)
    }
  }, [selectedCampaign])

  function handlePresetSelection (campaign) {
    setIsPreset(true)
    const copiedPreset = Object.assign({}, campaign)
    setSelectedCampaign(copiedPreset)
  }

  async function launchCampaign () {
    const { status, reason } = validatePhishingCampaign({ type: 'create', isCampaignFrequencyWeeksValid })

    if (status) {
      if (selectedCampaign.campaignType === campaignTypes.FIXEDLENGTH) {
        selectedCampaign.endTime = calculateEndTime(selectedCampaign.templateNames.length, startTime, company ? company.timezone.value : 'America/New_York', campaignFrequency)
      }

      selectedCampaign.startTime = startTime
      selectedCampaign.name = name

      await dispatch(createPhishingCampaignThunk({ companyId, selectedCampaign, history }))
      await dispatch(updateOnboardingChecklistDataThunk(companyId, { hasActiveCampaigns: true }))
    } else {
      NotificationUtilities.sendWarningMessage(reason)
    }
  }

  if (selectedCampaign) {
    const allTemplates = [...userTemplates, ...defaultTemplates, ...sharedTemplates]
    const content = (selectedCampaign.campaignType === campaignTypes.CONTINUOUS) ? { allCategories: campaignCategories, allTemplates } : allTemplates
    return (
      <MDBContainer>
        <NotificationWrapper />
        <MDBRow>
          <MDBCol>
            <h1>Campaign Launcher</h1>
            <p>Configure this campaign's scheduling details and then you're done!</p>
          </MDBCol>
        </MDBRow>
        {!onboardingChecklist.hasWelcomedUsers && (
          <MDBRow className='w-100'>
            <MDBAlert className='animated fadeInDown w-100' color='warning'>
              <MDBRow>
                <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
                  <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='exclamation-triangle' />
                </MDBCol>
                <MDBCol className='d-flex align-items-center'>
                  You have not sent a welcome notification to your users yet. Phin recommends sending a welcome email before launching a phishing campaign.
                  You can do this from the users page, or go back to the onboarding checklist on the home page.
                </MDBCol>
              </MDBRow>
            </MDBAlert>
          </MDBRow>
        )}
        {(!company || isLoadingCompany ||
        isLoadingTemplates || isLoadingCampaignCategories ||
        isLoadingSendingDomains || isLoadingActiveCampaigns ||
        isLoadingPhishingCampaignPresets) && (
          <Spinner />
        )}
        {(company && !isLoadingCompany &&
        !isLoadingTemplates && !isLoadingCampaignCategories &&
        !isLoadingSendingDomains && !isLoadingActiveCampaigns &&
        !isLoadingPhishingCampaignPresets) && (
          <>
            {isPreset &&
              <>
                <hr />
                <MDBRow>
                  <CampaignName name={name} setName={setName} />
                </MDBRow>
              </>}
            <hr />
            <MDBRow>
              <CampaignFrequencySelector
                startTime={startTime}
                setStartTime={setStartTime}
                campaignFrequency={campaignFrequency}
                tz={company ? company.timezone.value : 'America/New_York'}
                campaignType={campaignTypes.PHISHING}
                isRunning={selectedCampaign ? selectedCampaign.running : false}
                nextFireTimes={nextFireTimes}
                firstFireDate={firstFireDate}
                nextFireTimesErrorMessage={nextFireTimesErrorMessage}
                isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
                setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
              />
            </MDBRow>
            <hr />
            <MDBRow>
              <ContentViewer content={content} selectedCampaign={selectedCampaign} />
            </MDBRow>
            <hr />
            <MDBRow className='d-flex justify-content-end align-items-center'>
              <MDBBtn onClick={() => { history.goBack() }} color='danger'>Cancel</MDBBtn>
              <MDBBtn onClick={launchCampaign} color='primary'>Launch</MDBBtn>
            </MDBRow>
          </>
        )}
      </MDBContainer>
    )
  } else {
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <h1>Campaign Launcher</h1>
            <p>Select a preset below to launch that campaign. If you would rather build a custom campaign, select the "Start A New Campaign" button on the phishing dashboard.</p>
          </MDBCol>
        </MDBRow>
        <hr />
        {(!company || isLoadingCompany ||
        isLoadingTemplates || isLoadingCampaignCategories ||
        isLoadingSendingDomains || isLoadingActiveCampaigns ||
        isLoadingPhishingCampaignPresets) && (
          <Spinner />
        )}

        {(company && !isLoadingCompany &&
        !isLoadingTemplates && !isLoadingCampaignCategories &&
        !isLoadingSendingDomains && !isLoadingActiveCampaigns &&
        !isLoadingPhishingCampaignPresets) && (
          <>
            <PresetLibrary campaignPresets={phishingCampaignPresets} selectCallback={handlePresetSelection} />
            <hr />
            <MDBRow className='d-flex justify-content-end align-items-center'>
              <MDBBtn onClick={() => { history.goBack() }} color='danger'>Cancel</MDBBtn>
            </MDBRow>
          </>
        )}
      </MDBContainer>
    )
  }
}

export default CampaignLauncher
