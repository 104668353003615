import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import PartnerOverviewPage from '../pages/PartnerOverviewPage'
import CreatePartner from '../pages/CreatePartner'
import CreateAdmin from '../pages/CreateAdmin'
import { useDispatch } from 'react-redux'
import { getDistributorThunk } from '../store/DistributorSlice'
import { useAuth0 } from '@auth0/auth0-react'

export function DistributorRouter ({ id }) {
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const { logout } = useAuth0()

  useEffect(() => {
    async function setUp () {
      try {
        dispatch(getDistributorThunk(id))
      } catch (error) {
        logout({ returnTo: window.location.origin })
        throw error
      }
    }
    setUp()
  }, [])

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => (
          <PartnerOverviewPage
            id={id}
          />
        )}
      />

      <Route
        exact
        path={`${path}/create-partner`}
        render={() => (
          <CreatePartner
            id={id}
          />
        )}
      />

      <Route
        exact
        path={`${path}/create-admin`}
        render={() => (
          <CreateAdmin
            id={id}
          />
        )}
      />
    </Switch>
  )
}
