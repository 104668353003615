import React from 'react'

import { useSelector } from 'react-redux'
import { Checkbox, FormControlLabel } from '@mui/material'
import { ReorderComponent } from './curriculumBuilder/ReorderComponent'
import { ReorderTopicsComponent } from './curriculumBuilder/ReorderTopicsComponent'
import { campaignFrequencyIterations, campaignTypes } from '../../../frontendConsts.js'
import './trainingCampaignFinalize.scss'

function TrainingCampaignFinalize ({
  fixedAudienceList, initialFixedAudienceList,
  campaignType, modules, setModules,
  trainingTopics, setTrainingTopics,
  nextFireTimes, tz, currentModule,
  startingDate, campaignFrequency, isCampaignNew,
  isAutoCatchupEnabled, setIsAutoCatchupEnabled,
  isContinuous
}) {
  const { courseCatalog } = useSelector((state) => state.trainings)
  const catalogMap = courseCatalog.reduce((obj, item) => { return { ...obj, [item.id]: item } }, {})

  let firetimePointer = 0
  const updatedModules = modules.map((module, index) => {
    const courseDefinitionId = module.path.replace('course-definitions/', '')
    const [hours, minutes] = campaignFrequency.time.split(':')
    const startDateTime = new Date(startingDate)
    startDateTime.setHours(hours)
    startDateTime.setMinutes(minutes)
    startDateTime.setSeconds(0)

    // Offset the fire times to be displayed if they've already started. If they have already started, they'll use the first firetime,
    // but in the reordertopicscomponent the original dateStarted will be displayed as a locale string
    const thisNextFireTime = nextFireTimes[firetimePointer]
    if (!module.dateStarted) {
      firetimePointer = firetimePointer + 1
    }
    return {
      ...module,
      firetime: campaignFrequency.iterations.value === campaignFrequencyIterations.ALL_AT_ONCE ? startDateTime : thisNextFireTime,
      topic: catalogMap[courseDefinitionId]?.topic
    }
  })

  const headerMessage = campaignType === campaignTypes.ONBOARDING ? 'Finalize Onboarding Campaign' : 'Finalize Campaign'

  const getDescriptionMessage = () => {
    let message = 'Change the order of your courses and turn on additional features'
    if (campaignType === campaignTypes.ONBOARDING) {
      let newUsersAddedLength
      if (fixedAudienceList.length) {
        if (initialFixedAudienceList.length > 0) {
          newUsersAddedLength = fixedAudienceList.length - initialFixedAudienceList.length
        }
        const audienceLength = isCampaignNew ? fixedAudienceList.length : newUsersAddedLength
        message = `${audienceLength} new user${audienceLength > 1 ? 's' : ''} will be enrolled in the following courses immediately`
      } else {
        message = 'When a new user who matches this campaign\'s audience filter is added, the user will be enrolled in all courses in this campaign.'
      }
    }
    return message
  }

  return (
    <div className='curriculum-card-container'>
      <div>
        <h4 className='phin-h4'>{headerMessage}</h4>
        <p className='description margin-top:-1'>{getDescriptionMessage()}</p>
        {(campaignType === campaignTypes.TRAINING || isContinuous) && (
          <h5 className='phin-h5 margin-top:2 margin-bottom:-2'>Modify the Campaign Send Order</h5>
        )}
      </div>

      <div className='phin-border margin-top:0'>
        {!isContinuous && (
          <>
            <div className='reorder-flex-header'>
              {(campaignType === campaignTypes.TRAINING) && (
                <>
                  <p className='icon-col' />
                  <p className='order-col'>Order</p>
                </>
              )}
              <p className='data-col'>Name</p>
              <p className='data-col'>Topic</p>
              {campaignType === campaignTypes.TRAINING && (
                <p className='data-col'>Assignment date</p>
              )}
            </div>
            <ReorderComponent
              currentModule={currentModule}
              modules={updatedModules}
              setModules={setModules}
              tz={tz}
              campaignType={campaignType}
            />
          </>
        )}
        {isContinuous && (
          <>
            <div className='reorder-flex-header'>
              <p className='icon-col' />
              <p className='order-col'>Order</p>
              <p className='data-col'>Topic</p>
            </div>
            <ReorderTopicsComponent
              currentModule={currentModule}
              modules={updatedModules}
              trainingTopics={trainingTopics}
              setTrainingTopics={setTrainingTopics}
              isCampaignNew={isCampaignNew}
            />
          </>
        )}
      </div>

      {(campaignType === campaignTypes.TRAINING && !isContinuous) && (
        <p className='margin-top:-1 description'>Drag to reorder the curriculum courses</p>
      )}

      {(isContinuous) && (
        <p className='margin-top:-1 description'>Drag to reorder the curriculum topics</p>
      )}

      {((campaignType === campaignTypes.TRAINING || isContinuous) && campaignFrequency.iterations.value !== campaignFrequencyIterations.ALL_AT_ONCE) && (
        <>
          <h5 className='phin-h5 margin-top:2'>Additional Features</h5>
          <div className='phin-border margin-top:-1 padding-left:1 padding-top:-1 padding-bottom:-1'>
            <FormControlLabel
              control={<Checkbox name='catchup' checked={isAutoCatchupEnabled} onChange={(e) => { setIsAutoCatchupEnabled(e.target.checked) }} />}
              label={<><h6 className='phin-h6'>Training Catch-up</h6><p className='description margin:none'>Employees added to this campaign late will be caught-up with all the missed content when the next course is assigned.</p></>}
            />
          </div>
        </>
      )}

    </div>
  )
}

export default TrainingCampaignFinalize
