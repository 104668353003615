import { Card, Divider, Grid, Skeleton } from '@mui/material'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const basePrice = 2

function calculateNextBillingDate () {
  const currentDate = DateTime.now()
  let newDate = currentDate
  newDate = newDate.set({ day: 5 })

  if (currentDate.day < 5) {
    return newDate.toLocaleString()
  } else {
    newDate = newDate.plus({ month: 1 })
    return newDate.toLocaleString()
  }
}

const nextBillingDate = calculateNextBillingDate()

export function BillingEstimateWidget () {
  const { partner, loaders } = useSelector((state) => state.partner)
  const {
    isLoadingPartner
  } = loaders

  const { partnerBillingDetails, loaders: partnerBillingLoaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingPartnerBillingDetails } = partnerBillingLoaders

  const [unitPrice, setUnitPrice] = useState(basePrice)
  const [totalUnits, setTotalUnits] = useState(0)
  const [totalCost, setTotalCost] = useState(0)

  useEffect(() => {
    let newUnitPrice = unitPrice
    if (partnerBillingDetails && partnerBillingDetails.couponId) {
      newUnitPrice = (basePrice - (basePrice * (partnerBillingDetails.discountPercentage / 100))).toFixed(2)
      setUnitPrice(newUnitPrice)
    }

    if (partner && partnerBillingDetails) {
      const newTotalUnits = partner.minimumBillableUsers > (partner.currentBillableUsers || 0) ? partner.minimumBillableUsers : partner.currentBillableUsers
      setTotalCost(newTotalUnits * newUnitPrice)
      setTotalUnits(newTotalUnits)
    }
  }, [partnerBillingDetails, partner])

  return (
    <div className='margin-bottom:2'>
      {((isLoadingPartner && !partner) || (isLoadingPartnerBillingDetails && !partnerBillingDetails)) && (
        <Skeleton sx={{ bgcolor: '#c4c4c4', minWidth: 200 }} variant='rectangle' height={242} />
      )}

      {(!isLoadingPartner && partner && !isLoadingPartnerBillingDetails && partnerBillingDetails) && (
        <Card variant='outlined' sx={{ minWidth: 200, minHeight: 242, padding: 'var(--phin-s0)' }}>
          <div className='phin-h4 margin-bottom:-2'>Current Month Cost Estimate</div>
          <div className='phin-flex-space-between-row '>
            <div className='phin-h6  margin-bottom:0' style={{ color: 'var(--phin-gray)' }}>{partner.minimumBillableUsers} Monthly Minimum Users</div>
            <div className='phin-h6 margin-bottom:0'>Next Billing Date: {nextBillingDate}</div>
          </div>

          <Grid container spacing={2} sx={{ marginBottom: 'var(--phin-s0)' }} justifyContent='space-between'>
            <Grid item>
              <div className='phin-h6'>Price per User: ${unitPrice}</div>
            </Grid>
            <Grid item>
              <div className='phin-h6'>Base Price: ${basePrice.toFixed(2)}</div>
            </Grid>
            <Grid item>
              <div className='phin-h6'>Discount: {partnerBillingDetails.discountPercentage || 0}%</div>
            </Grid>
          </Grid>

          <Divider sx={{ marginBottom: 'var(--phin-s0)', borderBottomWidth: '2px', borderColor: 'black' }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={1} direction='column'>
                <Grid item xs={6}>
                  <div className='phin-h6'>Total Units</div>
                </Grid>
                <Grid item xs={6}>
                  <div className='phin-h4'>{totalUnits}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1} direction='column'>
                <Grid item xs={6}>
                  <div className='phin-h6'>Total Cost</div>
                </Grid>
                <Grid item xs={6}>
                  <div className='phin-h4'>${totalCost.toFixed(2)}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  )
}
