import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import React from 'react'

const ValidationModal = ({
  isOpen,
  toggleFunction,
  messageJSX,
  falseOptionText,
  trueOptionText,
  handlerFunction,
  falseOptionColor = 'red',
  trueOptionColor = 'primary',
  backdrop = true
}) => {
  return (
    <MDBModal size='md' isOpen={isOpen} toggle={toggleFunction} backdrop={backdrop}>
      <MDBModalHeader className=' text-center d-flex justify-content-center'>
        {messageJSX}
      </MDBModalHeader>

      <MDBModalBody className=' d-flex justify-content-center'>
        <MDBBtn id='deny-validation-button' value='cancel' onClick={toggleFunction} color={falseOptionColor}>{falseOptionText}</MDBBtn>
        <MDBBtn id='confirm-validation-button' type='submit' onClick={handlerFunction} color={trueOptionColor}>{trueOptionText}</MDBBtn>
      </MDBModalBody>
    </MDBModal>
  )
}

export default ValidationModal
