/*eslint-disable*/
import React, { Component } from "react"

import {
	ToastContainer
} from "mdbreact"

/**
 * Required to utilize the NotificationUtilities in any React Component.
 * Simply import and put this component inside your main page container.
 * @see notificationUtilities.js
 */
class NotificationWrapper extends Component {
	render() {
		return (
			<ToastContainer
          hideProgressBar
          newestOnTop
          autoClose={5000}
      ></ToastContainer>
		)
	}
}

export default NotificationWrapper
