import { useEffect, useState } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Box, Button, IconButton } from '@mui/material'
import { IoArrowBackOutline, IoEyeSharp } from 'react-icons/io5'
import NotificationWrapper from '../components/notifications/notificationWrapper'
import { getActiveCampaignsThunk, getCampaignAudienceThunk } from '../../store/CampaignsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import { AudienceDetailsTable } from '../components/audience/AudienceDetailsTable'
import { capitalizeFirstLetter } from '../../utils/FormattingUtils'
import { CampaignSchedulePreviewer } from '../components/Campaigns/CampaignSchedulePreviewer'
import { getTemplatesThunk } from '../../store/TemplatesSlice'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { getPhishingClickStatsThunk, getSendRecordsThunk } from '../../store/AnalyticsSlice'
import { DateTime } from 'luxon'
import { navigationRoutes } from '../../frontendConsts'

export const PhishingViewer = ({ id: companyId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { campaignId } = useParams()
  const { activeCampaigns, campaignAudience, loaders: { isLoadingActiveCampaigns, isLoadingCampaignAudience } } = useSelector((state) => state.campaigns)
  const { userTemplates, defaultTemplates, sharedTemplates } = useSelector((state) => state.templates)
  const { phishingClicksStats, sendRecords } = useSelector((state) => state.analytics)
  const [campaign, setCampaign] = useState(null)
  const [campaignTemplates, setCampaignTemplates] = useState([])

  const audienceColumns = [
    { field: 'first', headerName: 'First Name', flex: 0.8 },
    { field: 'last', headerName: 'Last Name', flex: 0.8 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 0.5, renderCell: (field) => capitalizeFirstLetter(field.value) },
    { field: 'analytics', headerName: 'Analytics', flex: 0.5, renderCell: (params) => renderViewUserAnalyticsButton(params.row) }
  ]

  const templateColumns = [
    { field: 'name', headerName: 'Template Name', flex: 0.8 },
    { field: 'tags', headerName: 'Category', flex: 0.8, renderCell: (field) => field.value.join(', ') },
    { field: 'subject', headerName: 'Subject', flex: 1 },
    { field: 'numberOfSends', headerName: 'Number of Sends', flex: 0.5 },
    { field: 'numberOfClicks', headerName: 'Number of Clicks', flex: 0.5 },
    {
      field: 'lastClickDate',
      headerName: 'Last Click Date',
      flex: 0.5,
      renderCell: (field) => {
        if (!field.value) return 'N/A'
        return DateTime.fromSeconds(Number(field.value)).toFormat('MM/dd/yyyy')
      }
    }
  ]

  useEffect(() => {
    dispatch(getActiveCampaignsThunk(companyId))
    dispatch(getCampaignAudienceThunk({ companyId, campaignId }))
    dispatch(getTemplatesThunk(companyId))
    dispatch(getPhishingClickStatsThunk(companyId))
    dispatch(getSendRecordsThunk(companyId))
  }, [])

  useEffect(() => {
    if (activeCampaigns) {
      setCampaign(activeCampaigns.find((campaign) => campaign.id === campaignId))
    }
  }, [activeCampaigns])

  useEffect(() => {
    // each time the useEffect runs, combine userTemplates, defaultTemplates, and sharedTemplates into one array of templates
    let templates = []
    let templatesInCampaign
    if (userTemplates) templates = [...templates, ...userTemplates]
    if (defaultTemplates) templates = [...templates, ...defaultTemplates]
    if (sharedTemplates) templates = [...templates, ...sharedTemplates]

    if (campaign?.campaignType === 'continuous' && userTemplates && defaultTemplates && sharedTemplates && phishingClicksStats && sendRecords) {
      // Filter by topic
      templatesInCampaign = templates.filter((template) => template.tags.every((tag) => campaign.categoryNames.includes(tag)))
        .map((temp) => ({
          ...temp,
          numberOfSends: sendRecords.filter((record) => (record.templateId === temp.id && record.campaignId === campaignId)).length || 0,
          numberOfClicks: phishingClicksStats.filter((stat) => (stat.templateName === temp.name && stat.campaignId === campaignId)).length || 0,
          lastClickDate: phishingClicksStats.filter((stat) => (stat.templateName === temp.name && stat.campaignId === campaignId)).sort((a, b) => new Date(b.clickDateTime._seconds) - new Date(a.clickDateTime._seconds))[0]?.clickDateTime._seconds || null
        }))
    } else if (campaign?.campaignType === 'fixed length' && userTemplates && defaultTemplates && sharedTemplates && phishingClicksStats && sendRecords) {
      // Filter by id
      templatesInCampaign = (templates.filter((template) => campaign.templateNames.includes(template.id)))
        .map((temp) => ({
          ...temp,
          numberOfSends: sendRecords.filter((record) => (record.templateId === temp.id && record.campaignId === campaignId)).length || 0,
          numberOfClicks: phishingClicksStats.filter((stat) => (stat.templateName === temp.name && stat.campaignId === campaignId)).length || 0,
          lastClickDate: phishingClicksStats.filter((stat) => (stat.templateName === temp.name && stat.campaignId === campaignId)).sort((a, b) => new Date(b.clickDateTime._seconds) - new Date(a.clickDateTime._seconds))[0]?.clickDateTime._seconds || null
        }))
    }
    setCampaignTemplates(templatesInCampaign)
  }, [campaign, userTemplates, defaultTemplates, sharedTemplates, phishingClicksStats, sendRecords])

  function renderViewUserAnalyticsButton (user) {
    return (
      <IconButton
        aria-label='View User Analytics'
        id={user.id}
        onClick={() => {
          history.push(`/companies/${companyId}${navigationRoutes.ANALYTICS_USERS}/${user.id}`, { previousPageTitle: 'Phishing Campaign Details' })
        }}
      >
        <IoEyeSharp />
      </IconButton>
    )
  }

  return (
    <>
      <NotificationWrapper />
      {(isLoadingActiveCampaigns || isLoadingCampaignAudience || !campaign || !campaignTemplates)
        ? (<Spinner message='Making everything look nice' />)
        : (
          <div className='screen-pad d-flex flex-column'>
            <Box style={{ alignSelf: 'left' }}>
              <Button component={Link} sx={{ mb: 1 }} variant='text' color='primary' size='small' to={`/companies/${companyId}/campaigns`} onClick={() => null} startIcon={<IoArrowBackOutline />}>Return to Campaign Dashboard</Button>
              <h1 className='phin-h1'>{campaign?.name}</h1>
              <p>{campaign?.description}</p>
            </Box>
            <CampaignSchedulePreviewer campaign={campaign} />
            <AudienceDetailsTable audience={campaignAudience} audienceColumns={audienceColumns} />
            <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
              <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Templates</h3>
              <DataGrid
                autoHeight
                style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                className='DataGrid'
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'creationDate', sort: 'desc' }]
                  }
                }}
                rows={campaignTemplates}
                columns={templateColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250, id: 'phishing-viewer-templates-table-search', ariaLabel: 'Search' },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                disableColumnSelector
                disableDensitySelector
              />
            </Box>
          </div>
          )}
    </>
  )
}
