import React from 'react'

const SelectOptions = ({ options, name, value, label, onChange, disabled, outerClassName, innerClassName, multiple }) => {
  /*
    options: [
      {
        text: string,
        value: string || int,
        disabled: true || blank
        selected: true || blank
      },
      ...
    ]
    */

  return (
    <div className={`my-auto${' ' + outerClassName}`}>
      <label> {label}
        <select
          disabled={disabled}
          name={name}
          value={value}
          className={`browser-default selector custom-select${' ' + innerClassName}`}
          onChange={e => onChange(e)}
          multiple={multiple}
        >
          {
            options.map((option) => (
              <option
                disabled={option.disabled}
                key={option.value}
                selected={option.selected}
                value={option.value}
              >
                {option.text}
              </option>
            ))
        }
        </select>
      </label>
    </div>
  )
}
export default SelectOptions
