import React, { Component } from 'react'
import ViewerModal from '../../components/ViewerModal'
import ActionButton from './ActionButton'
import { IoMail, IoPhonePortrait } from 'react-icons/io5'

import {
  MDBBtn, MDBCard, MDBCardBody, MDBRow, MDBCardHeader,
  MDBIcon, MDBCol, MDBDataTable, MDBTooltip
} from 'mdbreact'
import PhinBadge from '../PhinBadge'

class CampaignTemplateEditor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      viewerModal: false,
      viewerTemplate: {}
    }
    this.editCallback = this.props.editCallback
    this.handleToggleViewerModal = this.handleToggleViewerModal.bind(this)
  }

  handleToggleViewerModal (template = {}) {
    this.setState({
      viewerModal: !this.state.viewerModal,
      viewerTemplate: template
    })
  }

  generateTemplateOption (template, isDefault = false) {
    const name = isDefault ? <MDBIcon className='text-warning pr-1' icon='star' /> : ''
    return ({
      name: <div searchValue={template.name}>{name}{template.name}</div>,
      channel: (
        <div searchValue={template.channel}>
          <PhinBadge
            displayString={template.channel === 'email' ? 'Email' : 'SMS'}
            icon={template.channel === 'email' ? <IoMail /> : <IoPhonePortrait />}
            color='rgb(140, 140, 140)'
          />
        </div>
      ),
      tags: (template.tags || []).join(', '),
      action: (
        <div key={template.id}>
          <MDBRow>
            <ActionButton title='select' className='select-template-button' color='info' icon='plus' onClick={() => { this.handleTemplateSelection(template.id) }} />
            <ActionButton title='Info' icon='eye' color='default' outline onClick={() => { this.previewTemplate(template) }} />
          </MDBRow>
        </div>

      )

    })
  }

  generateSelectedTemplateOption (template, isDefault = false) {
    const name = isDefault ? <MDBIcon className='text-warning pr-1' icon='star' /> : ''
    return ({
      name: <div searchValue={template.name}>{name}{template.name}</div>,
      channel: (
        <div searchValue={template.channel}>
          <PhinBadge
            displayString={template.channel}
            icon={template.channel === 'email' ? <IoMail /> : <IoPhonePortrait />}
            color='rgb(140, 140, 140)'
          />
        </div>
      ),
      tags: (template.tags || []).join(', '),
      action: (
        <div key={template.id} searchValue='selected'>
          <MDBRow>
            <ActionButton color='success' onClick={() => { this.handleTemplateRemoval(template.id) }} icon='check' title='Unselect' />
            <ActionButton outline onClick={() => { this.previewTemplate(template) }} title='Info' icon='eye' />
          </MDBRow>
        </div>
      )

    })
  }

  generateTemplateOptions (templates, selectedTemplates) {
    const templateOptions = []
    templates.userTemplates.forEach((template, index) => {
      if (selectedTemplates.includes(template.id)) {
        templateOptions.push(this.generateSelectedTemplateOption(template))
      } else {
        templateOptions.push(this.generateTemplateOption(template))
      }
    })
    templates.sharedTemplates.forEach((template, index) => {
      if (selectedTemplates.includes(template.id)) {
        templateOptions.push(this.generateSelectedTemplateOption(template))
      } else {
        templateOptions.push(this.generateTemplateOption(template))
      }
    })
    templates.defaultTemplates.forEach(async (template, index) => {
      if (selectedTemplates.includes(template.id)) {
        templateOptions.push(this.generateSelectedTemplateOption(template, true))
      } else {
        templateOptions.push(this.generateTemplateOption(template, true))
      }
    })

    return templateOptions
  }

  previewTemplate (template) {
    this.setState({ viewerModal: !this.state.viewerModal, viewerTemplate: template })
  }

  handleTemplateSelectAll (templates) {
    this.editCallback(templates.map(template => template.id))
  }

  handleTemplateRemoveAll () {
    this.editCallback([])
  }

  handleTemplateSelection (templateID) {
    this.editCallback([...this.props.selectedTemplates, templateID])
  }

  handleTemplateRemoval (templateID) {
    this.editCallback([...this.props.selectedTemplates.filter(selectedTemplateID => selectedTemplateID !== templateID)])
  }

  render () {
    const { userTemplates, defaultTemplates, sharedTemplates, selectedTemplates } = this.props
    const templates = { defaultTemplates, userTemplates, sharedTemplates }

    const templateSelectionColumns = [
      {
        label: 'Name',
        field: 'name',
        searchable: true
      },
      {
        label: 'Channel',
        field: 'channel',
        searchable: false
      },
      {
        label: 'Tags',
        field: 'tags',
        searchable: true
      },
      {
        label: 'Actions',
        field: 'action',
        searchable: false
      }
    ]

    const templateSelectionData = {
      columns: templateSelectionColumns,
      rows: this.generateTemplateOptions(templates, selectedTemplates)
    }

    return (
      <>
        <ViewerModal isOpen={this.state.viewerModal} toggleViewerModal={this.handleToggleViewerModal} template={this.state.viewerTemplate} />
        <MDBCard>
          <MDBCardHeader>
            <MDBRow className='d-flex'>
              <MDBCol className='d-flex justify-content-start align-items-center'>
                <h5>Templates</h5>
              </MDBCol>
              <MDBCol className='d-flex justify-content-end'>
                <MDBTooltip
                  placement='left'
                  id='popover23'
                  material
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                  <div>
                    Here you can select the templates sent during the campaign. You can select from any of the Phin Security default templates or any of your own.
                  </div>
                </MDBTooltip>
              </MDBCol>
            </MDBRow>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBRow className='mt-2 d-flex justify-content-center'>
              <MDBBtn onClick={() => this.handleTemplateRemoveAll()} color='red'><MDBIcon icon='times' /> Clear All</MDBBtn>
              <MDBBtn onClick={() => this.handleTemplateSelectAll([...userTemplates, ...defaultTemplates, ...sharedTemplates])} color='primary'><MDBIcon icon='check-double' /> Select All</MDBBtn>
            </MDBRow>
            <hr />
            <MDBRow className='d-flex justify-content-center mb-4 template-selector-table'>
              <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <MDBDataTable disableRetreatAfterSorting sortable={false} sortRows={['name']} searchingLabel='name' small fixed btn striped data={templateSelectionData} />
              </div>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </>
    )
  }
}

export default CampaignTemplateEditor
