import React from 'react'
import {
  MDBSpinner,
  MDBBtnFixed,
  MDBBtnFixedItem
} from 'mdbreact'

const SlideUpDataExportButtons = ({ exporting, onMouseHover, onMouseLeave, buttonStyles, downloadOrgData, downloadCampaignData }) => {
  let exportButtonContent
  if (exporting) {
    exportButtonContent = (<MDBSpinner multicolor />)
  } else {
    exportButtonContent = (
      <MDBBtnFixed
        onMouseEnter={() => onMouseHover()}
        onMouseLeave={() => onMouseLeave()}
        disabled
        floating
        size='lg'
        color='blue'
        title='Download Data'
        icon='file-download'
      >
        <MDBBtnFixedItem
          buttonStyle={buttonStyles}
          color='green'
          icon='building'
          title='Download All Company Data'
          onClick={downloadOrgData}

        />
        <MDBBtnFixedItem
          id='download-campaign-data'
          buttonStyle={buttonStyles}
          color='teal'
          title='Download Selected Campaign Data'
          icon='file-export'
          onClick={downloadCampaignData}
        />

      </MDBBtnFixed>
    )
  }
  return (
    <div className='mb-5 mr-5 phin-fixed-bottom-right'>
      {exportButtonContent}
    </div>
  )
}

export default SlideUpDataExportButtons
