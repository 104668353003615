import { MDBIcon } from 'mdbreact'
import './css/integrationPhinAPI.css'
import { partnerIntegrationStatuses } from '../frontendConsts.js'
import { createPhinApiIntegrationThunk, disconnectPhinApiThunk, getPhinApiIntegrationThunk, rotatePhinApiSecretThunk, setAuth0ClientSecretAction } from '../store/PhinApiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import ValidationModal from './components/ValidationModal'
import { Skeleton } from '@mui/material'
import Spinner from './components/Spinner'

export function PhinApiIntegrationPage ({ id }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const { partnerIntegrationsMap } = useSelector((state) => state.partnerIntegrations)
  const { phinApiIntegration, auth0ClientSecret, loaders } = useSelector((state) => state.phinAPI)
  const { isLoadingPhinAPI, isLoadingPhinApiSecret } = loaders

  const [disableButtons, setDisableButtons] = useState(false)
  const [isReusableModalOpen, setIsReusableModalOpen] = useState(false)
  const [confirmModalFunction, setConfirmModalFunction] = useState(null)
  const [modalText, setModalText] = useState()

  useEffect(() => {
    if (partnerIntegrationsMap) {
      dispatch(getPhinApiIntegrationThunk())
    }
  }, [partnerIntegrationsMap])

  useEffect(() => {
    return function cleanUp () {
      dispatch(setAuth0ClientSecretAction(null))
    }
  }, [])

  const renderStatusBreadCrumbs = (phinApiIntegration) => {
    const breadCrumbArray = []
    const phinApiIntegrationStatus = phinApiIntegration ? phinApiIntegration.integrationStatus : partnerIntegrationStatuses.NOT_ENABLED
    for (const [stage] of Object.entries(partnerIntegrationStatuses)) {
      if (partnerIntegrationStatuses[stage] === partnerIntegrationStatuses.ENABLED) {
        breadCrumbArray.push(
          <>
            <div className={(partnerIntegrationStatuses[stage] === phinApiIntegrationStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{partnerIntegrationStatuses[stage]}</div>
          </>
        )
      } else {
        breadCrumbArray.push(
          <>
            <div className={(partnerIntegrationStatuses[stage] === phinApiIntegrationStatus) ? 'statusCrumb activeCrumb' : 'statusCrumb'}>{partnerIntegrationStatuses[stage]}</div><hr className='crumbHR' style={{ display: 'inline-block' }} />
          </>
        )
      }
    }
    return breadCrumbArray
  }

  function closeModal () {
    setIsReusableModalOpen(false)
    setDisableButtons(false)
  }

  const createPhinApiIntegration = async () => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    await dispatch(createPhinApiIntegrationThunk(id))
    setDisableButtons(false)
  }

  const rotatePhinApiSecret = () => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    setIsReusableModalOpen(true)
    setConfirmModalFunction(() => {
      return () => {
        dispatch(rotatePhinApiSecretThunk(id))
        closeModal()
      }
    })
    setModalText(<p>Are you sure you want to rotate your Phin API Client Secret? <br /> WARNING: This will revoke the current Client Secret. Any existing usage of the Phin API will need to use the new Client Secret.</p>)
  }

  const disconnectPhinApi = () => {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    setIsReusableModalOpen(true)
    setConfirmModalFunction(() => {
      return () => {
        dispatch(disconnectPhinApiThunk({ partnerId: id, history }))
        closeModal()
      }
    })
    setModalText(<p>Are you sure you want to delete the Phin API Integration? <br /> WARNING: This will revoke the current Client Secret. Any services using the Client ID and Client Secret will no longer be supported.</p>)
  }

  const saveToClipBoard = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      NotificationUtilities.sendSuccessMessage('Copied!')
    })
  }

  return (
    <div className='screen-pad'>
      <NotificationWrapper />

      <ValidationModal
        isOpen={isReusableModalOpen}
        toggleFunction={closeModal}
        handlerFunction={confirmModalFunction}
        messageJSX={modalText}
        falseOptionText='Cancel'
        trueOptionText='Confirm'
        falseOptionColor='red'
        trueOptionColor='primary'
      />

      <div className='detailsPageHeadingGroup'>
        <img src='/logoBlueNoTextSmall.png' />
        <div>
          <div className='detailsTitleGroup'>
            <h2 className='phin-page-heading'>Phin API</h2>
          </div>
          {(true) && (
            <div className='crumbContainer'>
              {renderStatusBreadCrumbs(phinApiIntegration)}
            </div>
          )}
        </div>
      </div>
      <div className='details'>
        <div className='left'>
          {/* start of the consent card */}
          <div className='consentCard'>
            <div className='consentTitle'> In order to use the Phin API you will need to generate an ID and a secret </div>
            <div className='permissions'>
              <p className='consentSubtitle'>Opting into the Phin API will grant anyone with your ID and secret read-only permission to:</p>
              <ul>
                <li>
                  User Information
                  <ul>
                    <li>
                      Including full name, email addresses, simulated phishing data, and training data.
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  Partner Name
                  <ul>
                    <li>
                      The name of the partner managing these users.
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  Partner's Clients' Names
                  <ul>
                    <li>
                      The list of companies that this partner is managing.
                    </li>
                  </ul>
                </li>
              </ul>
              <p className='consentSubtitle'>How will this information get used?</p>
              <ul>
                <li>
                  This information will get used to generate responses to api calls.
                </li>
              </ul>
            </div>

            <div className='d-flex justify-content-center'>
              <a className={!(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'consentBtn noSelect' : 'consentBtn noSelect disabled-color'} onClick={() => createPhinApiIntegration()}>Generate</a>
            </div>

          </div>
          {/* end of the consent card */}
        </div>
        {(!phinApiIntegration && isLoadingPhinAPI)
          ? (
            <div style={{ margin: 'auto' }}><Spinner message='Generating API Credentials' /></div>
            )
          : (
            <div className='right' style={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
              <div className='consentCard'>
                <div id='api-credentials'>
                  <div className='card-header-group'>
                    <h4 className='phin-h4'>API Credentials</h4>
                    {auth0ClientSecret && (
                      <div className='card-desc'> Note:
                        The Client Secret value can only be viewed immediately after creation. Be sure to save the secret before navigating away.
                      </div>
                    )}
                    {!auth0ClientSecret && (
                      <div className='card-desc'> Note:
                        The Client Secret value cannot be viewed after creation. If you need a new Client Secret, use the Rotate Secret button to generate a new one.
                        This will revoke the current Client Secret.
                      </div>
                    )}
                  </div>
                  <div className='credential-center'>
                    <div className='cred-group'>
                      <p>Partner ID:</p>
                      <div className='black-box'> {id} </div>
                      <button onClick={() => saveToClipBoard(id)} className='copy-btn'><MDBIcon far icon='clone' /></button>
                    </div>
                    <div className='cred-group'>
                      <p>Client ID:</p>
                      {isLoadingPhinAPI
                        ? (
                          <Skeleton sx={{ bgcolor: '#c4c4c4', margin: '.25em', borderRadius: '8px', paddingRight: '70%' }} height={40} width={380} variant='rectangle'>
                            <div className={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'black-box' : 'black-box disabled-color'}> {(phinApiIntegration && phinApiIntegration.auth0ClientId) || '-----'} </div>
                          </Skeleton>
                          )
                        : (
                          <div className={(phinApiIntegration && phinApiIntegration.auth0ClientId) ? 'black-box' : 'black-box disabled-color'}> {(phinApiIntegration && phinApiIntegration.auth0ClientId) || '-----'} </div>
                          )}
                      <button disabled={!(phinApiIntegration && phinApiIntegration.auth0ClientId)} onClick={() => saveToClipBoard(phinApiIntegration.auth0ClientId)} className='copy-btn'><MDBIcon far icon='clone' /></button>
                    </div>
                    <div className='cred-group'>
                      <p>Client Secret:</p>
                      {(isLoadingPhinAPI || isLoadingPhinApiSecret)
                        ? (
                          <Skeleton sx={{ bgcolor: '#c4c4c4', margin: '.25em', borderRadius: '8px', paddingRight: '70%' }} height={40} width={380} variant='rectangle'>
                            <div className={(auth0ClientSecret) ? 'black-box' : 'black-box disabled-color'}> {auth0ClientSecret || '-----'} </div>
                          </Skeleton>
                          )
                        : (
                          <div className={(auth0ClientSecret) ? 'black-box' : 'black-box disabled-color'}> {auth0ClientSecret || '-----'} </div>
                          )}
                      <button disabled={!auth0ClientSecret} onClick={() => saveToClipBoard(auth0ClientSecret)} className='copy-btn'><MDBIcon far icon='clone' /></button>
                    </div>
                  </div>

                  <div className='action-btn-group'>
                    <button className='api-action-btn noSelect' id='rotate' onClick={() => rotatePhinApiSecret()}>Rotate Secret</button>
                    <button className='api-action-btn noSelect' id='disconnect' onClick={disconnectPhinApi}>Disconnect</button>
                    <a className='api-action-btn noSelect' id='documentation' href='https://phinsec.notion.site/Phin-API-Documentation-519cd5836ab94b598c79369228c17783' target='_blank' rel='noreferrer'>Documentation  <MDBIcon fas icon='external-link-alt' /></a>
                  </div>
                </div>
              </div>
            </div>

            )}

      </div>
    </div>
  )
}
