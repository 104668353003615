import { MDBTooltip, MDBIcon, MDBBtn, MDBCol, MDBView, MDBMask, MDBCardBody, MDBCard, MDBCardTitle, MDBCardText, MDBCardFooter } from 'mdbreact'

import { shareLevels } from '../../../../frontendConsts.js'

export default function PresetCard ({ cardInfo, selectCallback, modify = false, editCallback = undefined, deleteCallback = undefined }) {
  const editButton = (cardInfo.shareLevel === shareLevels.PREMIUM) ? (<></>) : generateEditButton()
  const deleteButton = (cardInfo.shareLevel === shareLevels.PREMIUM) ? (<></>) : generateDeleteButton()
  const footerClassString = generateClassString()
  const footerText = generateFooterText()

  function generateEditButton () {
    return (
      <MDBTooltip placement='top'>
        <MDBBtn
          id={`edit-${cardInfo.name}-button`}
          aria-label={`Edit ${cardInfo.name} Button`}
          floating
          tag='a'
          color='primary'
          action
          onClick={() => {
            editCallback(cardInfo)
          }}
        >
          <MDBIcon icon='pen' className='primary' />
        </MDBBtn>
        <div>
          Edit Preset
        </div>
      </MDBTooltip>
    )
  }

  function generateDeleteButton () {
    return (
      <MDBTooltip placement='top'>
        <MDBBtn
          id={`delete-${cardInfo.name}-button`}
          aria-label={`Delete ${cardInfo.name} Button`}
          floating
          tag='a'
          color='danger'
          action
          onClick={() => {
            deleteCallback(cardInfo)
          }}
        >
          <MDBIcon icon='trash-alt' className='primary' />
        </MDBBtn>
        <div>
          Delete Preset
        </div>
      </MDBTooltip>
    )
  }

  function generateClassString () {
    if (cardInfo.shareLevel === shareLevels.PREMIUM) {
      return 'campaign-footer premium'
    } else if (cardInfo.shareLevel === shareLevels.PARTNER) {
      return 'campaign-footer partner'
    } else {
      return 'campaign-footer'
    }
  }

  function generateFooterText () {
    if (cardInfo.shareLevel === shareLevels.PREMIUM) {
      return 'Premium Preset'
    } else if (cardInfo.shareLevel === shareLevels.PARTNER) {
      return 'Shared Preset'
    } else {
      return ''
    }
  }

  return (
    <MDBCol id={`campaignTemplate-${cardInfo.name}`} size='3' className='pl-1 pr-1'>
      <MDBView rounded cascade className='h-100 preset-overlay' hover>
        <MDBCard className='preset-card h-100'>
          <MDBCardBody className='pb-4'>
            <MDBCardTitle>{cardInfo.name}</MDBCardTitle>
            <hr />
            <MDBCardText>
              {cardInfo.description}
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter className={footerClassString}>
            <div className='flex-center'>
              <p>{footerText}</p>
            </div>
          </MDBCardFooter>
        </MDBCard>
        <MDBMask className='flex-center' overlay='blue-slight'>
          {modify && editButton}
          <MDBTooltip style={{ zIndex: 2 }} className='action-button' placement='top'>
            <MDBBtn
              id={`launch-${cardInfo.name}-button`}
              aria-label={`Launch ${cardInfo.name} Button`}
              floating
              tag='a'
              className='launch-button'
              color='secondary'
              action
              onClick={() => {
                selectCallback(cardInfo)
              }}
            >
              <MDBIcon icon='rocket' />
            </MDBBtn>
            <div>
              Launch Preset
            </div>
          </MDBTooltip>
          {modify && deleteButton}
        </MDBMask>
      </MDBView>
    </MDBCol>
  )
}
