import {
  MDBCardBody, MDBCardHeader, MDBCol,
  MDBIcon, MDBInput, MDBRow, MDBCard, MDBBtn, MDBAlert, MDBTooltip
} from 'mdbreact'
import React from 'react'
import { campaignTypes } from '../../../frontendConsts.js'

class CampaignTypeSelector extends React.PureComponent {
  renderCampaignTypeSelector (campaignType, setCampaignType) {
    const options = ['Fixed Length', 'Continuous']

    return (
      <>
        <MDBRow className='d-flex justify-content-center'>
          {options.map((type, i) => (
            <MDBInput
              key={i}
              onClick={() => { this.props.setCampaignType(type.toLowerCase()) }}
              checked={campaignType === type.toLowerCase()}
              label={type}
              type='radio'
              aria-label={`${type} Radio Button`}
              id={`${type.toLowerCase()}-radio-button`}
            />
          ))}
        </MDBRow>
      </>
    )
  }

  render () {
    const {
      campaignType,
      setCampaignType
    } = this.props

    const frequencySelector = this.renderCampaignTypeSelector(campaignType, setCampaignType)

    return (
      <MDBCard className={this.props.fluid ? 'w-100 h-100' : ''}>
        <MDBCardHeader>
          <MDBRow className='d-flex'>
            <MDBCol className='d-flex justify-content-start align-items-center'>
              <h5>Campaign Type</h5>
            </MDBCol>
            <MDBCol className='d-flex justify-content-end'>
              <MDBTooltip
                placement='left'
                id='frequencypopover'
                material
              >
                <MDBBtn
                  color='elegant'
                  className='my-0'
                  floating
                  tag='a'
                  size='sm'
                >
                  <MDBIcon icon='question' />
                </MDBBtn>
                <div>
                  This will control the frequency messages will be sent to users during the campaign.
                  A fixed-length campaign will run from start until an end date you choose.
                  You can also select continuous which will run automatically from when you start it until you stop it.
                </div>
              </MDBTooltip>
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody>
          {frequencySelector}
          <hr />
          {campaignType === campaignTypes.CONTINUOUS &&
            <MDBAlert className='animated fadeInUp' color='info'>
              <MDBRow>
                <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
                  <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='info-circle' />
                </MDBCol>
                <MDBCol>
                  Due to the large number of templates a continuous campaign uses, previewing this campaign before launch is not available
                </MDBCol>
              </MDBRow>
            </MDBAlert>}
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export default CampaignTypeSelector
