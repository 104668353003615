import React from 'react'
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBInput,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBSelect,
  MDBSelectOptions,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBTableHead, MDBSelectInput, MDBSelectOption, MDBTooltip
} from 'mdbreact'

import { videos, videoPaths, learningTypes } from '../../../frontendConsts.js'

class BaseTemplateOptions extends React.Component {

  renderLearningTypePicker = (learningType, learningURL, updateLearningType, updateLearningUrl, id) => {
    function learningTypeClass(learningType, activeLearningType) {
      return learningType === activeLearningType ? "learning-type-active" : ""
    }
    function activeLearningLabel(learningType) {
      if (learningType === learningTypes.LM) {
        return "Learning Moment"
      } else if (learningType === learningTypes.VIDEO) {
        return "Video"
      } else if (learningType === learningTypes.CUSTOM) {
        return "Custom URL"
      } else {
        return "Learning Moment"
      }
    }
    const dropdown = (
      <MDBDropdown>
        <MDBDropdownToggle caret color="primary">
          {activeLearningLabel(learningType)}
        </MDBDropdownToggle>
        <MDBDropdownMenu basic>
          <MDBDropdownItem onClick={() => { updateLearningUrl(""); updateLearningType("lm") }} className={learningTypeClass(learningType || "lm", "lm")}>Learning Moment</MDBDropdownItem>
          <MDBDropdownItem onClick={() => { updateLearningUrl(Object.values(videos)[0]); updateLearningType("video") }} className={learningTypeClass(learningType, "video")}>Video</MDBDropdownItem>
          <MDBDropdownItem onClick={() => { updateLearningUrl(""); updateLearningType("custom") }} className={learningTypeClass(learningType, "custom")}>Custom URL</MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>
    )

    function createInput(learningType, learningUrl) {
      if (learningType && learningType === 'custom') {
        return <MDBInput label="https://example.com/video" value={learningUrl} onChange={(e) => updateLearningUrl(e.target.value)} />
      } else if (learningType && learningType === 'video') {
        const videoOptions = []
        for (let [videoName, videoPath] of Object.entries(videos)) {
          videoOptions.push(<MDBDropdownItem key={videoName} onClick={() => { updateLearningUrl(videoPath) }} >{videoName}</MDBDropdownItem>)
        }


        return (
          <>
            <MDBDropdown>
              <MDBDropdownToggle caret color="secondary">
                {videoPaths[learningUrl]}
              </MDBDropdownToggle>
              <MDBDropdownMenu basic>
                {videoOptions}
              </MDBDropdownMenu>
            </MDBDropdown>
          </>
        )
      }
    }

    return (<>
              {dropdown}
              {createInput(learningType, learningURL)}
            </>)

  }

  render() {
    const {
              tableData,
              updateTags,
              categories,
              learningType,
              learningUrl,
              updateLearningType,
              updateLearningUrl,
    } = this.props

    return (
            <>
              <MDBRow className='d-flex'>
                <MDBCol size='6'>
                  <MDBCard className='mt-4 injected-fields-card'>
                    <MDBCardHeader>
                      <MDBRow className='d-flex'>
                        <MDBCol className='d-flex justify-content-start align-items-center'>
                          <h5>Learning Type</h5>
                        </MDBCol>
                        <MDBCol className='d-flex justify-content-end'>
                          <MDBTooltip
                            placement='left'
                            id='injectedfields'
                            material
                          >
                            <MDBBtn
                              color='elegant'
                              className='my-0'
                              floating
                              tag='a'
                              size='sm'
                            >
                              <MDBIcon icon='question' />
                            </MDBBtn>
                            <div>
                                This is where you can change the type of learning a user will receive when they fall
                                for a phish. Choose from an automated tour of the email they fell for, a video from our
                                library, or specify a custom URL for us to redirect to.
                            </div>
                          </MDBTooltip>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardHeader>
                    <MDBCardBody>
                      <MDBRow className='d-flex justify-content-center'>
                        <MDBCol
                          className='justify-content-center'
                          size='10'
                        >
                          {this.renderLearningTypePicker(learningType, learningUrl, updateLearningType, updateLearningUrl, this.props.id)}
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol size='6'>
                  <MDBCard className='mt-4 injected-fields-card'>
                    <MDBCardHeader>
                      <MDBRow className='d-flex'>
                        <MDBCol className='d-flex justify-content-start align-items-center'>
                          <h5>Injected Fields</h5>
                        </MDBCol>
                        <MDBCol className='d-flex justify-content-end'>
                          <MDBTooltip
                            placement='left'
                            id='injectedfields'
                            material
                          >
                            <MDBBtn
                              color='elegant'
                              className='my-0'
                              floating
                              tag='a'
                              size='sm'
                            >
                              <MDBIcon icon='question' />
                            </MDBBtn>
                            <div>
                                Any custom injected fields detected in the template will be shown here. Select a value you wish to assign each
                                injection with here.
                            </div>
                          </MDBTooltip>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardHeader>
                    <MDBCardBody>
                      <MDBRow className='d-flex justify-content-center'>
                        <MDBCol
                          className='justify-content-center'
                          size='10'
                        >
                          <MDBTable btn>
                            <MDBTableHead
                              columns={
                                tableData.columns
                              }
                            />
                            <MDBTableBody
                              rows={tableData.rows}
                            />
                          </MDBTable>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              <MDBCard className='mt-4'>
                <MDBCardHeader>
                  <MDBRow className='d-flex'>
                    <MDBCol className='d-flex justify-content-start align-items-center'>
                      <h5>Tags</h5>
                    </MDBCol>
                    <MDBCol className='d-flex justify-content-end'>
                      <MDBTooltip
                        placement='left'
                        id='tagspopover'
                        material
                      >
                        <MDBBtn
                          color='elegant'
                          className='my-0'
                          floating
                          tag='a'
                          size='sm'
                        >
                          <MDBIcon icon='question' />
                        </MDBBtn>
                        <div>
                            These are the "keywords" of this template. These would be the category of template or a specific form of phish.
                            They are used to help categorize template types.
                            Examples include: billing, notification and travel
                        </div>
                      </MDBTooltip>
                    </MDBCol>
                  </MDBRow>
                </MDBCardHeader>
                <MDBCardBody>
                  <MDBRow className='d-flex justify-content-center'>
                    <MDBCol
                      className='justify-content-center'
                      size='10'
                    >
                      <MDBRow className='d-flex justify-content-center align-items-center'>
                        <MDBSelect multiple getValue={updateTags}>
                          <MDBSelectInput selected='Select a tag' />
                          <MDBSelectOptions>
                            {categories.map((option, i) => (<MDBSelectOption key={i} checked={this.props.tags.includes(option)} value={option}>{option}</MDBSelectOption>))}
                          </MDBSelectOptions>
                        </MDBSelect>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </>
    )
  }
}

export default BaseTemplateOptions
