import juice from 'juice'
export default (opt = {}) => {
  const editor = opt.editor
  const codeViewer = editor && editor.CodeManager.getViewer('CodeMirror').clone()
  const container = document.createElement('div')
  const pfx = opt.pfx || ''
  var cmdm = editor.Commands
  const juiceOpts = opt.juiceOpts || {}

  // Init code viewer
  codeViewer.set({
    codeName: 'htmlmixed',
    theme: opt.codeViewerTheme
  })
  // Set the command which could be used outside
  cmdm.add(opt.cmdInlineHtml, {
    run (editor, sender, opts = {}) {
      const tmpl = editor.getHtml() + `<style>${editor.getCss()}</style>`
      return juice(tmpl, opts)
    }
  })
  return {
    run (editor, sender) {
      const md = editor.Modal
      let viewer = codeViewer.editor
      md.setTitle(opt.modalTitleExport)
      // Init code viewer if not yet instantiated
      if (!viewer) {
        const txtarea = document.createElement('textarea')
        if (opt.modalLabelExport) {
          const labelEl = document.createElement('div')
          labelEl.className = pfx + 'export-label'
          labelEl.innerHTML = opt.modalLabelExport
          container.appendChild(labelEl)
        }
        container.appendChild(txtarea)
        codeViewer.init(txtarea)
        viewer = codeViewer.editor
        viewer.setOption('lineWrapping', 1)
      }
      md.setContent(container)
      const tmpl = editor.getHtml() + `<style>${editor.getCss()}</style>`
      codeViewer.setContent(opt.inlineCss ? juice(tmpl, juiceOpts) : tmpl)
      md.open()
      viewer.refresh()
      sender && sender.set && sender.set('active', 0)
    }
  }
}
