import React from 'react'
import {
  MDBNavbar,
  MDBBadge,
  MDBNavbarNav,
  MDBIcon,
  MDBNavLink
} from 'mdbreact'
import { useHistory } from 'react-router-dom'
import Grade from './components/Grade'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSideNavAction } from '../store/SideNavSlice'
import UserProfileButton from './components/UserProfileButton'

import './css/topNav.css'
import { orgTypes } from '../frontendConsts.js'

function TopNav () {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    topNavOrgType,
    topNavDistributorName, topNavDistributorId,
    topNavPartnerName, topNavPartnerId,
    topNavCompanyName, topNavCompanyId
  } = useSelector((state) => state.topNav)
  const { activeCampaigns } = useSelector((state) => state.campaigns)
  const { authorization } = useSelector((state) => state.auth)

  const renderRoleType = () => {
    if (topNavOrgType === orgTypes.COMPANY) {
      return (<div onClick={() => history.push(`/companies/${topNavCompanyId}`)} id='role-identifier'><MDBIcon far icon='building' /> {topNavCompanyName}</div>)
    } else if (topNavOrgType === orgTypes.PARTNER) {
      return (<div onClick={() => history.push(`/partners/${topNavPartnerId}`)} id='role-identifier'><MDBIcon icon='hands-helping' /> {topNavPartnerName}</div>)
    } else if (topNavOrgType === orgTypes.DISTRIBUTOR) {
      return (<div onClick={() => history.push(`/distributors/${topNavDistributorId}`)} id='role-identifier'><MDBIcon icon='hands-helping' /> {topNavDistributorName}</div>)
    }
  }

  const renderBackToPartner = () => {
    const { roles } = authorization
    const partners = Object.keys(roles[orgTypes.PARTNER] || {})
    const distributors = Object.keys(roles[orgTypes.DISTRIBUTOR] || {})

    function hasAccessToParentPartner (partners) {
      return partners.includes(topNavPartnerId)
    }
    function hasAccessToParentDistributor (distributors) {
      return distributors.includes(topNavDistributorId)
    }

    if (topNavOrgType === orgTypes.PARTNER) {
      if (hasAccessToParentDistributor(distributors, topNavDistributorId)) {
        return (
          <>
            <MDBNavLink aria-label='Disributor Breadcrumb' id='to-distributor-link' onClick={() => history.push(`/distributors/${topNavDistributorId}`)} to={`/distributors/${topNavDistributorId}`}>

              <MDBIcon icon='angle-double-left' />
              {topNavDistributorName}
            </MDBNavLink>
          </>
        )
      }
    }

    if (topNavOrgType === orgTypes.COMPANY) {
      const hasPartnerAccess = hasAccessToParentPartner(partners)
      const hasDistributorAccess = hasAccessToParentDistributor(distributors)
      const breadCrumbs = []
      if (hasDistributorAccess) {
        breadCrumbs.push((
          <div key='distributor'>
            <MDBNavLink id='to-distributors-link' onClick={() => history.push(`/distributors/${topNavDistributorId}`)} to={`/distributors/${topNavDistributorId}`}>
              {topNavDistributorName}
              <MDBIcon className='pl-2' icon='angle-double-left' />
            </MDBNavLink>
          </div>
        ))
      }
      if (hasPartnerAccess) {
        breadCrumbs.push((
          <div key='partner'>
            <MDBNavLink id='to-partners-link' onClick={() => history.push(`/partners/${topNavPartnerId}`)} to={`/partners/${topNavPartnerId}`}>
              {topNavPartnerName}
              <MDBIcon className='pl-2' icon='angle-double-left' />
            </MDBNavLink>
          </div>
        ))
      }
      return breadCrumbs
    }
  }

  return (
    <MDBNavbar
      light
      filled='true'
      expand='md'
      fixed='top'
      style={{ zIndex: 100, backgroundColor: 'white' }}
    >
      <div
        onClick={() => { dispatch(toggleSideNavAction()) }}
        key='sideNavToggleA'
        style={{
          lineHeight: '32px',
          marginleft: '1em',
          verticalAlign: 'middle',
          cursor: 'pointer'
        }}
      >
        <MDBIcon aria-label='Toggle Sidenav' id='sidenav-toggle-icon' icon='bars' color='white' size='lg' />
      </div>

      <MDBNavbarNav
        expand='sm'
        right
        style={{ flexDirection: 'row' }}
      >
        {process.env.REACT_APP_DEV_MODE &&
          <MDBNavLink key='dev-watermark' id='dev-watermark' to='#'>
            <MDBBadge>DEVELOPMENT</MDBBadge>
          </MDBNavLink>}
        {topNavOrgType !== orgTypes.DISTRIBUTOR &&
          renderBackToPartner()}
        <a className='nav-link'>
          {renderRoleType()}
        </a>
        {topNavOrgType === orgTypes.COMPANY &&
          <Grade activeCampaigns={activeCampaigns} />}
        <UserProfileButton distributorId={topNavDistributorId} />
      </MDBNavbarNav>
    </MDBNavbar>
  )
}

export default TopNav
