import React from 'react'
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCardBody,
  MDBCard,
  MDBCardHeader
} from 'mdbreact'

const CampaignName = ({ name, setName }) => {
  return (
    <MDBCard className='w-100'>
      <MDBCardHeader><h5><strong>Name</strong></h5></MDBCardHeader>
      <MDBCardBody>
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol size='12'>
            <MDBInput
              outline
              name='campaignName'
              label='Campaign Name'
              onChange={(e) => setName(e.target.value)}
              value={name}
              size='lg'
              labelClass='text-dark'
            />
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>)
}

export default CampaignName
