import {
  MDBRow, MDBCard, MDBCardHeader, MDBCol,
  MDBCardBody, MDBInput, MDBContainer
} from 'mdbreact'

function ConfigQuestion ({ index, questionsCollection, setQuestionsCollection, addPhishingCategories, removePhishingCategories, addTrainingTopic, removeTrainingTopic }) {
  const questionUpdate = (answer) => {
    questionsCollection[index].answer = answer
    setQuestionsCollection([...questionsCollection])
    if (questionsCollection[index].answer === 'Yes') {
      if (questionsCollection[index].topic && questionsCollection[index].topic !== '') {
        addTrainingTopic(questionsCollection[index].topic)
      }
      if (questionsCollection[index].categories && questionsCollection[index].categories !== []) {
        addPhishingCategories(questionsCollection[index].categories)
      }
    } else if (questionsCollection[index].answer === 'No') {
      if (questionsCollection[index].topic && questionsCollection[index].topic !== '') {
        removeTrainingTopic(questionsCollection[index].topic)
      }
      if (questionsCollection[index].categories && questionsCollection[index].categories !== []) {
        removePhishingCategories(questionsCollection[index].categories)
      }
    }
  }
  const renderAnswerOptions = () => {
    const { optionsArray } = questionsCollection[index]
    const optionInputs = []

    let count = 0
    optionsArray.forEach(option => {
      optionInputs.push(<MDBInput key={count} onClick={() => questionUpdate(option)} checked={option === questionsCollection[index].answer} label={option} type='radio' id={option} />)
      count = count++
    })

    return optionInputs
  }

  return (
    <MDBRow>
      <MDBCol>
        <br />
        <MDBRow>
          <MDBCard className='w-100'>
            <MDBCardHeader>
              <MDBRow className='d-flex'>
                <MDBCol className='d-flex justify-content-start align-items-center'>
                  <h5>Question {index + 1}</h5>
                </MDBCol>
              </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow className='d-flex justify-content-center mt-3'>
                <h3>{questionsCollection[index].question}</h3>
              </MDBRow>

              {questionsCollection[index].type === 'multi' && (
                <MDBContainer className='mt-4 mb-4'>
                  {renderAnswerOptions()}
                </MDBContainer>
              )}

              {questionsCollection[index].type === 'bool' && (
                <MDBContainer className='mt-4 mb-4'>
                  {renderAnswerOptions()}
                </MDBContainer>
              )}

            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  )
}

export default ConfigQuestion
