import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBIcon, MDBBtn, MDBRow, MDBCardBody, MDBCard, MDBCol,
  MDBCardFooter, MDBContainer, MDBCardHeader, MDBCardTitle, MDBInput, MDBSelect
} from 'mdbreact'
import NotificationUtilities from '../notifications/notificationUtils'
import NotificationWrapper from '../notifications/notificationWrapper'
import { apiRequestUtils } from '../../../utils/apiRequestUtils'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../Spinner'
import { integrationStatusUpdatedAction } from '../../../store/IntegrationsSlice'
import { integrationStatuses, microsoftIntegrationStatuses, integrationTypes } from '../../../frontendConsts.js'
import { disconnectSpamFilterBypassThunk } from '../../../store/SpamFilterBypassSlice'
import { updateOnboardingChecklistDataThunk } from '../../../store/CompanySlice'
import ValidationModal from '../ValidationModal'

export function MicrosoftEmailBypassPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const intervalRef = useRef()

  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { integrationsMap, loaders: integrationLoaders } = useSelector((state) => state.integrations)
  const { isLoadingIntegrations, isLoadingDisableSpamBypass } = integrationLoaders
  const { isLoadingSpamFilterBypassStatus } = useSelector((state) => state.spamFilterBypass.loaders)

  const [testEmail, setTestEmail] = useState('')
  const [fromAddress, setFromAddress] = useState('')
  const [fromName, setFromName] = useState('')
  const [disconnectModal, setDisconnectModal] = useState(false)
  const [fromEmailOptions] = useState([
    {
      text: 'bill@emails.notificationhandler.com',
      value: 'bill@emails.notificationhandler.com'
    },
    {
      text: 'research@coronacouncil.org',
      value: 'research@coronacouncil.org'
    },
    {
      text: 'reginald@invites.shippingalerts.com',
      value: 'reginald@invites.shippingalerts.com'
    },
    {
      text: 'maryberry@orders.berrysupply.net',
      value: 'maryberry@orders.berrysupply.net'
    }
  ])

  useEffect(() => {
    if (integrationsMap && integrationsMap.spamFilterBypass) {
      if (integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) {
        validateConsent()
        return function cleanUpInterval () {
          clearInterval(intervalRef.current)
        }
      }
    }
  }, [integrationsMap])

  async function handleDisconnect () {
    await dispatch(disconnectSpamFilterBypassThunk({ companyId: id, history }))
    setDisconnectModal(false)
  }

  async function sendTestEmail () {
    if (testEmail !== '' && fromAddress !== '' && fromName) {
      const res = await apiRequestUtils.post(`/api/companies/${id}/settings/integrations/test-email`, {
        toAddress: testEmail,
        fromAddress,
        fromName
      })
      if (res.status !== 200) {
        NotificationUtilities.sendErrorMessage('Error sending test email')
      } else {
        NotificationUtilities.sendSuccessMessage('Test Email Successfully sent')
      }
    } else {
      NotificationUtilities.sendWarningMessage('Please input a valid email and from address to test')
    }
  }

  function handleDomainSelect (val) {
    setFromAddress(val)
  }

  const consentToIntegration = () => {
    const redirectURL = encodeURIComponent(`${window.location.protocol}//${window.location.host}/integrations`)
    window.open(`https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_SPAM_BYPASS_APPLICATION_ID}&redirect_uri=${redirectURL}&state=${id}%7CspamFilterBypass`, '_self')
  }

  const validateConsent = () => {
    apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.SPAM_FILTER_BYPASS }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json()
        if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
          dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.SPAM_FILTER_BYPASS, integrationStatus: data.integrationStatus }))
        } else {
          intervalRef.current = setInterval(() => {
            apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.SPAM_FILTER_BYPASS }).then(async (response) => {
              if (response.status === 200) {
                const data = await response.json()
                if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                  clearInterval(intervalRef.current)
                  dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.SPAM_FILTER_BYPASS, integrationStatus: data.integrationStatus }))
                  dispatch(updateOnboardingChecklistDataThunk(id, { hasEmailSettings: true }))
                }
              }
            })
          }, 10000)
        }
      } else {
        intervalRef.current = setInterval(() => {
          apiRequestUtils.put(`/api/companies/${id}/integrations/microsoft/validateConsent`, { integrationId: integrationTypes.SPAM_FILTER_BYPASS }).then(async (response) => {
            if (response.status === 200) {
              const data = await response.json()
              if (data.integrationStatus === microsoftIntegrationStatuses.CONNECTED) {
                clearInterval(intervalRef.current)
                dispatch(integrationStatusUpdatedAction({ integrationId: integrationTypes.SPAM_FILTER_BYPASS, integrationStatus: data.integrationStatus }))
                dispatch(updateOnboardingChecklistDataThunk(id, { hasEmailSettings: true }))
              }
            }
          })
        }, 10000)
      }
    })
  }

  return (
    <MDBContainer>
      <NotificationWrapper />

      <ValidationModal
        isOpen={disconnectModal}
        toggleFunction={() => setDisconnectModal(false)}
        messageJSX={<p>Are you sure you want to disconnect Graph API Mail Delivery? Emails may not reach end users if Phin has not been properly allowlisted.</p>}
        falseOptionText='Cancel'
        trueOptionText='Disconnect'
        falseOptionColor='primary'
        trueOptionColor='red'
        handlerFunction={handleDisconnect}
      />

      <MDBContainer className='m-5'>
        <MDBRow className='mt-4 d-flex justify-content-center'>
          <h2 className='text-center'>Phin Microsoft Graph API Mail Delivery</h2>
        </MDBRow>

        {(!company || !integrationsMap ||
      isLoadingCompany || isLoadingIntegrations ||
      isLoadingDisableSpamBypass || isLoadingSpamFilterBypassStatus) && (
        <Spinner />
        )}

        {(company && integrationsMap &&
      !isLoadingCompany && !isLoadingIntegrations &&
      !isLoadingDisableSpamBypass && !isLoadingSpamFilterBypassStatus) && (
        <>
          {(!integrationsMap.spamFilterBypass || integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.NOT_CONNECTED) && (
            <>
              <div className='consentCard'>
                <div className='consentTitle'>You will need to grant Microsoft consent before you can start sending emails with this integration.</div>
                <div className='permissions'>
                  <p className='consentSubtitle'>What are the permissions?</p>
                  <ul>
                    <li>
                      Mail.ReadWrite
                      <ul>
                        <li>
                          used to create the email messages directly in a user's email mailbox without the use of Simple Mail Transfer Protocol (SMTP)
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      User.Read.All
                      <ul>
                        <li>
                          used to fetch the list of users to send emails to
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Sign in and read user profile
                      <ul>
                        <li>
                          allows Phin to make API calls to a Microsoft Azure tenant
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p className='consentSubtitle'>How will Phin use the permissions?</p>
                  <ul>
                    <li>
                      Phin will use the above listed permissions to automatically insert email messages directly into an end user's mailbox.
                    </li>
                  </ul>
                </div>
                <div className='d-flex justify-content-center'>
                  <a
                    id='continue-to-microsoft-button'
                    aria-label='Continue to Microsoft Button'
                    className='consentBtn'
                    onClick={() => consentToIntegration()}
                  >Continue to Microsoft
                  </a>
                </div>
              </div>
            </>)}

          {(integrationsMap.spamFilterBypass && integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.CONSENT_SUBMITTED) && (
            <>
              <Spinner message='Validating Microsoft Consent' />
            </>
          )}

          {(!integrationsMap.spamFilterBypass || integrationsMap.spamFilterBypass.integrationStatus === microsoftIntegrationStatuses.CONNECTED) && (
            <>
              <MDBRow className='mt-4 d-flex justify-content-center'>
                <MDBCol xl='5' md='6' className='mb-5'>
                  <MDBCard cascade className='cascading-admin-card'>
                    <div className='admin-up'>
                      <MDBIcon icon='plug' className='primary-color mr-3 z-depth-2' />
                      <div className='data'>
                        <p>Status</p>
                        <h4 id='device-count' className='font-weight-bold dark-grey-text'>{integrationsMap.spamFilterBypass ? integrationsMap.spamFilterBypass.integrationStatus : integrationStatuses.NOT_CONNECTED}</h4>
                      </div>
                    </div>
                  </MDBCard>
                </MDBCol>
              </MDBRow>

              <MDBRow style={integrationsMap.spamFilterBypass && integrationsMap.spamFilterBypass.integrationStatus === integrationStatuses.CONNECTED ? {} : { filter: 'opacity(.2)', pointerEvents: 'none' }}>
                <MDBCol size='12'>
                  <MDBCard>
                    <MDBCardHeader>
                      <MDBCardTitle className='pt-2'>Test Graph API Mail Delivery</MDBCardTitle>
                    </MDBCardHeader>
                    <MDBCardBody>
                      <MDBInput
                        value={testEmail} id='test-microsoft-email' size='lg' onChange={(e) => {
                          setTestEmail(e.target.value)
                        }}
                        label='to email'
                      />
                      <MDBInput
                        value={fromName} id='test-microsoft-from-name' size='lg' onChange={(e) => {
                          setFromName(e.target.value)
                        }}
                        label='from name'
                      />
                      <MDBSelect
                        id='from-email-dropdown'
                        color='primary'
                        label='Select From Email'
                        options={fromEmailOptions}
                        getValue={(vals) => { handleDomainSelect(vals[0]) }}
                      />
                    </MDBCardBody>
                    <MDBCardFooter className='justify-content-end'>
                      <MDBBtn
                        id='send-test-mail-button'
                        aria-label='Send Test Mail Button'
                        onClick={sendTestEmail}
                        color='primary'
                      >
                        Send Test Mail
                      </MDBBtn>
                    </MDBCardFooter>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </>
          )}

          {(integrationsMap.spamFilterBypass && integrationsMap.spamFilterBypass.integrationStatus !== integrationStatuses.NOT_CONNECTED) &&
            <MDBRow className='d-flex justify-content-center mb-5'>
              <MDBCol xl='4' md='4' className='pt-4 d-flex justify-content-center align-items-center'>
                <MDBBtn onClick={() => setDisconnectModal(true)} color='danger' size='lg'>
                  <MDBRow className='d-flex justify-content-center align-items-center'>
                    <MDBCol size='3'>
                      <MDBIcon size='3x' icon='ban' />
                    </MDBCol>
                    <MDBCol id='startNewCampaignBtn' size='9'>
                      Disable
                    </MDBCol>
                  </MDBRow>
                </MDBBtn>
              </MDBCol>
            </MDBRow>}
        </>

        )}
      </MDBContainer>
    </MDBContainer>
  )
}
