import { Grid, Skeleton } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnerFileThunk } from '../../../store/PartnerSlice'
import { CloudDownloadButton } from '../../components/buttons/CloudDownloadButton'
import { DateTime } from 'luxon'
import { getPartnerBillingReportsThunk } from '../../../store/PartnerBillingSlice'

export function UsageBreakdownReportsTable ({ partnerId }) {
  const dispatch = useDispatch()

  const { partner, loaders } = useSelector((state) => state.partner)
  const {
    isLoadingPartner, isDownloadingPartnerFile
  } = loaders

  const { partnerBillingReports, loaders: partnerBillingLoaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingPartnerBillingReports } = partnerBillingLoaders

  const [tablePageSize, setTablePageSize] = useState(10)

  const columns = [
    {
      headerName: 'Name',
      field: 'reportName',
      flex: 1
    },
    {
      headerName: 'Billing Period',
      field: 'billingPeriod',
      valueGetter: (params) => { return DateTime.fromISO(params.row.billingPeriodDate) },
      renderCell: (params) => { return params.row.billingPeriod },
      flex: 1
    },
    {
      headerName: 'Creation Date',
      field: 'creationDate',
      valueGetter: (params) => { return DateTime.fromISO(params.row.creationDate) },
      renderCell: (params) => { return DateTime.fromISO(params.row.creationDate).toLocaleString(DateTime.DATE_FULL) },
      flex: 1
    },
    {
      headerName: '',
      field: 'id',
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => { return generateDownloadButton(params.row) }
    }
  ]

  const generateDownloadButton = (companyBreakdownReport) => {
    return (
      <CloudDownloadButton
        id={companyBreakdownReport.reportName}
        isDownloading={isDownloadingPartnerFile[companyBreakdownReport.id]}
        downloadAction={() => { dispatch(getPartnerFileThunk({ partnerId, fileInfo: companyBreakdownReport, fileName: companyBreakdownReport.reportName })) }}
      />
    )
  }

  useEffect(() => {
    dispatch(getPartnerBillingReportsThunk({ partnerId }))
  }, [])

  return (
    <Grid container className='margin-bottom:2'>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 'var(--phin-s-2)' }}>
          <div className='phin-h4' style={{ textAlign: 'left' }}>Usage Breakdown Reports</div>
        </Grid>

        {(isLoadingPartner || !partner || isLoadingPartnerBillingReports || !partnerBillingReports) && (
          <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1396} height={400} />
        )}

        {(!isLoadingPartner && partner && !isLoadingPartnerBillingReports && partnerBillingReports) && (
          <div style={{ width: '100%' }}>
            <DataGrid
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'billingPeriodDate', sort: 'asc' }]
                }
              }}
              autoHeight
              rows={partnerBillingReports}
              columns={columns}
              pageSize={tablePageSize}
              onPageSizeChange={(newSize) => setTablePageSize(newSize)}
              rowsPerPageOptions={[10, 25, 50]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: {
                    debounceMs: 500,
                    id: 'company-management-table-search',
                    'aria-label': 'Search'
                  },
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true }
                }
              }}
              disableSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
            />
          </div>
        )}
      </Grid>
    </Grid>
  )
}
