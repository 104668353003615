import React, { useEffect } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import './App.css'
import RootRouter from './RootRouter'
import { apiRequestUtils } from './utils/apiRequestUtils'
import Spinner from './pages/components/Spinner'
import { useAuth0 } from '@auth0/auth0-react'
import ProtectedRoute from './auth/ProtectedRoute'
import { getBaseRole } from './utils/roleManager'
import { roleTypes } from './frontendConsts'
import './modal-video.scss'
import 'scorm-again'
import { useDispatch } from 'react-redux'
import { getAuthorizationThunk } from './store/AuthSlice'

window.API = new window.Scorm12API({ })

function App () {
  const { isLoading, isAuthenticated, user, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0()

  // user.sub is the User's unique ID

  const history = useHistory()
  const dispatch = useDispatch()

  const configureAuthenticationAndAuthorization = async () => {
    const accessToken = await getAccessTokenSilently()
    apiRequestUtils.setAccessToken(accessToken)
    apiRequestUtils.setLoginWithRedirect(loginWithRedirect)
    try {
      const authorization = await dispatch(getAuthorizationThunk(user.sub))
      return authorization
    } catch (error) {
      console.error(error)
    }
  }

  const smartRoute = (authorization) => {
    const baseRole = getBaseRole(authorization)
    if (baseRole === null) {
      logout({ returnTo: window.location.origin })
    } else {
      if (baseRole.roleType === roleTypes.COMPANY) {
        history.push(`/companies/${baseRole.id}`)
      } else if (baseRole.roleType === roleTypes.PARTNER) {
        history.push(`/partners/${baseRole.id}`)
      } else if (baseRole.roleType === roleTypes.DISTRIBUTOR) {
        history.push(`/distributors/${baseRole.id}`)
      }
    }
  }

  const checkForPageAccess = (pathName, authorization) => {
    const excludedRoutes = ['/integrations']
    if (!excludedRoutes.includes(pathName)) {
      const [routeNothing, routeRoleType, routeRoleId, ...pages] = pathName.split('/')
      const { roles } = authorization
      try {
        if (roles[routeRoleType][routeRoleId] !== undefined) {
          return true
        }
      } catch (err) {
        return false
      }
    } else {
      return true
    }
  }

  function configureHotJar (h, o, t, j, a, r) {
    // Install HotJar
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
    h._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script'); r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)

    // Replace your_user_id with your own if available.
    const userId = user.sub || null

    window.hj('identify', userId, {})
  }

  const callConfigure = async () => {
    const authorization = await configureAuthenticationAndAuthorization()

    const canAccessCurrentPath = checkForPageAccess(window.location.pathname, authorization)

    if (process.env.REACT_APP_HOTJAR_ID) {
      configureHotJar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }

    if (window.location.pathname === '/' || !canAccessCurrentPath) {
      smartRoute(authorization)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      callConfigure()
    }
  }, [isAuthenticated])

  if (isLoading) {
    return (
      <Spinner message='Authenticating' />
    )
  }

  return (
    <Switch>
      <ProtectedRoute
        path='/'
        component={RootRouter}
      />
    </Switch>
  )
}

export default App
