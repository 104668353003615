import {
  MDBRow, MDBCol, MDBCard,
  MDBCardHeader, MDBCardBody
} from 'mdbreact'

function renderTemplateTags (templateTags) {
  const templateTagsArray = []

  templateTags.forEach(templateTag => {
    templateTagsArray.push(<li key={templateTag}>{templateTag}</li>)
  })

  return templateTagsArray
}

function PhishingCampaignResource ({ phishingCategories, phishingDescription, phishingCampaignName }) {
  return (
    <MDBCard>
      {/* Phishing Campaign Name */}
      <MDBCardHeader><h5>{phishingCampaignName}</h5></MDBCardHeader>
      <MDBCardBody>
        {/* Phishing Campaign Description */}
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol>
            <div className='h5'>Description:</div>
          </MDBCol>
          <MDBCol>
            <div className='h6'>{phishingDescription}</div>
          </MDBCol>
        </MDBRow>

        <br />

        {/* Campaign templateTags */}
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol>
            <div className='h5'>Campaign Tags:</div>
          </MDBCol>
          <MDBCol>
            <div className='h6'>{renderTemplateTags(phishingCategories)}</div>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default PhishingCampaignResource
