import '../../css/TestWelcomeEmailModal.css'
const {
  MDBModalHeader, MDBModalBody,
  MDBInput, MDBModalFooter,
  MDBBtn
} = require('mdbreact')

export default function TestWelcomeEmailModal ({
  testEmail, setTestEmail,
  useSpamFilterBypass, setUseSpamFilterBypass,
  setTestEmailModal, testEmailModal,
  hasSpamFilterBypass, sendTestWelcomeEmail
}) {
  return (
    <>
      <MDBModalHeader>
        Schedule Test Welcome Email
      </MDBModalHeader>

      <MDBModalBody>
        <div className='sidePaddingOneEm'>
          <p>
            Schedule a test welcome email to the address below using the latest saved template.
          </p>
          <div>
            <MDBInput
              outline
              size='lg'
              value={testEmail}
              label='Email Address'
              onChange={e => { setTestEmail(e.target.value) }}
            />
          </div>
          {hasSpamFilterBypass &&
            <>
              <div className='spamFilterBypassToggle'>
                <div>
                  Use Graph API Mail Delivery:
                </div>
                <div>
                  <label className='switch branding-switch user-collapse'>
                    <input type='checkbox' checked={useSpamFilterBypass} onChange={() => setUseSpamFilterBypass(!useSpamFilterBypass)} />
                    <span className='slider round slider-preview' />
                  </label>
                </div>
              </div>
              {useSpamFilterBypass &&
                <p className='helperText'>If using Graph API Mail Delivery send, please ensure the email address belongs to a user in this company's Azure tenant.</p>}
            </>}
        </div>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn id='deny-validation-button' value='cancel' color='red' onClick={() => setTestEmailModal(!testEmailModal)}>Cancel</MDBBtn>
        <MDBBtn id='confirm-validation-button' type='submit' color='primary' onClick={sendTestWelcomeEmail}>Send</MDBBtn>
      </MDBModalFooter>
    </>
  )
}
