import { createSlice } from '@reduxjs/toolkit'
import { orgTypes } from '../frontendConsts.js'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { callAndRetry } from '../utils/CallAndRetryUtils'
import { setSideNavOrgDataAction, setSideNavOrgNameAction } from './SideNavSlice'
import { setTopNavCompanyDataAction, setTopNavCompanyNameAction } from './TopNavSlice'
import { makeUID } from '../utils/objectUtils.js'

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: null,
    leaderboardUrl: '',
    configQuestions: null,
    changesToBeSaved: false,
    onboardingChecklist: {},
    loaders: {
      isLoadingCompany: false,
      isLoadingLeaderboardUrl: false,
      isLoadingConfigQuestions: false,
      isLoadingOnboardingChecklist: false
    }
  },
  reducers: {
    setIsLoadingCompany: (state, action) => {
      state.loaders.isLoadingCompany = action.payload
    },
    getCompanyData: (state, action) => {
      state.company = action.payload
    },
    setLeaderboardUrl: (state, action) => {
      state.leaderboardUrl = `${process.env.REACT_APP_LEADERBOARD_URL_BASE}/leaderboard/${action.payload}`
    },
    deleteLeaderBoardUrl: (state, action) => {
      delete state.leaderboardUrl
    },
    setIsLoadingLeaderboardUrl: (state, action) => {
      state.loaders.isLoadingLeaderboardUrl = action.payload
    },
    setIsLoadingConfigQuestions: (state, action) => {
      state.loaders.isLoadingConfigQuestions = action.payload
    },
    getConfigQuestions: (state, action) => {
      state.configQuestions = action.payload
    },
    updateBasicEmailHeader: (state, action) => {
      const updatedCompany = { ...state.company }
      updatedCompany.phishTestHeader = action.payload
      state.company = updatedCompany
    },
    updateCustomEmailHeader: (state, action) => {
      const updatedCompany = { ...state.company }
      updatedCompany.customSendingHeader = action.payload
      state.company = updatedCompany
    },
    updateCompanyData: (state, action) => {
      const updatedCompany = { ...state.company }
      for (const [key, value] of Object.entries(action.payload)) {
        updatedCompany[key] = value
      }
      state.company = updatedCompany
    },
    setChangesToBeSaved: (state, action) => {
      state.changesToBeSaved = action.payload
    },
    incrementUserCount: (state) => {
      state.company.totalDevices += 1
    },
    setUserCount: (state, action) => {
      state.company.totalDevices = action.payload
    },
    removeAdmin: (state, action) => {
      state.company.admins = state.company.admins.filter(
        admin => admin.uid !== action.payload
      )
    },
    addAdmin: (state, action) => {
      const admins = []
      state.company.admins.forEach((admin) => {
        admins.push(({ name: admin.name, email: admin.email, uid: admin.uid }))
      })
      admins.push({ name: action.payload.displayName, email: action.payload.adminEmail, uid: action.payload.uid })
      state.company.admins = admins
    },
    addImportedUsersToUserCount: (state, action) => {
      state.company.totalDevices = state.company.totalDevices + action.payload
    },
    setSendWeeklyReports: (state, action) => {
      state.company.sendWeeklyReports = action.payload
    },
    setSendMonthlyReports: (state, action) => {
      state.company.sendMonthlyReports = action.payload
    },
    setWeeklyReportSendTime: (state, action) => {
      state.company.weeklyReportSendTime = action.payload
    },
    setMonthlyReportSendTime: (state, action) => {
      state.company.monthlyReportSendTime = action.payload
    },
    setIsLoadingOnboardingChecklist: (state, action) => {
      state.loaders.isLoadingOnboardingChecklist = action.payload
    },
    setOnboardingChecklist: (state, action) => {
      state.onboardingChecklist = action.payload
    }
  }
})

export const {
  setIsLoadingCompany: setIsLoadingCompanyAction,
  getCompanyData: getCompanyDataAction,
  setIsLoadingLeaderboardUrl: setIsLoadingLeaderboardUrlAction,
  setLeaderboardUrl: setLeaderboardUrlAction,
  deleteLeaderBoardUrl: deleteLeaderBoardUrlAction,
  setIsLoadingConfigQuestions: setIsLoadingConfigQuestionsAction,
  getConfigQuestions: getConfigQuestionsAction,
  updateBasicEmailHeader: updateBasicEmailHeaderAction,
  updateCustomEmailHeader: updateCustomEmailHeaderAction,
  setChangesToBeSaved: setChangesToBeSavedAction,
  updateCompanyData: updateCompanyDataAction,
  incrementUserCount: incrementUserCountAction,
  setUserCount: setUserCountAction,
  removeAdmin: removeAdminAction,
  addAdmin: addAdminAction,
  addImportedUsersToUserCount: addImportedUsersToUserCountAction,
  setSendWeeklyReports: setSendWeeklyReportsAction,
  setSendMonthlyReports: setSendMonthlyReportsAction,
  setWeeklyReportSendTime: setWeeklyReportSendTimeAction,
  setMonthlyReportSendTime: setMonthlyReportSendTimeAction,
  setIsLoadingOnboardingChecklist: setIsLoadingOnboardingChecklistAction,
  setOnboardingChecklist: setOnboardingChecklistAction
} = companySlice.actions

export default companySlice.reducer

export const getCompanyThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCompanyAction(true))
    try {
      const getCompanyData = async () => api.get(`/api/companies/${id}`)
      const res = await callAndRetry(getCompanyData, 3, 'Failed to load company.')
      if (res.status === 200) {
        const company = await res.json()
        const { name, id, isFreeTrial, partnerName, partnerId, distributorName, distributorId, leaderboardLookupId } = company
        dispatch(getCompanyDataAction(company))
        if (leaderboardLookupId) {
          dispatch(setLeaderboardUrlAction(leaderboardLookupId))
        } else {
          dispatch(deleteLeaderBoardUrlAction())
        }
        dispatch(setSideNavOrgDataAction({ name, id, isFreeTrial, orgType: orgTypes.COMPANY }))
        dispatch(setIsLoadingCompanyAction(false))
        dispatch(setTopNavCompanyDataAction({ name, id, partnerName, partnerId, distributorName, distributorId }))
        return company
      } else {
        dispatch(setIsLoadingCompanyAction(false))
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load company.')
        })
      }
    } catch (err) {
      dispatch(setIsLoadingCompanyAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load company.')
      })
      console.error(err)
    }
  }
}

export const generateLeaderboardUrlThunk = (id) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(setIsLoadingLeaderboardUrlAction(true))

      const response = await api.post(`/api/companies/${id}/settings/leaderboardURL`)

      if (response.status === 200) {
        const { shortCodeID } = await response.json()
        dispatch(setLeaderboardUrlAction(shortCodeID))
      } else {
        NotificationUtilities.sendErrorMessage('There was a problem setting a new leaderboard URL.')
      }
      NotificationUtilities.sendSuccessMessage('Successfully created new leaderboard URL')
    } catch (err) {
      NotificationUtilities.sendErrorMessage('There was a problem setting a new leaderboard URL.')
    }
    dispatch(setIsLoadingLeaderboardUrlAction(false))
  }
}

export const getConfigQuestionsThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingConfigQuestionsAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/config/questions`)
      if (res) {
        const configQuestions = await res.json()
        dispatch(getConfigQuestionsAction(configQuestions))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingConfigQuestionsAction(false))
  }
}

export const updateBasicHeaderThunk = (id, newHeaderValue) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.post(`/api/companies/${id}/settings/header-preferences`, { phishTestBoolean: newHeaderValue, reqType: 'phishHeader' })
      if (response.status === 500) {
        NotificationUtilities.sendErrorMessage('Error setting basic email header')
        return
      }
      dispatch(updateBasicEmailHeaderAction(newHeaderValue))
      if (newHeaderValue === false) {
        NotificationUtilities.sendSuccessMessage('Basic email header successfully removed.')
      } else {
        NotificationUtilities.sendSuccessMessage('Basic email header successfully added.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Error changing basic email header.')
      console.error(error)
    }
  }
}

export const updateCustomHeaderThunk = (id) => {
  return async (dispatch, getState, api) => {
    const customSendingHeader = {
      name: 'X-PHIN-CUSTOM',
      value: makeUID(24)
    }
    try {
      await api.post(`/api/companies/${id}/settings/header-preferences`, { headerName: customSendingHeader.name, headerValue: customSendingHeader.value, reqType: 'customHeader' })
      NotificationUtilities.sendSuccessMessage('Custom Header Successfully Generated')
      dispatch(updateCustomEmailHeaderAction(customSendingHeader))
    } catch (error) {
      console.log(error)
      NotificationUtilities.sendErrorMessage('Error Generating Custom Header')
    }
  }
}

export const changeCompanyNameThunk = (id, updatedName) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.post(`/api/companies/${id}/name`, { name: updatedName })

      if (res.ok) {
        dispatch(setTopNavCompanyNameAction({ name: updatedName }))
        dispatch(setSideNavOrgNameAction({ name: updatedName }))
        dispatch(updateCompanyDataAction({ name: updatedName }))

        NotificationUtilities.sendSuccessMessage('Changes successfully saved!')
      } else {
        // Something went wrong, kick to error handling
        throw new Error(`Received a ${res.status} status code`)
      }
    } catch (err) {
      console.error(`There was a problem updating the company name for company ${id}`, err)
      NotificationUtilities.sendErrorMessage('There was a problem updating your company name')
    }
  }
}

export const updateGeneralCompanyInfoThunk = (id, updatedSettings) => {
  return async (dispatch, getState, api) => {
    const promises = []

    const { reportRouting, timezone, ipIgnoreListEnabled } = getState().company.company

    if (updatedSettings.reportingInboxAddress && updatedSettings.reportingInboxAddress !== reportRouting) {
      promises.push(api.post(`/api/companies/${id}/settings/reporting-inbox`, { reportingInboxAddress: updatedSettings.reportingInboxAddress }))
    }

    if (updatedSettings.timezone && updatedSettings.timezone.text !== timezone.text) {
      promises.push(api.post(`/api/companies/${id}/settings/timezone`, { selectedTimezone: updatedSettings.timezone }))
    }

    if (updatedSettings.ipIgnoreListEnabled !== ipIgnoreListEnabled) {
      promises.push(api.put(`/api/companies/${id}/settings/ip-ignore-list`, { ipIgnoreListEnabled: updatedSettings.ipIgnoreListEnabled }))
    }

    try {
      await Promise.all(promises)
      dispatch(setChangesToBeSavedAction(false))
      const updateObj = {}

      if (updatedSettings.reportingInboxAddress) {
        updateObj.reportRouting = updatedSettings.reportingInboxAddress
      }

      if (updatedSettings.timezone) {
        updateObj.timezone = updatedSettings.timezone
      }

      updateObj.ipIgnoreListEnabled = updatedSettings.ipIgnoreListEnabled

      dispatch(updateCompanyDataAction(updateObj))

      NotificationUtilities.sendSuccessMessage('Changes successfully saved!')
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendErrorMessage('There was a problem saving your changes.')
    }
  }
}

export const addAdminThunk = (id, partnerName, adminEmail, firstName, lastName) => {
  return async (dispatch, getState, api) => {
    const res = await api.post('/api/auth/users', {
      displayName: `${firstName} ${lastName}`,
      email: adminEmail,
      companies: { [id]: 'admin' },
      whiteLabelName: partnerName
    })
    if (res.status === 200) {
      const { uid } = await res.json()

      dispatch(addAdminAction({ uid, adminEmail, displayName: `${firstName} ${lastName}` }))

      NotificationUtilities.sendSuccessMessage('Admin successfully added!')
    } else {
      NotificationUtilities.sendErrorMessage('Error creating admin! Our team has been notified.')
    }
  }
}

export const removeAdminThunk = (id, adminId) => {
  return async (dispatch, getState, api) => {
    NotificationUtilities.sendInfoMessage('Removing that admin now...')

    const companies = {
      [id]: 'admin'
    }

    try {
      const response = await api.delete(`/api/auth/users/${adminId}`, { companies })

      if (response.status === 403) {
        NotificationUtilities.sendWarningMessage('You do not have permission to remove that user')
        return
      }

      await response.json()
      dispatch(removeAdminAction(adminId))
      NotificationUtilities.sendSuccessMessage('Admin successfully removed')
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Error removing admin! Our team has been notified.')
      console.error(err)
    }
  }
}

export const setSendWeeklyReportsThunk = ({ companyId, value }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/companies/${companyId}/reports/settings/sendWeeklyReports`, { sendWeeklyReports: value })
      if (response.status === 200) {
        const { sendWeeklyReports } = await response.json()
        dispatch(setSendWeeklyReportsAction(sendWeeklyReports))
      } else {
        NotificationUtilities.sendErrorMessage('Failed to update Weekly Report Status. Please try again or contact Phin Support if issue persists.')
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendErrorMessage('Failed to update Weekly Report Status. Please try again or contact Phin Support if issue persists.')
    }
  }
}

export const setSendMonthlyReportsThunk = ({ companyId, value }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/companies/${companyId}/reports/settings/sendMonthlyReports`, { sendMonthlyReports: value })
      if (response.status === 200) {
        const { sendMonthlyReports } = await response.json()
        dispatch(setSendMonthlyReportsAction(sendMonthlyReports))
      } else {
        NotificationUtilities.sendErrorMessage('Failed to update Monthly Report Status. Please try again or contact Phin Support if issue persists.')
      }
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendErrorMessage('Failed to update Monthly Report Status. Please try again or contact Phin Support if issue persists.')
    }
  }
}

export const setWeeklyReportSendTimeThunk = ({ companyId, timeString }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/companies/${companyId}/reports/settings/weeklyReportSendTime`, { weeklyReportSendTime: timeString })
      if (response.status === 200) {
        const { weeklyReportSendTime } = await response.json()
        dispatch(setWeeklyReportSendTimeAction(weeklyReportSendTime))
        return true
      }
    } catch (error) {
      console.error(error)
    }
    NotificationUtilities.sendErrorMessage('Failed to update Weekly Report Send Time. Please try again or contact Phin Support if issue persists.')
    return false
  }
}

export const setMonthlyReportSendTimeThunk = ({ companyId, timeString }) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.put(`/api/companies/${companyId}/reports/settings/monthlyReportSendTime`, { monthlyReportSendTime: timeString })
      if (response.status === 200) {
        const { monthlyReportSendTime } = await response.json()
        dispatch(setMonthlyReportSendTimeAction(monthlyReportSendTime))
        return true
      }
    } catch (error) {
      console.error(error)
    }
    NotificationUtilities.sendErrorMessage('Failed to update Monthly Report Send Time. Please try again or contact Phin Support if issue persists.')
    return false
  }
}

export const getOnboardingChecklistDataThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingOnboardingChecklistAction(true))
    try {
      const response = await api.get(`/api/companies/${companyId}/config/onboarding/checklist`)
      if (response.ok) {
        const checklistData = await response.json()
        dispatch(setOnboardingChecklistAction(checklistData))
      } else {
        NotificationUtilities.sendErrorMessage('Failed to get onboarding checklist. Please try again or contact Phin if problem persists.')
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to get onboarding checklist. Please try again or contact Phin if problem persists.')
    }
    dispatch(setIsLoadingOnboardingChecklistAction(false))
  }
}

export const updateOnboardingChecklistDataThunk = (companyId, updatedChecklistData) => {
  return async (dispatch, getState, api) => {
    try {
      const checklistData = getState().company.onboardingChecklist

      const updatedObj = { ...checklistData }
      for (const [key, value] of Object.entries(updatedChecklistData)) {
        updatedObj[key] = value === true ? value : updatedObj[key]
      }

      const response = await api.put(`/api/companies/${companyId}/config/onboarding/checklist`, { checklistData: updatedObj })
      if (response.ok) {
        dispatch(setOnboardingChecklistAction(updatedObj))
      } else {
        NotificationUtilities.sendErrorMessage('Failed to update onboarding checklist. Please try again or contact Phin if problem persists.')
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to update onboarding checklist. Please try again or contact Phin if problem persists.')
    }
  }
}
