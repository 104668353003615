import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { createGenericDownload } from '../utils/fileUtils'

export const reportingSettingsSlice = createSlice({
  name: 'reportingSettings',
  initialState: {
    settings: {
      monthlyReportUsersToWatchEnabled: null,
      monthlyReportHistoricalDataEnabled: null,
      weeklyReportUsersToWatchEnabled: null,
      weeklyReportHistoricalDataEnabled: null,
      monthlyPhishingClicksCSVEnabled: null,
      weeklyPhishingClicksCSVEnabled: null,
      weeklyTrainingDataCSVEnabled: null,
      monthlyTrainingDataCSVEnabled: null,
      monthlyPhishingPDFReportEnabled: null,
      reports: null
    },
    loaders: {
      isLoadingReportingSettings: false,
      isLoadingReports: false,
      isDownloadingFile: {}
    }
  },
  reducers: {
    setIsLoadingReportingSettings: (state, action) => {
      state.loaders.isLoadingReportingSettings = action.payload
    },
    getReports: (state, action) => {
      state.settings.reports = action.payload
    },
    setReportLoader: (state, action) => {
      state.loaders.isLoadingReports = action.payload
    },
    setIsDownloadingFile: (state, action) => {
      state.loaders.isDownloadingFile[action.payload.id] = action.payload.value
    },
    setCompanyReportingSettings: (state, action) => {
      for (const prop in action.payload) {
        state[prop] = action.payload[prop]
      }
    }
  }

})

export const {
  setIsLoadingReportingSettings: setIsLoadingReportingSettingsAction,
  setCompanyReportingSettings: setCompanyReportingSettingsAction,
  getReports: getReportsAction,
  setReportLoader: setReportLoaderAction,
  setIsDownloadingFile: setIsDownloadingFileAction
} = reportingSettingsSlice.actions

export default reportingSettingsSlice.reducer

export const getCompanyReportingSettingsThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingReportingSettingsAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/reports/settings`)
      if (res.status === 200) {
        const reportingSettings = await res.json()
        dispatch(setCompanyReportingSettingsAction(reportingSettings))
        dispatch(setIsLoadingReportingSettingsAction(false))
      } else {
        dispatch(setIsLoadingReportingSettingsAction(false))
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load reporting settings. Please try again or reach out to Phin Support for assistance.')
        })
      }
    } catch (err) {
      dispatch(setIsLoadingReportingSettingsAction(false))
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load reporting settings. Please try again or reach out to Phin Support for assistance.')
      })
      console.error(err)
    }
  }
}

export const setCompanyReportSettingsThunk = ({ companyId, updateOpts }) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.put(`/api/companies/${companyId}/reports/settings`, updateOpts)
      if (res.status === 200) {
        dispatch(setCompanyReportingSettingsAction(updateOpts))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to update report settings. Please try again or reach out to Phin Support for assistance.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to update report settings. Please try again or reach out to Phin Support for assistance.')
      })
      console.error(err)
    }
  }
}

export const getReportsThunk = ({ companyId }) => {
  return async (dispatch, getState, api) => {
    try {
      const res = await api.get(`/api/companies/${companyId}/reports`)
      if (res.status === 200) {
        const body = await res.json()
        dispatch(getReportsAction(body))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to get reports. Please try again or reach out to Phin Support for assistance.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to get reports. Please try again or reach out to Phin Support for assistance.')
      })
      console.error(err)
    }
  }
}

export const getFileThunk = ({ fileInfo }) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(setIsDownloadingFileAction({ id: fileInfo.id, value: true }))
      const queryObj = {
        cloudFilePath: fileInfo.cloudFilePath,
        downloadFileName: fileInfo.downloadFileName,
        contentType: fileInfo.contentType
      }
      const queryStr = Object.keys(queryObj).map(k => { return `${k}=${queryObj[k]}` }).join('&')
      const res = await api.get(`/api/companies/${fileInfo.companyId}/reports/download?${queryStr}`)

      if (res.status !== 200) {
        return NotificationUtilities.sendErrorMessage('Error downloading report, please contact Phin if problem persists!')
      }

      const reportBlob = await res.blob()

      if (reportBlob.size === 0) {
        return NotificationUtilities.sendErrorMessage('Error downloading report, as it is empty, please contact Phin if problem persists!')
      }
      createGenericDownload({ fileBlob: reportBlob, fileName: queryObj.downloadFileName, fileType: queryObj.contentType })
      dispatch(setIsDownloadingFileAction({ id: fileInfo.id, value: false }))
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Error downloading report, please contact Phin if problem persists!')
    }
  }
}
