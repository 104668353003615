import { MDBRow, MDBBtn } from 'mdbreact'
import { useState, useEffect } from 'react'
import { IoIosPaper, IoMdPeople, IoIosBook, IoIosTime } from 'react-icons/io'
import { IoCheckmarkCircleSharp } from 'react-icons/io5'
import { useHistory, useParams } from 'react-router-dom'
import CurriculumInformation from './components/curriculums/CurriculumInformation'
import CurriculumAudience from './components/curriculums/CurriculumAudience'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import { buildCRONschedule, calculateEndTime, getNextCampaignFrequencyInterval } from '../utils/dateUtils'
import { apiRequestUtils } from '../utils/apiRequestUtils'
import CampaignFrequencySelector from './components/Campaigns/modules/CampaignFrequencySelector'

import parser from 'cron-parser'
import Moment from 'moment'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseCatalogThunk, getCurriculumCatalogThunk } from '../store/TrainingSlice'
import Spinner from './components/Spinner'
import {
  createOnboardingCampaignThunk,
  createTrainingCampaignThunk,
  updateOnboardingCampaignThunk,
  updateTrainingCampaignThunk,
  getBaseTrainingTopicsForCampaignThunk,
  getNonBaseTrainingTopicsForCampaignThunk
} from '../store/CampaignsSlice'
import { campaignSelectedAction, setIterationsOptionsAction } from '../store/CampaignFrequencySlice'
import { audienceOperations, audienceSelectionTypes, AUDIENCE_FILTER_TYPES, campaignFrequencyIterations, campaignTypes, CURRICULUM_BUILD_TYPES } from '../frontendConsts.js'
import { updateOnboardingChecklistDataThunk } from '../store/CompanySlice'
import { SharedStepper } from '../shared/components/SharedStepper'
import { CurriculumContainer } from './components/curriculums/curriculumBuilder/CurriculumContainer'
import TrainingCampaignFinalize from './components/curriculums/TrainingCampaignFinalize'

function TrainingCurriculumEditor ({ companyId }) {
  const NEXT = 'next'
  const PREVIOUS = 'previous'

  const history = useHistory()
  const dispatch = useDispatch()
  const { campaignId } = useParams()
  const isCampaignNew = !campaignId || campaignId === 'new'
  const [initialFixedAudienceList, setInitialFixedAudienceList] = useState([])

  const { activeCampaigns, baseTrainingCampaignTopics, nonBaseTrainingCampaignTopics, loaders: campaignsLoaders } = useSelector((state) => state.campaigns)
  const { isLoadingActiveCampaigns, isLoadingCreateTrainingCampaign, isLoadingUpdateTrainingCampaign } = campaignsLoaders
  const { curriculumCatalog, loaders: trainingsLoaders } = useSelector((state) => state.trainings)
  const { company, onboardingChecklist } = useSelector((state) => state.company)
  const { isSideNavOpen } = useSelector((state) => state.sideNav)
  const { isLoadingCurriculumCatalog } = trainingsLoaders
  const { groups, loaders: userLoaders } = useSelector((state) => state.users)
  const campaignFrequency = useSelector((state) => state.campaignFrequency)
  const { isLoadingGroups } = userLoaders

  const tabs = {
    CURRICULUM_INFO: 'curriculumInfo',
    AUDIENCE: 'audience',
    CURRICULUM: 'courses',
    SCHEDULE: 'schedule',
    FINALIZE: 'finalize'
  }

  const [campaignType, setCampaignType] = useState(campaignTypes.TRAINING)
  const [lock, setLock] = useState(false)
  const [isContinuous, setIsContinuous] = useState(false)
  const [isAutoGenerated, setIsAutoGenerated] = useState(false)
  const [isAutoCatchupEnabled, setIsAutoCatchupEnabled] = useState(false)
  const [activeTab, setActiveTab] = useState(tabs.CURRICULUM_INFO)
  const [name, setName] = useState('')
  const [campaignData, setCampaignData] = useState(null)
  const [description, setDescription] = useState('')
  const [audienceSelectionType, setAudienceSelectionType] = useState(audienceSelectionTypes.DYNAMIC)
  const [fixedAudienceList, setFixedAudienceList] = useState([])
  const [audienceStats, setAudienceStats] = useState({})
  const [audienceFilterType, setAudienceFilterType] = useState('first')
  const [audienceFilters, setAudienceFilters] = useState([{
    field: 'first',
    type: '!=',
    value: '~~~'
  }])
  const [startDate, setStartDate] = useState(new Moment().add(1, 'days'))
  const [trainingTopics, setTrainingTopics] = useState(undefined)
  const [usedModules, setUsedModules] = useState(undefined)
  const [nextFireTimes, setNextFireTimes] = useState([])
  const [firstFireDate, setFirstFireDate] = useState()
  const [nextFireTimesErrorMessage, setNextFireTimesErrorMessage] = useState()
  const [currentModule, setCurrentModule] = useState(0)

  // Curriculum Managememt
  const [curriculumBuildType, setCurriculumBuildType] = useState(CURRICULUM_BUILD_TYPES.PREBUILT)
  const [curriculumId, setCurriculumId] = useState(undefined)
  const [curriculumDescription, setCurriculumDescription] = useState(undefined)
  const [modules, setModules] = useState([])

  const [endDateTime, setEndDateTime] = useState(new Moment().add(2, 'days'))
  const [isRunning, setIsRunning] = useState(false)
  const [departmentNames, setDepartmentNames] = useState([])
  const [supervisorEmails, setSupervisorEmails] = useState([])

  const [isCampaignFrequencyWeeksValid, setIsCampaignFrequencyWeeksValid] = useState(true)

  const changeActiveTab = (tab) => {
    if (isCampaignFrequencyWeeksValid) {
      setActiveTab(tab)
    } else {
      NotificationUtilities.sendWarningMessage('Please select two week options for when to send.')
    }
  }

  const toggleCampaignType = (campaignType) => {
    if (campaignType === campaignTypes.ONBOARDING) {
      setCurriculumBuildType(CURRICULUM_BUILD_TYPES.MANUAL)
      setIsAutoCatchupEnabled(false)
    }

    if (campaignType === campaignTypes.CONTINUOUS_TRAINING && !isContinuous) {
      setIsContinuous(true)
      dispatch(setIterationsOptionsAction([{ text: 'Once', value: 'Once' }, { text: 'Twice', value: 'Twice' }]))
      dispatch(getBaseTrainingTopicsForCampaignThunk(companyId))
      dispatch(getNonBaseTrainingTopicsForCampaignThunk({ companyId }))
    } else if (campaignType !== campaignTypes.CONTINUOUS_TRAINING) {
      setIsContinuous(false)
    }

    setAudienceSelectionType(audienceSelectionTypes.DYNAMIC)
    setAudienceFilterType('first')
    setFixedAudienceList([])

    setAudienceFilters([{
      field: 'first',
      type: '!=',
      value: '~~~'
    }])

    setNextFireTimesErrorMessage()
    setNextFireTimes([])
    setFirstFireDate()
    setModules([])

    setCampaignType(campaignType)
  }

  const configureAudienceFilters = async ({ audienceFilterType, audienceFilters }) => {
    try {
      const response = await apiRequestUtils.post(`/api/companies/${companyId}/audience/stats`, { isGroup: audienceFilterType === 'group', userParameters: audienceFilters })

      if (response.status === 200) {
        const audience = await response.json()

        const departments = audience.users.filter(user => user.deptname).map((user) => user.deptname)
        const supervisors = audience.users.filter(user => user.supvemail).map((user) => user.supvemail)

        setDepartmentNames([...new Set(departments)])
        setSupervisorEmails([...new Set(supervisors)])
      } else {
        NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
    }
  }

  const refreshAudienceStats = async (audienceFilters) => {
    try {
      if (audienceFilters.length > 0) {
        const response = await apiRequestUtils.post(`/api/companies/${companyId}/audience/stats`, { isGroup: audienceFilterType === 'group', userParameters: audienceFilters })

        if (response.status === 200) {
          const audience = await response.json()

          setAudienceStats(audience.users)
        } else {
          NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
        }
      } else {
        setAudienceStats([])
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
    }
  }

  const validateTrainingCampaign = () => {
    if (name === undefined || name === null || name.length < 1) {
      setActiveTab(tabs.CURRICULUM_INFO)
      return { status: false, reason: 'Please enter a name for this campaign.' }
    }

    if (isContinuous === false && (modules === undefined || modules === null || modules.length < 1)) {
      setActiveTab(tabs.CURRICULUM)
      return { status: false, reason: 'Please select a curriculum or at least one course.' }
    }

    if (isContinuous && (trainingTopics === undefined || trainingTopics === null || trainingTopics.length < 1)) {
      setActiveTab(tabs.CURRICULUM)
      return { status: false, reason: 'Please select at least one training topic.' }
    }

    if (campaignType === campaignTypes.TRAINING && !isCampaignFrequencyWeeksValid) {
      setActiveTab(tabs.SCHEDULE)
      return { status: false, reason: 'Please select two weeks of the month to send content or change the frequency.' }
    }
    // If we ever change how we do audience filters, this will need to change as well!
    if (audienceFilterType === AUDIENCE_FILTER_TYPES.DEPARTMENT_NAME && audienceFilters[0].value === null) {
      setActiveTab(tabs.AUDIENCE)
      return { status: false, reason: 'Please select a Department Name from the dropdown or select a different audience filter type.' }
    }
    if (audienceFilterType === AUDIENCE_FILTER_TYPES.SUPERVISOR_EMAIL && audienceFilters[0].value === null) {
      setActiveTab(tabs.AUDIENCE)
      return { status: false, reason: 'Please select a Supervisor Email from the dropdown or select a different audience filter type' }
    }

    return { status: true }
  }

  const submit = async () => {
    const { status, reason } = validateTrainingCampaign()

    if (status) {
      const [hours, minutes] = campaignFrequency.time.split(':')

      if (modules) {
        for (const module of modules) {
          delete module.firetime
        }
      }

      const curriculum = {
        description: curriculumDescription,
        curriculumBuildType,
        modules
      }

      // If usedModules exists, add it to the curriculum
      if (usedModules) {
        curriculum.usedModules = usedModules
      }

      if (curriculumId) {
        curriculum.id = curriculumId
      }

      const trainingCampaign = {
        name,
        description,
        curriculum,
        audienceSelectionType,
        audienceFilterType,
        audienceFilters,
        campaignType,
        usedModules,
        isContinuous,
        isAutoGenerated,
        isAutoCatchupEnabled,
        fixedAudienceList
      }

      if (isContinuous) {
        trainingCampaign.trainingTopics = trainingTopics
      }

      if (campaignType === campaignTypes.TRAINING && nextFireTimes.length) {
        trainingCampaign.nextFireTimestamp = nextFireTimes[0]
      } else if (campaignType === campaignTypes.TRAINING && campaignFrequency.iterations.value === campaignFrequencyIterations.ALL_AT_ONCE) {
        trainingCampaign.nextFireTimestamp = startDate.toDate()
        const [hours, minutes] = campaignFrequency.time.split(':')
        trainingCampaign.nextFireTimestamp.setMinutes(minutes)
        trainingCampaign.nextFireTimestamp.setHours(hours)
      }

      if (!isCampaignNew) {
        // Identify the changes between the current training campaign fixed audience and the updated list in state

        if (audienceSelectionType === audienceSelectionTypes.FIXED) {
          const audienceMapCopy = { ...campaignData.audienceMap }

          const audienceChangeSets = []

          for (const audienceMember of fixedAudienceList) {
            if (audienceMapCopy[audienceMember.id]) {
              delete audienceMapCopy[audienceMember.id]
            } else {
              audienceChangeSets.push({ userId: audienceMember.id, operation: audienceOperations.ADD })
            }
          }

          for (const userId of Object.keys(audienceMapCopy)) {
            audienceChangeSets.push({ userId, operation: audienceOperations.REMOVE })
          }

          trainingCampaign.audienceChangeSets = audienceChangeSets
        }

        if (campaignType === campaignTypes.ONBOARDING) {
          dispatch(updateOnboardingCampaignThunk({ companyId, campaignId, trainingCampaign, history }))
        } else {
          const startDateTime = DateTime.fromObject({ year: startDate.year(), month: ((startDate.month() + 1) % 12), day: startDate.date(), hour: hours, minute: minutes }, { zone: company.timezone.value })
          trainingCampaign.startTime = startDateTime.toJSDate()
          trainingCampaign.endTime = (isContinuous) ? null : calculateEndTime(modules.length, startDate, company ? company.timezone.value : 'America/New_York', campaignFrequency)
          dispatch(updateTrainingCampaignThunk({ companyId, campaignId, trainingCampaign, history }))
        }
      } else {
        if (campaignType === campaignTypes.ONBOARDING) {
          dispatch(createOnboardingCampaignThunk({ companyId, trainingCampaign, history }))
        } else {
          // Set the fixed audience list for a new campaign to create all the users
          const startDateTime = DateTime.fromObject({ year: startDate.year(), month: ((startDate.month() + 1) % 12), day: startDate.date(), hour: hours, minute: minutes }, { zone: company.timezone.value })
          trainingCampaign.startTime = startDateTime.toJSDate()
          trainingCampaign.endTime = (isContinuous) ? null : calculateEndTime(modules.length, startDate, company ? company.timezone.value : 'America/New_York', campaignFrequency)

          dispatch(createTrainingCampaignThunk({ companyId, trainingCampaign, history }))
          if (!onboardingChecklist.hasActiveCampaigns) {
            dispatch(updateOnboardingChecklistDataThunk(companyId, { hasActiveCampaigns: true }))
          }
        }
      }
    } else {
      NotificationUtilities.sendWarningMessage(reason)
    }
  }

  useEffect(() => {
    try {
      // update scheduling dates on component load
      let firstDateToFire
      const tempArr = []
      // Convert the Moment to a JS Date
      const startingDate = startDate.toDate()
      const startingDateTime = DateTime.fromJSDate(startingDate)
      const tz = company ? company.timezone.value : 'America/New_York'
      const cron = buildCRONschedule(campaignFrequency, startingDateTime)
      const interval = parser.parseExpression(cron, { currentDate: startingDate, tz })
      let datesRendered = 0

      const currentDateTime = new Date()

      const numberOfNextFireTimes = isContinuous ? 5 : modules.length - currentModule

      if (campaignFrequency.iterations.value === campaignFrequencyIterations.ALL_AT_ONCE) {
        setIsAutoCatchupEnabled(false)
      }

      if (campaignFrequency.iterations.value !== campaignFrequencyIterations.ALL_AT_ONCE) {
        while (datesRendered < numberOfNextFireTimes) {
          const nextFireTime = getNextCampaignFrequencyInterval(campaignFrequency, interval, tz)

          if (nextFireTime > startingDate) {
            if (!firstDateToFire) {
              firstDateToFire = nextFireTime
            }

            if (nextFireTime > currentDateTime) {
              tempArr.push(nextFireTime)
              datesRendered += 1
            }
          }
        }

        setNextFireTimesErrorMessage()
        setNextFireTimes(tempArr)
        setFirstFireDate(firstDateToFire)
      }
    } catch (error) {
      setNextFireTimesErrorMessage(error.message)
    }
  }, [modules, startDate, campaignFrequency])

  const getCampaignToUpdate = async () => {
    try {
      const res = await apiRequestUtils.get(`/api/companies/${companyId}/training/campaign/${campaignId}`)

      if (res.status === 200) {
        const campaign = await res.json()
        dispatch(campaignSelectedAction(campaign))

        setName(campaign.name)
        setDescription(campaign.description)
        setCampaignType(campaign.campaignType)

        setAudienceFilterType(campaign.audienceFilterType)
        setAudienceFilters(campaign.audienceFilters)

        // Set up additional state for curriculum
        setCurriculumId(campaign.curriculum.id || undefined)
        setCurriculumDescription(campaign.curriculum.description)
        setModules(campaign.curriculum.modules)

        //  Set up Training Campaign Properties
        if (campaign.campaignType === campaignTypes.TRAINING) {
          const startDateTime = new Date(campaign.startTime._seconds * 1000)
          if (startDateTime <= new Date()) {
            setLock(true)
          }

          if (campaign.isContinuous) {
            setIsContinuous(campaign.isContinuous)
            dispatch(setIterationsOptionsAction([{ text: 'Once', value: 'Once' }, { text: 'Twice', value: 'Twice' }]))
            setTrainingTopics(campaign.trainingTopics)
            setUsedModules(campaign.usedModules)
            setCurriculumBuildType(campaign.curriculum.curriculumBuildType || CURRICULUM_BUILD_TYPES.TOPICS)
          } else {
            setCurriculumBuildType(campaign.curriculum.curriculumBuildType || CURRICULUM_BUILD_TYPES.PREBUILT)
            dispatch(setIterationsOptionsAction([{ text: 'Once', value: 'Once' }, { text: 'Twice', value: 'Twice' }, { text: 'All At Once', value: 'All At Once' }]))
          }

          if (campaign.isAutoGenerated) {
            setIsAutoGenerated(campaign.isAutoGenerated)
          }
          if (campaign.isAutoCatchupEnabled) {
            setIsAutoCatchupEnabled(campaign.isAutoCatchupEnabled)
          }

          setCurrentModule(campaign.currentModule)
          setStartDate(new Moment(startDateTime))
        } else {
          setCurriculumBuildType(campaign.curriculum.curriculumBuildType || CURRICULUM_BUILD_TYPES.PREBUILT)
        }

        if (campaign.audienceSelectionType) {
          setAudienceSelectionType(campaign.audienceSelectionType)
        } else {
          setAudienceSelectionType(audienceSelectionTypes.DYNAMIC)
        }

        setIsRunning(campaign.running)
        setCampaignData(campaign)

        configureAudienceFilters({ audienceFilterType: campaign.audienceFilterType, audienceFilters: campaign.audienceFilters })

        if (campaign.audienceSelectionType === audienceSelectionTypes.FIXED && campaign.audienceMap) {
          const audienceList = Object.values(campaign.audienceMap)
          setFixedAudienceList(audienceList)
          setInitialFixedAudienceList(audienceList)
        }
      } else {
        NotificationUtilities.sendErrorMessage('Failed to load Training Campaign. Please try again or contact Phin Support for assistance.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to load Training Campaign. Please try again or contact Phin Support for assistance.')
    }
  }

  useEffect(() => {
    if (!isCampaignNew) {
      getCampaignToUpdate()
    } else {
      dispatch(setIterationsOptionsAction([{ text: 'Once', value: 'Once' }, { text: 'Twice', value: 'Twice' }, { text: 'All At Once', value: 'All At Once' }]))
      configureAudienceFilters({ audienceFilterType, audienceFilters })
    }
  }, [campaignId])

  // Initialize the training topics if it's a new campaign
  useEffect(() => {
    if (isCampaignNew) {
      if (baseTrainingCampaignTopics.length > 0) {
        setTrainingTopics([...baseTrainingCampaignTopics])
      }
    }
  }, [baseTrainingCampaignTopics])

  // Automatically update endDateTime of campaign
  useEffect(() => {
    const [hours, minutes] = campaignFrequency.time.split(':')

    startDate.hours(hours)
    startDate.minutes(minutes)
    if (modules !== undefined) {
      setEndDateTime(calculateEndTime(modules.length, startDate, company ? company.timezone.value : 'America/New_York', campaignFrequency))
    } else {
      setEndDateTime(new Moment(startDate).add(1, 'days'))
    }
  }, [modules, startDate])

  useEffect(() => {
    // if we're on the final tab, the only way the campaign type could change to TRAINING is if we're
    // prepping the data JUST before submit.. this is a hack to intercept before the submit() during the changeTab() function
    if (activeTab === tabs.FINALIZE && campaignType === campaignTypes.TRAINING && isContinuous) {
      submit()
    }
  }, [campaignType])

  // Initialize the default audience stats
  useEffect(() => {
    document.title = 'Phin Security | Training Campaign Creator'
    dispatch(getCourseCatalogThunk({ companyId }))
    dispatch(getCurriculumCatalogThunk({ companyId }))
    dispatch(getBaseTrainingTopicsForCampaignThunk(companyId))
    dispatch(getNonBaseTrainingTopicsForCampaignThunk({ companyId }))
  }, [])

  // Update the audience stats based on audienceFilters
  useEffect(() => {
    if (campaignType !== campaignTypes.ONBOARDING) {
      if (audienceSelectionType === audienceSelectionTypes.DYNAMIC) {
        refreshAudienceStats(audienceFilters)
      } else {
        refreshAudienceStats([{
          field: 'first',
          type: '!=',
          value: '~~~'
        }])
      }
    }
  }, [audienceFilters])

  const trainingCampaignStepsArray = [
    { tab: tabs.CURRICULUM_INFO, icon: IoIosPaper, label: 'Information' },
    { tab: tabs.AUDIENCE, icon: IoMdPeople, label: 'Audience' },
    { tab: tabs.CURRICULUM, icon: IoIosBook, label: 'Curriculum' },
    { tab: tabs.SCHEDULE, icon: IoIosTime, label: 'Schedule' },
    { tab: tabs.FINALIZE, icon: IoCheckmarkCircleSharp, label: 'Finalize' }
  ]

  const onboardingCampaignStepsArray = [
    { tab: tabs.CURRICULUM_INFO, icon: IoIosPaper, label: 'Information' },
    { tab: tabs.AUDIENCE, icon: IoMdPeople, label: 'Audience' },
    { tab: tabs.CURRICULUM, icon: IoIosBook, label: 'Curriculum' },
    { tab: tabs.FINALIZE, icon: IoCheckmarkCircleSharp, label: 'Finalize' }
  ]

  const changeTab = (direction) => {
    switch (activeTab) {
      case tabs.CURRICULUM_INFO: {
        if (direction === NEXT) {
          setActiveTab(tabs.AUDIENCE)
        } else {
          history.goBack()
        }
        break
      }

      case tabs.AUDIENCE: {
        if (direction === NEXT) {
          setActiveTab(tabs.CURRICULUM)
        } else {
          setActiveTab(tabs.CURRICULUM_INFO)
        }
        break
      }

      case tabs.CURRICULUM: {
        if (direction === NEXT) {
          if (isContinuous && (trainingTopics === undefined || trainingTopics === null || trainingTopics.length < 1)) {
            return NotificationUtilities.sendWarningMessage('Please select at least one training topic.')
          }
          if (campaignType === campaignTypes.ONBOARDING) {
            setActiveTab(tabs.FINALIZE)
          } else {
            setActiveTab(tabs.SCHEDULE)
          }
        } else {
          setActiveTab(tabs.AUDIENCE)
        }
        break
      }

      case tabs.SCHEDULE: {
        if (isCampaignFrequencyWeeksValid) {
          if (direction === NEXT) {
            setActiveTab(tabs.FINALIZE)
          } else {
            setActiveTab(tabs.CURRICULUM)
          }
        } else {
          NotificationUtilities.sendWarningMessage('Please select two week options for when to send.')
        }
        break
      }

      case tabs.FINALIZE:
        if (direction === NEXT && campaignType === campaignTypes.CONTINUOUS_TRAINING && isContinuous) {
          setCampaignType(campaignTypes.TRAINING)
        } else if (direction === NEXT) {
          submit()
        } else {
          if (campaignType === campaignTypes.ONBOARDING) {
            setActiveTab(tabs.CURRICULUM)
          } else {
            setActiveTab(tabs.SCHEDULE)
          }
        }
    }
  }

  const chooseFinalButtonText = (tab, lock) => {
    let btnText

    if (tab === tabs.FINALIZE && !isCampaignNew) {
      btnText = 'update'
    } else if (tab === tabs.FINALIZE && isCampaignNew) {
      btnText = 'launch'
    } else {
      btnText = 'next'
    }

    return btnText
  }

  return (
    <div
      className='editor-container' style={isSideNavOpen
        ? { marginLeft: '320px', marginRight: '200px' }
        : { marginLeft: '128px', marginRight: '128px' }}
    >
      <NotificationWrapper />

      {(!activeCampaigns || isLoadingActiveCampaigns ||
        !curriculumCatalog || isLoadingCurriculumCatalog ||
        !groups || isLoadingGroups ||
        isLoadingCreateTrainingCampaign || isLoadingUpdateTrainingCampaign) && (
          <Spinner message='Loading Training Campaign' />
      )}

      {(activeCampaigns && !isLoadingActiveCampaigns &&
        curriculumCatalog && !isLoadingCurriculumCatalog &&
        groups && !isLoadingGroups &&
        !isLoadingCreateTrainingCampaign && !isLoadingUpdateTrainingCampaign) && (
          <>
            <MDBRow className='d-flex justify-content-center pt-3'>
              <SharedStepper
                steps={(campaignType === campaignTypes.TRAINING || campaignType === campaignTypes.CONTINUOUS_TRAINING) ? trainingCampaignStepsArray : onboardingCampaignStepsArray}
                activeTab={activeTab}
                setActiveTab={changeActiveTab}
              />
            </MDBRow>

            {activeTab === tabs.CURRICULUM_INFO &&
              <CurriculumInformation
                name={name}
                description={description}
                setName={setName}
                setDescription={setDescription}
                campaignType={campaignType}
                toggleCampaignType={toggleCampaignType}
                isCampaignNew={isCampaignNew}
              />}

            {(activeTab === tabs.AUDIENCE) &&
              <CurriculumAudience
                groups={groups}
                audienceFilters={audienceFilters}
                setAudienceFilters={setAudienceFilters}
                audienceFilterType={audienceFilterType}
                setAudienceFilterType={setAudienceFilterType}
                audienceStats={audienceStats}
                departmentNames={departmentNames}
                supervisorEmails={supervisorEmails}
                audienceSelectionType={audienceSelectionType}
                setAudienceSelectionType={setAudienceSelectionType}
                campaignType={campaignType}
                fixedAudienceList={fixedAudienceList}
                setFixedAudienceList={setFixedAudienceList}
                isCampaignNew={isCampaignNew}
                initialFixedAudienceList={initialFixedAudienceList}
              />}

            {(activeTab === tabs.CURRICULUM) &&
              <CurriculumContainer
                campaignType={campaignType}
                companyId={companyId}
                curriculumCatalog={curriculumCatalog}
                lock={lock}
                isContinuous={isContinuous}
                curriculumBuildType={curriculumBuildType}
                setCurriculumBuildType={setCurriculumBuildType}
                modules={modules}
                setModules={setModules}
                trainingTopics={trainingTopics}
                setTrainingTopics={setTrainingTopics}
                baseTrainingCampaignTopics={baseTrainingCampaignTopics}
                nonBaseTrainingCampaignTopics={nonBaseTrainingCampaignTopics}
                setCurriculumDescription={setCurriculumDescription}
                curriculumId={curriculumId}
                setCurriculumId={setCurriculumId}
              />}

            {(activeTab === tabs.SCHEDULE) && (
              <CampaignFrequencySelector
                modules={modules}
                endTime={endDateTime}
                startTime={startDate}
                setStartTime={setStartDate}
                campaignFrequency={campaignFrequency}
                tz={company ? company.timezone.value : 'America/New_York'}
                campaignType={campaignType}
                isRunning={isRunning}
                nextFireTimes={nextFireTimes}
                firstFireDate={firstFireDate}
                nextFireTimesErrorMessage={nextFireTimesErrorMessage}
                isCampaignFrequencyWeeksValid={isCampaignFrequencyWeeksValid}
                setIsCampaignFrequencyWeeksValid={setIsCampaignFrequencyWeeksValid}
              />
            )}

            {(activeTab === tabs.FINALIZE) && (
              <TrainingCampaignFinalize
                fixedAudienceList={fixedAudienceList}
                initialFixedAudienceList={initialFixedAudienceList}
                campaignType={campaignType}
                isCampaignNew={isCampaignNew}
                modules={modules}
                setModules={setModules}
                trainingTopics={trainingTopics}
                setTrainingTopics={setTrainingTopics}
                nextFireTimes={nextFireTimes}
                tz={company ? company.timezone.value : 'America/New_York'}
                currentModule={currentModule}
                startingDate={startDate.toDate()}
                campaignFrequency={campaignFrequency}
                isAutoCatchupEnabled={isAutoCatchupEnabled}
                setIsAutoCatchupEnabled={setIsAutoCatchupEnabled}
                isContinuous={isContinuous}
              />
            )}

            <MDBRow className='d-flex justify-content-between align-items-center mt-4'>
              <MDBBtn
                onClick={() => changeTab(PREVIOUS)}
                color={activeTab === tabs.CURRICULUM_INFO ? 'danger' : ''}
                style={activeTab !== tabs.CURRICULUM_INFO ? { border: 'solid 2px var(--action-blue)', color: 'var(--action-blue)' } : {}}
              >
                {(activeTab === tabs.CURRICULUM_INFO) ? 'Cancel' : 'Previous'}
              </MDBBtn>
              <MDBBtn id='next-button' onClick={() => changeTab(NEXT)} color='primary'>{chooseFinalButtonText(activeTab, lock)}</MDBBtn>
            </MDBRow>

          </>
      )}
    </div>
  )
}

export default TrainingCurriculumEditor
