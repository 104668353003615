/* eslint-disable */
import React, { Component } from "react"
import {
	MDBInput,
	MDBContainer,
	MDBModalFooter,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBBtn,
	MDBAlert,
	MDBCollapse,
	MDBIcon
} from "mdbreact"

class AddUserModal extends Component {
	

	render() {
		let {
			deviceStructure,
			isOpen,
			addUserInfo,
			uploadUser,
			masterToggle,
			user,
			addUserModalButtonText,
			toggleOptionalFields,
			isOptionalFieldsOpen,
		} = this.props


		let primaryFields = []
		let optionalFields = []
		
		deviceStructure.map((column, index) => {
			if (column.field === 'first' || column.field === 'last' || column.field === 'email') {
				primaryFields.push(
					<MDBInput
					outline
					id={`add-user-modal-input-${column.field}`}
					aria-label={`add user input ${column.field}`}
					key={index}
					value={user[column.field]}
					label={column.label}
					onChange={e => {
						addUserInfo(e, column.field)
					}}></MDBInput>
				)
			}
			else {
				optionalFields.push(
					<MDBInput
					outline
					id={`add-user-modal-input-${column.field}`}
					aria-label={`add user input ${column.field}`}
					key={index}
					value={user[column.field]}
					label={column.label}
					onChange={e => {
						addUserInfo(e, column.field)
					}}></MDBInput>
				)
			}
		})

	
		return (
			<MDBContainer>
				<MDBModal
					isOpen={isOpen}
					toggle={() => masterToggle("Close Add User")}>
					<MDBModalHeader>{addUserModalButtonText}</MDBModalHeader>
					<MDBModalBody id="user-fields">
						{primaryFields}

						{optionalFields.length >= 1 &&
						<>
							<MDBBtn
								color="primary"
								id="new-user-additional-fields"
								onClick={toggleOptionalFields}
								className='mb-2'
								size='sm'
							>
								Additional Fields <MDBIcon icon='chevron-down'/>
							</MDBBtn>
							<MDBCollapse id="optionalFields" isOpen={isOptionalFieldsOpen}>
								{optionalFields}
							</MDBCollapse>
						</>
						}
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn
							color='red'
							onClick={() => masterToggle("Close Add User")}>
							Cancel
						</MDBBtn>
						<MDBBtn
							id="add-user-confirm"
							onClick={() => {
								uploadUser()
							}}
							color='primary'>
							{addUserModalButtonText}
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
			</MDBContainer>
		)
	}
}

export default AddUserModal
