import PresetCard from './PresetCard'
import { MDBContainer, MDBRow } from 'mdbreact'
import generateGroups from '../../../../utils/generateGroups'
import { shareLevels } from '../../../../frontendConsts.js'

export default function PresetLibrary ({ campaignPresets, selectCallback, modify = false, editCallback = undefined, deleteCallback = undefined }) {
  function generateCardGroups () {
    const finalCards = []
    const groupSize = 4

    const itemGroups = generateGroups(campaignPresets, groupSize)
    itemGroups.forEach((itemGroup, i) => {
      const mappedItemGroup = itemGroup.map((item, j) => {
        const hasAccess = (item.shareLevel === shareLevels.PARTNER) || item.shareLevel === shareLevels.CLIENT
        return <PresetCard key={i * groupSize + j} modify={hasAccess && modify} cardInfo={item} selectCallback={selectCallback} editCallback={editCallback} deleteCallback={deleteCallback} />
      })

      finalCards.push(mappedItemGroup)
    })
    return finalCards
  }

  const cards = generateCardGroups()
  return (
    <MDBContainer>
      {cards.map((card, i) => (
        <MDBRow key={i} className='size-columns my-4'>
          {card}
        </MDBRow>
      ))}
    </MDBContainer>
  )
}
