import React from 'react'
import {
  MDBCol, MDBRow, MDBIcon, MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBTooltip
} from 'mdbreact'

export default function AudienceStats ({ audienceStats }) {
  return (
    <MDBCard className='w-100 h-100'>
      <MDBCardHeader>
        <MDBRow className='d-flex'>
          <MDBCol className='d-flex justify-content-start align-items-center'>
            <h5>Audience Size</h5>
          </MDBCol>
          <MDBCol className='d-flex justify-content-end'>
            <MDBTooltip
              placement='left'
              id='popover23'
              material
            >
              <MDBBtn
                color='elegant'
                className='my-0'
                floating
                tag='a'
                size='sm'
              >
                <MDBIcon icon='question' />
              </MDBBtn>
              <div>
                This is the number of users who will receive emails during each sending interval
              </div>
            </MDBTooltip>
          </MDBCol>
        </MDBRow>
      </MDBCardHeader>
      <MDBCardBody className='d-flex align-items-center justify-content-center'>
        <MDBRow>
          <MDBCol size='12'>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol xl='12' md='12'>
                <MDBRow className='d-flex text-center justify-content-center mt-4'>
                  <MDBCol className={!audienceStats?.length ? 'red-text' : ''}>
                    <MDBIcon style={{ fontSize: '30px' }} icon='users' />
                    <h5 style={{ fontSize: '20px' }} className='font-weight-bold'>{audienceStats?.length ? audienceStats.length : 0} Users</h5>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}
