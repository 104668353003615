import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBBtn, MDBCard, MDBCardBody, MDBRow,
  MDBInput, MDBCardHeader, MDBCol
} from 'mdbreact'

import NotificationUtilities from './components/notifications/notificationUtils'
import NotificationWrapper from './components/notifications/notificationWrapper'
import ValidationUtils from '../utils/validationUtils'
import { useSelector, useDispatch } from 'react-redux'
import { createAdminThunk } from '../store/DistributorSlice'
import Spinner from './components/Spinner'

const totalSteps = 1

function nextStep (currentStep, setStep, finishCreation) {
  switch (currentStep) {
    // Getting started
    case (totalSteps - 1):
      finishCreation()
      break
    default:
      setStep(currentStep + 1)
      break
  }
}

function previousStep (currentStep, setStep) {
  setStep(currentStep - 1)
}

function cancelCreation (history) {
  history.goBack()
}

const CreateAdmin = ({ id }) => {
  const [currentStep, setStep] = useState(0)
  const [adminFirst, setAdminFirstName] = useState('')
  const [adminLast, setAdminLastName] = useState('')
  const [adminEmail, setAdminEmail] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoadingCreateAdmin } = useSelector((state) => state.distributor.loaders)
  const distributor = useSelector((state) => state.distributor.distributor)

  function validate () {
    if (!ValidationUtils.isNotWhiteSpace(adminFirst)) {
      throw Error('Please input a first name')
    }
    if (!ValidationUtils.isNotWhiteSpace(adminLast)) {
      throw Error('Please input a last name')
    }
    if (!ValidationUtils.isValidEmail(adminEmail)) {
      throw Error('Please use a valid email')
    }
  }

  async function finishCreation () {
    try {
      validate()
      dispatch(createAdminThunk({
        adminEmail,
        adminFirst,
        adminLast,
        id,
        distributorName: distributor.name,
        history
      }))
    } catch (error) {
      console.error(error)
      NotificationUtilities.sendWarningMessage(error.message)
    }
  }

  return (
    <>
      <NotificationWrapper />
      <MDBRow>
        <MDBCol className='d-flex flex-column flex-center mb-3'>
          <h1 id='partner-page-heading'>Add A New Administrator</h1>
        </MDBCol>
      </MDBRow>
      {!isLoadingCreateAdmin && (
        <>
          {currentStep === 0 &&
            <MDBCard>
              <MDBCardHeader>
                <h5 style={{ marginBottom: '0px' }}>Admin Info</h5>
              </MDBCardHeader>
              <MDBCardBody className='p-3'>
                <MDBRow center>
                  <MDBCol size='4'>
                    <MDBInput
                      outline value={adminFirst} id='new-admin-first-input' size='lg' onChange={(e) => {
                        setAdminFirstName(e.target.value)
                      }} name='admin first name' label='First Name'
                    />
                  </MDBCol>
                  <MDBCol size='4'>
                    <MDBInput
                      outline value={adminLast} id='new-admin-last-input' size='lg' onChange={(e) => {
                        setAdminLastName(e.target.value)
                      }} name='admin last name' label='Last Name'
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow className='d-flex justify-content-center'>
                  <MDBCol size='4'>
                    <MDBInput
                      outline value={adminEmail} id='new-admin-email-input' size='lg' onChange={(e) => {
                        setAdminEmail(e.target.value)
                      }} name='admin email' label='Email'
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>}
          <MDBBtn
            id='cancel-admin-creation-button'
            aria-label='Cancel Admin Creation Button'
            className='mt-4'
            color='red'
            onClick={() => cancelCreation(history)}
          >Cancel
          </MDBBtn>
          {currentStep > 0 &&
            <MDBBtn className='mt-4' onClick={() => previousStep(currentStep, setStep)} color='secondary'>Previous</MDBBtn>}
          <MDBBtn
            className='mt-4'
            id='create-admin-button'
            aria-label='Create Admin Button'
            onClick={() => nextStep(currentStep, setStep, finishCreation)}
            color='primary'
          >{currentStep < totalSteps - 1 ? 'Next' : 'Finish'}
          </MDBBtn>
        </>
      )}

      {isLoadingCreateAdmin && (
        <Spinner />
      )}
    </>
  )
}

export default CreateAdmin
