import React from 'react'
import { MDBRow, MDBCol, MDBIcon, toast } from 'mdbreact'

/**
 * Series of static utility methods that allow for easy interaction with the notification system.
 * Requires the NotificationWrapper component to be present in the page for these to work!
 * @see notificationWrapper.js
 */
class NotificationUtilities {
  /**
   * Sends a warning notification to the user with the passed message
   *
   * @static
   * @param {String} message The message you wish to send
   * @memberof NotificationUtilities
   */
  static sendWarningMessage (message) {
    toast.warn(
      <MDBRow className='d-flex align-items-center'>
        <MDBCol size='2'>
          <MDBIcon
            className='d-flex justify-content-center align-items-center ml-4'
            icon='exclamation-triangle'
            size='2x'
          />
        </MDBCol>
        <MDBCol
          className='d-flex justify-content-center align-items-center'
          size='9'
        >
          {message}
        </MDBCol>
      </MDBRow>,

      { closeButton: false }
    )
  };

  /**
   * Sends a error notification to the user with the passed message
   *
   * @static
   * @param {String} message The message to send to the user
   * @memberof NotificationUtilities
   */
  static sendErrorMessage (message) {
    toast.error(
      <MDBRow className='d-flex align-items-center'>
        <MDBCol size='2'>
          <MDBIcon
            className='d-flex justify-content-center align-items-center ml-4'
            icon='exclamation-circle'
            size='2x'
          />
        </MDBCol>
        <MDBCol
          className='d-flex justify-content-center align-items-center'
          size='9'
        >
          {message}
        </MDBCol>
      </MDBRow>,

      { closeButton: false }
    )
  };

  /**
   * Sends a success notification to the user with the passed in message
   *
   * @static
   * @param {String} message The message to send to the user
   * @memberof NotificationUtilities
   */
  static sendSuccessMessage (message) {
    toast.success(
      <MDBRow className='d-flex align-items-center'>
        <MDBCol size='2'>
          <MDBIcon
            className='d-flex justify-content-center align-items-center ml-4'
            icon='check'
            size='2x'
          />
        </MDBCol>
        <MDBCol
          className='d-flex justify-content-center align-items-center'
          size='9'
        >
          {message}
        </MDBCol>
      </MDBRow>,

      { closeButton: false }
    )
  }

  /**
   * Sends a success notification to the user with the passed in message
   *
   * @static
   * @param {String} message The message to send to the user
   * @memberof NotificationUtilities
   */
  static sendInfoMessage (message) {
    toast.info(
      <MDBRow className='d-flex align-items-center'>
        <MDBCol size='2'>
          <MDBIcon
            className='d-flex justify-content-center align-items-center ml-4'
            icon='info-circle'
            size='2x'
          />
        </MDBCol>
        <MDBCol
          className='d-flex justify-content-center align-items-center'
          size='9'
        >
          {message}
        </MDBCol>
      </MDBRow>,

      { closeButton: false }
    )
  }
}

export default NotificationUtilities
