import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBContainer, MDBCard,
  MDBCollapseHeader, MDBIcon,
  MDBCollapse, MDBCardBody,
  MDBBtn, MDBRow
} from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import { updateOnboardingChecklistDataThunk } from '../store/CompanySlice'
import TableBadge from './components/TableBadge'
import ValidationModal from './components/ValidationModal'

function CompanyOnboardingChecklist ({
  id, companyName
}) {
  const history = useHistory()
  const disptach = useDispatch()
  const { onboardingChecklist } = useSelector((state) => state.company)

  const [currentCard, setCard] = useState('')
  const [stepToOverride, setStepToOverride] = useState(null)
  const [stepToOverrideText, setStepToOverrideText] = useState(null)
  const [showOverrideModal, setShowOverrideModal] = useState(false)

  const selectOpenCard = () => {
    let defaultCard = ''
    if (!onboardingChecklist.hasPersonalizations) {
      defaultCard = 'personalizeCard'
    } else if (!onboardingChecklist.hasEmailSettings) {
      defaultCard = 'emailCard'
    } else if (!onboardingChecklist.hasWelcomedUsers) {
      defaultCard = 'welcomeCard'
    } else if (!onboardingChecklist.hasUsers) {
      defaultCard = 'userCard'
    } else if (!onboardingChecklist.hasActiveCampaigns) {
      defaultCard = 'campaignCard'
    }

    setCard(defaultCard)
  }

  useEffect(() => {
    selectOpenCard()
  }, [onboardingChecklist])

  const toggleCollapse = (passedCard) => {
    setCard(passedCard !== currentCard ? passedCard : '')
  }

  const acknowledgeOverride = () => {
    const updateObject = {}
    updateObject[stepToOverride] = true

    disptach(updateOnboardingChecklistDataThunk(id, updateObject))

    setStepToOverride(null)
    setStepToOverrideText(null)
    setShowOverrideModal(false)
  }

  const cancelOverride = () => {
    setStepToOverride(null)
    setStepToOverrideText(null)
    setShowOverrideModal(false)
  }

  const overrideChecklist = (stepToOverride, stepToOverrideText, e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }

    setStepToOverride(stepToOverride)
    setStepToOverrideText(stepToOverrideText)
    setShowOverrideModal(true)
  }

  return (
    <MDBContainer>
      <ValidationModal
        isOpen={showOverrideModal}
        toggleFunction={() => cancelOverride()}
        handlerFunction={() => acknowledgeOverride()}
        messageJSX={
          <div style={{ textAlign: 'left' }}>
            <p>Are you sure you want to override this step: <b>{stepToOverrideText}</b>?</p>
            <p>This action cannot be undone.</p>
          </div>
                    }
        falseOptionText='Cancel'
        falseOptionColor='red'
        trueOptionText='Override'
        trueOptionColor='primary'
      />

      <MDBCard
        className='card-image' style={{
          backgroundColor: '#189CB2'
        }}
      >
        <MDBContainer className='accordion md-accordion accordion-5 mt-5'>

          {/* Personalize Card */}
          <MDBCard className='mb-3'>
            <MDBCollapseHeader
              className='p-0 z-depth-1'
              tag='h4'
              tagClassName='mb-0 d-flex justify-content-between align-items-center white'
              onClick={() => toggleCollapse('personalizeCard')}
            >
              <div
                className='d-flex justify-content-start align-items-center mr-4'
                style={{ backgroundColor: '#052460', minWidth: '100px' }}
              >
                <MDBIcon
                  icon='palette'
                  size='2x'
                  className='m-3 white-text'
                />
              </div>
              <div className='pl-3 pr-3'>
                Personalization
              </div>
              <div className='pl-3 pr-3'>
                <TableBadge
                  iconName={(onboardingChecklist.hasPersonalizations) ? 'check' : 'ban'}
                  description={(onboardingChecklist.hasPersonalizations) ? '' : 'Override Checklist Item'}
                  color={(onboardingChecklist.hasPersonalizations) ? 'success' : 'warning'}
                  onClick={!onboardingChecklist.hasPersonalizations
                    ? (e) => overrideChecklist('hasPersonalizations', 'Personalization', e)
                    : () => {}}
                  cardName='personalization'
                />
              </div>
            </MDBCollapseHeader>
            <MDBCollapse id='personalizeCard' isOpen={currentCard === 'personalizeCard'}>
              <MDBCardBody className='rgba-black-light white-text z-depth-1' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <MDBRow className='d-flex justify-content-around pb-3'>
                  <MDBBtn
                    onClick={() => history.push(`/companies/${id}/branding`)}
                    color='primary'
                    size='lg'
                    id='personalize-button'
                    aria-label='Personalize Button'
                  >Personalize
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => history.push(`/companies/${id}/users`)}
                    color='secondary'
                    size='lg'
                    id='manage-company-admins-button'
                    aria-label='Manage Company Admins Button'
                  >Manage Company Admins
                  </MDBBtn>
                </MDBRow>
                <p className='p-md-4 mb-0' style={{ fontSize: '1.3rem', fontWeight: 500 }}>
                  Personalize this company's experience by
                  configuring a logo or learning moment image,
                  adding {companyName} admins to help manage
                  or view this company, or configure notification emails
                  for weekly company activity reports.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          {/* Email Settings Card */}
          <MDBCard className='mb-3'>
            <MDBCollapseHeader
              className='p-0 z-depth-1'
              tag='h4'
              tagClassName='mb-0 d-flex justify-content-between align-items-center white'
              onClick={() => toggleCollapse('emailCard')}
            >
              <div
                className='d-flex justify-content-start align-items-center mr-4'
                style={{ backgroundColor: '#052460', minWidth: '100px' }}
              >
                <MDBIcon
                  icon='envelope'
                  size='2x'
                  className='m-3 white-text'
                />
              </div>
              <div className='pl-3 pr-3'>
                Email Settings
              </div>
              <div className='pl-3 pr-3'>
                <TableBadge
                  iconName={(onboardingChecklist.hasEmailSettings) ? 'check' : 'ban'}
                  description={(onboardingChecklist.hasEmailSettings) ? '' : 'Override Checklist Item'}
                  color={(onboardingChecklist.hasEmailSettings) ? 'success' : 'warning'}
                  onClick={!onboardingChecklist.hasEmailSettings
                    ? (e) => overrideChecklist('hasEmailSettings', 'Email Settings', e)
                    : () => {}}
                  cardName='email-settings'
                />
              </div>
            </MDBCollapseHeader>
            <MDBCollapse id='emailCard' isOpen={currentCard === 'emailCard'}>
              <MDBCardBody className='rgba-black-light white-text z-depth-1' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <div style={{ opacity: '1' }}>
                  <MDBRow className='d-flex justify-content-around pb-3'>
                    <MDBBtn
                      onClick={() => history.push(`/companies/${id}/integrations`)}
                      color='primary'
                      size='lg'
                      id='graph-api-mail-delivery-button'
                      aria-label='Graph API Mail Delivery Button'
                    >Graph API Mail Delivery
                    </MDBBtn>
                  </MDBRow>
                  <MDBRow className='d-flex justify-content-around pb-3'>
                    <MDBBtn
                      onClick={() => history.push(`/companies/${id}/resources#allowlistingGuide`)}
                      color='default'
                      size='lg'
                      id='allowlisting-guide-button'
                      aria-label='Allowlisting Guide Button'
                    >Allowlisting Guide
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => history.push(`/companies/${id}/settings#customHeader`)}
                      color='secondary'
                      size='lg'
                      id='enable-custom-header-button'
                      aria-label='Enable Custom Header Button'
                    >Enable Custom Header
                    </MDBBtn>
                  </MDBRow>
                  <p className='p-md-4 mb-0' style={{ fontSize: '1.3rem', fontWeight: 500 }}>
                    Configure Phin's Graph API Mail Delivery integration
                    that delivers emails directly to employees' Microsoft
                    Outlook Inboxes. Check out Phin's IP Allowlisting guide
                    to ensure this company's firewall does not block Phin's
                    emails. Set a custom header to be attached to emails we send.
                    You can use this value to find and filter our emails in other email products.
                  </p>
                </div>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          {/* Welcome Card */}
          <MDBCard className='mb-3'>
            <MDBCollapseHeader
              className='p-0 z-depth-1'
              tag='h4'
              tagClassName='mb-0 d-flex justify-content-between align-items-center white'
              onClick={() => toggleCollapse('welcomeCard')}
            >
              <div
                className='d-flex justify-content-start align-items-center mr-4'
                style={{ backgroundColor: '#052460', minWidth: '100px' }}
              >
                <MDBIcon
                  icon='paper-plane'
                  size='2x'
                  className='m-3 white-text'
                />
              </div>
              <div className='pl-3 pr-3'>
                Configure Welcome Emails
              </div>
              <div className='pl-3 pr-3'>
                <TableBadge
                  iconName={(onboardingChecklist.hasWelcomedUsers) ? 'check' : 'ban'}
                  description={(onboardingChecklist.hasWelcomedUsers) ? '' : 'Override Checklist Item'}
                  color={(onboardingChecklist.hasWelcomedUsers) ? 'success' : 'warning'}
                  onClick={!onboardingChecklist.hasWelcomedUsers
                    ? (e) => overrideChecklist('hasWelcomedUsers', 'Welcome Users', e)
                    : () => {}}
                  cardName='configure-welcome-emails'
                />
              </div>
            </MDBCollapseHeader>
            <MDBCollapse id='welcomeCard' isOpen={currentCard === 'welcomeCard'}>
              <MDBCardBody className='rgba-black-light white-text z-depth-1' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <div style={{ opacity: '1' }}>
                  <MDBRow className='d-flex justify-content-around pb-3'>
                    <MDBBtn
                      onClick={() => history.push(`/companies/${id}/branding#welcomeEmails`)}
                      color='primary'
                      size='lg'
                      id='enable-automatic-welcome-emails-button'
                      aria-label='Enable Automatic Welcome Emails Button'
                    >Enable Automatic Welcome Emails
                    </MDBBtn>
                    <MDBBtn
                      onClick={() => history.push(`/companies/${id}/users`)}
                      color='secondary'
                      size='lg'
                      id='send-welcome-emails-button'
                      aria-label='Send Welcome Emails Button'
                    >Send Welcome Emails
                    </MDBBtn>
                  </MDBRow>
                  <p className='p-md-4 mb-0' style={{ fontSize: '1.3rem', fontWeight: 500 }}>
                    Enable automatic welcome email sending on the branding page so users get a notification when they are added or imported. The welcome
                    message sent can also be customized on the branding page. If you prefer you can instead send welcome notifications directly
                    to your users manually from the users page.
                  </p>
                </div>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          {/* Users Card */}
          <MDBCard className='mt-3 mb-3'>
            <MDBCollapseHeader
              className='p-0 z-depth-1'
              tag='h4'
              tagClassName='mb-0 d-flex justify-content-between align-items-center white'
              onClick={() => toggleCollapse('userCard')}
            >
              <div
                className='d-flex justify-content-start align-items-center mr-4'
                style={{ backgroundColor: '#052460', minWidth: '100px' }}
              >
                <MDBIcon
                  icon='user-cog'
                  size='2x'
                  className='m-3 white-text'
                />
              </div>
              <div className='pl-3 pr-3'>
                User Management
              </div>
              <div className='pl-3 pr-3'>
                <TableBadge
                  iconName={(onboardingChecklist.hasUsers) ? 'check' : 'ban'}
                  description={(onboardingChecklist.hasUsers) ? '' : 'Override Checklist Item'}
                  color={(onboardingChecklist.hasUsers) ? 'success' : 'warning'}
                  onClick={!onboardingChecklist.hasUsers
                    ? (e) => overrideChecklist('hasUsers', 'User Management', e)
                    : () => {}}
                  cardName='user-management'
                />
              </div>
            </MDBCollapseHeader>
            <MDBCollapse id='userCard' isOpen={currentCard === 'userCard'}>
              <MDBCardBody className='rgba-black-light white-text z-depth-1' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <MDBRow className='d-flex justify-content-around pb-3'>
                  <MDBBtn
                    onClick={() => history.push(`/companies/${id}/integrations/userSync`)}
                    color='primary'
                    size='lg'
                    id='sync-users-with-azure-button'
                    aria-label='Sync Users with Azure Button'
                  >Sync users with Azure
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => history.push(`/companies/${id}/users`)}
                    color='secondary'
                    size='lg'
                    id='manually-import-users-button'
                    aria-label='Manually Import Users Button'
                  >Manually import users
                  </MDBBtn>
                </MDBRow>
                <p className='p-md-4 mb-0' style={{ fontSize: '1.3rem', fontWeight: 500 }}>
                  Turn on Phin's Azure User Sync integration to automatically
                  import employees from this company's Microsoft Azure instance.
                  Available in Azure AD (global service) and
                  Azure AD for US Government.
                  Separately, manually upload users using Phin's CSV upload tool
                  or by adding users individually.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          {/* Campaign Card */}
          <MDBCard className='mb-3'>
            <MDBCollapseHeader
              className='p-0 z-depth-1'
              tag='h4'
              tagClassName='mb-0 d-flex justify-content-between align-items-center white'
              onClick={() => toggleCollapse('campaignCard')}
            >
              <div
                className='d-flex justify-content-start align-items-center mr-4'
                style={{ backgroundColor: '#052460', minWidth: '100px' }}
              >
                <MDBIcon
                  icon='bullhorn'
                  size='2x'
                  className='m-3 white-text'
                />
              </div>
              <div className='pl-3 pr-3'>
                Training and Phishing Campaigns
              </div>
              <div className='pl-3 pr-3'>
                <TableBadge
                  iconName={(onboardingChecklist.hasActiveCampaigns) ? 'check' : 'ban'}
                  description={(onboardingChecklist.hasActiveCampaigns) ? '' : 'Override Checklist Item'}
                  color={(onboardingChecklist.hasActiveCampaigns) ? 'success' : 'warning'}
                  onClick={!onboardingChecklist.hasActiveCampaigns
                    ? (e) => overrideChecklist('hasActiveCampaigns', 'Training and Phishing Campaigns', e)
                    : () => {}}
                  cardName='training-and-phishing-campaigns'
                />
              </div>
            </MDBCollapseHeader>
            <MDBCollapse id='campaignCard' isOpen={currentCard === 'campaignCard'}>
              <MDBCardBody className='rgba-black-light white-text z-depth-1' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <MDBRow className='d-flex justify-content-around pb-3'>
                  <MDBBtn
                    onClick={() => history.push(`/companies/${id}/config-wizard`)}
                    color='primary'
                    size='lg'
                    id='continuous-campaign-creator-button'
                    aria-label='Continuous Campaign Creator Button'
                  >Continuous Campaign Creator
                  </MDBBtn>
                </MDBRow>
                <MDBRow className='d-flex justify-content-around pb-3'>
                  <MDBBtn
                    onClick={() => history.push(`/companies/${id}/training`)}
                    color='default'
                    size='lg'
                    id='training-dashboard-button'
                    aria-label='Training Dashboard Button'
                  >Training Dashboard
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => history.push(`/companies/${id}/campaigns`)}
                    color='secondary'
                    size='lg'
                    id='phishing-dashboard-button'
                    aria-label='Phishing Dashboard Button'
                  >Phishing Dashboard
                  </MDBBtn>
                </MDBRow>
                <p className='p-md-4 mb-0' style={{ fontSize: '1.3rem', fontWeight: 500 }}>
                  Create continuous Training and Phishing campaigns
                  with Phin's Continuous Campaign Creator.
                  Schedule Training Campaigns or manually launch
                  a training module from the Training Dashboard.
                  Schedule continuous or fixed-length Phishing
                  campaigns from the Phishing Dashboard.
                </p>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

        </MDBContainer>
      </MDBCard>
    </MDBContainer>
  )
}

export default CompanyOnboardingChecklist
