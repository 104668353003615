import { Box, Button, Grid, Skeleton } from '@mui/material'
import { IoAdd, IoArrowForwardSharp, IoCaretDown, IoCaretUp, IoEllipseSharp } from 'react-icons/io5'
import PhinBadge from '../PhinBadge'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationUtilities from '../notifications/notificationUtils'
import { createCompanyThunk } from '../../../store/PartnerSlice'
import { useAuth0 } from '@auth0/auth0-react'
import ClientCreator from '../partnersPage/clientCreator'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function CompaniesTable () {
  const history = useHistory()
  const dispatch = useDispatch()

  const { partner, loaders } = useSelector((state) => state.partner)
  const {
    isLoadingPartner, isLoadingCreateCompany
  } = loaders

  const { user } = useAuth0()

  const [tablePageSize, setTablePageSize] = useState(10)
  const [companyTableRows, setCompanyTableRows] = useState([])
  const [companyCreatorModal, setCompanyCreatorModal] = useState(false)

  useEffect(() => {
    if (partner) {
      const newCompanyValues = []

      partner.companies.forEach((company) => {
        const companyRow = { ...company }

        companyRow.isNew = false

        if (companyRow.lastMonthEnrolledUsers === undefined) {
          companyRow.isNew = true
        }

        // Default these values to 0 if they do not exist
        if (companyRow.totalEnrolledUsers === undefined) {
          companyRow.totalEnrolledUsers = 0
        }

        if (companyRow.changeFromLastMonth === undefined) {
          companyRow.changeFromLastMonth = 0
        }

        if (companyRow.percentChangeFromLastMonth === undefined) {
          companyRow.percentChangeFromLastMonth = 0
        }

        if (companyRow.absoluteChangeFromLastMonth === undefined) {
          companyRow.absoluteChangeFromLastMonth = 0
        }

        if (companyRow.absolutePercentChangeFromLastMonth === undefined) {
          companyRow.absolutePercentChangeFromLastMonth = 0
        }

        newCompanyValues.push(companyRow)
      })

      setCompanyTableRows(newCompanyValues)
    }
  }, [partner])

  const handleClientCreate = async () => {
    if (partner.freeTrial) {
      NotificationUtilities.sendWarningMessage(`This feature is unavailable on free tier. Please reach out to ${partner.distributorName} for assistance.`)
    } else {
      setCompanyCreatorModal(true)
    }
  }

  const createClient = async (name) => {
    const companyId = await dispatch(createCompanyThunk({ name, partnerId: partner.id, authId: user.sub }))
    return companyId
  }

  const simpleGenerateArrowNumber = (value, absoluteValue, isPercentage) => {
    const sign = Math.sign(value)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {((sign === 1) && (<IoCaretUp style={{ color: 'green' }} />))}
        {((sign === -1) && (<IoCaretDown style={{ color: 'red' }} />))}
        {isPercentage
          ? Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 }).format(absoluteValue)
          : absoluteValue || 0}

      </Box>
    )
  }

  const companyTableColumns = [
    {
      headerName: 'Name',
      field: 'name',
      width: 300,
      renderCell: (params) => {
        return (
          <>
            {params.row.name}{params.row.isNew && (<span style={{ fontSize: '0.5em' }}><PhinBadge displayString='New' icon={<IoEllipseSharp />} color='var(--phin-blue)' /></span>)}
          </>
        )
      }
    },
    {
      headerName: 'Total Active Users',
      field: 'totalDevices',
      width: 150,
      renderCell: (params) => params.row.totalDevices
    },
    {
      headerName: 'Total Enrolled in Campaigns',
      field: 'totalEnrolledUsers',
      width: 150,
      renderCell: (params) => params.row.totalEnrolledUsers
    },
    {
      headerName: 'Change in Campaign Enrollment',
      field: 'changeFromLastMonth',
      width: 150,
      valueGetter: (params) => { return +params.row.changeFromLastMonth },
      renderCell: (params) => simpleGenerateArrowNumber(params.row.changeFromLastMonth, params.row.absoluteChangeFromLastMonth)
    },
    {
      headerName: 'Percent Change in Campaign Enrollment',
      field: 'percentChangeFromLastMonth',
      width: 150,
      valueGetter: (params) => { return +params.row.percentChangeFromLastMonth },
      renderCell: (params) => simpleGenerateArrowNumber(params.row.percentChangeFromLastMonth, params.row.absolutePercentChangeFromLastMonth, true)
    },
    {
      headerName: '',
      field: 'id',
      renderCell: (params) => generateViewButton(params.id, params.row.name),
      width: 200,
      sortable: false,
      filterable: false,
      disableExport: true
    }
  ]

  const generateViewButton = (companyId, companyName) => {
    return (
      <Button
        id={`partner-dashboard-view-company-button-${companyName}`}
        aria-label='View Company Button'
        sx={{}}
        endIcon={<IoArrowForwardSharp />} onClick={() => { history.push(`/companies/${companyId}`) }}
      >
        View Company
      </Button>
    )
  }

  return (
    <Grid container className='margin-bottom:2'>
      {/* Create Client Modal */}

      {!isLoadingCreateCompany &&
        <ClientCreator
          isOpen={companyCreatorModal}
          createClient={createClient}
          closeModal={() => { setCompanyCreatorModal(false) }}
        />}

      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <h4 className='phin-h4' style={{ textAlign: 'left' }}>Companies</h4>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            id='add-company-button'
            aria-label='Add a Company Button'
            variant='contained'
            startIcon={<IoAdd />}
            disabled={!partner || isLoadingPartner || (partner && partner.freeTrial)}
            onClick={() => {
              handleClientCreate()
            }}
          >
            ADD A COMPANY
          </Button>
        </Grid>
      </Grid>

      {(isLoadingPartner || !partner) && (
        <Skeleton sx={{ bgcolor: '#c4c4c4' }} variant='rectangle' width={1110} height={400} />
      )}

      {(!isLoadingPartner && partner) && (
        <div style={{ width: '100%' }}>
          <DataGrid
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            className='DataGrid'
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }]
              }
            }}
            autoHeight
            rows={companyTableRows}
            columns={companyTableColumns}
            pageSize={tablePageSize}
            onPageSizeChange={(newSize) => setTablePageSize(newSize)}
            rowsPerPageOptions={[10, 25, 50]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  debounceMs: 500,
                  id: 'company-management-table-search',
                  ariaLabel: 'Search'
                },
                printOptions: { disableToolbarButton: true },
                csvOptions: {
                  fileName: `${partner.name}_Companies_Breakdown.csv`,
                  delimiter: ','
                }
              }
            }}
            disableSelectionOnClick
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            disableColumnMenu
          />
        </div>
      )}
    </Grid>
  )
}

export default CompaniesTable
