import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { moveIndexItemIntoOtherArray } from '../utils/arrayTools'

export const templatesSlice = createSlice({
  name: 'templates',
  initialState: {
    sharedTemplates: [],
    userTemplates: [],
    defaultTemplates: [],
    campaignCategories: [],
    sendingDomains: [],
    loaders: {
      isLoadingTemplates: false,
      isLoadingCampaignCategories: false,
      isLoadingSendingDomains: false
    }
  },
  reducers: {
    setIsLoadingTemplates: (state, action) => {
      state.loaders.isLoadingTemplates = action.payload
    },
    setIsLoadingCampaignCategories: (state, action) => {
      state.loaders.isLoadingCampaignCategories = action.payload
    },
    setIsLoadingSendingDomains: (state, action) => {
      state.loaders.isLoadingSendingDomains = action.payload
    },
    addSharedTemplate: (state, action) => {
      state.sharedTemplates = [...state.sharedTemplates, action.payload]
    },
    addUserTemplate: (state, action) => {
      state.userTemplates = [...state.userTemplates, action.payload]
    },
    deleteSharedTemplate: (state, action) => {
      state.sharedTemplates = state.sharedTemplates.filter((template) => action.payload !== template.id)
    },
    deleteUserTemplate: (state, action) => {
      state.userTemplates = state.userTemplates.filter((template) => action.payload !== template.id)
    },
    shareTemplate: (state, action) => {
      const userTemplateIndex = state.userTemplates.map(t => t.id).indexOf(action.payload.oldTemplateId)
      state.userTemplates[userTemplateIndex].shared = true
      state.userTemplates[userTemplateIndex].id = action.payload.newTemplateId
      const [userTemplates, sharedTemplates] = moveIndexItemIntoOtherArray(userTemplateIndex, state.userTemplates, state.sharedTemplates)
      state.userTemplates = userTemplates
      state.sharedTemplates = sharedTemplates
    },
    setSharedTemplates: (state, action) => {
      state.sharedTemplates = action.payload
    },
    setDefaultTemplates: (state, action) => {
      state.defaultTemplates = action.payload
    },
    setUserTemplates: (state, action) => {
      state.userTemplates = action.payload
    },
    setSendingDomains: (state, action) => {
      state.sendingDomains = action.payload
    },
    setCampaignCategories: (state, action) => {
      state.campaignCategories = action.payload
    }
  }
})

export const {
  setIsLoadingTemplates: setIsLoadingTemplatesAction,
  setIsLoadingCampaignCategories: setIsLoadingCampaignCategoriesAction,
  setIsLoadingSendingDomains: setIsLoadingSendingDomainsAction,
  addSharedTemplate: addSharedTemplateAction,
  addUserTemplate: addUserTemplateAction,
  deleteSharedTemplate: deleteSharedTemplateAction,
  deleteUserTemplate: deleteUserTemplateAction,
  shareTemplate: shareTemplateAction,
  setSharedTemplates: setSharedTemplatesAction,
  setDefaultTemplates: setDefaultTemplatesAction,
  setUserTemplates: setUserTemplatesAction,
  setSendingDomains: setSendingDomainsAction,
  setCampaignCategories: setCampaignCategoriesAction
} = templatesSlice.actions

export default templatesSlice.reducer

export const uploadTemplateThunk = (id, { newTemplate, endPoint, templateId }) => {
  return async (dispatch, getState, api) => {
    try {
      const queryString = templateId ? `id=${templateId}` : ''
      const response = await api.post(`/api/${endPoint}/${id}/templates?${queryString}`, newTemplate)
      const { id: newTemplateId } = await response.json()

      if (response.status !== 200) {
        return 500
      }

      newTemplate.id = newTemplateId
      if (newTemplate.shared) {
        dispatch(addSharedTemplateAction(newTemplate))
      } else {
        dispatch(addUserTemplateAction(newTemplate))
      }

      return response.status
    } catch (err) {
      return 500
    }
  }
}

export const cloneTemplateThunk = (id, template) => {
  return async (dispatch, getState, api) => {
    const response = await api.post(`/api/companies/${id}/templates`, template)
    const newTemplate = await response.json()
    template.id = newTemplate.id
    dispatch(addUserTemplateAction(template))
  }
}

export const deleteTemplateThunk = (templateId, orgType, orgId) => {
  return async (dispatch, getState, api) => {
    const response = await api.delete(`/api/${orgType}/${orgId}/templates/${templateId}`)
    if (response.status !== 200) {
      throw (Error('there was an error deleting template'))
    }
    if (orgType === 'partners') {
      dispatch(deleteSharedTemplateAction(templateId))
    } else {
      dispatch(deleteUserTemplateAction(templateId))
    }
    return 200
  }
}

export const shareTemplateThunk = (id, partnerId, template) => {
  return async (dispatch, getState, api) => {
    try {
      const resp = await api.post(`/api/partners/${partnerId}/shares/templates`, { companyId: id, templateId: template.id })
      const { id: newId } = await resp.json()
      dispatch(shareTemplateAction({ oldTemplateId: template.id, newTemplateId: newId }))
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('There was an error sharing your template. Our team has been notified.')
      return
    }
    NotificationUtilities.sendSuccessMessage('Template successfully shared!')
  }
}

export const getTemplatesThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingTemplatesAction(true))
    try {
      const templatesRes = await api.get(`/api/companies/${id}/templates`)
      const { userTemplates, sharedTemplates, defaultTemplates } = await templatesRes.json()

      dispatch(setSharedTemplatesAction(sharedTemplates))
      dispatch(setUserTemplatesAction(userTemplates))
      dispatch(setDefaultTemplatesAction(defaultTemplates))
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingTemplatesAction(false))
  }
}

export const getTemplateCategoriesThunk = (id, orgType) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingTemplatesAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/templates/categories`)
      const campaignCategories = await res.json()

      dispatch(setCampaignCategoriesAction(campaignCategories))
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingTemplatesAction(false))
  }
}

export const getSendingDomainsThunk = (id, orgType) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingSendingDomainsAction(true))
    try {
      const res = await api.get(`/api/companies/${id}/templates/sending-domains`)
      const sendingDomains = await res.json()

      dispatch(setSendingDomainsAction(sendingDomains))
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingSendingDomainsAction(false))
  }
}
