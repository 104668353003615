import {
  MDBCardImage, MDBCardBody, MDBCardTitle, MDBCard,
  MDBCol, MDBIcon, MDBRow, MDBContainer, MDBBtn,
  MDBView, MDBMask, MDBTooltip, MDBCardFooter, MDBCardText
} from 'mdbreact'
import SelectOptions from './components/SelectOptions'
import ValidationModal from './components/ValidationModal'

import { useHistory } from 'react-router'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import { navigationRoutes } from '../frontendConsts.js'

import React, { useState, useEffect } from 'react'

import './css/training.css'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseCatalogThunk, launchTrainingCourseThunk } from '../store/TrainingSlice'

import Spinner from './components/Spinner'
import { CoursePreviewModal } from '../shared/components/CoursePreviewModal'

function renderCourseCard (course, openConfirmModal, togglePreviewModal) {
  return (
    <MDBCol key={course.id} className='d-flex justify-content-center mt-4'>
      <MDBView rounded zoom className=' h-100 preset-overlay' hover>

        <MDBCard className='h-100' style={{ width: '22rem' }}>
          <MDBCardImage
            top
            overlay={false}
            waves={false}
            src={course.thumbnail || 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg'}
          />
          <MDBCardBody>
            <MDBCardTitle>{course.name}
            </MDBCardTitle>
            <MDBCardText>
              {course.description}
            </MDBCardText>
          </MDBCardBody>
          <MDBCardFooter className='d-flex justify-content-between'>
            <span className='text-uppercase'>{course.provider}</span>
            <span>{course.topic}</span>
          </MDBCardFooter>
        </MDBCard>
        <MDBMask className='flex-center' overlay='blue-strong'>
          <MDBTooltip style={{ zIndex: 2 }} className='action-button' placement='top'>
            <MDBBtn
              floating
              tag='a'
              className='launch-button'
              id={`launch-training-${course.name}-button`}
              aria-label='Launch Training Button'
              color='secondary'
              action
              onClick={() => {
                openConfirmModal(course)
              }}
            >
              <MDBIcon icon='rocket' />
            </MDBBtn>
            <div>
              Launch Training Module
            </div>
          </MDBTooltip>
          <MDBTooltip style={{ zIndex: 2 }} className='action-button' placement='top'>
            <MDBBtn
              floating
              tag='a'
              className='launch-button'
              id={`preview-training-${course.name}-button`}
              aria-label='Preview Training Button'
              color='default'
              action
              onClick={() => {
                togglePreviewModal(course, true)
              }}
            >
              <MDBIcon far icon='eye' />
            </MDBBtn>
            <div>
              Preview Training Module
            </div>
          </MDBTooltip>
        </MDBMask>
      </MDBView>
    </MDBCol>
  )
}

function renderCourses (courseCatalog, selectedTopic, selectedProvider, openConfirmModal, togglePreviewModal) {
  const courseArray = []
  courseCatalog.forEach((course) => {
    if ((selectedTopic === 'All' || (course.topic && selectedTopic.toLowerCase() === course.topic.toLowerCase())) &&
      (selectedProvider === 'All' || (course.provider && selectedProvider.toLowerCase() === course.provider.toLowerCase()))) {
      courseArray.push(renderCourseCard(course, openConfirmModal, togglePreviewModal))
    }
  })

  return courseArray
}

function CatalogPage ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { trainings, courseCatalog, loaders: trainingLoaders } = useSelector((state) => state.trainings)
  const { isLoadingTrainings, isLoadingCourseCatalog } = trainingLoaders

  const [confirmModal, showConfirmModal] = useState(false)
  const [selectedCourse, selectCourse] = useState(null)
  const [selectedTopic, setSelectedTopic] = useState('All')
  const [selectedProvider, setSelectedProvider] = useState('All')
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewModalHeader, setPreviewModalHeader] = useState('')
  const [course, setCourse] = useState(null)
  const [topics, setTopics] = useState([
    {
      selected: true,
      disabled: false,
      icon: null,
      value: 'All',
      text: 'All'
    }
  ])
  const [providers, setProviders] = useState([
    {
      selected: true,
      disabled: false,
      icon: null,
      value: 'All',
      text: 'All'
    }
  ])

  useEffect(() => {
    dispatch(getCourseCatalogThunk({ companyId }))
  }, [])

  useEffect(() => {
    if (courseCatalog) {
      const topicsSet = new Set()
      const providersSet = new Set()
      const topicsArray = [{
        selected: true,
        disabled: false,
        icon: null,
        value: 'All',
        text: 'All'
      }]
      const providersArray = [{
        selected: true,
        disabled: false,
        icon: null,
        value: 'All',
        text: 'All'
      }]

      courseCatalog.forEach((course) => {
        if (course.topic && course.topic !== '') {
          topicsSet.add(course.topic)
        }
        if (course.provider && course.provider !== '') {
          providersSet.add(course.provider)
        }
      })

      topicsSet.forEach((topic) => {
        topicsArray.push({
          selected: false,
          disabled: false,
          icon: null,
          value: topic,
          text: topic
        })
      })

      providersSet.forEach((provider) => {
        providersArray.push({
          selected: false,
          disabled: false,
          icon: null,
          value: provider,
          text: provider
        })
      })

      setTopics(topicsArray)
      setProviders(providersArray)
    }
  }, [courseCatalog])

  function handleTrainingCreatorPress () {
    if (company.freeTrial) {
      NotificationUtilities.sendWarningMessage('This feature is unavailable on free tier.')
    } else {
      history.push(`/companies/${companyId}${navigationRoutes.TRAININGCREATOR}`)
    }
  }

  async function launchTraining () {
    NotificationUtilities.sendInfoMessage('Starting course...')
    await dispatch(launchTrainingCourseThunk({ companyId, selectedCourse, history }))
    cancelTrainingLaunch()
  }

  function openConfirmModal (course) {
    selectCourse(course)
    showConfirmModal(true)
  }

  function cancelTrainingLaunch () {
    selectCourse(null)
    showConfirmModal(false)
  }

  function togglePreviewModal (course, shouldShowPreviewModal) {
    setCourse(course)
    setPreviewModalHeader(course === null ? '' : course.name)
    setShowPreviewModal(shouldShowPreviewModal)
  }

  return (
    <MDBContainer>
      <NotificationWrapper />

      <ValidationModal
        isOpen={confirmModal}
        toggleFunction={() => cancelTrainingLaunch()}
        messageJSX={<p>Are you sure you want to schedule this course?</p>}
        falseOptionText='Cancel'
        trueOptionText='Start'
        handlerFunction={launchTraining}
      />

      <CoursePreviewModal
        companyId={companyId}
        courseDefinition={course}
        previewModalHeader={previewModalHeader}
        showPreviewModal={showPreviewModal}
        togglePreviewModal={togglePreviewModal}
      />

      <MDBRow className='d-flex justify-content-center'>
        <h1>Training Courses Catalog</h1>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center'>
        <p>View our available course selection here as well as launch new trainings for your employees</p>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center mb-5'>
        <MDBCol size='12' className='d-flex justify-content-center align-items-center'>
          <MDBBtn onClick={() => handleTrainingCreatorPress()} style={!company || company.freeTrial ? { opacity: '0.6' } : {}} color={!company || company.freeTrial ? 'grey' : 'primary'} size='lg'>
            <MDBRow className=' d-flex justify-content-center align-items-center'>
              <MDBCol size='2' className='mr-2 pl-0'>
                <MDBIcon size='3x' icon='plus-square' />
              </MDBCol>
              <MDBCol className='px-1' id='startNewCampaignBtn' size='9'>
                Upload an intro video
              </MDBCol>
            </MDBRow>
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      {(!company || isLoadingCompany || !trainings ||
        isLoadingTrainings || !courseCatalog || isLoadingCourseCatalog) && (
          <Spinner message='Loading Course Catalog' />
      )}

      {(company && !isLoadingCompany &&
        trainings && !isLoadingTrainings &&
        courseCatalog && !isLoadingCourseCatalog) && (
          <>
            <MDBRow className='d-flex justify-content-around align-items-center'>
              <SelectOptions
                label='Course Topic'
                name='Course Topic'
                onChange={(e) => setSelectedTopic(e.target.value)}
                options={topics}
              />
              <SelectOptions
                label='Provider'
                name='Provider'
                onChange={(e) => setSelectedProvider(e.target.value)}
                options={providers}
              />
            </MDBRow>

            <MDBRow>
              {renderCourses(courseCatalog, selectedTopic, selectedProvider, openConfirmModal, togglePreviewModal)}
            </MDBRow>
          </>
      )}

    </MDBContainer>
  )
}

export default CatalogPage
