import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const partnerIntegrationsSlice = createSlice({
  name: 'partnerIntegrations',
  initialState: {
    partnerIntegrationsMap: null,
    loaders: {
      isLoadingPartnerIntegrations: false
    }
  },
  reducers: {
    setIsLoadingPartnerIntegrations: (state, action) => {
      state.loaders.isLoadingPartnerIntegrations = action.payload
    },
    setPartnerIntegrationsMap: (state, action) => {
      const newMap = {}
      for (const partnerIntegration of action.payload) {
        newMap[partnerIntegration.id] = partnerIntegration
      }

      state.partnerIntegrationsMap = newMap
    },
    removePartnerIntegration: (state, action) => {
      const newMap = { ...state.partnerIntegrationsMap }
      delete newMap[action.payload]

      state.partnerIntegrationsMap = newMap
    }
  }
})

export const {
  setIsLoadingPartnerIntegrations: setIsLoadingPartnerIntegrationsAction,
  setPartnerIntegrationsMap: setPartnerIntegrationsMapAction,
  removePartnerIntegration: removePartnerIntegrationAction
} = partnerIntegrationsSlice.actions

export default partnerIntegrationsSlice.reducer

export const getPartnerIntegrationsThunk = (partnerId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerIntegrationsAction(true))
    try {
      const response = await api.get(`/api/partners/${partnerId}/integrations/`)
      if (response.status === 200) {
        const partnerIntegrations = await response.json()
        dispatch(setPartnerIntegrationsMapAction(partnerIntegrations))
      } else {
        const statusObj = await response.json()

        setTimeout(() => {
          NotificationUtilities.sendErrorMessage(statusObj.error)
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Partner Integrations. Please try again or contact Phin Support for assistance.')
      }, 0)
    }
    dispatch(setIsLoadingPartnerIntegrationsAction(false))
  }
}
