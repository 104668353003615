import { Alert, Button, Card, Grid, Skeleton } from '@mui/material'
import { IoArrowForward, IoCard } from 'react-icons/io5'
import { useSelector } from 'react-redux'

export function PaymentInfoWidget () {
  const { partner, loaders } = useSelector((state) => state.partner)
  const { isLoadingPartner } = loaders

  const { partnerBillingDetails, loaders: partnerBillingLoaders } = useSelector((state) => state.partnerBilling)
  const { isLoadingPartnerBillingDetails } = partnerBillingLoaders

  return (
    <div className='margin-bottom:2'>
      {((isLoadingPartner && !partner) || (isLoadingPartnerBillingDetails && !partnerBillingDetails)) && (
        <Skeleton sx={{ bgcolor: '#c4c4c4', minWidth: 200 }} variant='rectangle' height={242} />
      )}

      {(!isLoadingPartner && partner && !isLoadingPartnerBillingDetails && partnerBillingDetails) && (

        <Card variant='outlined' sx={{ minWidth: 200, minHeight: 242, padding: 'var(--phin-s0)' }}>
          <div className='phin-h4 margin-bottom:1'>Payment Info</div>

          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='center'
          >
            {(!partnerBillingDetails.paymentInfo) && (
              <Grid item sx={{ margin: 'var(--phin-s0)' }}>
                <Alert severity='warning' variant='outlined' sx={{ background: 'var(--phin-light-yellow)' }}>
                  Please configure Payment Method via ChargeBee below.
                </Alert>
              </Grid>
            )}
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              {partnerBillingDetails.paymentInfo && (
                <Grid item>
                  <IoCard className='margin-right:-1' size='3em' style={{ color: 'var(--phin-gray)' }} />
                  <span className='phin-h6 margin-right:-1'>{partnerBillingDetails.paymentInfo.card}</span>
                  <span>{partnerBillingDetails.paymentInfo.expirationMonth}/{partnerBillingDetails.paymentInfo.expirationYear}</span>
                </Grid>
              )}

              {partnerBillingDetails.billingAddress && (
                <Grid item>
                  <div>{partnerBillingDetails.billingAddress.line1}</div>
                  {partnerBillingDetails.billingAddress.line2 && (
                    <div>{partnerBillingDetails.billingAddress.line2}</div>
                  )}
                  <div>{partnerBillingDetails.billingAddress.city}, {partnerBillingDetails.billingAddress.state_code} {partnerBillingDetails.billingAddress.zip} {partnerBillingDetails.billingAddress.country}</div>
                </Grid>
              )}
            </Grid>

            <Grid item sx={{ marginTop: 'var(--phin-s3)' }}>
              <Button
                id='manage-payment-information-button'
                aria-label='Manage Payment Information'
                endIcon={<IoArrowForward />}
                href={process.env.REACT_APP_CHARGEBEE_PORTAL_LINK}
                target='_blank'
              > Manage Payment Information
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
    </div>
  )
}
