import React, { Component } from 'react'
import './Grade.css'
import {
  MDBIcon,
  MDBTooltip
} from 'mdbreact'

import { farthestCampaignendTime, getEndOfYearInSeconds } from './utils'

class Grade extends Component {
  tooltipContent ({ gradeCode }) {
    // generate text for each tooltip and check off each criteria that has already been met
    const contents = [
      { text: 'Have campaigns set up for the rest of the year' },
      { text: 'Have at least one active campaign' }]
      .map((content, i) => {
        const header = i >= 1 ? <hr /> : ''
        return (
          <div key={i}>
            {header}
            {gradeCode <= i ? <><del>{content.text}</del> <MDBIcon icon='check' /></> : content.text}
          </div>
        )
      })
    return (
      contents
    )
  }

  calculateGrade (activeCampaigns) {
    if (!activeCampaigns || activeCampaigns.length === 0) {
      return { gradeStr: 'C+', color: 'danger', gradeCode: 2 }
    } else if (activeCampaigns.length > 0 && (getEndOfYearInSeconds(new Date()) > farthestCampaignendTime(activeCampaigns))) {
      return { gradeStr: 'B+', color: 'warning', gradeCode: 1 }
    }
    return { gradeStr: 'A+', color: 'success', gradeCode: 0 }
  }

  render () {
    const gradeObj = this.calculateGrade(this.props.activeCampaigns)
    return (
      <>
        <MDBTooltip
          placement='bottom'
          material
          domElement
        >
          <div className='nav-link'>
            {this.props.activeCampaigns &&
              <span className={`rounded-grade btn-outline-${gradeObj.color}`}>{gradeObj.gradeStr}</span>}
          </div>
          <div>
            {this.tooltipContent(gradeObj)}
          </div>
        </MDBTooltip>
      </>
    )
  }
}

export default Grade
