import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Select, Switch, MenuItem, Box } from '@mui/material'
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBIcon } from 'mdbreact'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import { generateReportThunk } from '../../../store/ReportGeneratorSlice'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import SharedBasicModal from '../SharedBasicModal'
import Spinner from '../Spinner'

const ReportGenerator = ({
  companyId
}) => {
  const dispatch = useDispatch()
  const [includeHistoricalData, setIncludeHistoricalData] = useState(false)
  const [includeUsersToWatch, setIncludeUsersToWatch] = useState(false)
  const [includePhishingCSV, setIncludePhishingCSV] = useState(false)
  const [includeTrainingCSV, setIncludeTrainingCSV] = useState(false)
  const [includePhishingReport, setIncludePhishingReport] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [deliveryEmails, setDeliveryEmails] = useState([])
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('monthly')
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const { loaders } = useSelector(state => state.reportGenerator)
  const { isLoadingReportGenerationRequest } = loaders

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick' && isLoadingReportGenerationRequest) { return }
    setOpen(false)
  }
  const addEmail = async () => {
    if (ValidationUtils.isValidEmail(emailInput) && !deliveryEmails.includes(emailInput)) {
      setDeliveryEmails([...deliveryEmails, emailInput])
      setEmailInput('')
    } else {
      NotificationUtilities.sendWarningMessage('Please enter a valid email address.')
    }
  }

  const generateReport = async () => {
    const options = { includeHistoricalData, includeUsersToWatch, includePhishingCSV, includeTrainingCSV, includePhishingReport }
    const succeeded = await dispatch(generateReportThunk({ companyId, deliveryEmails, options, type }))
    if (succeeded) {
      setOpen(false)
      setConfirmationModalOpen(true)
    }
  }

  const removeEmail = async (email) => {
    setDeliveryEmails(deliveryEmails.filter(thisEmail => thisEmail !== email))
  }

  const generateReportViewerList = () => {
    const reportViewersList = []
    if (deliveryEmails && deliveryEmails.length > 0) {
      deliveryEmails.forEach((deliveryEmail, idx) => {
        reportViewersList.push(
          <MDBRow key={idx} className='d-flex justify-content-around align-items-center'>
            <MDBCol size='8'>
              <h5 className='align-self-center'>{deliveryEmail}</h5>
            </MDBCol>

            <MDBCol size='3'>
              <MDBRow className='d-flex justify-content-center'>
                <MDBBtn
                  onClick={() => removeEmail(deliveryEmail)}
                  rounded
                  className='align-items-center'
                  type='submit'
                  size='sm'
                  color='danger'
                  style={{ marginLeft: '10px' }}
                >
                  <MDBIcon
                    size='md'
                    color='blue'
                    icon='trash-alt'
                  />
                </MDBBtn>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )
      })
    } else {
      reportViewersList.push(
        <h5 key='1'>No Selected Recipients</h5>
      )
    }

    return reportViewersList
  }

  return (
    <>
      <Button
        variant='contained'
        sx={{ marginBottom: '1em' }}
        id='generate-report-button'
        aria-label='Generate Report Button'
        onClick={handleClickOpen}
      >
        Generate Report
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        maxWidth={false}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Report Generator
        </DialogTitle>
        <DialogContent dividers>
          {isLoadingReportGenerationRequest
            ? <Spinner message='Working on your report request' />
            : (
              <Grid sx={{ margin: 6 }}>
                <p>
                  Generate an Ad-Hoc Report using custom settings with optional delivery to multiple recipients. Choose options for the custom delivery including a list of recipients. Standard configured report viewers will not receive this report, only those selected for this unique generation. If no emails are specified, the report will be saved in the Reporting History table only.
                </p>
                <h4>Options:</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p>Report Recipients</p>
                    <MDBRow className='d-flex justify-content-around align-items-center'>
                      {generateReportViewerList()}
                    </MDBRow>
                    <MDBRow className='d-flex justify-content-around align-items-center'>
                      <MDBCol size='8'>
                        <MDBInput
                          id='report-generator-email-address-field'
                          aria-label='Report Generator Email Address Field'
                          className='my-0 pb-0'
                          outline
                          icon='envelope'
                          value={emailInput}
                          onChange={(e) => setEmailInput(e.target.value)}
                          label='Email Address'
                          size='lg'
                        />
                      </MDBCol>
                      <MDBCol size='3' className='d-flex justify-content-center'>
                        <MDBBtn
                          id='report-generator-add-email-button'
                          aria-label='Report Generator Add Email Button'
                          onClick={() => addEmail()}
                        >
                          Add
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </Grid>
                  <Grid item xs={6}>
                    Include "Historical Data" Table
                    <Switch
                      id='toggleIncludeHistoricalData' checked={includeHistoricalData} onClick={() => { setIncludeHistoricalData(!includeHistoricalData) }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Include "Users To Watch" Table
                    <Switch id='toggleIncludeUsersToWatch' checked={includeUsersToWatch} onClick={() => { setIncludeUsersToWatch(!includeUsersToWatch) }} />
                  </Grid>
                  <Grid item xs={6}>
                    Include CSV output of raw phishing data
                    <Switch id='toggleIncludePhishingCSV' checked={includePhishingCSV} onClick={() => setIncludePhishingCSV(!includePhishingCSV)} />
                  </Grid>
                  <Grid item xs={6}>
                    Include CSV output of training data
                    <Switch id='toggleIncludeTrainingCSV' checked={includeTrainingCSV} onClick={() => setIncludeTrainingCSV(!includeTrainingCSV)} />
                  </Grid>
                  {type === 'monthly' && (
                    <Grid item xs={6}>
                      Include Phishing Report PDF
                      <Switch id='togglemonthlyPhishingPDFReport' checked={includePhishingReport} onClick={() => setIncludePhishingReport(!includePhishingReport)} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    Report Type to generate:
                    <Select
                      id='select'
                      value={type}
                      onChange={(event) => { setType(event.target.value) }}
                      sx={{ marginLeft: '1rem' }}
                      displayEmpty
                    >
                      <MenuItem value='monthly'>Monthly - Last Month's Monthly Report</MenuItem>
                      <MenuItem value='weekly'>Weekly - Current Month Progress Report</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoadingReportGenerationRequest} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            id='report-generator-generate-now-button'
            aria-label='Report Generator Generate Now Button'
            disabled={isLoadingReportGenerationRequest}
            autoFocus
            onClick={generateReport}
          >
            Generate Now
          </Button>
        </DialogActions>
      </Dialog>
      <SharedBasicModal
        width='40%'
        onClose={() => {
          setConfirmationModalOpen(false)
          setIncludeHistoricalData(false)
          setIncludeUsersToWatch(false)
          setIncludePhishingCSV(false)
          setIncludeTrainingCSV(false)
          setIncludePhishingReport(false)
          setDeliveryEmails([])
          setType('monthly')
          setEmailInput('')
        }}
        open={confirmationModalOpen}
        Content={
          <Box>
            <h3>Report Generation Confirmed</h3>
            <p>Your report will be generated in a few minutes, and will also appear in the reporting history table after it has been created.</p>
            {deliveryEmails.length > 0 ? <p>It will be sent to the following emails: {deliveryEmails.join(', ')}</p> : <p>It will not be sent to any user.</p>}
          </Box>
          }
      />
    </>
  )
}

export default ReportGenerator
