import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { integrationDisconnectedAction } from './IntegrationsSlice'

export const spamFilterBypassSlice = createSlice({
  name: 'spamFilterBypass',
  initialState: {
    loaders: {
      isLoadingSpamFilterBypassStatus: false
    }
  },
  reducers: {
    loadingSpamFilterBypassStatusUpdated: (state, action) => {
      state.loaders.isLoadingSpamFilterBypassStatus = action.payload
    }
  }
})

const {
  loadingSpamFilterBypassStatusUpdated: loadingSpamFilterBypassStatusUpdatedAction
} = spamFilterBypassSlice.actions

export default spamFilterBypassSlice.reducer

export const disconnectSpamFilterBypassThunk = ({ companyId, history }) => {
  return async (dispatch, getState, api) => {
    dispatch(loadingSpamFilterBypassStatusUpdatedAction(true))

    try {
      const response = await api.delete(`/api/companies/${companyId}/integrations/spamFilterBypass/`)

      if (response.status === 200) {
        const { removedIntegrationId } = await response.json()
        dispatch(integrationDisconnectedAction(removedIntegrationId))

        history.push(`/companies/${companyId}/integrations`)
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to disconnect Graph API Mail Delivery. Please try again or contact Phin Support if issue persists.')
        }, 0)
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to disconnect Graph API Mail Delivery. Please try again or contact Phin Support if issue persists.')
      }, 0)
    }

    dispatch(loadingSpamFilterBypassStatusUpdatedAction(false))
  }
}
