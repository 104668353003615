import React, { useRef } from 'react'
import {
  MDBRow, MDBCol, MDBCard, MDBIcon, MDBInput,
  MDBCardBody, MDBBtn, MDBTooltip
} from 'mdbreact'
import { useMountEffect } from '../../../utils/SettingsUtils'
import { phishTestHeaderText } from '../../../frontendConsts.js'
import { Grid, Switch } from '@mui/material'

const SendingSettings = ({
  phishTestHeader,
  setPhishTestHeader,
  customSendingHeader,
  setCustomSendingHeader,
  shouldFocusSendingSettings
}) => {
  const customHeaderRef = useRef(null)

  const executeScroll = () => {
    if (shouldFocusSendingSettings) {
      customHeaderRef.current.scrollIntoView()
    }
  }

  useMountEffect(executeScroll)

  return (
    <MDBRow className='my-4 d-flex justify-content-center'>
      <MDBCol xl='12' md='12' className='mb-5'>
        <MDBCard cascade className='cascading-admin-card hud-stats'>
          <div className='d-flex justify-content-between'>
            <div className='admin-up'>
              <MDBIcon icon='envelope-open' className='primary-color mr-3 z-depth-2' />
            </div>
            <div className='d-flex justify-content-end align-items-center' style={{ marginTop: '15px' }} ref={customHeaderRef}>
              <h4><strong>Sending Settings </strong></h4>
              <div className='pl-2' style={{ zIndex: 10 }}>
                <MDBTooltip
                  placement='bottom'
                  id='description-popover'
                  material
                >
                  <MDBBtn
                    color='elegant'
                    className='my-0'
                    floating
                    tag='a'
                    size='sm'
                  >
                    <MDBIcon icon='question' />
                  </MDBBtn>
                  <div>
                    Define a custom header to be attached to emails sent by Phin.
                    You can use this value to find and filter Phin's emails in other email products.
                  </div>
                </MDBTooltip>
              </div>
            </div>
          </div>
          <MDBCardBody className='mt-4'>
            <MDBRow>
              <MDBCol size='3'>
                <MDBRow className='d-flex justify-content-center align-items-center mt-4'>
                  <Grid item xs={6}>
                    <Switch
                      id='phish-test-header-toggle'
                      aria-label='Phish Test Header Toggle'
                      checked={phishTestHeader}
                      onClick={() => setPhishTestHeader()}
                    />
                  </Grid>
                </MDBRow>
                <MDBRow className='d-flex justify-content-center align-items-center'>
                  <p>Phish Test Header</p>
                </MDBRow>
              </MDBCol>
              <MDBCol size='9'>
                {phishTestHeader &&
                  <MDBInput className='overflow-auto overflow-scroll d-flex justify-content-center align-items-center' outline type='textarea' rows='2' label='PhishTest Header' value={`${phishTestHeaderText.name} : ${phishTestHeaderText.value}`} />}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size='3' className='d-flex justify-content-center align-items-center'>
                <MDBBtn
                  id='generate-header-button'
                  aria-label='Generate Header Button'
                  color='info'
                  onClick={setCustomSendingHeader}
                >Generate
                </MDBBtn>
              </MDBCol>
              <MDBCol size='9'>
                <MDBInput
                  id='custom-header-text'
                  aria-label='Custom Header Text'
                  className='d-flex justify-content-center align-items-center'
                  outline
                  label='Custom Header'
                  value={customSendingHeader.name !== '' ? `${customSendingHeader.name} : ${customSendingHeader.value}` : 'No Custom Header'}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>

  )
}

export default SendingSettings
