import React, { Component } from 'react';
import ViewerModal from '../ViewerModal'
import {
	MDBContainer,
	MDBModal,
	MDBDataTable,
	MDBBtn,
	MDBIcon,
	MDBModalBody,
	MDBTooltip
} from 'mdbreact'

import ActionButton from './ActionButton';

class TemplatesPreviewer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			viewerModal: false,
			previewTemplate: {}
		}
	}

	handleToggleViewerModal = (template) => {
		this.setState({
			viewerModal: !this.state.viewerModal,
			previewTemplate: template
		})
	}

	generateOptions = (templates) => {

		let templateOptions = []
		templates.forEach((template) => {
			let templateObj = {}
			templateObj.name = template.name
			templateObj.viewer = <ActionButton title='View' outline onClick={() => { this.handleToggleViewerModal(template) }} icon='eye'/>
			templateOptions.push(templateObj)
		})
		return templateOptions
	}

	renderTemplatesTable = (templates) => {
		if (templates.length === 0) {
			return (<h2 className='p-1 d-flex justify-content-center'>No Templates to Display!</h2>)
		}

		const templatesColumns = [
			{
				'label': 'Name',
				'field': 'name',
				'sort': 'asc'
			},
			{
				'label': 'View template',
				'field': 'viewer'
			}
		];

		let templatesData = {
			columns: templatesColumns,
			rows: this.generateOptions(templates)
		}

		return (<MDBDataTable disableRetreatAfterSorting className='p-1' data={templatesData} nobottomcolumns='true' paging={false} searching={false} sortable={false} />)
	}

	render() {


		const {
			templates,
			isOpen,
			toggleModal
		} = this.props

		return (
			<MDBContainer>
				<MDBModal className='p-1' size='md' isOpen={isOpen} toggle={toggleModal}>
					<MDBModalBody>
						<ViewerModal isOpen={this.state.viewerModal} toggleViewerModal={this.handleToggleViewerModal} template={this.state.previewTemplate}></ViewerModal>
						{this.renderTemplatesTable(templates)}
					</MDBModalBody>
				</MDBModal>
			</MDBContainer>
		);
	}
}

export default TemplatesPreviewer;