import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMessagesThunk } from '../store/MessagesSlice'
import Spinner from './components/Spinner'
import NotificationWrapper from './components/notifications/notificationWrapper'
import './css/OutgoingMessagesPage.css'
import { DateTime } from 'luxon'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { capitalizeFirstLetter } from '../utils/FormattingUtils'
import { campaignTypes } from '../frontendConsts.js'

const OutgoingMessagesPage = ({ id }) => {
  const dispatch = useDispatch()
  const { failedMessages, outgoingMessages, loaders } = useSelector(state => state.messages)
  const { isLoadingMessages } = loaders

  useEffect(() => {
    async function getMessages () {
      dispatch(getMessagesThunk(id))
    }

    getMessages()
  }, [])

  const renderMessageType = (params) => {
    if (params.row.messageType) {
      return capitalizeFirstLetter(params.row.messageType)
    } else {
      if (params.row.campaignInfo.campaignType === campaignTypes.FIXEDLENGTH ||
        params.row.campaignInfo.campaignType === campaignTypes.CONTINUOUS) {
        return 'Phishing'
      } else {
        return capitalizeFirstLetter(params.row.campaignInfo.campaignType)
      }
    }
  }

  const outgoingMessagesColumns = [
    { field: 'userEmail', headerName: 'Email', flex: 1, valueGetter: (params) => { return params.row.user.email } },
    { field: 'userFirst', headerName: 'First Name', flex: 0.8, valueGetter: (params) => { return params.row.user.first } },
    { field: 'userLast', headerName: 'Last Name', flex: 0.8, valueGetter: (params) => { return params.row.user.last } },
    { field: 'sendAt', headerName: 'Scheduled Send Time', flex: 0.8, renderCell: (field) => { return DateTime.fromISO(field.value).toLocaleString(DateTime.DATETIME_SHORT) } },
    { field: 'campaignName', headerName: 'Campaign Name', flex: 0.8, valueGetter: (params) => { return params.row.campaignInfo?.name || 'N/A' } },
    { field: 'messageType', headerName: 'Message Type', flex: 0.8, valueGetter: (params) => { return renderMessageType(params) } }
  ]

  return (
    <>
      <NotificationWrapper />

      <div className='logs-container screen-pad-80 padding-left:1'>
        <div className='margin-top:2 margin-bottom:2'>
          <h3 className='margin-bottom:1'>Failed Messages </h3>

          {(isLoadingMessages || !failedMessages) && (
            <Spinner />
          )}

          {(!isLoadingMessages && failedMessages) && (
            <DataGrid
              autoHeight
              style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
              className='DataGrid'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'creationDate', sort: 'desc' }]
                }
              }}
              rows={failedMessages}
              columns={outgoingMessagesColumns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true }
                }
              }}
              disableColumnSelector
              disableDensitySelector
            />
          )}
        </div>
        <div className='margin-top:2 margin-bottom:2 padding-bottom:2'>
          <h3 className='margin-bottom:1'>Scheduled Outgoing Messages </h3>

          {(isLoadingMessages || !outgoingMessages) && (
            <Spinner />
          )}

          {(!isLoadingMessages && outgoingMessages) && (
            <Box sx={{ width: '100%', height: 'auto' }}>
              <DataGrid
                autoHeight
                style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                className='DataGrid'
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'creationDate', sort: 'desc' }]
                  }
                }}
                rows={outgoingMessages}
                columns={outgoingMessagesColumns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                disableColumnSelector
                disableDensitySelector
              />
            </Box>
          )}
        </div>
      </div>
    </>
  )
}
export default OutgoingMessagesPage
