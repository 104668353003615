import { MDBBtn, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from 'mdbreact'
import MomentUtils from '@date-io/moment'
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export default function WelcomeUsersModal ({
  toggleFunction,
  handlerFunction,
  userCount
}) {
  const today = moment()

  const { welcomeEmailBody, welcomeEmailSubject, welcomeEmailFromName } = useSelector((state) => state.welcomeEmail)
  const { logoUrl } = useSelector((state) => state.logo)

  const [sendDate, setSendDate] = useState(today)
  const [sendTime, setSendTime] = useState(today)

  function updateDate (selectedDate) {
    setSendDate(selectedDate)
  }

  function updateTime (selectedTime) {
    setSendTime(selectedTime)
  }

  function confirmHandler () {
    const completeDate = moment(sendDate)
    completeDate.hour(sendTime.hours())
    completeDate.minute(sendTime.minutes())
    handlerFunction(completeDate.toDate())
  }

  return (
    <>
      <MDBModalHeader>
        Welcome Users
      </MDBModalHeader>

      <MDBModalBody className='d-flex justify-content-center'>
        <MDBRow className='pt-4 pb-4 w-100 d-flex flex-column justify-content-center align-items-center'>
          <div>
            <h5>Schedule when these welcome emails send below</h5>
          </div>
          <div className='d-flex justify-content-center w-75 mt-4 mb-4'>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <div>
                <DatePicker
                  disableToolbar
                  variant='inline'
                  format='MM/dd/yyyy'
                  margin='normal'
                  id='date-picker-inline'
                  label='Send Date'
                  minDate={today}
                  value={sendDate}
                  onChange={updateDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className='pl-3'>
                <TimePicker
                  label='Send Time'
                  onChange={updateTime}
                  value={sendTime}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          </div>

          <div className='welcomeModalPreviewContainer'>
            <div className='emailPreviewGroup'>
              <div className='emailPreviewPaneGroup'>
                <div className='emailPreviewPane'>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingLeft: '16px', paddingTop: '1em', borderBottom: '1px solid' }}>
                    <p><b>From: </b> {welcomeEmailFromName}</p>
                    <p><b>Subject: </b>{welcomeEmailSubject}</p>
                  </div>
                  <div className='emailHeader'>
                    <img
                      alt=''
                      src={logoUrl}
                    />
                  </div>
                  <div className='emailBody'>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{welcomeEmailBody}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pb-2 pt-2'>
            <h5>
              {userCount === 1 && (
                <>
                  <strong>{userCount}</strong> user will be sent this welcome email
                </>
              )}
              {userCount > 1 && (
                <>
                  <strong>{userCount}</strong> users will be sent this welcome email
                </>
              )}
            </h5>
          </div>
        </MDBRow>
      </MDBModalBody>

      <MDBModalFooter>
        <MDBBtn id='deny-validation-button' value='cancel' color='red' onClick={toggleFunction}>Cancel</MDBBtn>
        <MDBBtn id='confirm-validation-button' type='submit' color='primary' onClick={confirmHandler}>Confirm</MDBBtn>
      </MDBModalFooter>
    </>
  )
}
