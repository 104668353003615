import React, { Component } from 'react'

import {
  MDBCardBody,
  MDBCard,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput
} from 'mdbreact'

import { processFromField } from '../../utils/templateUtils'

class ViewerModal extends Component {
  renderIframe (template, height) {
    if (template.videoSource !== undefined) {
      return (<iframe style={{ borderColor: '#fff', boxShadow: 'none' }} title='Viewer' height={`${height}px`} src={template.videoSource} />)
    }

    if (template.html !== undefined) {
      return (<iframe title='Viewer' style={{ borderColor: '#fff', boxShadow: 'none' }} height={`${height}px`} srcDoc={template.html} />)
    }
  }

  renderSMS (template) {
    return (
      <MDBCardBody>
        <MDBInput
          outline
          name='sms-message'
          label='Message'
          labelClass='text-dark'
          type='textarea'
          valueDefault={template.message}
          rows='10'
          disabled
        />
      </MDBCardBody>
    )
  }

  renderView (template) {
    const iframeHeightInPixels = window.innerHeight / 1.8
    if (template) {
      if (template.channel === 'email') {
        return this.renderIframe(template, iframeHeightInPixels)
      } else {
        return this.renderSMS(template)
      }
    } else {
      return <></>
    }
  }

  render () {
    const {
      isOpen,
      toggleViewerModal,
      template
    } = this.props

    return (
      <MDBContainer>
        <MDBModal
          centered
          key='1234'
          size='lg'
          autoFocus={false}
          isOpen={isOpen}
          wrapClassName='overflow-auto'
          toggle={toggleViewerModal}
        >
          <MDBModalBody>
            <MDBCard className='mb-4'>
              <MDBCardBody>
                <strong>From: {processFromField(template)}</strong>
              </MDBCardBody>
            </MDBCard>
            <MDBCard>
              {this.renderView(template)}
            </MDBCard>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color='danger' onClick={toggleViewerModal}>Close</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    )
  }
}

export default ViewerModal
