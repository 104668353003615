import { Grid } from '@mui/material'
import { DateTime } from 'luxon'
import { MDBRow } from 'mdbreact'
import { IoWarningSharp } from 'react-icons/io5'

const CampaignScheduleDisplay = ({
  tz, nextFireTimes,
  firstFireDate, nextFireTimesErrorMessage,
  isCampaignFrequencyWeeksValid, modules // modules is optional
}) => {
  function renderNextFireTimes() {
    if (nextFireTimes && nextFireTimes.length > 0) {
      const content = []

      for (const nextFireTime of nextFireTimes) {
        content.push(
          <span
            style={{ padding: '3px 0' }}
            key={nextFireTime?.toString()}
          >
            {nextFireTime?.toLocaleString(navigator.language, { timeZone: tz })} ({tz})
          </span>
        )
      }

      return content
    }
  }

  function renderFirstFireTime() {
    if (modules && modules[0]?.dateStarted) {
      return (<span className='p-3 border rounded'>{new Date(modules[0].dateStarted._seconds * 1000).toLocaleString(navigator.language, { timeZone: tz })} ({tz})</span>)
    } else {
      return (<span className='p-3 border rounded'>{firstFireDate?.toLocaleString(navigator.language, { timeZone: tz })} ({tz})</span>)
    }
  }

  return (
    <>
      <MDBRow className='d-flex flex-column'>
        <h5>
          First Send:
        </h5>
        <div className='d-flex'>
          {firstFireDate && renderFirstFireTime()}
          {!firstFireDate && <p>No Items Picked Yet</p>}
        </div>
      </MDBRow>
      <br />

      {(!nextFireTimesErrorMessage && isCampaignFrequencyWeeksValid &&
        nextFireTimes.length > 0) && (
          <MDBRow className='d-flex flex-column'>
            <h5>
              This campaign will fire batches on:
            </h5>
            {(DateTime.fromJSDate(nextFireTimes[0]) > DateTime.now() &&
              DateTime.fromJSDate(nextFireTimes[0]) < DateTime.now().plus({ minutes: 60 })) && (
                <Grid container sx={{ paddingBottom: 'var(--phin-s0)' }}>
                  <Grid item md={6} sx={{ backgroundColor: 'var(--phin-yellow)', padding: 'var(--phin-s0)', borderRadius: '8px' }}>
                    <div style={{ color: 'var(--phin-extra-dark-yellow)' }}><IoWarningSharp className='margin-right:-2' size='1.25em' />Because of the schedule you've selected, the next module may not be sent out until the second listed fire time. To avoid this, move your release time further back.</div>
                  </Grid>
                </Grid>
              )}
            <div className='d-flex'>
              <div className='p-3 d-flex flex-column border rounded'>
                {renderNextFireTimes()}
              </div>
            </div>
          </MDBRow>
        )}

      {nextFireTimesErrorMessage && (
        <div className='phin-error-text'>
          Your campaign schedule is malformed: {nextFireTimesErrorMessage}
        </div>
      )}

      {!isCampaignFrequencyWeeksValid && (
        <div className='phin-error-text'>
          Your campaign schedule is malformed: Please select two weeks of the month to send content or change the frequency.
        </div>
      )}
    </>
  )
}

export default CampaignScheduleDisplay
