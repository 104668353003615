import React, { Component } from 'react'
import {
  MDBBtn,
  MDBIcon,
  MDBTooltip,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtnGroup,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBDataTable,
  MDBCol
} from 'mdbreact'
import {
  checkIfUserTemplateExists,
  sendTestEmail,
  processFromField
} from '../utils/templateUtils.js'
import { apiRequestUtils } from '../utils/apiRequestUtils'

import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'

import Mustache from 'mustache'

import TemplateBuilder from './components/templatesPage/TemplateBuilder'
import SMSPreviewModal from './components/templatesPage/SMSPreviewModal'
import ValidationModal from './components/ValidationModal'
import TemplateBadge from './components/TemplateBadge'

import { connect } from 'react-redux'

import './css/templates.css'

import { makeUID } from '../utils/objectUtils'
import { cloneTemplateThunk, uploadTemplateThunk, deleteTemplateThunk, shareTemplateThunk } from '../store/TemplatesSlice'

class TemplatesPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editModal: false,
      showTemplateBuilder: false,
      deleteModal: false,
      shareModal: false,
      previewModal: false,

      focusedTemplate: {},
      previewTemplate: {},

      feedback: [],
      previewHTML: '',
      deleteModalName: '',

      selectedTemplate: {},

      sendingDomains: [],
      templateColumns: [
        {
          label: 'Template Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'Channel',
          field: 'channel'
        },
        {
          label: 'Learning',
          field: 'learning'
        },
        {
          label: 'Tags',
          field: 'tags'
        },
        {
          label: 'Options',
          field: 'options'
        }
      ],
      premiumTemplateColumns: [
        {
          label: 'Template Name',
          field: 'name',
          sort: 'asc'
        },
        {
          label: 'Channel',
          field: 'channel'
        },
        {
          label: 'Tags',
          field: 'tags'
        },
        {
          label: 'From',
          field: 'from'
        },
        {
          label: 'Options',
          field: 'options'
        }
      ]
    }
  }

  masterToggle (modalName, info) {
    switch (modalName) {
      case 'create':
        this.setState({
          showTemplateBuilder: !this.state.showTemplateBuilder,
          selectedTemplate: {}
        })
        break

      case 'edit':
        this.setState({
          showTemplateBuilder: !this.state.showTemplateBuilder
        })
        break

      case 'closeEditTemplate':
        this.setState({
          editModal: !this.state.editModal,
          editTemplate: {
            name: '',
            html: '',
            fromEmail: '',
            fromName: '',
            type: '',
            injectedFields: []
          },
          templateNameRef: '',
          editError: ''
        })
        break

      case 'openDelete':
        this.setState({
          deleteModal: true,
          focusedTemplate: info
        })
        break

      case 'closeDelete':
        this.setState({
          deleteModal: false
        })
        break
      case 'share':
        this.setState({
          shareModal: !this.state.shareModal
        })
        break
      case 'preview':
        this.setState({
          previewModal: !this.state.previewModal
        })
        break
      default:
        console.log(`No modal with the name ${modalName}`)
    }
  }

  parseTemplate (text) {
    const injectedFields = []
    try {
      const result = Mustache.parse(text)
        .filter(v => {
          return v[0] === 'name'
        })
        .map(v => {
          return v[1]
        })
      result.forEach((field) => {
        if (field !== 'action_url') {
          injectedFields.push({ name: field, value: '' })
        }
      })
      return injectedFields
    } catch (error) {

    }
  }

  async testTemplate () {
    try {
      await sendTestEmail(this.props.id,
        {
          templateName: this.state.name,
          content: this.state.html,
          email: this.state.email
        },
        apiRequestUtils)
      this.setState({
        email: '',
        emailInputModal: false
      })
    } catch (err) {
      console.log(err)
      NotificationUtilities.sendErrorMessage('Test email failed to send! Please try again.')
    }
  }

  async uploadTemplate (newTemplate, templateId) {
    let endPoint, id
    if (newTemplate.shared === true) {
      endPoint = 'partners'
      id = this.props.company.partnerId
    } else {
      endPoint = 'companies'
      id = this.props.id
    }
    return this.props.uploadTemplateThunk(id, { newTemplate, endPoint, templateId })
  }

  async editTemplate (template) {
    const oldId = this.state.selectedTemplate.id
    const deleteResponseStatus = await this.deleteTemplate(this.state.selectedTemplate.id, template.shared)
    if (deleteResponseStatus !== 200) {
      NotificationUtilities.sendErrorMessage('Failed to edit your template. Our team has been notified.')
      console.log('Failed to edit template: ', deleteResponseStatus)
      return
    }

    const uploadResponseStatus = await this.uploadTemplate(template, oldId)
    if (uploadResponseStatus !== 200) {
      NotificationUtilities.sendErrorMessage('There was an error uploading your template. Our team has been notified.')
      return
    }

    NotificationUtilities.sendSuccessMessage('Template successfully updated')
  }

  async createTemplate (newTemplateObj) {
    NotificationUtilities.sendInfoMessage('Creating Template...')

    const checkForDuplicateResponseStatus = await checkIfUserTemplateExists(this.props.id, this.props.company.partnerId, newTemplateObj.name, apiRequestUtils)

    if (checkForDuplicateResponseStatus === 200) {
      NotificationUtilities.sendWarningMessage('A template with that name already exists!')
      return
    } else if (checkForDuplicateResponseStatus === 500) {
      this.masterToggle('create')
      NotificationUtilities.sendErrorMessage('Failed to create template! Our team has been notified.')
      return
    }

    // creating a new template is never shared by default. Hence the empty second param and third param
    const uploadResponseStatus = await this.uploadTemplate(newTemplateObj)

    if (uploadResponseStatus !== 200) {
      this.masterToggle('create')
      NotificationUtilities.sendErrorMessage('Error uploading your template! Our team has been notified.')
      return
    }

    NotificationUtilities.sendSuccessMessage('Template successfully created!')
  }

  computeTemplateCopyName (oldTemplateName, templates) {
    const uidPattern = /-[a-z0-9].*$/
    if (oldTemplateName.match(uidPattern)) {
      return oldTemplateName.replace(uidPattern, '-' + makeUID(8))
    } else {
      return `oldTemplateName-${makeUID(8)}`
    }
  }

  async cloneTemplate (templateToClone) {
    const template = Object.assign({}, templateToClone)
    // clone directly into this companies templates
    template.shared = false
    // ?
    template.id = undefined

    if (!template.injectedFields) {
      try {
        template.injectedFields = this.parseTemplate(template.html)
      } catch (error) {
        NotificationUtilities.sendWarningMessage('There is an error in the template you are trying to copy')
        return
      }
    }

    template.name = this.computeTemplateCopyName(templateToClone.name, this.props.userTemplates)
    this.props.cloneTemplateThunk(this.props.id, template)
  }

  templateBuilderCallback (template, shouldEdit = false) {
    if (shouldEdit) {
      this.editTemplate(template)
    } else {
      this.createTemplate(template)
    }
  }

  selectTemplateForEditing (template) {
    const templateCopy = Object.assign({}, template)
    this.setState({ selectedTemplate: templateCopy })
    this.masterToggle('edit')
  }

  previewSMSTemplate (template) {
    this.setState({ previewTemplate: template })
    this.masterToggle('preview')
  }

  previewTemplate (template) {
    if (template.channel === 'email') {
      const win = window.open('')
      const cssString = template.css ? `<style>${template.css}</style>` : ''
      win.document.body.innerHTML = template.html + cssString
      win.document.title = template.name
    } else {
      this.previewSMSTemplate(template)
    }
  }

  async deleteTemplate (id, shared) {
    // trigger render manually since we editted the object itself
    const orgId = shared ? this.props.company.partnerId : this.props.id
    const orgType = shared ? 'partners' : 'companies'
    return this.props.deleteTemplateThunk(id, orgType, orgId)
  }

  async deleteTemplateFromButton (template) {
    try {
      NotificationUtilities.sendInfoMessage('Deleting Template...')
      await this.deleteTemplate(template.id, template.shared)
      NotificationUtilities.sendSuccessMessage('Template successfully deleted!')
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Error while deleting your template! Our team has been notified.')
    }
    this.masterToggle('closeDelete')
  }

  async shareTemplate (template) {
    this.masterToggle('share')
    this.props.shareTemplateThunk(this.props.id, this.props.company.partnerId, template)
  }

  render () {
    const {
      defaultTemplates, sharedTemplates, userTemplates,
      campaignCategories, sendingDomains
    } = this.props
    const rows = []
    const defaultRows = []
    const typeOptions = [
      {
        text: 'Choose a template type',
        value: '',
        disabled: true,
        selected: true
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Text (SMS) Beta',
        value: 'sms'
      }
    ]

    const sendingOptions = [
      {
        text: 'Choose a Sending Domain',
        value: '',
        disabled: true,
        selected: true
      }
    ]

    sendingDomains.forEach((domain) => {
      sendingOptions.push({ text: domain, value: domain })
    })

    defaultTemplates.forEach((template) => {
      defaultRows.push({
        name: template.name,
        channel: <TemplateBadge channel={template.channel} />,
        tags: (template.tags || []).join(', '),
        from: processFromField(template),
        options:
  <div className='mx-1 justify-content-center text-center'>
    <MDBTooltip placement='top'>
      <MDBBtn size='sm' className='preview-premium-template-button' rounded onClick={() => this.previewTemplate(template)}><MDBIcon size='lg' far icon='eye' /></MDBBtn>
      <div>Preview</div>
    </MDBTooltip>
    <MDBTooltip placement='top'>
      <MDBBtn size='sm' className='clone-premium-template-button' color='secondary' rounded onClick={() => { this.cloneTemplate(template) }}><MDBIcon size='lg' icon='clone' /> </MDBBtn>
      <div>Clone</div>
    </MDBTooltip>
  </div>

      })
    })

    const showTemplateShareWarning = (template) => {
      this.masterToggle('share')
      this.setState({ focusedTemplate: template })
    }

    const handleTemplateCreate = () => {
      if (this.props.company.freeTrial) {
        NotificationUtilities.sendWarningMessage('Sorry this feature is unavailble on free tier')
      } else {
        this.masterToggle('create')
      }
    }

    userTemplates.concat(sharedTemplates).forEach(template => {
      rows.push({
        name: template.shared ? <><MDBIcon className='text-warning' icon='share-alt' /> {template.name}</> : template.name,
        channel: <TemplateBadge channel={template.channel} />,
        learning: <TemplateBadge learningType={template.learningType} />,
        tags: (template.tags || []).map(tag => tag.charAt(0).toUpperCase() + tag.slice(1)).join(', '),
        options: (
          <>
            <MDBBtnGroup>
              <MDBBtn color='primary' onClick={() => { this.selectTemplateForEditing(template) }}>
                <MDBIcon size='lg' icon='pencil-alt' />  Edit
              </MDBBtn>
              <MDBBtnGroup>
                <MDBDropdown>
                  <MDBDropdownToggle style={{ paddingLeft: '15px', paddingRight: '20px', marginLeft: '0px' }} caret color='primary' />
                  <MDBDropdownMenu>
                    <MDBDropdownItem onClick={() => this.previewTemplate(template)}>
                      <MDBIcon size='lg' far icon='eye' />  Preview
                    </MDBDropdownItem>
                    <MDBDropdownItem onClick={() => { this.cloneTemplate(template) }}>
                      <MDBIcon size='lg' icon='clone' />  Clone
                    </MDBDropdownItem>
                    <MDBDropdownItem disabled={!!template.shared} onClick={() => showTemplateShareWarning(template)}>
                      <MDBIcon size='lg' icon='share' />  Share
                    </MDBDropdownItem>
                    <MDBDropdownItem className='danger-hover' onClick={() => this.masterToggle('openDelete', template)}>
                      <MDBIcon size='lg' icon='trash-alt' />  Delete
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBBtnGroup>
            </MDBBtnGroup>
          </>
        )
      })
    })

    const warningText = 'Are you sure you would like to delete this template?'
    const trashModalJSX = this.state.focusedTemplate.shared
      ? (
        <><p>This template is shared with your partner network. Deleting it will delete it for <b> everyone</b>.</p>
          <p>{warningText}</p>
        </>
        )
      : <p>{warningText}</p>

    return (
      <MDBContainer>
        <NotificationWrapper />

        <TemplateBuilder
          sendingDomains={sendingOptions}
          toggle={this.masterToggle.bind(this)}
          id={this.props.id}
          display={this.state.showTemplateBuilder}
          categories={campaignCategories}
          templateUpdateCallback={this.templateBuilderCallback.bind(this)}
          typeOptions={typeOptions}
          editTemplate={this.state.selectedTemplate}
        />

        <SMSPreviewModal template={this.state.previewTemplate} isOpen={this.state.previewModal} toggleModal={(() => this.masterToggle('preview'))} />

        <ValidationModal
          isOpen={this.state.shareModal}
          toggleFunction={() => this.masterToggle('share')}
          messageJSX={<p>Are you sure you would like to share this template? It will make it available to all other admins and clients. <strong>This can not be undone!</strong></p>}
          falseOptionText='Cancel'
          trueOptionText='Share'
          handlerFunction={() => this.shareTemplate(this.state.focusedTemplate)}
        />

        {/* Template Deletion modal */}
        <ValidationModal
          isOpen={this.state.deleteModal}
          toggleFunction={() => this.masterToggle('closeDelete')}
          messageJSX={trashModalJSX}
          falseOptionText='Cancel'
          trueOptionText='Delete'
          falseOptionColor='primary'
          trueOptionColor='red'
          handlerFunction={() => this.deleteTemplateFromButton(this.state.focusedTemplate)}
        />

        <MDBRow className='d-flex justify-content-center'>
          <MDBCol className='text-center'>
            <h1>Template Manager</h1>
            <p>Add, Remove, and search for your templates here</p>
          </MDBCol>
        </MDBRow>

        <MDBRow className='d-flex justify-content-center'>
          <MDBCol xl='4' md='6' className='mb-5 d-flex justify-content-center'>
            <MDBBtn onClick={() => { handleTemplateCreate() }} style={this.props.company && this.props.company.freeTrial ? { opacity: '0.6' } : {}} color={this.props.company && this.props.company.freeTrial ? 'grey' : 'primary'} size='lg'>
              <MDBRow>
                <MDBCol size='2'>
                  <MDBIcon size='3x' icon='plus-square' />
                </MDBCol>
                <MDBCol size='10'>
                  Create a new template
                </MDBCol>
              </MDBRow>
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        <MDBRow className='mb-4 d-flex flex-row justify-content-center'>
          <MDBCard narrow style={{ width: '100%' }}>
            <MDBCardHeader className='view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3 text-center'>
              <a href='javascript:void(0)' className='white-text mx-3'>Your Templates</a>
            </MDBCardHeader>
            <MDBCardBody id='your-templates-table-container' cascade>
              <MDBDataTable disableRetreatAfterSorting id='your-templates-table' btn className='justify-content-center text-center' style={{ width: '100%' }} responsiveXl striped data={{ columns: this.state.templateColumns, rows: rows }} />
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
        <MDBRow className='mt-4'>
          <MDBCard narrow style={{ width: '100%' }}>
            <MDBCardHeader className='view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3 text-center'>
              <a href='javascript:void(0)' className='white-text mx-3'>Default Templates</a>
            </MDBCardHeader>
            <MDBCardBody cascade>
              <MDBDataTable disableRetreatAfterSorting id='default-templates-table' btn responsiveXl style={{ width: '100%' }} striped data={{ columns: this.state.premiumTemplateColumns, rows: defaultRows }} />
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    userTemplates: state.templates.userTemplates,
    sharedTemplates: state.templates.sharedTemplates,
    defaultTemplates: state.templates.defaultTemplates,
    campaignCategories: state.templates.campaignCategories,
    sendingDomains: state.templates.sendingDomains,
    company: state.company.company
  })
}

const mapDispatchToProps = { cloneTemplateThunk, uploadTemplateThunk, deleteTemplateThunk, shareTemplateThunk }

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesPage)
