import {
  MDBBtn, MDBCardImage, MDBCardBody,
  MDBCardTitle, MDBCard, MDBCol, MDBIcon, MDBRow,
  MDBContainer, MDBTooltip, MDBAlert, MDBMask, MDBView
} from 'mdbreact'
import ValidationModal from './components/ValidationModal'
import NotificationWrapper from './components/notifications/notificationWrapper'
import React, { useState } from 'react'
import './css/training.css'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { deletePolicyThunk } from '../store/PoliciesSlice'

function formatDateForCard (date) {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

function renderActivePolicy (policy, openCancelModal) {
  return (
    <MDBCol key={policy.id} className='d-flex justify-content-center mt-4'>
      <MDBView rounded zoom className='h-100 preset-overlay' hover>
        <MDBCard style={{ width: '22rem' }}>
          <MDBCardImage
            top
            overlay={false}
            waves={false}
            src={policy.thumbnail || 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg'}
          />
          <MDBCardBody>
            <MDBCardTitle>{policy.name}
            </MDBCardTitle>
          </MDBCardBody>
          <div className='rounded-bottom training-card-bottom text-center pt-3'>
            <ul className='list-unstyled list-inline font-small'>
              <li className='list-inline-item pr-2'>
                {formatDateForCard(new Date(policy.dateStarted))}
              </li>
              <li className='list-inline-item pr-2'>
                <MDBIcon icon='users' className='mr-1' />
                {policy.totalAssigned}
              </li>
              <li className='list-inline-item pr-2'>
                <MDBIcon icon='check-circle' className='mr-1' />
                {policy.totalCompleted}
              </li>
            </ul>
          </div>
        </MDBCard>
        <MDBMask className='flex-center' overlay='blue-strong' pattern={5}>

          <MDBTooltip placement='top'>
            <MDBBtn
              floating
              tag='a'
              color='danger'
              size='lg'
              action
              onClick={() => {
                openCancelModal(policy)
              }}
            >
              <MDBIcon
                id={`cancel-policy-${policy.name}-button`}
                aria-label='Cancel Policy Button'
                size='2x'
                icon='trash-alt'
                className='primary'
              />
            </MDBBtn>
            <div>
              Cancel Policy
            </div>
          </MDBTooltip>

        </MDBMask>
      </MDBView>
    </MDBCol>
  )
}

function ActivePolicies ({ policies, openCancelModal }) {
  if (policies.length === 0) {
    return (
      <MDBAlert color='secondary'>
        <MDBRow>
          <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
            <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='question-circle' />
          </MDBCol>
          <MDBCol className='d-flex align-items-center'>
            You do not have any assigned policy documents yet. Go to the policy catalog to start one!
          </MDBCol>
        </MDBRow>
      </MDBAlert>
    )
  } else {
    return (
      <MDBRow>
        {policies.map(policy => renderActivePolicy(policy, openCancelModal))}
      </MDBRow>
    )
  }
}

function ActivePolicyPage ({ companyId }) {
  const dispatch = useDispatch()
  const { policies, loaders } = useSelector((state) => state.policies)
  const { isLoadingPolicies } = loaders

  const [cancelModal, showCancelModal] = useState(false)
  const [selectedPolicy, selectPolicy] = useState(null)

  function openCancelModal (policy) {
    selectPolicy(policy)
    showCancelModal(true)
  }

  function closeCancelModal () {
    selectPolicy(null)
    showCancelModal(false)
  }

  async function confirmPolicyDeletion (policy) {
    showCancelModal(false)
    dispatch(deletePolicyThunk({ companyId, policy }))
    selectPolicy(null)
  }

  return (
    <MDBContainer>
      <NotificationWrapper />
      <ValidationModal
        isOpen={cancelModal}
        toggleFunction={() => closeCancelModal()}
        handlerFunction={() => confirmPolicyDeletion(selectedPolicy)}
        messageJSX={
          <p>Are you sure you would like to cancel this policy? <strong>This can not be undone!</strong>
            <br /> Your data will be saved and viewable from the Analytics page.
          </p>
        }
        falseOptionText='Continue Policy'
        trueOptionText='Cancel Policy'
        falseOptionColor='primary'
        trueOptionColor='red'
      />

      <MDBRow className='d-flex justify-content-center'>
        <h1>Active Policy Documents</h1>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center'>
        <p>All active policy documents are shown here at a glance as well as info on their progress.</p>
      </MDBRow>

      {(!policies || isLoadingPolicies) && (
        <Spinner />
      )}
      {(policies && !isLoadingPolicies) && (
        <ActivePolicies policies={policies} openCancelModal={openCancelModal} />
      )}
    </MDBContainer>
  )
}

export default ActivePolicyPage
