import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authorization: null,
    isLoadingAuthorization: false
  },
  reducers: {
    setAuthorization: (state, action) => {
      state.authorization = action.payload
    },
    setIsLoadingAuthorization: (state, action) => {
      state.isLoadingAuthorization = action.payload
    }
  }
})

export const {
  setAuthorization: setAuthorizationAction,
  setIsLoadingAuthorization: setIsLoadingAuthorizationAction
} = authSlice.actions

export default authSlice.reducer

export const getAuthorizationThunk = (id) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingAuthorizationAction(true))

    const res = await api.post(`/api/auth/users/${id}/login`)
    if (res.status !== 200) {
      throw new Error('We could not authorize you')
    } else {
      const authorization = await res.json()
      dispatch(setAuthorizationAction(authorization))
      dispatch(setIsLoadingAuthorizationAction(false))
      return authorization
    }
  }
}

export const getAuthorizationSilentlyThunk = (id) => {
  return async (dispatch, getState, api) => {
    const res = await api.post(`/api/auth/users/${id}/login`)
    if (res.status !== 200) {
      throw new Error('We could not authorize you')
    } else {
      const authorization = await res.json()
      dispatch(setAuthorizationAction(authorization))
      return authorization
    }
  }
}
