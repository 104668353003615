/**
 *
 * @param {Array} items An array of the items that will be grouped together
 * @param {Number} groupSize The size of the groups
 * @returns {Array} An array of arrays consisting of the grouped items
 */

export default function generateGroups (items, groupSize, startingItems = null) {
  const finalArray = []
  let counter = 0
  let arrItem = []

  if (startingItems != null) {
    for (const item of startingItems) {
      arrItem.push(item)
      counter++
    }
  }

  for (const item of items) {
    if (counter === groupSize) {
      finalArray.push(arrItem)
      arrItem = []
      counter = 0
    }

    counter += 1
    arrItem.push(item)
  }

  if (arrItem !== []) {
    finalArray.push(arrItem)
  }
  return finalArray
}
