import React, { useEffect, useState } from 'react'
import {
  MDBIcon, MDBCol, MDBRow,
  MDBInput, MDBBtn, MDBModal,
  MDBModalHeader, MDBModalBody,
  MDBModalFooter
} from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../Spinner'
import { setMonthlyReportSendTimeThunk, setSendMonthlyReportsThunk, setSendWeeklyReportsThunk, setWeeklyReportSendTimeThunk } from '../../../store/CompanySlice'
import { createReportViewerThunk, deleteReportViewerThunk, getReportViewersThunk, updateReportViewerThunk } from '../../../store/ReportViewersSlice'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import { TextField, Grid, Switch } from '@mui/material'
import MomentUtils from '@date-io/moment'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { setCompanyReportSettingsThunk } from '../../../store/ReportingSettingsSlice'
import '../../css/reportSettings.scss'

const ReportSettings = ({
  companyId
}) => {
  const dispatch = useDispatch()
  const { company, loaders } = useSelector((state) => state.company)
  const {
    monthlyReportHistoricalDataEnabled,
    monthlyReportUsersToWatchEnabled,
    weeklyReportHistoricalDataEnabled,
    weeklyReportUsersToWatchEnabled,
    weeklyPhishingClicksCSVEnabled,
    monthlyPhishingClicksCSVEnabled,
    weeklyTrainingDataCSVEnabled,
    monthlyTrainingDataCSVEnabled,
    monthlyPhishingPDFReportEnabled
  } = useSelector(state => state.reportingSettings)
  const { isLoadingCompany } = loaders
  const { reportViewers, loaders: reportViewerLoaders } = useSelector((state) => state.reportViewers)
  const { isLoadingReportViewers, isLoadingUpdateReportViewer } = reportViewerLoaders
  const [newReportViewerEmail, setNewReportViewerEmail] = useState()
  const [editableReportViewerEmail, setEditableReportViewerEmail] = useState()
  const [editableReportviewerId, setEditableReportviewerId] = useState()
  const [showEditModal, setShowEditModal] = useState(false)
  const [monthlyReportSendTime, setMonthlyReportSendTime] = useState(moment().hour(8).minutes(0))
  const [weeklyReportSendTime, setWeeklyReportSendTime] = useState(moment().hour(8).minutes(0))

  useEffect(() => {
    dispatch(getReportViewersThunk({ companyId }))
  }, [])

  useEffect(() => {
    if (company && company.monthlyReportSendTime) {
      const timeArray = company.monthlyReportSendTime.split(':')
      setMonthlyReportSendTime(moment().hour(timeArray[0]).minute(timeArray[1]))
    }

    if (company && company.weeklyReportSendTime) {
      const timeArray = company.weeklyReportSendTime.split(':')
      setWeeklyReportSendTime(moment().hour(timeArray[0]).minute(timeArray[1]))
    }
  }, [company])

  const toggleShowEditModal = (editableReportviewer) => {
    if (editableReportviewer) {
      setEditableReportviewerId(editableReportviewer.id)
      setEditableReportViewerEmail(editableReportviewer.reportViewerEmail)
      setShowEditModal(true)
    } else {
      setEditableReportviewerId(null)
      setEditableReportViewerEmail(null)
      setShowEditModal(false)
    }
  }

  const updateMonthlyReportSendTime = async (value) => {
    if (moment.isMoment(value) && value.isValid()) {
      let minutesString = '' + value.minutes()
      if (minutesString.length === 1) {
        minutesString = '0' + minutesString
      }
      const timeString = `${value.hour()}:${minutesString}`

      await dispatch(setMonthlyReportSendTimeThunk({ companyId, timeString }))
    }
  }

  const updateWeeklyReportSendTime = async (value) => {
    if (moment.isMoment(value) && value.isValid()) {
      let minutesString = '' + value.minutes()
      if (minutesString.length === 1) {
        minutesString = '0' + minutesString
      }
      const timeString = `${value.hour()}:${minutesString}`
      await dispatch(setWeeklyReportSendTimeThunk({ companyId, timeString }))
    }
  }

  const generateReportViewerList = () => {
    const reportViewersList = []

    if (reportViewers && reportViewers.length > 0) {
      reportViewers.forEach((reportViewer, idx) => {
        reportViewersList.push(
          <MDBRow key={idx} className='d-flex justify-content-around align-items-center'>
            <MDBCol size='8'>
              <h5 className='align-self-center'>{reportViewer.reportViewerEmail}</h5>
            </MDBCol>

            <MDBCol size='3'>
              <MDBRow className='d-flex justify-content-center'>
                <MDBBtn
                  onClick={() => toggleShowEditModal(reportViewer)}
                  rounded
                  className='align-items-center'
                  type='submit'
                  size='sm'
                  color='primary'
                >
                  <MDBIcon
                    size='md'
                    color='blue'
                    icon='edit'
                  />
                </MDBBtn>

                <MDBBtn
                  onClick={() => dispatch(deleteReportViewerThunk({ companyId, id: reportViewer.id }))}
                  rounded
                  className='align-items-center'
                  type='submit'
                  size='sm'
                  color='danger'
                >
                  <MDBIcon
                    size='md'
                    color='blue'
                    icon='trash-alt'
                  />
                </MDBBtn>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )
      })
    } else {
      reportViewersList.push(
        <h5 key='1'>No Report Viewers</h5>
      )
    }

    return reportViewersList
  }

  const createReportViewer = async () => {
    if (newReportViewerEmail && newReportViewerEmail !== '') {
      if (ValidationUtils.isValidEmail(newReportViewerEmail)) {
        const succeeded = await dispatch(createReportViewerThunk({ companyId, reportViewerEmail: newReportViewerEmail }))

        if (succeeded) {
          setNewReportViewerEmail('')
        }
      } else {
        NotificationUtilities.sendWarningMessage('Please enter a valid email address.')
      }
    }
  }

  const updateReportViewer = async () => {
    if (editableReportViewerEmail && editableReportViewerEmail !== '') {
      if (ValidationUtils.isValidEmail(editableReportViewerEmail)) {
        const succeeded = await dispatch(updateReportViewerThunk({ companyId, id: editableReportviewerId, reportViewerEmail: editableReportViewerEmail }))

        if (succeeded) {
          toggleShowEditModal()
        }
      } else {
        NotificationUtilities.sendWarningMessage('Please enter a valid email address.')
      }
    }
  }

  return (
    <>
      <MDBModal
        isOpen={showEditModal}
        toggle={() => toggleShowEditModal()}
      >
        <MDBModalHeader toggle={() => toggleShowEditModal()}>Update</MDBModalHeader>
        {isLoadingUpdateReportViewer && (
          <Spinner />
        )}
        {!isLoadingUpdateReportViewer && (
          <>
            <MDBModalBody>
              <MDBInput className='my-0 pb-0' outline icon='envelope' value={editableReportViewerEmail || ''} onChange={(e) => setEditableReportViewerEmail(e.target.value)} label='Email Address' size='lg' />
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => toggleShowEditModal()}>
                Close
              </MDBBtn>
              <MDBBtn color='primary' onClick={() => updateReportViewer()}>Save</MDBBtn>
            </MDBModalFooter>
          </>
        )}
      </MDBModal>

      <Grid sx={{ mt: 6 }}>
        <div style={{ alignSelf: 'start' }}>
          <h4 className='phin-h4 margin-bottom:-1' style={{ textAlign: 'left' }}>Settings</h4>
        </div>
        <Grid>
          <div cascade className='phin-border padding:2'>
            {(!company || isLoadingCompany ||
              !reportViewers || isLoadingReportViewers) && (
                <Spinner />
            )}
            {(company && !isLoadingCompany &&
              reportViewers && !isLoadingReportViewers) && (

                <MDBCol size='12'>
                  <h4>Report Viewers</h4>
                  <div className='margin-bottom:2'>Add email addresses to send a weekly or monthly report for this company containing: Phishing, Training, and performance related details.</div>
                  {generateReportViewerList()}

                  <MDBRow className='d-flex justify-content-around align-items-center'>
                    <MDBCol size='8'>
                      <MDBInput className='my-0 pb-0' outline icon='envelope' value={newReportViewerEmail} onChange={(e) => setNewReportViewerEmail(e.target.value)} label='Email Address' size='lg' />
                    </MDBCol>
                    <MDBCol size='3' className='d-flex justify-content-center'>
                      <MDBBtn
                        onClick={() => createReportViewer()}
                      >
                        Add
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow className='pt-5 d-flex justify-content-between pb-3'>
                    <div className='phin-h4'>Weekly Reports</div>
                    <Grid item xs={6}>
                      <Switch
                        id='toggleSendWeeklyReports' checked={company.sendWeeklyReports} onClick={() => { dispatch(setSendWeeklyReportsThunk({ companyId, value: !company.sendWeeklyReports })) }}
                      />
                    </Grid>
                  </MDBRow>
                  <MDBRow className='d-flex justify-content-between align-items-center pb-3'>
                    {company.sendWeeklyReports && (
                      <>
                        <p>Send Time On Mondays</p>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                          <TimePicker
                            label='Weekly Send Time'
                            value={weeklyReportSendTime}
                            onChange={(newValue) => {
                              updateWeeklyReportSendTime(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </>
                    )}
                  </MDBRow>
                  {company.sendWeeklyReports &&
                          (
                            <>
                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include "Historical Data" Table</>
                                <Switch
                                  id='toggleWeeklyReportHistoricalDataTable' checked={weeklyReportHistoricalDataEnabled} onClick={(e) => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyReportHistoricalDataEnabled: !weeklyReportHistoricalDataEnabled } })) }}
                                />
                              </Grid>
                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include "Users To Watch" Table</>
                                <Switch
                                  id='toggleWeeklyReportUsersToWatchTable' checked={weeklyReportUsersToWatchEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyReportUsersToWatchEnabled: !weeklyReportUsersToWatchEnabled } })) }}
                                />
                              </Grid>
                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include CSV output of raw phishing data</>
                                <Switch
                                  id='toggleWeeklyPhishingClicksCSVEnabled' checked={weeklyPhishingClicksCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyPhishingClicksCSVEnabled: !weeklyPhishingClicksCSVEnabled } })) }}
                                />
                              </Grid>
                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include CSV output of training data</>
                                <Switch
                                  id='toggleWeeklyTrainingDataCSVEnabled' checked={weeklyTrainingDataCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { weeklyTrainingDataCSVEnabled: !weeklyTrainingDataCSVEnabled } })) }}
                                />
                              </Grid>
                            </>
                          )}
                  <br />
                  <hr />
                  <MDBRow className='pt-5 d-flex justify-content-between pb-3'>
                    <div className='phin-h4'>Monthly Reports</div>
                    <Grid item xs={6}>
                      <Switch
                        id='toggleSendMonthlyReports' checked={company.sendMonthlyReports} onClick={() => { dispatch(setSendMonthlyReportsThunk({ companyId, value: !company.sendMonthlyReports })) }}
                      />
                    </Grid>
                  </MDBRow>
                  <MDBRow className='d-flex justify-content-between align-items-center pb-3'>
                    {company.sendMonthlyReports && (
                      <>
                        <p>Send Time On The 1st</p>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                          <TimePicker
                            label='Monthly Send Time'
                            value={monthlyReportSendTime}
                            onChange={(newValue) => {
                              updateMonthlyReportSendTime(newValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </>
                    )}
                  </MDBRow>
                  {company.sendMonthlyReports &&
                          (
                            <>
                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include "Historical Data" Table</>
                                <Switch
                                  id='toggleWeeklyReportHistoricalDataTable' checked={monthlyReportHistoricalDataEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyReportHistoricalDataEnabled: !monthlyReportHistoricalDataEnabled } })) }}
                                />
                              </Grid>

                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include "Users To Watch" Table</>
                                <Switch
                                  id='toggleWeeklyReportUsersToWatchTable' checked={monthlyReportUsersToWatchEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyReportUsersToWatchEnabled: !monthlyReportUsersToWatchEnabled } })) }}
                                />
                              </Grid>

                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include CSV output of raw phishing data</>
                                <Switch
                                  id='toggleMonthlyPhishingClicksCSVEnabled' checked={monthlyPhishingClicksCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyPhishingClicksCSVEnabled: !monthlyPhishingClicksCSVEnabled } })) }}
                                />
                              </Grid>

                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include CSV output of training data</>
                                <Switch
                                  id='togglemonthlyTrainingDataCSVEnabled' checked={monthlyTrainingDataCSVEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyTrainingDataCSVEnabled: !monthlyTrainingDataCSVEnabled } })) }}
                                />
                              </Grid>

                              <Grid item xs={6} className='phin-flex-space-between'>
                                <>Include Phishing PDF Report</>
                                <Switch
                                  id='togglemonthlyPhishingPDFReportEnabled' checked={monthlyPhishingPDFReportEnabled} onClick={() => { dispatch(setCompanyReportSettingsThunk({ companyId, updateOpts: { monthlyPhishingPDFReportEnabled: !monthlyPhishingPDFReportEnabled } })) }}
                                />
                              </Grid>
                            </>
                          )}
                </MDBCol>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ReportSettings
