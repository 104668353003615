import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoEyeSharp } from 'react-icons/io5'

import Spinner from '../Spinner'
import { IconButton } from '@mui/material'
import { capitalizeFirstLetter } from '../../../utils/FormattingUtils'
import { navigationRoutes } from '../../../frontendConsts.js'
import { useHistory } from 'react-router-dom'

function UsersViewTrainingAnalytics ({ companyId }) {
  const history = useHistory()

  const { users, loaders } = useSelector((state) => state.users)
  const { isLoadingUsers } = loaders

  const [userRows, setUserRows] = useState([])

  function viewUserTrainingAnalytics (userId) {
    history.push(`/companies/${companyId}${navigationRoutes.ANALYTICS_USERS}/${userId}`, { previousPageTitle: 'Training Analytics' })
  }

  function renderViewUserButton (user) {
    return (
      <IconButton
        id={`View-${user.email}-button`}
        aria-label={`View ${user.email} Button`}
        onClick={() => {
          viewUserTrainingAnalytics(user.id)
        }}
      >
        <IoEyeSharp />
      </IconButton>
    )
  }

  const userColumns = [
    { field: 'last', headerName: 'Last Name', flex: 1 },
    { field: 'first', headerName: 'First Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'status', headerName: 'User Status', flex: 1, renderCell: (params) => capitalizeFirstLetter(params.row.status) },
    { field: 'action', headerName: 'View', flex: 1, renderCell: (params) => renderViewUserButton(params.row) }
  ]

  useEffect(() => {
    const userRowData = []
    if (users !== null) {
      for (const user of users) {
        const userData = { ...user }
        userRowData.push(userData)
      }
      setUserRows(userRowData)
    }
  }, [users])

  return (
    <div>

      {(isLoadingUsers || !users) && (
        <Spinner message='Loading Users' />
      )}

      {(!isLoadingUsers && users) &&
        <div style={{ height: '65vh', width: '100%' }}>
          {/* TODO: Allow users to change the pageSize */}
          <DataGrid
            className='DataGrid'
            style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
            rows={userRows}
            columns={userColumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        </div>}
    </div>
  )
}

export default UsersViewTrainingAnalytics
