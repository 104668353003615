import React, { useState } from 'react'
import { Box, Button, Grid, Modal, TextField } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import LuxonUtils from '@date-io/luxon'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'
import { markTrainingCompleteForUserThunk } from '../../../store/AnalyticsSlice'

const CompleteTrainingModal = ({
  companyId, user,
  course, selectedCourse,
  onClose, isOpen, confirmAction,
  isSingleUserView
}) => {
  const dispatch = useDispatch()

  const { loaders } = useSelector((state) => state.analytics)
  const { isLoadingCourseStats, isLoadingUserInteractionDataStats } = loaders

  const [score, setScore] = useState(100)
  const [dateCompleted, setDateCompleted] = useState(DateTime.now())
  const [overrideReason, setOverrideReason] = useState()

  const [showWarning, setShowWarning] = useState(false)

  function updateScore (newScore) {
    const newScoreInt = parseInt(newScore, 10)
    if (newScore === '') {
      setScore(newScore)
    } else if (newScore && !Number.isNaN(newScore) &&
    newScoreInt >= 0 && newScoreInt <= 100 &&
    (newScore.length <= 2 || newScoreInt === 100)) {
      setScore(newScore)
    }
  }

  async function markTrainingComplete () {
    const status = await dispatch(markTrainingCompleteForUserThunk(
      { user, companyId, courseId: course?.id || selectedCourse?.id, isSingleUserView, score, dateCompleted, overrideReason }))

    if (status) {
      setScore(100)
      setDateCompleted(DateTime.now())
      setOverrideReason()
    }

    confirmAction()
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Grid container justifyContent='flex-end'>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3
        }}
        >

          {!showWarning && (
            <div className='main-container-left-aligned '>
              <div>
                <div className='phin-h4'>Mark User Complete</div>
                <p className='margin:none'><b>User:</b> {user?.first} {user?.last} </p>
                <p className='margin:none'><b>Course:</b> {course ? course.name : selectedCourse.name}</p>
              </div>

              <TextField
                id='score'
                label='Score'
                type='number'
                value={score}
                onChange={(event) => updateScore(event.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
                disableMaskedInput
                required
                sx={{
                  marginTop: '1.5em',
                  marginBottom: '1.5em'
                }}
              />

              <LocalizationProvider dateAdapter={LuxonUtils}>
                <DateTimePicker
                  required
                  disableToolbar
                  id='date-time-picker-inline'
                  label='Date Completed'
                  value={dateCompleted}
                  onChange={setDateCompleted}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={course
                    ? (course.dateAssigned
                        ? DateTime.fromSeconds(course.dateAssigned._seconds)
                        : DateTime.fromSeconds(course.dateStarted._seconds))
                    : selectedCourse.dateAssigned
                      ? DateTime.fromJSDate(new Date(selectedCourse.dateAssigned))
                      : DateTime.fromJSDate(new Date(selectedCourse.dateStarted))}
                  disableMaskedInput
                  sx={{
                    marginTop: '1.5em',
                    marginBottom: '1.5em'
                  }}
                />

                <TextField
                  required
                  fullWidth
                  id='override-reason'
                  label='Override Reason'
                  value={overrideReason}
                  onChange={(event) => setOverrideReason(event.target.value)}
                  inputProps={{ maxLength: 256 }}
                  sx={{
                    marginTop: '1.5em',
                    marginBottom: '1.5em'
                  }}
                />

              </LocalizationProvider>

              <div className='button-container-right-aligned'>
                <Button sx={{ marginRight: '16px' }} variant='contained' onClick={onClose}>Cancel</Button>
                <Button disabled={!score || !dateCompleted || !overrideReason || overrideReason === ''} color='success' variant='contained' onClick={() => setShowWarning(true)}>Continue</Button>
              </div>
            </div>
          )}

          {showWarning && (
            <div className='main-container-left-aligned'>
              <div className='margin-top:1 margin-bottom:1'>
                <p>You are about to mark training course: "{course?.name || selectedCourse?.name}" complete for user {user?.first} {user?.last}. </p> <p> Are you sure you would like to do this?</p>
              </div>

              <div className='button-container-right-aligned'>
                <Button sx={{ marginRight: '16px' }} variant='contained' onClick={onClose}>Cancel</Button>
                <Button
                  disabled={
                  !score || !dateCompleted || !overrideReason || overrideReason === '' ||
                  isLoadingUserInteractionDataStats || isLoadingCourseStats
                  }
                  color='success'
                  variant='contained'
                  onClick={markTrainingComplete}
                >Mark Complete
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Grid>
    </Modal>
  )
}

export default CompleteTrainingModal
