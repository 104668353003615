import React, { useState } from 'react'
import NotificationUtilities from '../notifications/notificationUtils'
import { useHistory } from 'react-router-dom'
import {
  MDBContainer, MDBModal, MDBModalHeader,
  MDBModalBody, MDBModalFooter, MDBBtn,
  MDBCard, MDBCardBody, MDBRow, MDBInput
} from 'mdbreact'
import ValidationUtils from '../../../utils/validationUtils'

function ClientCreator ({ isOpen, closeModal, createClient }) {
  const history = useHistory()

  const [clientName, setClientName] = useState('')
  const [shouldCreateAnotherCompany, setShouldCreateAnotherCompany] = useState(false)

  const handleCancelCreation = () => {
    closeModal()
  }

  const handleCreation = async () => {
    if (ValidationUtils.isNotWhiteSpace(clientName)) {
      NotificationUtilities.sendInfoMessage('Creating your company now...')
      try {
        const companyId = await createClient(clientName)
        if (shouldCreateAnotherCompany) {
          setClientName('')
        } else {
          closeModal()
          history.push(`/companies/${companyId}`)
        }
      } catch (err) {

      }
    } else {
      NotificationUtilities.sendWarningMessage('You must input a valid company name!')
    }
  }

  return (
    <MDBModal modalStyle='info' size='lg' isOpen={isOpen} toggle={() => 'do nothing'}>
      <MDBModalHeader>Company Creator</MDBModalHeader>
      <MDBModalBody>

        <MDBCard>
          <MDBCardBody>
            <MDBContainer>
              <MDBRow>
                <h6>Give this new client a name, then log in and add some users to your new client.</h6>
              </MDBRow>
              <MDBRow className='flex justify-content-center'>
                <MDBInput
                  outline value={clientName} id='new-client-name-input' size='lg' onChange={(e) => {
                    setClientName(e.target.value)
                  }} name='clientName' label='Company Name'
                />
              </MDBRow>
            </MDBContainer>
          </MDBCardBody>
        </MDBCard>

      </MDBModalBody>
      <MDBModalFooter>
        <MDBContainer>
          <MDBRow className='flex flex-row pl-3 pr-3 justify-content-between black-text'>
            <MDBRow>
              <MDBBtn color='red' onClick={handleCancelCreation}>Cancel</MDBBtn>
            </MDBRow>
            <MDBRow className='flex flex-row justify-content-end'>
              <MDBInput id='client-creator-create-another-company-checkbox' aria-label='Create Another Checkbox' label='Create another Company' type='checkbox' checked={shouldCreateAnotherCompany} onChange={() => setShouldCreateAnotherCompany(!shouldCreateAnotherCompany)} className='mr-3' />
              <MDBBtn
                id='create-company-button'
                aria-label='Create Company Button'
                onClick={() => handleCreation(false)}
                color='primary'
              >
                Create Company
              </MDBBtn>
            </MDBRow>
          </MDBRow>
        </MDBContainer>
      </MDBModalFooter>
    </MDBModal>
  )
}

export default ClientCreator
