import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import { useSelector, useDispatch } from 'react-redux'
import { getActiveCampaignsThunk, getCampaignAudienceThunk, getCampaignCoursesWithTotalEnrolledThunk } from '../store/CampaignsSlice'
import { capitalizeFirstLetter } from '../utils/FormattingUtils'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { IoArrowBackOutline, IoEyeSharp } from 'react-icons/io5'
import { Button, Box, IconButton } from '@mui/material'
import { campaignTypes, navigationRoutes, campaignFrequencyIterations } from '../frontendConsts.js'
import { buildCRONscheduleDBObject, getNextCampaignFrequencyIntervalDBObject } from '../utils/dateUtils'
import { DateTime } from 'luxon'
import parser from 'cron-parser'
import Spinner from './components/Spinner'
import { CoursePreviewModal } from '../shared/components/CoursePreviewModal'
import { getCourseCatalogThunk } from '../store/TrainingSlice'
import { AudienceDetailsTable } from './components/audience/AudienceDetailsTable'
import { CampaignSchedulePreviewer } from './components/Campaigns/CampaignSchedulePreviewer'

function TrainingViewer ({ id: companyId }) {
  const history = useHistory()

  const [campaign, setCampaign] = useState(null)
  const [formattedCampaignCourses, setFormattedCampaignCourses] = useState([])
  const [futureCourses, setFutureCourses] = useState([])
  const { campaignId } = useParams()
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewModalHeader, setPreviewModalHeader] = useState('')
  const [course, setCourse] = useState(null)

  const activeCampaigns = useSelector((state) => state.campaigns.activeCampaigns)
  const audience = useSelector((state) => state.campaigns.campaignAudience)
  const campaignCourses = useSelector((state) => state.campaigns.campaignCourses)
  const company = useSelector((state) => state.company.company)
  const { courseCatalog, loaders: trainingLoaders } = useSelector((state) => state.trainings)
  const { isLoadingCourseCatalog } = trainingLoaders
  const isLoadingCampaignCourses = useSelector((state) => state.campaigns.loaders.isLoadingCampaignCourses)
  const isLoadingAudience = useSelector((state) => state.campaigns.loaders.isLoadingCampaignAudience)
  const campaignSchedule = campaign?.campaignFrequency

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getActiveCampaignsThunk(companyId))
    dispatch(getCampaignAudienceThunk({ companyId, campaignId }))
    dispatch(getCampaignCoursesWithTotalEnrolledThunk({ companyId, campaignId }))
    dispatch(getCourseCatalogThunk({ companyId }))
  }, [])

  useEffect(() => {
    if (activeCampaigns && courseCatalog && campaignCourses) {
      const tempActiveCampaign = activeCampaigns.find(campaign => campaign.id === campaignId)
      setCampaign(tempActiveCampaign)

      // Add formatted courses for rows on the Launched Courses and Onboarding Courses tables
      const tempFormattedCampaignCourses = campaignCourses.courses.map((course) => {
        return { ...course, id: course.courseDefinitionId, courseDefinitionId: course.courseDefinitionId, type: course.type, indexPath: course.indexPath }
      })
      setFormattedCampaignCourses(tempFormattedCampaignCourses)

      // Pre-work to grab required fields for course preview from courseCatalog
      const coursesWithPreviewFields = tempActiveCampaign.curriculum.modules.map((module) => {
        // Grab the courseDefinitionId from each path by stripping out the 'course-definition/' string from the start
        // For custom courses, there will also be a 'clients/{companyId}... prefix that needs to be stripped out, so
        // we grab the last element of the split array.
        const courseDefinitionIdSplit = module.path.split('/')
        const courseDefinitionId = courseDefinitionIdSplit[courseDefinitionIdSplit.length - 1]

        // find the index of the course in courseCatalog using the courseDefintion id we stripped out
        const courseDefinition = courseCatalog.findIndex((courseDefinition) => courseDefinition.id === courseDefinitionId)
        // If we find the course, grab what we need to preview a course from the course catalog
        if (courseDefinition !== -1) {
          const { indexPath, type } = courseCatalog[courseDefinition]
          return { ...module, courseDefinitionId, indexPath, type }
        }
        // Course was not defined. Leave the indexPath and type as null and we will error handle this
        // in the renderCoursePreviewButton function.
        return { ...module, courseDefinitionId: null, indexPath: null, type: null }
      })

      // Fill the Future Courses table for fixed/continuous training campaigns
      if (tempActiveCampaign.campaignType === campaignTypes.TRAINING) {
        const tempFutureCourses = coursesWithPreviewFields.filter((module) => !module.moduleId)

        // array of luxon DateTimes
        const fireTimes = calculateFireTimes(tempFutureCourses, tempActiveCampaign)
        try {
          // Each row in a datagrid is required to have an id
          const result = tempFutureCourses.map((module, index) => {
            if (!module.id) {
              // We need to grab the indexPath of each course to show the preview
              return { ...module, id: module.path, scheduledDate: fireTimes[index] }
            } else {
              return { ...module, scheduledDate: fireTimes[index] }
            }
          })
          setFutureCourses(result)
        } catch (error) {
          NotificationUtilities.sendErrorMessage('Failed to load future courses')
        }
      }
    }
  }, [activeCampaigns, campaignCourses, courseCatalog])

  function viewUserTrainingAnalytics (userId) {
    history.push(`/companies/${companyId}${navigationRoutes.ANALYTICS_USERS}/${userId}`, { previousPageTitle: 'Training Campaign Details' })
  }

  const calculateFireTimes = (futureCourses, campaign) => {
    const startingDateTime = DateTime.fromISO(campaign.startTime)
    const startingDate = startingDateTime.toJSDate()

    const campaignFrequency = campaign.campaignFrequency
    // update scheduling dates on component load
    let firstDateToFire
    const tempArr = []
    // Convert the Moment to a JS Date
    const tz = company ? company.timezone.value : 'America/New_York'
    const cron = buildCRONscheduleDBObject(campaignFrequency, startingDateTime)
    const interval = parser.parseExpression(cron, { currentDate: startingDate, tz })
    let datesRendered = 0

    const currentDateTime = new Date()

    const numberOfNextFireTimes = futureCourses.length

    if (campaignFrequency.iterations !== campaignFrequencyIterations.ALL_AT_ONCE) {
      while (datesRendered < numberOfNextFireTimes) {
        const nextFireTime = getNextCampaignFrequencyIntervalDBObject(campaignFrequency, interval, startingDate)
        if (nextFireTime > startingDate) {
          if (!firstDateToFire) {
            firstDateToFire = nextFireTime
          }
          if (nextFireTime > currentDateTime) {
            tempArr.push(nextFireTime)
            datesRendered += 1
          }
        }
      }
    } else {
      const nextFireTime = getNextCampaignFrequencyIntervalDBObject(campaignFrequency, interval, startingDate)

      for (let i = 0; i < numberOfNextFireTimes; i++) {
        tempArr.push(nextFireTime)
      }
    }

    return tempArr
  }

  function renderViewUserButton (user) {
    return (
      <IconButton
        aria-label='View' onClick={() => {
          viewUserTrainingAnalytics(user.id)
        }}
      >
        <IoEyeSharp />
      </IconButton>
    )
  }

  function togglePreviewModal (course, shouldShowPreviewModal) {
    setCourse(course)
    setPreviewModalHeader(course === null ? '' : course.name)
    setShowPreviewModal(shouldShowPreviewModal)
  }

  function renderCoursePreviewButton (course) {
    return (
      <IconButton
        aria-label={`preview-${course.name}-button`}
        onClick={
          course.courseDefinitionId
            ? () => { togglePreviewModal(course, true) }
            : () => { NotificationUtilities.sendErrorMessage('Course Preview was not found in Course Catalog. Please contact Phin Support') }
        }
        style={course.courseDefinitionId ? {} : { color: 'red' }}
        title={course.courseDefinitionId ? '' : 'Unable to load Course Preview'}
      >
        <IoEyeSharp />
      </IconButton>
    )
  }

  const audienceColumns = [
    { field: 'first', headerName: 'First Name', flex: 0.8 },
    { field: 'last', headerName: 'Last Name', flex: 0.8 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 0.5, renderCell: (field) => capitalizeFirstLetter(field.value) },
    { field: 'action', headerName: 'Analytics', flex: 1, renderCell: (params) => renderViewUserButton(params.row) }
  ]

  const onboardingCourseColumns = [
    { field: 'name', headerName: 'Course Name', flex: 1 },
    { field: 'dateStarted', headerName: 'Start Date', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } },
    { field: 'totalEnrolled', headerName: 'Total Enrolled', flex: 1 },
    { field: 'totalCompleted', headerName: 'Total Completed', flex: 1 },
    { field: 'action', headerName: 'Preview', flex: 0.5, renderCell: (params) => { return renderCoursePreviewButton(params.row) } }
  ]
  const futureCourseColumns = [

    { field: 'name', headerName: 'Course Name', flex: 1 },
    { field: 'scheduledDate', headerName: 'Scheduled Assignment Date', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } },
    { field: 'action', headerName: 'Preview', flex: 0.5, renderCell: (params) => { return renderCoursePreviewButton(params.row) } }
  ]

  const futureContinuousCampaignCourseColumns = [

    { field: 'name', headerName: 'Course Name', flex: 1 },
    { field: 'topic', headerName: 'Topic', flex: 1, renderCell: (field) => { return field.value || 'Custom' } },
    { field: 'scheduledDate', headerName: 'Scheduled Assignment Date', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } },
    { field: 'action', headerName: 'Preview', flex: 0.5, renderCell: (params) => { return renderCoursePreviewButton(params.row) } }
  ]

  const pastCourseColumns = [

    { field: 'name', headerName: 'Course Name', flex: 1 },
    { field: 'topic', headerName: 'Topic', flex: 1, renderCell: (field) => { return field.value || 'Custom' } },
    { field: 'dateStarted', headerName: 'Date Assigned', flex: 1, renderCell: (field) => { return new Date(field.value).toLocaleString() } },
    { field: 'totalEnrolled', headerName: 'Total Enrolled', flex: 1 },
    { field: 'totalCompleted', headerName: 'Total Completed', flex: 1 },
    { field: 'action', headerName: 'Preview', flex: 0.5, renderCell: (params) => { return renderCoursePreviewButton(params.row) } }
  ]

  return (
    <>
      <CoursePreviewModal
        companyId={companyId}
        courseDefinition={course}
        previewModalHeader={previewModalHeader}
        showPreviewModal={showPreviewModal}
        togglePreviewModal={togglePreviewModal}
      />
      <NotificationWrapper />
      {(isLoadingAudience || isLoadingCampaignCourses || isLoadingCourseCatalog) && (<Spinner message='Making everything look nice' />)}
      {(!isLoadingAudience && !isLoadingCampaignCourses && !isLoadingCourseCatalog) &&
        <div className='screen-pad d-flex flex-column'>
          <div style={{ alignSelf: 'left' }}>
            <Button component={Link} sx={{ mb: 1 }} variant='text' color='primary' size='small' to={`/companies/${companyId}/training`} onClick={() => null} startIcon={<IoArrowBackOutline />}>Return to Campaign Dashboard</Button>
            <h1 className='phin-h1'>{campaign?.name}</h1>
            <p>{campaign?.description}</p>
          </div>
          {(campaign && campaignSchedule && campaign.campaignType === campaignTypes.TRAINING) && (
            <CampaignSchedulePreviewer campaign={campaign} nextTraining={futureCourses[0]} />
          )}
          <AudienceDetailsTable audience={audience} audienceColumns={audienceColumns} />
          {(campaign && campaign.campaignType === campaignTypes.TRAINING) &&
            <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
              <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Launched Courses</h3>
              <DataGrid
                autoHeight
                style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                className='DataGrid'
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'dateStarted', sort: 'asc' }]
                  }
                }}
                rows={formattedCampaignCourses || []}
                columns={pastCourseColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      debounceMs: 250,
                      id: 'launched-courses-table-search',
                      ariaLabel: 'Search'
                    },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                disableColumnSelector
                disableDensitySelector
              />
            </Box>}
          {(campaign && campaign.campaignType === campaignTypes.TRAINING) && (
            <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
              <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Future Courses</h3>
              <DataGrid
                autoHeight
                style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                className='DataGrid'
                rows={futureCourses || []}
                columns={(campaign && campaign.isContinuous)
                  ? futureContinuousCampaignCourseColumns
                  : futureCourseColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      debounceMs: 250,
                      id: 'future-courses-table-search',
                      ariaLabel: 'Search'
                    },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                disableColumnSelector
                disableDensitySelector
              />
            </Box>
          )}
          {campaign && campaign.campaignType === campaignTypes.ONBOARDING && (
            <Box sx={{ paddingBottom: '2em', width: '100%', height: 'auto' }}>
              <h3 className='phin-h4' style={{ paddingBottom: '0.5em' }}>Courses</h3>
              <DataGrid
                autoHeight
                style={{ borderRadius: '8px', border: '2px solid var(--phin-light-gray)' }}
                className='DataGrid'
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }]
                  }
                }}
                rows={formattedCampaignCourses || []}
                columns={onboardingCourseColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true }
                  }
                }}
                disableColumnSelector
                disableDensitySelector
              />
            </Box>
          )}

        </div>}
    </>
  )
}

export default TrainingViewer
