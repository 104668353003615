import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBBtn,
  MDBRow,
  MDBIcon,
  MDBAlert,
  MDBInput,
  MDBTooltip
} from 'mdbreact'
import React from 'react'

const renderTextWaring = (sendingChannels) => {
  if (sendingChannels.sms === true) {
    return (
      <MDBAlert className='animated fadeInDown' color='warning'>
        <MDBRow>
          <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
            <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='exclamation-triangle' />
          </MDBCol>
          <MDBCol>
            You have selected to send SMS Messages to your users. Please ensure you have the correct 10 digit phone number for each user
            or we cannot guarantee proper delivery.
          </MDBCol>
        </MDBRow>
      </MDBAlert>
    )
  } else {
    return (<></>)
  }
}

const CampaignSendingChannelEditor = ({ sendingChannels, updateSendingChannels, disableUpdating = false }) => {
  return (
    <MDBCard className='mt-2 w-100'>
      <MDBCardHeader>
        <MDBRow className='d-flex'>
          <MDBCol className='d-flex justify-content-start align-items-center'>
            <h5>Sending Channels</h5>
          </MDBCol>
          <MDBCol className='d-flex justify-content-end'>
            <MDBTooltip
              placement='left'
              id='templates'
              material
            >
              <MDBBtn
                color='elegant'
                className='my-0'
                floating
                tag='a'
                size='sm'
              >
                <MDBIcon icon='question' />
              </MDBBtn>
              <div>
                Here you can select the channels from which you would like your users to receive phishes. For Example: Email & SMS
              </div>
            </MDBTooltip>
          </MDBCol>
        </MDBRow>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol size='3' className='d-flex justify-content-center'>
            <MDBInput label='SMS' disabled={disableUpdating} type='checkbox' id='SMSCheckBox' checked={sendingChannels.sms} onChange={() => updateSendingChannels('sms')} />
          </MDBCol>
          <MDBCol size='3' className='d-flex justify-content-center'>
            <MDBInput label='Email' disabled={disableUpdating} type='checkbox' id='emailCheckBox' checked={sendingChannels.email} onChange={() => updateSendingChannels('email')} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {renderTextWaring(sendingChannels)}
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default CampaignSendingChannelEditor
