import React, { Component } from 'react'
import NotificationUtilities from '../notifications/notificationUtils'
import {
  MDBContainer, MDBModal, MDBModalHeader, MDBModalBody,
  MDBModalFooter, MDBBtn,
  MDBCard, MDBCardBody, MDBRow, MDBInput,
  MDBCol
} from 'mdbreact'

import ValidationUtils from '../../../utils/validationUtils'
import { Alert, Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'

class AdminCreator extends Component {
  constructor (props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      billingPermission: 'standard'
    }
  }

  async handleCreation () {
    NotificationUtilities.sendInfoMessage('Creating your new admin now...')

    const firstName = this.state.firstName.trim()
    const lastName = this.state.lastName.trim()

    const email = this.state.email.trim()
    const billingPermission = this.state.billingPermission

    // Run validation
    if (!ValidationUtils.isNotWhiteSpace(firstName)) {
      NotificationUtilities.sendWarningMessage('You must have a first name for the admin')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(lastName)) {
      NotificationUtilities.sendWarningMessage('You must have a last name for the admin')
      return
    }

    if (!ValidationUtils.isValidEmail(email)) {
      NotificationUtilities.sendWarningMessage('You must have a valid email for the admin')
      return
    }

    if (!billingPermission) {
      NotificationUtilities.sendWarningMessage('you must select a billing permission for the admin')
      return
    }

    if (billingPermission === 'full-access' && !this.props.isBillingAdmin) {
      NotificationUtilities.sendWarningMessage('You cannot grant full access to an admin if you are not a billing admin, contact your main admin for support')
      return
    }

    await this.props.createAdmin(firstName, lastName, email, billingPermission)
  }

  render () {
    return (
      <MDBContainer>
        <MDBModal modalStyle='info' size='lg' isOpen={this.props.isOpen} toggle={() => 'do nothing'}>
          <MDBModalHeader>Admin Creator</MDBModalHeader>
          <MDBModalBody>
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBContainer>
                    <h6>Fill in the information for the new admin below. These admins will have full access to partner clients.</h6>
                  </MDBContainer>
                </MDBRow>
                <MDBRow className='d-flex justify-content-center'>
                  <MDBCol size='4'>
                    <MDBInput
                      outline onChange={(e) => {
                        this.setState({ firstName: e.target.value })
                      }}
                      name='newUName'
                      label='First Name'
                      id='first-name-field'
                      aria-label='First Name Field'
                    />
                  </MDBCol>
                  <MDBCol size='4'>
                    <MDBInput
                      outline onChange={(e) => {
                        this.setState({ lastName: e.target.value })
                      }}
                      name='newUName'
                      label='Last Name'
                      id='last-name-field'
                      aria-label='Last Name Field'
                    />
                  </MDBCol>
                  <MDBCol size='4'>
                    <MDBInput
                      outline onChange={(e) => {
                        this.setState({ email: e.target.value })
                      }}
                      name='newUEmail'
                      label='Email'
                      id='email-field'
                      aria-label='Email Field'
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <Box mt='1rem'>
            <Typography m='1rem'>Select permission levels for this Admin</Typography>
            <Alert severity="info" sx={{marginBottom: '1rem'}}>Full Access grants users permission to view & edit billing information under Billing as well as edit user access setting under Administrators. Standard access only has all admin functionalities outside of billing</Alert>
              <FormControl sx={{ m: 1, minWidth: 80 }} size='medium'>
              <InputLabel id="demo-simple-select-autowidth-label">Permission</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={this.state.billingPermission}
                    onChange={(event) => {
                      this.setState({ billingPermission: event.target.value })
                    }}
                    label="Permissions"
                  >
                    <MenuItem value={'full-access'}>Full Access</MenuItem>
                    <MenuItem value={'standard'}>Standard</MenuItem>
                  </Select>
              </FormControl>
            </Box>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color='red'
              onClick={() => this.props.closeModal()}
              id='cancel-admin-creation-button'
              aria-label='Cancel Admin Creation Button'
            >Cancel
            </MDBBtn>
            <MDBBtn
              onClick={() => this.handleCreation()}
              color='primary'
              id='create-admin-button'
              aria-label='Create Admin Button'
            >Create
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    )
  }
}

export default AdminCreator
