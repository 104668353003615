import { navigationRoutes } from '../../../frontendConsts'
import {
  MDBSideNavCat,
  MDBSideNavLink,
  MDBIcon,
  MDBSideNavNav
} from 'mdbreact'

function CompanyNavRoutes ({ id }) {
  return (
    // Routing to any of these will cause the routers to
    // remount and refetch all the company/partner/distributor APIs
    <MDBSideNavNav>
      <MDBSideNavLink
        to={`/companies/${id}`}
        id='home-page'
        className='nav-icon'
      >
        <MDBIcon fixed icon='home' className='mr-2' />
        Home
      </MDBSideNavLink>

      <MDBSideNavCat className='nav-icon' name='Phishing' id='phishingPageNavLink' icon='mail-bulk'>
        <MDBSideNavLink
          to={`/companies/${id}${navigationRoutes.CAMPAIGNS}`}
          id='campaignPageNavLink'
        >
          Dashboard
        </MDBSideNavLink>
        <MDBSideNavLink
          to={`/companies/${id}${navigationRoutes.CAMPAIGNPRESETS}`}
          id='presetPageLibraryNavLink'
        >
          Preset Library
        </MDBSideNavLink>
      </MDBSideNavCat>

      <MDBSideNavCat className='nav-icon' name='Training' id='trainingPageNavLink' icon='graduation-cap'>
        <MDBSideNavLink
          to={`/companies/${id}/training`}
          id='trainingPageTrainingNavLink'
        >
          Dashboard
        </MDBSideNavLink>
        <MDBSideNavLink
          to={`/companies/${id}/training/catalog`}
          id='trainingPageCatalogNavLink'
        >
          Catalog
        </MDBSideNavLink>
      </MDBSideNavCat>

      <MDBSideNavCat className='nav-icon' name='Policy' id='PolicyPageNavLink' icon='file-alt'>
        <MDBSideNavLink
          to={`/companies/${id}/policy/active`}
          id='policyPageTrainingNavLink'
        >
          Active
        </MDBSideNavLink>
        <MDBSideNavLink
          to={`/companies/${id}/policy/catalog`}
          id='policyPageCatalogNavLink'
        >
          Catalog
        </MDBSideNavLink>
      </MDBSideNavCat>

      <MDBSideNavLink
        to={`/companies/${id}/users`}
        id='usersPageNavLink'
        className='nav-icon'
      >
        <MDBIcon fixed icon='user-cog' className='mr-2' />
        Users
      </MDBSideNavLink>
      <MDBSideNavLink
        id='reportingPageNavLink'
        to={`/companies/${id}/reports`}
        className='nav-icon'
      >
        <MDBIcon fixed icon='chart-line' className='mr-2' />
        Reporting
      </MDBSideNavLink>
      <MDBSideNavCat className='nav-icon' name='Analytics' id='analyticsPageNavLink' icon='chart-pie'>
        <MDBSideNavLink
          to={`/companies/${id}/analytics/phishing`}
          id='phishingAnalyticsPageNavLink'
        >
          Phishing
        </MDBSideNavLink>
        <MDBSideNavLink
          to={`/companies/${id}/analytics/training`}
          id='trainingAnalyticsPageNavLink'
        >
          Training
        </MDBSideNavLink>
        <MDBSideNavLink
          to={`/companies/${id}/analytics/policies`}
          id='policiesAnalyticsPageNavLink'
        >
          Policies
        </MDBSideNavLink>
      </MDBSideNavCat>

      <MDBSideNavLink
        to={`/companies/${id}/templates`}
        id='templates-nav-link'
        className='nav-icon'
      >
        <MDBIcon fixed icon='file-code' className='mr-2' />
        Templates
      </MDBSideNavLink>

      <MDBSideNavLink
        to={`/companies/${id}/resources`}
        id='resources-nav-link'
        className='nav-icon'
      >
        <MDBIcon
          fixed
          icon='copy'
          className='mr-2 mt-2'
        />
        Resources
      </MDBSideNavLink>

      <MDBSideNavLink
        to={`/companies/${id}/integrations`}
        id='integrations-nav-link'
        className='nav-icon'
      >
        <MDBIcon
          icon='toolbox'
          className='mr-2'
        />
        Integrations
      </MDBSideNavLink>

      <MDBSideNavLink
        to={`/companies/${id}/messages`}
        id='logs-nav-link'
        className='nav-icon'
      >
        <MDBIcon
          icon='plane-departure'
          className='mr-2'
        />
        Outgoing Messages
      </MDBSideNavLink>

      <MDBSideNavLink
        to={`/companies/${id}/branding`}
        id='company-branding-nav-link'
        className='nav-icon'
      >
        <MDBIcon
          fixed
          icon='palette'
          className='mr-2'
        />
        Branding
      </MDBSideNavLink>

      <MDBSideNavLink
        to={`/companies/${id}/settings`}
        id='settings-nav-link'
        className='nav-icon'
      >
        <MDBIcon
          icon='cog'
          className='mr-2'
        />
        Settings
      </MDBSideNavLink>
    </MDBSideNavNav>
  )
}

export default CompanyNavRoutes
