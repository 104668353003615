import React, { useState } from 'react'
import ViewerModal from '../../ViewerModal'
import TemplatesPreviewer from '../TemplatesPreviewer'
import {
  MDBRow,
  MDBCardBody,
  MDBCard,
  MDBCardHeader,
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBTooltip
} from 'mdbreact'

import CampaignSendingChannelEditor from '../CampaignSendingChannelEditor.js'
import { campaignTypes } from '../../../../frontendConsts.js'

const previewTemplate = (template, setModalViewer, setSelectedTemplate) => {
  setModalViewer(true)
  setSelectedTemplate(template)
}

const makeTemplateRow = (template, setModalViewer, setSelectedTemplate) => {
  return ({
    name: template.name,
    options: (
      <MDBTooltip placement='top'>
        <MDBBtn size='sm' title='View Templates' rounded onClick={() => { previewTemplate(template, setModalViewer, setSelectedTemplate) }}><MDBIcon size='lg' far icon='eye' /></MDBBtn>
        <div>View Template</div>
      </MDBTooltip>)
  })
}

const makeCategoryRow = (category, allTemplates, setTemplatesPreviewerModal, setCategoryTemplates) => {
  const templates = []

  allTemplates.forEach((template) => {
    if (template.tags) {
      if (template.tags.includes(category)) {
        templates.push(template)
      }
    }
  })
  return ({
    name: category,
    options: (
      <MDBTooltip placement='top'>
        <MDBBtn size='sm' rounded onClick={() => { previewTemplates(templates, setTemplatesPreviewerModal, setCategoryTemplates) }}><MDBIcon size='lg' far icon='eye' /></MDBBtn>
        <div>View Category</div>
      </MDBTooltip>)
  })
}

const previewTemplates = (templates, setTemplatesPreviewerModal, setCategoryTemplates) => {
  setTemplatesPreviewerModal(true)
  setCategoryTemplates(templates)
}

const generateTemplateTable = (selectedCampaign, allTemplates, setModalViewer, setSelectedTemplate) => {
  const campaignTemplates = []
  for (const templateID of selectedCampaign.templateNames) {
    const template = allTemplates.find((template) => template.id === templateID)
    if (template) {
      campaignTemplates.push(template)
    }
  }

  return {
    columns: [
      {
        label: 'Template Name',
        field: 'name'
      },
      {
        label: 'View Template',
        field: 'options'
      }
    ],
    rows: campaignTemplates.map(template => makeTemplateRow(template, setModalViewer, setSelectedTemplate))
  }
}

const generateCategoryTable = (selectedCampaign, { allTemplates, allCategories }, setTemplatesPreviewerModal, setCategoryTemplates) => {
  const campaignCategories = []

  selectedCampaign.categoryNames.forEach(categoryName => {
    const category = allCategories.find(category => category === categoryName)
    if (category) {
      campaignCategories.push(category)
    }
  })
  return {
    columns: [
      {
        label: 'Category Name',
        field: 'name'
      },
      {
        label: 'Options',
        field: 'options'
      }
    ],
    rows: campaignCategories.map(category => makeCategoryRow(category, allTemplates, setTemplatesPreviewerModal, setCategoryTemplates))
  }
}

const ContentViewer = ({ selectedCampaign, content }) => {
  const isContinuous = (selectedCampaign.campaignType === campaignTypes.CONTINUOUS)
  const [modalViewer, setModalViewer] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState({})

  const [templatesPreviewerModal, setTemplatesPreviewerModal] = useState(false)
  const [categoryTemplates, setCategoryTemplates] = useState([])

  const handleToggleViewerModal = (html) => {
    setModalViewer(!modalViewer)
    setSelectedTemplate({})
  }

  const handleToggleTemplatesPreviewerModal = (templates) => {
    setTemplatesPreviewerModal(!templatesPreviewerModal)
    setCategoryTemplates([])
  }

  const tableData = (isContinuous) ? generateCategoryTable(selectedCampaign, content, setTemplatesPreviewerModal, setCategoryTemplates) : generateTemplateTable(selectedCampaign, content, setModalViewer, setSelectedTemplate)
  const sendingChannelViewer = (isContinuous) ? <CampaignSendingChannelEditor disableUpdating updateSendingChannels={() => {}} sendingChannels={selectedCampaign.sendingChannels} /> : (<></>)
  return (
    <>
      <ViewerModal isOpen={modalViewer} toggleViewerModal={handleToggleViewerModal} template={selectedTemplate} />
      <TemplatesPreviewer toggleModal={handleToggleTemplatesPreviewerModal} isOpen={templatesPreviewerModal} templates={categoryTemplates} />
      {sendingChannelViewer}
      <MDBCard className='w-100 mt-2'>
        <MDBCardHeader><h5><strong>Campaign {isContinuous ? 'Categories' : 'Templates'}</strong></h5></MDBCardHeader>
        <MDBCardBody>
          <MDBRow className='d-flex justify-content-center align-items-center'>
            <MDBDataTable
              disableRetreatAfterSorting
              btn
              className='justify-content-center align-self-center text-center w-100'
              responsive
              sortable={false}
              paging={false}
              searching={false}
              striped
              data={tableData}
            />
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </>
  )
}

export default ContentViewer
