import moment from 'moment'
import parser from 'cron-parser'
import { DateTime } from 'luxon'
const time24Regex = /^([0-2][0-9]):([0-5][0-9])$/

export const getYearAndNextMonth = (startDate = new Date()) => {
  const nextMonth = (startDate.getMonth() === 11 ? 0 : startDate.getMonth() + 1)
  const year = (nextMonth === 0) ? startDate.getFullYear() + 1 : startDate.getFullYear()
  return { year, nextMonth }
}

export const calculateStartTime = (startDate = new Date()) => {
  const { year, nextMonth } = getYearAndNextMonth(startDate)

  // this calculates the first monday of a month in all cases
  let firstMonday = moment().set('year', year).set('month', nextMonth).set('date', 1).isoWeekday(8)
  firstMonday = (firstMonday.date() === 8 ? firstMonday.isoWeekday(-6) : firstMonday)

  return firstMonday
}

const weekNumberToRange = (week) => {
  if (week === 1) {
    return [1, 7]
  } else if (week === 2) {
    return [8, 14]
  } else if (week === 3) {
    return [15, 21]
  } else if (week === 4) {
    return [22, 28]
  } else {
    throw new Error('Invalid Week Provided')
  }
}

// This takes a luxon datetime
export const buildCRONschedule = (campaignFrequency, startDateTime) => {
  const matches = campaignFrequency.time.match(time24Regex)
  const hours = matches[1].replace(/^0+/, '') || '0'
  const minutes = matches[2].replace(/^0+/, '') || '0'
  if (campaignFrequency.iterations.value === 'All At Once') {
    // Luxon DateTimes are 1 indexed for months. Jan=1, same as CRON
    return `${minutes} ${hours} ${startDateTime.day} ${startDateTime.month} *`
  }
  if (campaignFrequency.frequency.value === 'Quarter') {
    if (campaignFrequency.month.value === 1 || campaignFrequency.month.value === '1') {
      return `${minutes} ${hours} * 1,4,7,10 ${campaignFrequency.weekday.value}`
    } else if (campaignFrequency.month.value === 2 || campaignFrequency.month.value === '2') {
      return `${minutes} ${hours} * 2,5,8,11 ${campaignFrequency.weekday.value}`
    } else if (campaignFrequency.month.value === 3 || campaignFrequency.month.value === '3') {
      return `${minutes} ${hours} * 3,6,9,12 ${campaignFrequency.weekday.value}`
    }
  } else if (campaignFrequency.frequency.value === 'Week' || campaignFrequency.frequency.value === 'Month') {
    return `${minutes} ${hours} * * ${campaignFrequency.weekday.value}`
  }
}

export const buildCRONscheduleDBObject = (campaignFrequency, startDateTime) => {
  const matches = campaignFrequency.time.match(time24Regex)
  const hours = matches[1].replace(/^0+/, '') || '0'
  const minutes = matches[2].replace(/^0+/, '') || '0'
  if (campaignFrequency.iterations === 'All At Once') {
    return `${minutes} ${hours} ${startDateTime.day} ${startDateTime.month} *`
  }
  if (campaignFrequency.frequency === 'Quarter') {
    if (campaignFrequency.month === 1) {
      return `${minutes} ${hours} * 1,4,7,10 ${campaignFrequency.weekday}`
    } else if (campaignFrequency.month === 2) {
      return `${minutes} ${hours} * 2,5,8,11 ${campaignFrequency.weekday}`
    } else if (campaignFrequency.month === 3) {
      return `${minutes} ${hours} * 3,6,9,12 ${campaignFrequency.weekday}`
    }
  } else if (campaignFrequency.frequency === 'Week' || campaignFrequency.frequency === 'Month') {
    return `${minutes} ${hours} * * ${campaignFrequency.weekday}`
  }
}

export const getNextCampaignFrequencyInterval = (campaignFrequency, interval) => {
  let nextFireDate = new Date(interval.next().toString())
  if (campaignFrequency.iterations.value === 'Once') {
    if (campaignFrequency.frequency.value === 'Month' || campaignFrequency.frequency.value === 'Quarter') {
      let dayOfMonth = nextFireDate.getDate()
      if (campaignFrequency.week.length !== 1) {
        throw new Error('Invalid number of Weeks provided')
      }
      const [weekRange] = campaignFrequency.week.map(week => weekNumberToRange(+week.value))
      while (!(dayOfMonth >= weekRange[0] && dayOfMonth <= weekRange[1])) {
        nextFireDate = new Date(interval.next().toString())
        dayOfMonth = nextFireDate.getDate()
      }
    } else if (campaignFrequency.frequency.value === 'Week') {
      return nextFireDate
    } else {
      throw new Error('Invalid Frequency Provided')
    }
  } else if (campaignFrequency.iterations.value === 'Twice') {
    if (campaignFrequency.frequency.value === 'Month') {
      let dayOfMonth = nextFireDate.getDate()
      if (campaignFrequency.week.length !== 2) {
        throw new Error('Please select two weeks of the month to send content or change the frequency.')
      }
      const [week1Range, week2Range] = campaignFrequency.week.map(week => weekNumberToRange(+week.value))
      while (!(dayOfMonth >= week1Range[0] && dayOfMonth <= week1Range[1]) && !(dayOfMonth >= week2Range[0] && dayOfMonth <= week2Range[1])) {
        nextFireDate = new Date(interval.next().toString())
        dayOfMonth = nextFireDate.getDate()
      }
    } else {
      throw new Error('Invalid Frequency Provided')
    }
  } else if (campaignFrequency.iterations.value !== 'All At Once') {
    throw new Error('Invalid Iterations Provided')
  }
  return nextFireDate
}

export const getNextCampaignFrequencyIntervalDBObject = (campaignFrequency, interval, startDateTime) => {
  let nextFireDate = interval.next()._date
  if (campaignFrequency.iterations === 'All At Once') {
    const matches = campaignFrequency.time.match(time24Regex)
    const hours = matches[1].replace(/^0+/, '') || '0'
    const minutes = matches[2].replace(/^0+/, '') || '0'
    const nextFireDateTime = new Date(startDateTime)

    nextFireDateTime.setHours(hours)
    nextFireDateTime.setMinutes(minutes)

    return nextFireDateTime
  }
  if (campaignFrequency.iterations === 'Once') {
    if (campaignFrequency.frequency === 'Month' || campaignFrequency.frequency === 'Quarter') {
      let dayOfMonth = nextFireDate.day
      const [weekRange] = campaignFrequency.week.map(week => weekNumberToRange(week))
      while (!(dayOfMonth >= weekRange[0] && dayOfMonth <= weekRange[1])) {
        nextFireDate = interval.next()._date
        dayOfMonth = nextFireDate.day
      }
    } else if (campaignFrequency.frequency === 'Week') {
      return nextFireDate
    }
  } else if (campaignFrequency.iterations === 'Twice') {
    if (campaignFrequency.frequency === 'Month') {
      let dayOfMonth = nextFireDate.day
      const [week1Range, week2Range] = campaignFrequency.week.map(week => weekNumberToRange(week))
      while (!(dayOfMonth >= week1Range[0] && dayOfMonth <= week1Range[1]) && !(dayOfMonth >= week2Range[0] && dayOfMonth <= week2Range[1])) {
        nextFireDate = interval.next()._date
        dayOfMonth = nextFireDate.day
      }
    }
  }
  return nextFireDate
}

export const calculateEndTime = (numberOfItems, startTime, tz, campaignFrequency) => {
  let endTime = moment(startTime)

  if (campaignFrequency.iterations.value === 'All At Once') {
    endTime = moment(startTime).add(3, 'days')
  } else {
    const cron = buildCRONschedule(campaignFrequency)
    const interval = parser.parseExpression(cron, { tz, currentDate: startTime.toDate() })
    for (let i = 0; i < numberOfItems; i++) {
      endTime = getNextCampaignFrequencyInterval(campaignFrequency, interval)
    }

    endTime = moment(endTime).add(1, 'days')
  }
  return endTime
}

export const determinePhishingScheduleString = (campaign, companyTimeZone) => {
// iterations - the number of iterations per frequency
//      'All At Once', 'Once', or 'Twice' (Only if frequency is 'Month')
// frequency - the frequency we want to launch
//      'Week', 'Month', or 'Quarter'
// week - the weeks that we want to launch
//      always 1 for weekly, 1-4 and have 1 or 2 values for monthly based on iterations
// weekday - the day of the week we want to launch
//      0-6, 0 is Sunday, 6 is Saturday
// month - the month of the quarter that we want to launch
//      null if weekly or monthly, 1-3 for quarterly
  const campaignSchedule = campaign?.campaignFrequency

  const calcFromTime =
      (DateTime.local().setZone(companyTimeZone) > DateTime.fromISO(campaign.startTime))
        ? DateTime.local()
        : DateTime.fromISO(campaign.startTime)

  // Convert our weekday number to Luxon's weekday number
  // Phin: 0 - Sunday, 6 - Saturday
  // Luxon: 1 - Monday, 7 - Sunday
  const weekday = campaignSchedule.weekday === 0 ? 7 : campaignSchedule.weekday

  // Init our DateTimes
  let nextLaunchDate, currentFrequencyLaunchTime, nextFrequencyLaunchTime

  // Campaigns firing once per iteration (Weekly, Monthly, Quarterly)
  if (campaignSchedule?.iterations === 'Once') {
    if (campaignSchedule.frequency === 'Quarter') {
      currentFrequencyLaunchTime = calcFromTime.startOf('quarter')
        .plus({ months: campaignSchedule.month - 1 }).startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
      nextFrequencyLaunchTime = calcFromTime.startOf('quarter').plus({ quarters: 1 })
        .plus({ months: campaignSchedule.month - 1 }).startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
    } else if (campaignSchedule.frequency === 'Month') {
      currentFrequencyLaunchTime = calcFromTime.startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
      nextFrequencyLaunchTime = calcFromTime.startOf('month').plus({ months: 1 })
        .plus({ weeks: campaignSchedule.week[0] - 1 })
    } else if (campaignSchedule.frequency === 'Week') {
      currentFrequencyLaunchTime = calcFromTime.startOf('week')
      nextFrequencyLaunchTime = calcFromTime.startOf('week').plus({ weeks: 1 })
    }
    // Adjust the week if the calculated weekday is greater than the weekday we set
    // This is important for the first week of a given month
    (currentFrequencyLaunchTime.weekday > weekday)
      ? currentFrequencyLaunchTime = currentFrequencyLaunchTime.plus({ weeks: 1 }).set({ weekday })
      : currentFrequencyLaunchTime = currentFrequencyLaunchTime.set({ weekday });

    (nextFrequencyLaunchTime.weekday > weekday)
      ? nextFrequencyLaunchTime = nextFrequencyLaunchTime.plus({ weeks: 1 }).set({ weekday })
      : nextFrequencyLaunchTime = nextFrequencyLaunchTime.set({ weekday });

    // Decide which launch date is next
    (calcFromTime < currentFrequencyLaunchTime)
      ? nextLaunchDate = currentFrequencyLaunchTime
      : nextLaunchDate = nextFrequencyLaunchTime

    return {
      start: nextLaunchDate.toFormat('MM/dd/yyyy'),
      end: nextLaunchDate.plus({ days: 3 }).toFormat('MM/dd/yyyy')
    }
  }
  // Campaigns firing twice per iteration (Monthly Only)
  if (campaignSchedule?.iterations === 'Twice') {
    if (campaignSchedule.frequency === 'Month') {
      let firstIterationLaunchTime = calcFromTime.startOf('month')
        .plus({ weeks: campaignSchedule.week[0] - 1 })
      let secondIterationLaunchTime = calcFromTime.startOf('month')
        .plus({ weeks: campaignSchedule.week[1] - 1 })

      // Grab the next cycle's first iteration if the both iterations are in the past
      if (firstIterationLaunchTime < calcFromTime && secondIterationLaunchTime < calcFromTime) {
        firstIterationLaunchTime = firstIterationLaunchTime.plus({ months: 1 })
      }

      (firstIterationLaunchTime.weekday > weekday)
        ? firstIterationLaunchTime = firstIterationLaunchTime.plus({ weeks: 1 }).set({ weekday })
        : firstIterationLaunchTime = firstIterationLaunchTime.set({ weekday });

      (secondIterationLaunchTime.weekday > weekday)
        ? secondIterationLaunchTime = secondIterationLaunchTime.plus({ weeks: 1 }).set({ weekday })
        : secondIterationLaunchTime = secondIterationLaunchTime.set({ weekday });

      // Decide which launch date is next
      (calcFromTime < firstIterationLaunchTime)
        ? nextLaunchDate = firstIterationLaunchTime
        : nextLaunchDate = secondIterationLaunchTime

      return {
        start: nextLaunchDate.toFormat('MM/dd/yyyy'),
        end: nextLaunchDate.plus({ days: 3 }).toFormat('MM/dd/yyyy')
      }
    }
  }
}
