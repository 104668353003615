import React from 'react'
import { learningTypes } from '../../frontendConsts.js'
import TableBadge from './TableBadge'

function renderBadge (iconName, description, color) {
  return <TableBadge iconName={iconName} description={description} color={color} />
}

function renderChannelBadge (channel) {
  if (channel === 'email') {
    return renderBadge('envelope', 'Email')
  } else {
    return renderBadge('sms', 'Text Message', 'warning dark')
  }
}

function renderLearningTypeBadge (learningType) {
  if (learningType === learningTypes.LM) {
    return renderBadge('lightbulb', 'Learning Moment')
  } else if (learningType === learningTypes.CUSTOM) {
    return renderBadge('external-link-alt', 'Custom Redirect', 'secondary dark')
  } else if (learningType === learningTypes.VIDEO) {
    return renderBadge('video', 'Video', 'success dark')
  } else {
    return renderBadge('lightbulb', 'Learning Moment')
  }
}

function TemplateBadge ({ channel, learningType }) {
  if (channel) {
    return renderChannelBadge(channel)
  } else {
    return renderLearningTypeBadge(learningType)
  }
}

export default TemplateBadge
