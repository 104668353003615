// Private utility methods

/**
 * A raw wrapper function to connect to the API. Is not exposed.
 * @param {string} route The API route to hit
 * @param {string} head The headers to be sent with this request
 */
const fetchGetRaw = async (route, head, accessToken, cuid) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    cuid
  }

  if (head !== null) {
    Object.keys(head).forEach(key => {
      headers[key] = head[key]
    })
  }

  const res = await fetch(route, {
    method: 'GET',
    headers
  })
  return res
}

/**
 * A raw wrapper function to connect to the API. Is not exposed.
 * @param {string} route The API route to hit
 * @param {string} head The headers to be sent with this request
 */
const fetchPutRaw = async (route, head, body, accessToken, cuid) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    cuid
  }

  const finishedBody = {}

  if (head !== null) {
    Object.keys(head).forEach(key => {
      headers[key] = head[key]
    })
  }

  if (body !== null) {
    Object.keys(body).forEach(key => {
      finishedBody[key] = body[key]
    })
  }

  const res = await fetch(route, {
    method: 'PUT',
    headers,
    body: JSON.stringify(finishedBody)
  })

  return res
}

/**
 * A raw wrapper function to connect to the API. Is not exposed.
 * @param {string} route The API route to hit
 * @param {string} head The headers to be sent with this request
 */
const fetchPostRaw = async (route, head, body, accessToken, cuid) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    cuid
  }

  const finishedBody = {}

  if (head !== null) {
    Object.keys(head).forEach(key => {
      headers[key] = head[key]
    })
  }

  if (body !== null) {
    Object.keys(body).forEach(key => {
      finishedBody[key] = body[key]
    })
  }

  const res = await fetch(route, {
    method: 'POST',
    headers,
    body: JSON.stringify(finishedBody)
  })

  return res
}

/**
 * A raw wrapper function to connect to the API. Is not exposed.
 * @param {string} route The API route to hit
 * @param {string} head The headers to be sent with this request
 */
const fetchPostFormRaw = async (route, head, body, accessToken, cuid) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    cuid
  }

  if (head !== null) {
    Object.keys(head).forEach(key => {
      headers[key] = head[key]
    })
  }

  const res = await fetch(route, {
    method: 'POST',
    headers,
    body
  })

  return res
}

/**
 * A raw wrapper function to connect to the API. Is not exposed.
 * @param {string} route The API route to hit
 * @param {string} head The headers to be sent with this request
 */
const fetchDeleteRaw = async (route, head, body, accessToken, cuid) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    cuid
  }

  const finishedBody = {}

  if (head !== null) {
    Object.keys(head).forEach(key => {
      headers[key] = head[key]
    })
  }

  if (body !== null) {
    Object.keys(body).forEach(key => {
      finishedBody[key] = body[key]
    })
  }

  const res = await fetch(route, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(finishedBody)
  })

  return res
}

/**
 * Provides middleware to properly access the API
 */
class APIRequestUtils {
  setAccessToken (newToken) {
    this.accessToken = newToken
  }

  setCuid (cuid) {
    this.cuid = cuid
  }

  setLoginWithRedirect (loginWithRedirect) {
    this.loginWithRedirect = loginWithRedirect
  }

  /**
   * Checks and handles various special response codes that should trigger a sign out before passing it along to the caller
   * @param {Response} res
   */
  async handleResponseCodes (res) {
    if (res.status === 401) {
      this.loginWithRedirect()
    }

    if (res.status === 429) {
      this.loginWithRedirect()
    }
  }

  /**
   * Sends a GET request to the specified API route
   * @param {string} route The route as a string (ie '/api/cuidLookup')
   * @param {{}} headers The headers as a JSON object
   * @returns {Response} A fetch API response object
   */
  async get (route, headers = null) {
    const res = await fetchGetRaw(route, headers, this.accessToken, this.cuid)
    await this.handleResponseCodes(res)

    return res
  }

  /**
     * Sends a POST request to the specified API route
     * @param {string} route The route as a string (ie '/api/cuidLookup')
     * @param {{}} body The body as a JSON object
     * @param {{}} headers The headers as a JSON object
     */
  async post (route, body = null, headers = null) {
    const res = await fetchPostRaw(route, headers, body, this.accessToken, this.cuid)

    await this.handleResponseCodes(res)

    return res
  }

  /**
     * Sends a PUT request to the specified API route
     * @param {string} route The route as a string (ie '/api/cuidLookup')
     * @param {{}} body The body as a JSON object
     * @param {{}} headers The headers as a JSON object
     */
  async put (route, body = null, headers = null) {
    const res = await fetchPutRaw(route, headers, body, this.accessToken)

    await this.handleResponseCodes(res)

    return res
  }

  /**
     * Sends a POST request to the specified API route with a Form as the body
     * @param {string} route The route as a string (ie '/api/cuidLookup')
     * @param {{}} body The body as a FormData object
     * @param {{}} headers The headers as a JSON object
     */
  async postForm (route, body = null, headers = null) {
    const res = await fetchPostFormRaw(route, headers, body, this.accessToken, this.cuid)

    await this.handleResponseCodes(res)

    return res
  }

  /**
     * Sends a DELETE request to the specified API route
     * @param {string} route The route as a string (ie '/api/cuidLookup')
     * @param {{}} body The body as a JSON object
     * @param {{}} headers The headers as a JSON object
     */
  async delete (route, body = null, headers = null) {
    const res = await fetchDeleteRaw(route, headers, body, this.accessToken, this.cuid)

    await this.handleResponseCodes(res)

    return res
  }

  getCourseRootPath (courseDefinition) {
    return `/api/${this.accessToken}/course-assets/${courseDefinition.courseDefinitionId || courseDefinition.id}/${courseDefinition.indexPath}`
  }

  getPolicyRootPath (policyId, companyId) {
    return `/api/${this.accessToken}/customer-resources/companies/${companyId}/policy-assets/${policyId}`
  }

  getCourseVideo ({ companyId, courseDefinition }) {
    return `/api/${this.accessToken}/customer-resources/companies/${companyId}/${encodeURIComponent(`companies/${companyId}/courses/${courseDefinition.courseDefinitionId || courseDefinition.id}/video.mp4`)}`
  }
}

export const apiRequestUtils = new APIRequestUtils()
