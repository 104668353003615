import { useState, useEffect } from 'react'
import { Pie } from 'react-chartjs-2'
import {
  MDBContainer, MDBRow, MDBCol, MDBCard, MDBIcon,
  MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBCardBody,
  MDBDataTable, MDBAnimation, MDBTooltip
} from 'mdbreact'
import { useSelector, useDispatch } from 'react-redux'
import { getPolicyStatsThunk } from '../store/AnalyticsSlice'

import Spinner from './components/Spinner'

function HUDElement ({ title, icon, value, tooltip }) {
  return (
    <MDBTooltip
      placement='top'
      domElement
      className='mr-2 ml-2'
      material
    >
      <a>
        <MDBCol>
          <p className='dark-grey-text'><MDBIcon icon={icon} /> {title}</p>
          <h4 className='font-weight-bold dark-grey-text'>{value}</h4>
        </MDBCol>
      </a>
      <div>
        {tooltip}
      </div>
    </MDBTooltip>
  )
}

function PieChart ({ policy }) {
  const chartBaseStyles = {
    title: {
      fontSize: 20,
      fontFamily: 'Roboto',
      fontColor: '#212529'
    },
    legend: {
      fontColor: '#212529',
      fontFamily: 'Roboto',
      fontSize: 13
    },
    axisColor: 'rgba(0, 0, 0, 0.3)',
    colors: ['#00C851', '#ff4444']
  }

  const chartData = {
    labels: [
      'Completed',
      'Not Completed'
    ],
    datasets: [{
      data: [
        policy.totalCompleted,
        policy.totalAssigned - policy.totalCompleted
      ],
      backgroundColor: chartBaseStyles.colors
    }]
  }

  const chartOptions = {
    response: true,
    tooltips: {
      enabled: true
    },
    title: {
      display: true,
      fontSize: chartBaseStyles.title.fontSize,
      text: 'Completion Rate',
      fontColor: chartBaseStyles.title.fontColor
    },
    legend: {
      labels: {
        fontColor: chartBaseStyles.legend.fontColor,
        fontFamily: chartBaseStyles.legend.fontFamily,
        fontSize: chartBaseStyles.legend.fontSize
      }
    }
  }

  return (
    <Pie data={chartData} options={chartOptions} height={250} />
  )
}

function PolicySelector ({ policyData, selectedPolicy, switchPolicy }) {
  const options = []

  for (const policyObj of policyData) {
    const policy = policyObj.policy
    options.push(
      <MDBDropdownItem active={selectedPolicy.id === policy.id} key={policy.id} onClick={() => switchPolicy(policyObj)}>
        {policy.name}
      </MDBDropdownItem>
    )
  }

  return (
    <MDBDropdown>
      <MDBDropdownToggle className='m-0 mb-1' id='analytics-switch-campaign-toggle' caret color='primary'>
        {selectedPolicy.name}
      </MDBDropdownToggle>
      <MDBDropdownMenu id='analytics-switch-campaign-dropdown' color='info' right basic>
        {options}
      </MDBDropdownMenu>
    </MDBDropdown>
  )
}

function UserDataTable ({ userData }) {
  const rows = []
  for (const user of userData) {
    rows.push(
      {
        first: user.first,
        last: user.last,
        email: user.email,
        status: user.status.charAt(0).toUpperCase() + user.status.slice(1),
        acknowledged: (user.acknowledged ? 'Acknowledged' : 'Not Acknowledged')
      }
    )
  }

  const data = {
    columns: [{ label: 'First', field: 'first' }, { label: 'Last', field: 'last' }, { label: 'Email', field: 'email' }, { label: 'User Status', field: 'status' }, { label: 'Policy Status', field: 'acknowledged' }],
    rows
  }

  return (
    <MDBDataTable disableRetreatAfterSorting data={data} />
  )
}

function WarningPanel () {
  return (
    <MDBAnimation duration='1.2s' type='fadeInUp'>
      <MDBRow className='d-flex mb-4 justify-content-center'>
        <MDBCol size='12'>
          <MDBCard className='hud-stats'>
            <MDBRow className='text-center d-flex justify-content-center m-4'>
              <h3>
                No policy data found. Check back here once you have assigned a policy!
              </h3>
            </MDBRow>
          </MDBCard>
        </MDBCol>

      </MDBRow>
    </MDBAnimation>
  )
}

function PoliciesAnalyticsPage ({ companyId }) {
  const [selectedPolicy, setSelectedPolicy] = useState(null)
  const [selectedPolicyUsers, setSelectedPolicyUsers] = useState(null)

  const { policyStats, loaders } = useSelector((state) => state.analytics)
  const { isLoadingPolicyStats } = loaders
  const dispatch = useDispatch()

  useEffect(() => {
    // Make sure we reset the scroll upon load
    window.scrollTo(0, 0)
    dispatch(getPolicyStatsThunk(companyId))
  }, [])

  useEffect(() => {
    if (policyStats && policyStats.length > 0) {
      if (!selectedPolicy) {
        const { policy, users } = policyStats[0]
        setSelectedPolicy(policy)
        setSelectedPolicyUsers(users)
      } else {
        const selectedCourseStat = policyStats.find((policyStat) => policyStat.policy.id === selectedPolicy.id)
        setSelectedPolicy(selectedCourseStat.policy)
        setSelectedPolicyUsers(selectedCourseStat.users)
      }
    }
  }, [policyStats])

  function switchPolicy (policyObj) {
    const { policy, users } = policyObj
    setSelectedPolicy(policy)
    setSelectedPolicyUsers(users)
  }

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol className='d-flex flex-column flex-center mb-4'>
          <h1>Policy Analytics</h1>
        </MDBCol>
      </MDBRow>

      {isLoadingPolicyStats && (
        <Spinner message='Making everything look nice' />
      )}

      {policyStats && (policyStats.length === 0) && !isLoadingPolicyStats && (
        <WarningPanel />
      )}

      {policyStats && (policyStats.length > 0) && !isLoadingPolicyStats && selectedPolicy && selectedPolicyUsers && (
        <>
          <MDBRow>
            <MDBCol className='d-flex justify-content-end p-0'>
              <PolicySelector policyData={policyStats} switchPolicy={switchPolicy} selectedPolicy={selectedPolicy} />
            </MDBCol>
          </MDBRow>
          <MDBAnimation duration='1.2s' type='fadeInUp'>
            <MDBRow>
              <MDBCard className='hud-stats w-100 mb-4'>
                <MDBRow className='text-center d-flex justify-content-center my-4'>
                  <HUDElement title='Total Users Assigned' icon='users' tooltip='The total number of users assigned this policy, regardless of status: active, slept, deleted.' value={selectedPolicy !== null ? selectedPolicy.totalAssigned : 0} />
                  <HUDElement title='Users Finished' icon='medal' tooltip='The number of users who have acknowledged their policy, regardless of status: active, slept, deleted.' value={selectedPolicy !== null ? selectedPolicy.totalCompleted : 0} />
                </MDBRow>
                <MDBRow className='text-center d-flex justify-content-center my-4'>
                  <HUDElement title='Active Users' icon='sun' tooltip='The number of active users assigned this policy' value={selectedPolicy !== null ? selectedPolicy.totalActiveUsers : 0} />
                  <HUDElement title='Slept Users' icon='bed' tooltip='The number of slept users assigned this policy' value={selectedPolicy !== null ? selectedPolicy.totalSleptUsers : 0} />
                  <HUDElement title='Deleted Users' icon='trash' tooltip='The number of deleted users assigned this policy' value={selectedPolicy !== null ? selectedPolicy.totalDeletedUsers : 0} />
                </MDBRow>
              </MDBCard>
            </MDBRow>
          </MDBAnimation>
          <MDBAnimation duration='1.2s' type='fadeInUp'>
            <MDBRow className='d-flex mb-4 justify-content-center'>
              <MDBCol size='6'>
                <MDBCard className='d-flex justify-content-center'>
                  <MDBCardBody className='p-1'>
                    <PieChart policy={selectedPolicy} />
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBAnimation>
          <MDBAnimation duration='1.2s' type='fadeInUp'>
            <MDBRow className='d-flex mb-4 justify-content-center'>
              <MDBCard className='p-2 w-100'>
                <UserDataTable userData={selectedPolicyUsers} isUserCourseTable={false} />
              </MDBCard>
            </MDBRow>
          </MDBAnimation>
        </>
      )}
    </MDBContainer>
  )
}

export default PoliciesAnalyticsPage
