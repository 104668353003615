import sendEmail from './emailer.js'
import Mustache from 'mustache'
import ValidationUtils from './validationUtils'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

/**
 * @description Checks if a template exists by template name
 * @param {string} templateName The Name of the template for which you would like to check
 * @returns {promise} Returns a success or failure status
 */
async function checkIfUserTemplateExists (id, partnerMuid, templateName, API) {
  try {
    const response = await API.get(`/api/companies/${id}/templates/?name=${templateName}&partnerMuid=${partnerMuid}`)
    return response.status
  } catch (err) {
    sendEmail('Error in checkIfTemplateExists ' + JSON.stringify(err), id, API)
    return 500
  }
}

async function sendTestEmail (id, info, API) {
  try {
    const response = await API.post(`/api/companies/${id}/templates/test`, { templateName: info.templateName, content: info.content, email: info.email })
    const json = await response.json()
    return json
  } catch (err) {
    console.error(err)
  }
}

function validateInjectedFields (injectedFields) {
  if (injectedFields.length > 0) {
    let valid = true
    for (const field of injectedFields) {
      if (field.name !== 'action_url' && !field.value) {
        valid = false
      }
    }
    return valid
  } else {
    return true
  }
}

function processFromField (template) {
  if (template) {
    if (template.channel === 'email') {
      return template.fromEmail.split('@')[1]
    } else {
      return template.fromNumber
    }
  }
}

function validateSMSTemplate (template) {
  const { name, channel, message, injectedFields, tags } = template

  if (!ValidationUtils.isNotWhiteSpace(name)) {
    NotificationUtilities.sendWarningMessage('Your template must have a name')
    return false
  }

  if (!ValidationUtils.isNotWhiteSpace(channel)) {
    NotificationUtilities.sendWarningMessage('Your template must have a type')
    return false
  }

  if (!ValidationUtils.isValidMustache(message)) {
    NotificationUtilities.sendWarningMessage('You must have a valid message. Make sure your injected fields are correct.')
    return false
  }

  if (tags.length <= 0) {
    NotificationUtilities.sendWarningMessage('You must give this template at least one tag!')
    return false
  }

  if (!validateInjectedFields(injectedFields)) {
    NotificationUtilities.sendWarningMessage('You must select values for all injected fields')
    return false
  }

  if (!validateMustacheTemplateHasActionURL(message)) {
    NotificationUtilities.sendWarningMessage('Make sure to add {{action_url}} to your template. We will convert this into the learning moment link for you')
    return false
  }

  return true
}

function validateHTMLTemplate (template) {
  const { name, channel, html, fromName, injectedFields, fromEmail, tags } = template

  const splitEmail = fromEmail.split('@')

  const emailName = splitEmail[0]
  const emailDomain = splitEmail[1]

  if (!ValidationUtils.isNotWhiteSpace(name)) {
    NotificationUtilities.sendWarningMessage('Your template must have a name')
    return false
  }

  if (!ValidationUtils.isNotWhiteSpace(channel)) {
    NotificationUtilities.sendWarningMessage('Your template must have a type')
    return false
  }

  if (!ValidationUtils.isValidMustache(html) || !ValidationUtils.isNotWhiteSpace(html)) {
    NotificationUtilities.sendWarningMessage('Your uploaded template file has invalid markup or is empty. Make sure it is all valid HTML')
    return false
  }

  if (!ValidationUtils.isNotWhiteSpace(fromName)) {
    NotificationUtilities.sendWarningMessage('You must have a email display name')
    return false
  }

  if (!ValidationUtils.isNotWhiteSpace(emailName) || emailName === 'undefined') {
    NotificationUtilities.sendWarningMessage('You must have a valid email sending name')
    return false
  }

  if (!ValidationUtils.isNotWhiteSpace(emailDomain) || emailDomain === 'undefined') {
    NotificationUtilities.sendWarningMessage('You must choose a sending domain for your email')
    return false
  }

  if (tags.length <= 0) {
    NotificationUtilities.sendWarningMessage('You must give this template at least one tag!')
    return false
  }

  if (!validateInjectedFields(injectedFields)) {
    NotificationUtilities.sendWarningMessage('You must select values for all injected fields')
    return false
  }

  if (!validateMustacheTemplateHasActionURL(html)) {
    NotificationUtilities.sendWarningMessage('Make sure to add {{action_url}} to your template. We will convert this into the learning moment link for you')
    return false
  }

  return true
}

function parseMustacheTemplate (text) {
  const injectedFields = []
  const parsedFields = Mustache.parse(text)
    .filter(v => {
      return v[0] === 'name'
    })
    .map(v => {
      return v[1]
    })

  for (const field of parsedFields) {
    if (field !== 'action_url') {
      injectedFields.push({ name: field, value: '' })
    }
  }

  return injectedFields
}

function validateMustacheTemplateHasActionURL (text) {
  const parsedFields = Mustache.parse(text)
    .filter(v => {
      return v[0] === 'name'
    })
    .map(v => {
      return v[1]
    })

  let valid = false
  for (const field of parsedFields) {
    if (field === 'action_url') {
      valid = true
      break
    }
  }

  return valid
}

function validateSubmittedTemplate (template) {
  switch (template.channel) {
    case 'email':
      return validateHTMLTemplate(template)
    case 'sms':
      return validateSMSTemplate(template)
    default:
      NotificationUtilities.sendWarningMessage('Your template must have a type')
      return false
  }
}

export {
  checkIfUserTemplateExists,
  sendTestEmail,
  validateHTMLTemplate,
  validateInjectedFields,
  validateSubmittedTemplate,
  validateSMSTemplate,
  parseMustacheTemplate,
  processFromField
}
