import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import TrainingModulePreview from '../../pages/components/training/TrainingModulePreview'
import CustomTrainingVideoPreview from '../../pages/components/training/CustomTrainingVideoPreview'

export const CoursePreviewModal = ({ courseDefinition, companyId, showPreviewModal, previewModalHeader, togglePreviewModal }) => {
  return (
    <MDBModal
      isOpen={showPreviewModal}
      toggle={() => togglePreviewModal(null, false)}
      closeOnEsc
      centered
      size='xl'
    >
      <MDBModalHeader toggle={() => togglePreviewModal(null, false)}>{previewModalHeader}</MDBModalHeader>
      <MDBModalBody>
        {courseDefinition && courseDefinition.type === 'single' && (
          <CustomTrainingVideoPreview
            courseDefinition={courseDefinition}
            companyId={companyId}
          />
        )}

        {courseDefinition && courseDefinition.type !== 'single' && (
          <TrainingModulePreview
            courseDefinition={courseDefinition}
            companyId={companyId}
          />
        )}
      </MDBModalBody>
    </MDBModal>
  )
}
