import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  MDBContainer, MDBRow, MDBCard, MDBCardBody,
  MDBCardHeader, MDBCol, MDBTooltip, MDBIcon, MDBBtn, MDBInput,
  MDBFileInput, MDBSpinner

} from 'mdbreact'
import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'
import { useDispatch, useSelector } from 'react-redux'
import { createTrainingCourseThunk, getCourseCatalogThunk } from '../store/TrainingSlice'
import Spinner from './components/Spinner'

function handleImageUpload (fileInput, setImageToState) {
  if (fileInput[0].type !== 'image/png') {
    NotificationUtilities.sendErrorMessage('Please upload a PNG file that is less than 32 MB.')
  } else if (fileInput[0].size > 32000000) {
    NotificationUtilities.sendErrorMessage('Please upload a PNG file that is less than 32 MB.')
  } else {
    setImageToState(fileInput)
  }
}

function handleVideoUpload (fileInput, setVideoToState) {
  if (fileInput[0].type !== 'video/mp4') {
    NotificationUtilities.sendErrorMessage('Please upload an MP4 file that is less than 32 MB.')
  } else if (fileInput[0].size > 32000000) {
    NotificationUtilities.sendErrorMessage('Please upload an MP4 file that is less than 32 MB.')
  } else {
    setVideoToState(fileInput)
  }
}

async function readFile (inputFile, outputType) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
    if (outputType === 'ArrayBuffer') {
      fileReader.readAsArrayBuffer(inputFile[0])
    } else if (outputType === 'DataURL') {
      fileReader.readAsDataURL(inputFile[0])
    }
  })
}

async function makeFormData (course) {
  const {
    courseTitle,
    courseDescription,
    courseVideo,
    courseThumbnail
  } = course
  const formData = new FormData()
  const videoBuffer = await readFile(courseVideo, 'ArrayBuffer')
  // const imageDataURL = await readFile(courseThumbnail, 'DataURL')
  formData.append('courseVideo', new Blob([videoBuffer], { type: 'video/mp4' }))
  formData.append('courseThumbnail', courseThumbnail[0], 'courseThumbnail')
  formData.append('courseDescription', new Blob([courseDescription], { type: 'text/plain' }))
  formData.append('courseTitle', new Blob([courseTitle], { type: 'text/plain' }), `${courseTitle}`)

  return formData
}

function validateCourse (course) {
  const {
    courseTitle,
    courseDescription,
    courseVideo,
    courseThumbnail
  } = course
  let isValidCourse = true
  if (courseTitle === undefined) {
    NotificationUtilities.sendErrorMessage('Course Title cannot be blank')
    isValidCourse = false
  }

  if (courseDescription === undefined) {
    NotificationUtilities.sendErrorMessage('Course Description cannot be blank')
    isValidCourse = false
  }

  if (courseVideo === undefined || courseThumbnail === undefined) {
    NotificationUtilities.sendErrorMessage('Error creating course. Please ensure to include a Course Thumbnail (PNG) and Video (MP4) with a combined file size of 32MB or less.')
    isValidCourse = false
  }

  return isValidCourse
}

function TrainingCreator ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { courseCatalog, loaders } = useSelector((state) => state.trainings)
  const { isLoadingCourseCatalog, isLoadingCreateTrainingCourse } = loaders

  const [courseTitle, setCourseTitle] = useState()
  const [courseDescription, setCourseDescription] = useState()
  const [courseVideo, setCourseVideo] = useState()
  const [courseThumbnail, setCourseThumbnail] = useState()

  const courseObject = { courseTitle, courseDescription, courseVideo, courseThumbnail }

  useEffect(() => {
    if (!courseCatalog) {
      dispatch(getCourseCatalogThunk({ companyId }))
    }
  }, [])

  async function createCourse (course) {
    if (validateCourse(course) !== false) {
      try {
        const formData = await makeFormData(course)

        dispatch(createTrainingCourseThunk({ formData, companyId, history }))
      } catch (err) {
        console.error(err)
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error creating course. Please ensure to include a Course Thumbnail (PNG) and Video (MP4) with a combined file size of 32MB or less.')
        })
      }
    }
  }

  function renderButtons () {
    if (!isLoadingCreateTrainingCourse) {
      return (
        <>
          <MDBBtn
            id='cancel-course-creation-button'
            aria-label='Cancel Course Creation Button'
            onClick={() => history.goBack()}
            color='danger'
          >Cancel
          </MDBBtn>
          <MDBBtn
            id='create-course-button'
            aria-label='Create Course Button'
            onClick={() => createCourse(courseObject)}
            color='primary'
          >Create
          </MDBBtn>
        </>
      )
    } else if (isLoadingCreateTrainingCourse) {
      return (
        <MDBSpinner className='mx-4' multicolor />
      )
    }
  }

  return (
    <MDBContainer>
      <NotificationWrapper />
      <MDBRow>
        <h1>Course Creator</h1>
        <p>
          Here we will set up the basic content and information for a training course.
          Hover over the question bubble for specific information about this process.
        </p>
      </MDBRow>
      <hr />
      {(!courseCatalog || isLoadingCourseCatalog) && (
        <Spinner message='Loading Course Creator' />
      )}

      {(courseCatalog && !isLoadingCourseCatalog) && (
        <>
          <MDBCard className='w-100 h-100'>
            <MDBCardHeader>
              <MDBRow className='d-flex'>
                <MDBCol className='d-flex justify-content-start align-items-center'>
                  <h5>Course Information</h5>
                </MDBCol>
                <MDBCol className='d-flex justify-content-end'>
                  <MDBTooltip
                    placement='left'
                    id='description-popover'
                    material
                  >
                    <MDBBtn
                      color='elegant'
                      className='my-0'
                      floating
                      tag='a'
                      size='sm'
                    >
                      <MDBIcon icon='question' />
                    </MDBBtn>
                    <div>
                      Here you can input the course name, provide a description, select a video for the course, and choose an image to be used as the course thumbnail.
                    </div>
                  </MDBTooltip>
                </MDBCol>
              </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
              <div className='form-group w-100'>
                <MDBInput
                  id='course-title-field'
                  aria-label='Course Title Field'
                  outline
                  value={courseTitle}
                  size='lg'
                  label='Course Title'
                  className='w-100 h-100'
                  onChange={(e) => { setCourseTitle(e.target.value) }}
                />
              </div>
              <MDBRow className='d-flex justify-content-center'>
                <MDBCol>
                  <MDBInput
                    id='course-description-field'
                    aria-label='Course Description Field'
                    type='textarea'
                    label='Course Description'
                    rows='5'
                    value={courseDescription}
                    outline
                    onChange={(e) => { setCourseDescription(e.target.value) }}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className='d-flex justify-content-center'>
                <MDBCol size='6'>
                  <MDBFileInput
                    type=''
                    textFieldTitle='Upload Video'
                    label='Course Video'
                    outline
                    btnTitle='Choose video'
                    id='campaignDesc'
                    getValue={(file) => handleVideoUpload(file, setCourseVideo)}
                    name='campDesc'
                  />
                </MDBCol>
                <MDBCol size='6'>
                  <MDBFileInput
                    type=''
                    textFieldTitle='Upload Thumbnail'
                    label='Course Thumbnail'
                    outline
                    reverse
                    btnTitle='Choose Image'
                    getValue={(file) => handleImageUpload(file, setCourseThumbnail)}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <hr />
          <hr />
          <MDBRow className='d-flex justify-content-end align-items-center'>
            {renderButtons()}
          </MDBRow>
        </>
      )}
    </MDBContainer>

  )
}

export default TrainingCreator
