import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'

import PresetLibrary from './components/Campaigns/PresetLibrary'
import NotificationUtilities from './components/notifications/notificationUtils'
import NotificationWrapper from './components/notifications/notificationWrapper'
import ValidationModal from './components/ValidationModal'
import { useSelector, useDispatch } from 'react-redux'
import { removePhishingCampaignPresetAction } from '../store/CampaignsSlice'

import { navigationRoutes } from '../frontendConsts.js'
import { apiRequestUtils } from '../utils/apiRequestUtils'

import './css/presetLibrary.css'
import Spinner from './components/Spinner'

function CampaignPresetLibrary ({ companyId }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [validationModal, setValidationModal] = useState(false)
  const [selectedPreset, setSelectedPreset] = useState({})

  const { phishingCampaignPresets, loaders } = useSelector((state) => state.campaigns)
  const { isLoadingPhishingCampaignPresets } = loaders

  function launchCampaignPreset (campaignPreset) {
    const presetCopy = Object.assign({}, campaignPreset)
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNLAUNCHER}`, { campaign: presetCopy, preset: true })
  }

  function editCampaignPreset (campaignPreset) {
    history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNEDITOR}`, { presetId: campaignPreset.id, edit: true })
  }

  async function deleteCampaignPreset () {
    try {
      const res = await apiRequestUtils.delete(`/api/companies/${companyId}/campaign-presets/${selectedPreset.id}`, { shareLevel: selectedPreset.shareLevel })
      if (res.status === 200) {
        dispatch(removePhishingCampaignPresetAction(selectedPreset.id))
        NotificationUtilities.sendSuccessMessage('Successfully deleted campaign preset!')
        toggleValidationModal()
      } else {
        throw Error('Failed to delete campaign preset. Server responded with non 200 status')
      }
    } catch (err) {
      NotificationUtilities.sendErrorMessage('Failed to delete campaign preset!')
      toggleValidationModal()
      console.error(err)
    }
  }

  function handlePresetDelete (campaignPreset) {
    setSelectedPreset(campaignPreset)
    toggleValidationModal()
  }

  function toggleValidationModal () {
    setValidationModal(!validationModal)
  }

  return (
    <>
      <NotificationWrapper />
      <ValidationModal
        isOpen={validationModal}
        toggleFunction={toggleValidationModal}
        messageJSX={<p>Are you sure you want to delete this preset? This will delete the preset for any company in this partner that shares this preset! <strong>This can not be undone!</strong></p>}
        handlerFunction={deleteCampaignPreset}
        trueOptionText='Delete'
        trueOptionColor='red'
        falseOptionText='Cancel'
        falseOptionColor='primary'
      />
      <MDBRow className='d-flex justify-content-center align-items-center'>
        <MDBCol className='text-center'>
          <h1>Preset Library</h1>
          <p>Edit and manage your saved campaign presets here</p>
        </MDBCol>
      </MDBRow>
      {isLoadingPhishingCampaignPresets && (
        <Spinner message='Loading presets' />
      )}
      {phishingCampaignPresets && !isLoadingPhishingCampaignPresets && (
        <>
          <hr />
          <PresetLibrary modify campaignPresets={phishingCampaignPresets} selectCallback={launchCampaignPreset} editCallback={editCampaignPreset} deleteCallback={handlePresetDelete} />
          <hr />
          <MDBRow className='d-flex justify-content-end align-items-center'>
            <MDBBtn onClick={() => { history.push(`/companies/${companyId}${navigationRoutes.CAMPAIGNS}`) }} color='danger'>Cancel</MDBBtn>
          </MDBRow>
        </>
      )}
    </>
  )
}

export default CampaignPresetLibrary
