import {
  MDBRow, MDBContainer
} from 'mdbreact'
import PhishingCampaignResource from './PhishingCampaignResource'
import TrainingCampaignResource from './TrainingCampaignResource'

function ContinuousCampaignViewer ({ phishingCategories, phishingCampaignName, phishingDescription, trainingTopics, trainingCampaignName, trainingDescription }) {
  return (
    <MDBContainer>
      <MDBRow>
        <h1 className='font-weight-bold pl-0 my-4'><strong>Company Resource Selector</strong></h1>
      </MDBRow>
      <MDBRow>
        <MDBContainer>
          <PhishingCampaignResource
            phishingDescription={phishingDescription}
            phishingCampaignName={phishingCampaignName}
            phishingCategories={phishingCategories}
          />
        </MDBContainer>
      </MDBRow>
      <br />
      <MDBRow>
        <MDBContainer>
          <TrainingCampaignResource
            trainingCampaignName={trainingCampaignName}
            trainingDescription={trainingDescription}
            trainingTopics={trainingTopics}
          />
        </MDBContainer>
      </MDBRow>
    </MDBContainer>
  )
}
export default ContinuousCampaignViewer
