import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import PartnersPage from '../pages/PartnersPage'
import ResourcesPage from '../pages/resources'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnerThunk } from '../store/PartnerSlice'
import { getLogoLocationThunk } from '../store/LogoSlice'
import { useAuth0 } from '@auth0/auth0-react'
import PartnerBrandingPage from '../pages/PartnerBrandingPage'
import { PartnerIntegrationsPage } from '../pages/PartnerIntegrationsPage'
import { PhinApiIntegrationPage } from '../pages/PhinApiIntegrationPage'
import { getPartnerIntegrationsThunk } from '../store/PartnerIntegrationSlice'
import { GradientIntegrationPage } from '../pages/GradientIntegrationPage'
import { PartnerBillingPage } from '../pages/partnerPages/PartnerBillingPage'

export function PartnerRouter ({ id }) {
  const { path } = useRouteMatch()
  const { partner } = useSelector((state) => state.partner)
  const dispatch = useDispatch()
  const { logout } = useAuth0()

  useEffect(() => {
    async function setup () {
      try {
        dispatch(getPartnerThunk(id))
        dispatch(getLogoLocationThunk({ partnerId: id }))
        dispatch(getPartnerIntegrationsThunk(id))
      } catch (error) {
        logout({ returnTo: window.location.origin })
        throw error
      }
    }
    setup()
  }, [])

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={() => (
          <PartnersPage
            id={id}
          />
        )}
      />
      <Route
        exact
        path={`${path}/resources`}
        render={() => (
          <ResourcesPage />
        )}
      />
      <Route
        exact
        path={`${path}/branding`}
        render={() => (
          <PartnerBrandingPage
            id={id}
          />
        )}
      />
      <Route
        exact
        path={`${path}/integrations`}
        render={() => (<PartnerIntegrationsPage id={id} />)}
      />

      {(partner && partner.distributorId === '0Sky1I4aUzDXjgHqPjZk') &&
        <Route
          exact
          path={`${path}/billing`}
          render={() => (<PartnerBillingPage partnerId={id} />)}
        />}

      <Route
        exact
        path={`${path}/integrations/phinApi`}
        render={() => (<PhinApiIntegrationPage id={id} />)}
      />
      <Route
        exact
        path={`${path}/integrations/gradientMSP`}
        render={() => (<GradientIntegrationPage id={id} />)}
      />
    </Switch>
  )
}
