import { Alert, Grid } from '@mui/material'
import NotificationWrapper from '../components/notifications/notificationWrapper'
import { ChargeBeeInvoicesTable } from './components/ChargeBeeInvoicesTable'
import { UsageBreakdownReportsTable } from './components/UsageBreakdownReportsTable'
import { PaymentInfoWidget } from './components/PaymentInfoWidget'
import { BillingEstimateWidget } from './components/BillingEstimateWidget'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getPartnerBillingDetailsThunk, getPartnerBillingInvoicesThunk, setPartnerBillingDetailsAction, setPartnerBillingInvoicesAction, setPartnerBillingReportsAction, validateAutomatedBillingReadyThunk } from '../../store/PartnerBillingSlice'
import { EnableAutomatedBillingWidget } from './components/EnableAutomatedBillingWidget'

export function PartnerBillingPage ({ partnerId }) {
  const dispatch = useDispatch()
  const { partner } = useSelector((state) => state.partner)
  const { authorization } = useSelector((state) => state.auth)

  const isBillingAdmin = authorization.billingAdminPartnerIds.includes(partnerId)

  useEffect(() => {
    dispatch(setPartnerBillingReportsAction(null))
    dispatch(setPartnerBillingDetailsAction(null))
    dispatch(setPartnerBillingInvoicesAction(null))
  }, [])

  useEffect(() => {
    if (partner && partner.automaticBillingEnabled) {
      dispatch(getPartnerBillingDetailsThunk({ partnerId }))
      dispatch(getPartnerBillingInvoicesThunk({ partnerId }))
    } else if (partner && !partner.automaticBillingEnabled) {
      dispatch(validateAutomatedBillingReadyThunk({ partnerId }))
    }
  }, [partner])

  return isBillingAdmin ? (
    <div className='screen-pad'>
      <NotificationWrapper />

      <div className='phin-h2 margin-bottom:2'>Billing</div>

      <EnableAutomatedBillingWidget />

      <Grid container spacing={4}>
        <Grid item md={12} lg={6}>
          <BillingEstimateWidget />
        </Grid>

        <Grid item md={12} lg={6}>
          <PaymentInfoWidget />
        </Grid>
      </Grid>

      <ChargeBeeInvoicesTable
        partnerId={partnerId}
      />

      <UsageBreakdownReportsTable
        partnerId={partnerId}
      />
    </div>
  ) : <Alert severity='warning' variant='outlined' sx={{ background: 'var(--phin-light-yellow)' }}>
        <div>You do not have permission to view this page. Please reach out to your administrator for permission or contact <a href='https://www.phinsec.io/knowledge/kb-tickets/new'>Phin Support.</a></div>
      </Alert>
}
