import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SaveCancelButtonsRow } from '../../../shared/components/SaveCancelButtonsRow'
import { changeCompanyNameThunk } from '../../../store/CompanySlice'
import ValidationUtils from '../../../utils/validationUtils'
import NotificationUtilities from '../notifications/notificationUtils'
import { MDBAnimation } from 'mdbreact'

const ChangeCompanyName = ({ id }) => {
  const companyName = useSelector((state) => state.company.company?.name)
  const [newCompanyName, setNewCompanyName] = useState('')
  const [hasChanged, setHasChanged] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = async function () {
    if (!ValidationUtils.isNotWhiteSpace(newCompanyName)) {
      NotificationUtilities.sendWarningMessage('Company Name can not be empty')
      return
    }
    try {
      if (submitting) return true
      setSubmitting(true)

      await dispatch(changeCompanyNameThunk(id, newCompanyName))

      setSubmitting(false)
      setHasChanged(false)
    } catch (err) {
      setSubmitting(false)
      setHasChanged(false)
    }
  }

  const updateCompanyName = function (updatedCompanyName) {
    setHasChanged(true)
    setNewCompanyName(updatedCompanyName)
  }

  const resetChanges = function () {
    setHasChanged(false)
    setNewCompanyName(companyName)
  }

  useEffect(() => {
    setNewCompanyName(companyName)
  }, [companyName])

  return (
    <div className='pageContainer'>
      <div id='logoEditCard'>
        <div className='w-100'>
          <div>
            <p className='phinCardHeader'>Company Name</p>
            <p>The name of your company. This will be used as the name of your company in the Phin portal and in all communications Phin will send.</p>
          </div>
          <input
            className='textInputBox2 w-100 mb-4'
            id='company-name-field'
            aria-label='Company Name Field'
            placeholder='New Company Name'
            type='text'
            value={newCompanyName}
            onChange={(e) => { updateCompanyName(e.target.value) }}
            name='new-company-name'
          />
          {hasChanged &&
            <MDBAnimation className='h-100' type='fadeIn' duration='500ms'>
              <SaveCancelButtonsRow saveLabel='Save Changes' cancelLabel='Discard Changes' submitAction={onSubmit} cancelAction={() => { resetChanges() }} />
            </MDBAnimation>}
        </div>
      </div>
    </div>
  )
}

export default ChangeCompanyName
