import React, { useEffect, useState } from 'react'
import {
  MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody, MDBIcon, MDBInput,
  MDBBtn, MDBTooltip, MDBModal, MDBModalBody, MDBModalHeader,
  MDBModalFooter,
  MDBAnimation, MDBTabContent, MDBTabPane, MDBNav, MDBNavItem, MDBDataTableV5
} from 'mdbreact'
import Moment from 'moment'
import Papa from 'papaparse'
import AddUserModal from './components/AddUserModal'

import NotificationWrapper from './components/notifications/notificationWrapper'
import NotificationUtilities from './components/notifications/notificationUtils'

import ValidationUtils from '../utils/validationUtils'
import ValidationModal from './components/ValidationModal'

import { groupStatuses, navigationRoutes, orgLevels } from '../frontendConsts.js'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { addUserThunk, bulkSleepThunk, bulkWelcomeThunk, deleteUsersThunk, setEditingUserAction } from '../store/UsersSlice'
import { setUserCountAction, removeAdminThunk, addAdminThunk, incrementUserCountAction, updateOnboardingChecklistDataThunk } from '../store/CompanySlice'

import './css/users.css'

import { apiRequestUtils } from '../utils/apiRequestUtils'
import { useHistory } from 'react-router-dom'
import WelcomeUsersModal from './components/WelcomeUsersModal'
import { getWelcomeEmailThunk } from '../store/WelcomeEmailSlice'

export function UsersPage ({ id }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [addAdminModal, setAddAdminModal] = useState(false)
  const [addUserModal, setAddUserModal] = useState(false)
  const [deleteUsersModal, setDeleteUsersModal] = useState(false)
  const [deleteAdminModal, setDeleteAdminModal] = useState(false)
  const [welcomeUsersModal, setWelcomeUsersModal] = useState(false)
  const [sleepUsersModal, setSleepUsersModal] = useState(false)
  const [wakeUsersModal, setWakeUsersModal] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState('')
  const [openTab, setOpenTab] = useState('users')
  const [selectedGroupId, setSelectedGroupId] = useState()
  const [groupTabView, setGroupTabView] = useState('overview')
  const [bulkUsers, setBulkUsers] = useState([])
  const [newUFirstName, setNewUFirstName] = useState('')
  const [newULastName, setNewULastName] = useState('')
  const [newUEmail, setNewUEmail] = useState('')
  const [user, setUser] = useState({})
  const [isOptionalFieldsOpen, setIsOptionalFieldsOpen] = useState(false)
  const [addUserModalButtonText, setAddUserModalButtonText] = useState()
  const [allUsersSelected, setAllUsersSelected] = useState(false)

  const { users, groups } = useSelector((state) => state.users)
  const { company, onboardingChecklist } = useSelector((state) => state.company)
  const { hasUserSync } = useSelector((state) => state.integrations)

  const [userTableData, setUserTableData] = useState(
    {
      columns: [],
      rows: []
    })

  useEffect(() => {
    dispatch(getWelcomeEmailThunk(id, orgLevels.COMPANY))
  }, [])

  useEffect(() => {
    if (users) {
      const userRows = []
      users.forEach(user => {
        const newUser = {}
        newUser.rowClassNames = user.status === 'slept' ? 'slept-row' : ''
        newUser.check = <MDBInput label=' ' searchValue='checkbox' type='checkbox' id={`user-${user.email}-checkbox`} aria-label='User Checkbox' checked={searchBulkUserList(user.id)} onClick={() => updateBulkUsers(user)} />
        newUser.first = user.first
        newUser.last = user.last
        newUser.email = user.email
        newUser.status = user.status
        newUser.welcomed = renderWelcomedBadge(user.welcomed)
        newUser.actions = (
          <div searchValue='==='>
            <MDBBtn
              rounded
              onClick={() => { handleEditingUser(user) }}
              id={`edit-user-${user.email}-button`}
              aria-label='Edit User Button'
            >
              <MDBIcon className='px-0' size='lg' icon={(user.externalId && hasUserSync) ? 'eye' : 'pencil-alt'} />
            </MDBBtn>
          </div>
        )

        userRows.push(newUser)
      })

      const userTableData = {
        columns: [
          {
            label: <MDBInput label=' ' type='checkbox' id='selectAllBox' checked={allUsersSelected} onClick={() => { selectAllUsers() }} style={{ zIndex: '10000000' }} />,
            field: 'check',
            width: 50,
            sort: 'disabled'
          },
          {
            label: 'Last',
            field: 'last',
            width: 150,
            attributes: {
              'aria-controls': 'DataTable',
              'aria-label': 'Last'
            }
          },
          {
            label: 'First',
            field: 'first'
          },
          {
            label: 'Email',
            field: 'email',
            width: 270
          },
          {
            label: 'Status',
            field: 'status'
          },
          {
            label: 'Welcomed',
            field: 'welcomed',
            sortable: false
          },
          {
            label: 'Actions',
            field: 'actions',
            sortable: false
          }

        ],
        rows: userRows
      }
      setUserTableData(userTableData)
    }
  }, [users, bulkUsers, allUsersSelected])

  function renderWelcomedBadge (welcomed) {
    if (welcomed) {
      return <MDBIcon style={{ fontSize: '1.5em' }} icon='check' className='green-text pl-3' />
    } else {
      return <MDBIcon style={{ fontSize: '1.5em' }} icon='exclamation-triangle' className='grey-text pl-3' />
    }
  }

  const selectAllUsers = () => {
    if (allUsersSelected) {
      setBulkUsers([])
      setAllUsersSelected(false)
    } else {
      setBulkUsers(users)
      setAllUsersSelected(true)
    }
  }

  const masterToggle = (modal) => {
    switch (modal) {
      case 'Close Add User':
        setAddUserModal(!addUserModal)
        setUser({})
        setIsOptionalFieldsOpen(false)
        break
      case 'Open Add User':
        setAddUserModal(!addUserModal)
        break
      case 'Add Admin':
        setAddAdminModal(!addAdminModal)
        setNewULastName('')
        setNewUFirstName('')
        setNewUEmail('')
        break
      case 'Welcome Users':
        setWelcomeUsersModal(!welcomeUsersModal)
        break
      case 'Delete Users':
        setDeleteUsersModal(!deleteUsersModal)
        break
      case 'Delete Admin':
        setDeleteAdminModal(!deleteAdminModal)
        break
      case 'Sleep Users':
        setSleepUsersModal(!sleepUsersModal)
        break
      case 'Wake Users':
        setWakeUsersModal(!wakeUsersModal)
        break
    }
  }

  const toggleOptionalFields = () => {
    setIsOptionalFieldsOpen(!isOptionalFieldsOpen)
  }

  const searchBulkUserList = (userId) => {
    const found = bulkUsers.find(user => user.id === userId)
    if (found) {
      return true
    } else return false
  }

  const uploadUserInputs = async () => {
    const upUser = user
    const missing = ['first', 'last', 'email'].filter(key => !Object.keys(upUser).includes(key))
    if (missing.length) {
      NotificationUtilities.sendWarningMessage(`Missing ${missing} fields. These are required for all new devices.`)
      return
    }

    try {
      const { success, statusCode } = await dispatch(addUserThunk(id, upUser))
      if (success) {
        setAddUserModal(false)
        setUser({})
        setIsOptionalFieldsOpen(false)
        dispatch(incrementUserCountAction())

        if (!onboardingChecklist.hasUsers) {
          dispatch(updateOnboardingChecklistDataThunk(id, { hasUsers: true }))
        }

        NotificationUtilities.sendSuccessMessage('User successfully added!')
      } else {
        if (statusCode === 409) {
          NotificationUtilities.sendWarningMessage('A user with that email already exists.')
        } else {
          NotificationUtilities.sendErrorMessage('There was a problem uploading user.')
        }
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Error adding new user.')
    }
  }

  const downloadDevices = () => {
    apiRequestUtils.get(`/api/companies/${id}/users`)
      .then((devices) => {
        return devices.json()
      })
      .then((devices) => {
        const today = Moment().format('YYYY-MM-DD')
        const inputFields = JSON.parse(company.deviceStructure).map(col => col.field)
        inputFields.push('status')
        const csv = Papa.unparse(devices.length ? devices : [{}], { columns: inputFields })

        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(new Blob([csv]), { type: 'csv' })
        a.download = `${today}-Active-Devices.csv`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }

  async function addAdmin () {
    const tempEmail = newUEmail.trim()
    const tempFirstName = newUFirstName.trim()
    const tempLastName = newULastName.trim()

    if (!ValidationUtils.isNotWhiteSpace(tempFirstName)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid first name for this admin')
      return
    }

    if (!ValidationUtils.isNotWhiteSpace(tempLastName)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid last name for this admin')
      return
    }

    if (!ValidationUtils.isValidEmail(tempEmail)) {
      NotificationUtilities.sendWarningMessage('You must enter a valid email')
      return
    }

    NotificationUtilities.sendInfoMessage('Creating your admin now...')

    await dispatch(addAdminThunk(id, company.partnerName, tempEmail, tempFirstName, tempLastName))

    if (!onboardingChecklist.hasPersonalizations) {
      await dispatch(updateOnboardingChecklistDataThunk(id, { hasPersonalizations: true }))
    }

    masterToggle('Add Admin')
  }

  const handleEditingUser = (user) => {
    dispatch(setEditingUserAction(user))
    history.push(`/companies/${id}/edit-user`)
  }

  const handleRemovingAdmin = (uid) => {
    setSelectedAdmin(uid)
    masterToggle('Delete Admin')
  }

  const removeAdmin = async (uid) => {
    masterToggle('Delete Admin')
    dispatch(removeAdminThunk(id, uid))
  }

  const bulkActionDelete = async () => {
    const syncedUsers = bulkUsers.reduce((prev, curr) => {
      return prev && (!curr.externalId || !hasUserSync)
    }, true)
    if (!syncedUsers) {
      NotificationUtilities.sendErrorMessage(`Error deleting ${bulkUsers.length} users! Some of these users have been synced from an external system and must be deleted from there.`)
      return
    }
    const res = await dispatch(deleteUsersThunk(id, bulkUsers.map(user => user.id)))
    if (res.status !== 200) {
      NotificationUtilities.sendErrorMessage(`Error deleting ${bulkUsers.length} users! Our team has been notified.`)
    } else {
      let userDelta = 0
      const usersToDelete = bulkUsers
      for (const user of usersToDelete) {
        if (user.status === 'active') {
          userDelta++
        }
      }

      dispatch(setUserCountAction(company.totalDevices - userDelta))
      NotificationUtilities.sendSuccessMessage(`${bulkUsers.length} users deleted!`)
      setBulkUsers([])
      setAllUsersSelected(false)
      masterToggle('Delete Users')
    }
  }

  const bulkActionSleepToggle = async (status) => {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
      return
    }

    const userStatuses = []
    if (status === 'slept') { // button type check
      for (const user of bulkUsers) {
        const userStatus = { id: user.id, shouldSleep: true }
        userStatuses.push(userStatus)
      }
    } else {
      for (const user of bulkUsers) {
        const userStatus = { id: user.id, shouldSleep: false }
        userStatuses.push(userStatus)
      }
    }

    // send request to back end
    const { success, users } = await dispatch(bulkSleepThunk(id, userStatuses))
    if (success) {
      let userDelta = 0
      for (const user of users) {
        users.forEach(existingUser => {
          if (existingUser.id === user.userId) {
            if (user.oldStatus === 'slept' && user.status === 'active') {
              userDelta++
            } else if (user.oldStatus === 'active' && user.status === 'slept') {
              userDelta--
            }
          }
        })
      }

      dispatch(setUserCountAction(company.totalDevices + userDelta))
      setBulkUsers([])
      setAllUsersSelected(false)

      NotificationUtilities.sendSuccessMessage(`Users' statuses successfully changed to ${status}!`)
    }
  }

  async function bulkActionWelcome (sendAt) {
    NotificationUtilities.sendInfoMessage('Scheduling welcome messages...')
    const success = await dispatch(bulkWelcomeThunk(id, bulkUsers.map(user => user.id), sendAt))

    if (success) {
      setBulkUsers([])
      setAllUsersSelected(false)
      masterToggle('Welcome Users')
      if (!onboardingChecklist.hasWelcomedUsers) {
        dispatch(updateOnboardingChecklistDataThunk(id, { hasWelcomedUsers: true }))
      }
      NotificationUtilities.sendSuccessMessage('Welcome messages have been scheduled. It may take a few minutes for the changes to appear.')
    }
  }

  const updateBulkUsers = (bulkUser) => {
    // add or remove bulk user list
    const bulkUsersCopy = [...bulkUsers]
    const userFound = bulkUsersCopy.find(userObj => userObj.id === bulkUser.id)
    if (userFound) {
      const newBulkUsers = bulkUsersCopy.filter(existingUser => existingUser.id !== bulkUser.id)
      setBulkUsers(newBulkUsers)
    } else {
      bulkUsersCopy.push(bulkUser)
      setBulkUsers(bulkUsersCopy)
    }
  }

  const makeAdminList = (admins) => {
    const newAdmins = []
    if (admins === undefined) {
      return newAdmins
    }
    admins.forEach(admin => {
      const newAdmin = {}
      newAdmin.name = admin.name
      newAdmin.email = admin.email
      newAdmin.actions = (

        <MDBTooltip placement='top'>
          <MDBBtn
            id={`delete-admin-${admin.email}-button`}
            aria-label='Delete Admin Button'
            onClick={e => {
              handleRemovingAdmin(admin.uid)
            }}
            rounded
            type='submit'
            size='sm'
            color='danger'
          >
            <MDBIcon
              name={admin.email}
              onClick={e => {
                handleRemovingAdmin(admin.uid)
              }}
              size='lg'
              color='blue'
              icon='trash-alt'
            />
          </MDBBtn>
          <div>Remove Admin</div>
        </MDBTooltip>
      )

      newAdmins.push(newAdmin)
    })

    return newAdmins
  }

  const addUserInfo = (e, fieldName) => {
    const addUser = user
    addUser[fieldName] = e.target.value
    setUser(addUser)
  }

  const renderBulkActionButtons = () => {
    return (
      <>
        <MDBAnimation style={{ display: 'inline-block' }} type='fadeInRight' duration='.5s'>
          <MDBTooltip placement='top'>
            <MDBBtn
              onClick={handleWakeModalToggle}
              color='default'
              size='sm'
              rounded
              id='wake-users-button'
              aria-label='Wake Users Button'
            >
              <MDBIcon size='lg' icon='sun' />
            </MDBBtn>
            <div>Wake Users</div>
          </MDBTooltip>
          <MDBTooltip placement='top'>
            <MDBBtn
              onClick={handleSleepModalToggle}
              color='default'
              size='sm'
              rounded
              id='sleep-users-button'
              aria-label='Sleep Users Button'
            >
              <MDBIcon size='lg' icon='bed' />
            </MDBBtn>
            <div>Sleep Users</div>
          </MDBTooltip>
          <MDBTooltip placement='top'>
            <MDBBtn
              onClick={handleWelcomeModalToggle}
              color='default'
              size='sm'
              rounded
              id='welcome-users-button'
              aria-label='Welcome Users Button'
            >
              <MDBIcon size='lg' icon='paper-plane' />
            </MDBBtn>
            <div>Welcome Users</div>
          </MDBTooltip>
          <MDBTooltip placement='top'>
            <MDBBtn
              onClick={handleDeleteModalToggle}
              color='default'
              rounded
              size='sm'
              id='delete-users-button'
              aria-label='Delete Users Button'
            >
              <MDBIcon size='lg' icon='trash' />
            </MDBBtn>
            <div>Delete Users</div>
          </MDBTooltip>
        </MDBAnimation>
      </>
    )
  }
  const navigateToGroupViewer = (selectedGroupId) => {
    setGroupTabView('viewer')
    setSelectedGroupId(selectedGroupId)
  }
  const setCurrentOpenTab = (tabToOpen) => {
    setOpenTab(tabToOpen)
    const buggyNavEls = document.querySelectorAll('.user-page-nav-link')
    for (const el of [...buggyNavEls]) {
      el.classList.remove('active')
    }

    const activeEl = document.querySelector(`#user-page-nav-link-${tabToOpen}`)
    activeEl.classList.add('active')
  }
  const navigateToGroupOverview = () => {
    setGroupTabView('overview')
    setSelectedGroupId(undefined)
  }

  function handleWelcomeModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
      return
    }

    masterToggle('Welcome Users')
  }

  function handleDeleteModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
      return
    }

    masterToggle('Delete Users')
  }

  function handleWakeModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
    } else if (bulkUsers.length > 1) {
      masterToggle('Wake Users')
    } else {
      bulkActionSleepToggle('active')
    }
  }

  function handleSleepModalToggle () {
    if (bulkUsers.length === 0) {
      NotificationUtilities.sendWarningMessage('You must select at least one user.')
    } else if (bulkUsers.length > 1) {
      masterToggle('Sleep Users')
    } else {
      bulkActionSleepToggle('slept')
    }
  }

  const renderGroupViewer = (selectedGroupId) => {
    let selectedGroupMemberIds
    for (const group of groups) {
      if (group.id === selectedGroupId) {
        selectedGroupMemberIds = group.members
        break
      }
    }

    const newUsers = users.filter(user => selectedGroupMemberIds.includes(user.id))
    const userRows = []
    newUsers.forEach(user => {
      const newUser = {}

      newUser.name = user.first + ' ' + user.last
      newUser.email = user.email

      userRows.push(newUser)
    })

    const userCols = [
      {
        label: 'Name',
        field: 'name',
        sort: 'asc'

      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc'

      },
      {
        label: 'Actions',
        field: 'actions'
      }
    ]

    const newUsersTableData = {
      columns: userCols,
      rows: userRows
    }

    return (
      <MDBDataTableV5
        disableRetreatAfterSorting
        responsiveXl
        small
        className='pb-0'
        btn
        fixed
        data={newUsersTableData || []}
        barReverse
        searchTop
        searchBottom={false}
      />
    )
  }

  if (!company || !users || !groups) {
    return <Spinner />
  }
  const {
    admins,
    totalDevices: deviceCount
  } = company
  const adminList = makeAdminList(admins)

  const adminCol = [
    {
      label: 'Name',
      field: 'name',
      sort: 'asc'
    },
    {
      label: 'Email',
      field: 'email'
    },
    {
      label: 'Actions',
      field: 'actions'
    }
  ]

  const adminTableData = {
    columns: adminCol,
    rows: adminList
  }

  const groupTableData = {
    columns: [
      {
        label: 'Group Name',
        field: 'name'
      },
      {
        label: 'Members',
        field: 'members'
      },
      {
        label: 'Actions',
        field: 'actions'
      }
    ],
    rows: groups.filter((group) => {
      return group.status === groupStatuses.ACTIVE
    }).map(group => ({
      name: group.displayName,
      members: group.members.length,

      actions: (
        <div searchValue='==='>
          <MDBBtn rounded onClick={() => navigateToGroupViewer(group.id)}>
            <MDBIcon className='px-0' icon='eye' />
          </MDBBtn>
        </div>)
    }))
  }
  return (
    <MDBContainer>
      <NotificationWrapper />
      {/* Add User Modal */}
      <AddUserModal
        deviceStructure={JSON.parse(company.deviceStructure)}
        isOpen={addUserModal}
        addUserInfo={addUserInfo}
        uploadUser={uploadUserInputs}
        masterToggle={masterToggle}
        user={user}
        addUserModalButtonText={addUserModalButtonText}
        toggleOptionalFields={toggleOptionalFields}
        isOptionalFieldsOpen={isOptionalFieldsOpen}
      />

      {/* Add Admin Modal */}

      <MDBModal isOpen={addAdminModal} toggle={() => { masterToggle('Add Admin') }}>
        <MDBModalHeader>Add New Admin</MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size='6'>
              <MDBInput
                outline onChange={(e) => {
                  setNewUFirstName(e.target.value)
                }}
                name='newUFirstName'
                label='First Name'
                id='add-admin-modal-input-first'
                aria-label='Add Admin Modal First Name Field'
              />
            </MDBCol>
            <MDBCol size='6'>
              <MDBInput
                outline onChange={(e) => {
                  setNewULastName(e.target.value)
                }}
                name='newULastName'
                label='Last Name'
                id='add-admin-modal-input-last'
                aria-label='Add Admin Modal Last Name Field'
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className='d-flex justify-content-center'>
            <MDBCol>
              <MDBInput
                outline onChange={(e) => {
                  setNewUEmail(e.target.value)
                }}
                name='newUEmail'
                label='Email'
                id='add-admin-modal-input-email'
                aria-label='Add Admin Modal Email Field'
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            id='add-admin-modal-cancel-button'
            aria-label='Add Admin Modal Cancel Button'
            color='red'
            dark
            onClick={() => { masterToggle('Add Admin') }}
          >Cancel
          </MDBBtn>
          <MDBBtn
            id='add-admin-modal-add-admin-button'
            aria-label='Add Admin Modal Add Admin Button'
            onClick={() => { addAdmin() }}
            color='primary'
          >Add Admin
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      {/* End Add Admin Modal */}

      <MDBModal size='lg' isOpen={welcomeUsersModal} toggle={() => masterToggle('Welcome Users')}>
        <WelcomeUsersModal
          toggleFunction={() => masterToggle('Welcome Users')}
          handlerFunction={bulkActionWelcome}
          userCount={bulkUsers.length}
        />
      </MDBModal>

      <ValidationModal
        isOpen={deleteUsersModal}
        toggleFunction={() => masterToggle('Delete Users')}
        messageJSX={<p>Are you sure you want to delete these users? <strong>This can not be undone!</strong></p>}
        falseOptionText='Cancel'
        trueOptionText='Delete'
        falseOptionColor='primary'
        trueOptionColor='red'
        handlerFunction={bulkActionDelete}
      />

      <ValidationModal
        isOpen={sleepUsersModal}
        toggleFunction={() => masterToggle('Sleep Users')}
        messageJSX={<p>Are you sure you want to sleep <strong>{bulkUsers.length}</strong> users?</p>}
        falseOptionText='Cancel'
        trueOptionText='Sleep'
        falseOptionColor='red'
        trueOptionColor='primary'
        handlerFunction={() => {
          bulkActionSleepToggle('slept')
          masterToggle('Sleep Users')
        }}
      />

      <ValidationModal
        isOpen={wakeUsersModal}
        toggleFunction={() => masterToggle('Wake Users')}
        messageJSX={<p>Are you sure you want to wake <strong>{bulkUsers.length}</strong> users?</p>}
        falseOptionText='Cancel'
        trueOptionText='Wake'
        falseOptionColor='red'
        trueOptionColor='primary'
        handlerFunction={() => {
          bulkActionSleepToggle('active')
          masterToggle('Wake Users')
        }}
      />

      <ValidationModal
        isOpen={deleteAdminModal}
        toggleFunction={() => masterToggle('Delete Admin')}
        messageJSX={<p>Are you sure you want to delete this admin? This will remove their access to the dashboard! <strong>This can not be undone!</strong></p>}
        falseOptionText='Cancel'
        trueOptionText='Delete'
        falseOptionColor='primary'
        trueOptionColor='red'
        handlerFunction={() => removeAdmin(selectedAdmin)}
      />

      {/* End Modals */}

      <MDBRow>
        <MDBCol className='d-flex flex-column flex-center mb-3'>
          <h1>User Management</h1>
          <p>Configure and manage your users and appoint admins here</p>
        </MDBCol>
      </MDBRow>
      <MDBRow className='mt-4 d-flex justify-content-center'>
        <MDBCol xl='5' md='6' className='mb-5'>
          <MDBCard cascade className='cascading-admin-card'>
            <div className='admin-up'>
              <MDBIcon icon='laptop' className='primary-color mr-3 z-depth-2' />
              <div className='data'>
                <p>Active Users</p>
                <h4 id='device-count' className='font-weight-bold dark-grey-text'>{deviceCount}</h4>
              </div>
            </div>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <div className='classic-tabs'>
        <MDBNav classicTabs style={{ backgroundColor: '#2bbbad', padding: '8px 0' }} className='mt-5'>
          <MDBNavItem className={(openTab === 'users') ? 'user-page-nav-link nav-active' : 'user-page-nav-link'}>
            <div id='user-page-nav-link-users' className='nav-link' onClick={() => { setCurrentOpenTab('users') }}>
              <MDBIcon icon='user' size='2x' />
              <br />
              Users
            </div>
          </MDBNavItem>
          {groups.length > 0 &&
            <MDBNavItem className={(openTab === 'groups') ? 'user-page-nav-link nav-active' : 'user-page-nav-link'}>
              <div id='user-page-nav-link-groups' className='nav-link' onClick={() => { setCurrentOpenTab('groups') }}>
                <MDBIcon icon='users' size='2x' />
                <br />
                Groups
              </div>
            </MDBNavItem>}
          <MDBNavItem className={(openTab === 'admins') ? 'user-page-nav-link nav-active' : 'user-page-nav-link'}>
            <div id='user-page-nav-link-admins' className='nav-link' onClick={() => { setCurrentOpenTab('admins') }}>
              <MDBIcon icon='hammer' size='2x' />
              <br />
              Admins
            </div>
          </MDBNavItem>
        </MDBNav>
      </div>
      <MDBTabContent className='card' activeItem={openTab} style={{ padding: '2em 2em' }}>
        <MDBTabPane className='pl-2 pr-2' tabId='users'>
          <p>Manage users here</p>
          <MDBRow>
            <MDBBtn id='download-active-users-button' onClick={() => downloadDevices()}> Download All Users</MDBBtn>
            <MDBBtn id='upload-user-csv-button' aria-label='Upload User CSV Button' onClick={() => history.push(`/companies/${id}${navigationRoutes.USERS_UPLOAD}`)}> Upload CSV </MDBBtn>
            <MDBBtn id='add-user-button' onClick={() => { setAddUserModalButtonText('Add User'); masterToggle('Open Add User') }}>
              Add User
            </MDBBtn>
          </MDBRow>
          <hr />
          <MDBRow size='12'>
            {renderBulkActionButtons()}
            <>
              {(users && userTableData.rows.length >= 1 && userTableData.rows.length < 1000) && (
                <MDBDataTableV5
                  disableRetreatAfterSorting
                  hover
                  selectable='true'
                  entriesOptions={[10, 20, 25]}
                  entries={10}
                  pagesAmount={4}
                  data={userTableData}
                  responsiveXl
                  fixed
                  btn
                  barReverse
                  searchTop
                  searchBottom={false}
                />
              )}

              {(users && userTableData.rows.length > 1000) && (
                <h4>You have over 1000 users! Please download the list to see them all.</h4>
              )}
            </>
          </MDBRow>
        </MDBTabPane>
        <MDBTabPane tabId='groups'>
          {groupTabView === 'viewer' &&
            (
              <>
                <MDBBtn onClick={() => navigateToGroupOverview()}> Go Back</MDBBtn>
                {renderGroupViewer(selectedGroupId)}
              </>)}

          {groupTabView === 'overview' &&
            <MDBDataTableV5
              disableRetreatAfterSorting
              responsiveXl
              small
              className='pb-0'
              btn
              fixed
              data={groupTableData}
              barReverse
              searchTop
              searchBottom={false}
            />}
        </MDBTabPane>
        <MDBTabPane tabId='admins'>
          <MDBRow>
            <MDBCol>
              <p>These are people who can access this dashboard and manage other users</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className='p-2'>
            <MDBBtn
              id='add-admin-button'
              aria-label='Add Admin Button'
              onClick={() => { masterToggle('Add Admin') }}
            >
              Add Admins
            </MDBBtn>
            <MDBCardBody cascade>
              <MDBDataTableV5
                striped
                small
                disableRetreatAfterSorting
                searching={false}
                sortRows={['name']}
                btn
                fixed
                data={adminTableData}
                barReverse
                searchTop
                searchBottom={false}
              />
            </MDBCardBody>
          </MDBRow>
        </MDBTabPane>

      </MDBTabContent>

    </MDBContainer>
  )
}

export default UsersPage
