import React from 'react'
import {
  MDBRow,
  MDBCol,
  DatePicker,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
  MDBBtn,
  MDBTooltip
} from 'mdbreact'
import { dateIntervals } from '../../../frontendConsts.js'
import './CampaignSelector.css'

const buildCampaignOptions = (campaigns, pastCampaigns, selectedCampaignId, handleCampaignSelection) => {
  const campaignOptions = []

  campaignOptions.push(
    <MDBDropdownItem active={selectedCampaignId === ''} key='' onClick={() => { handleCampaignSelection('', '') }}>
      All Campaigns
    </MDBDropdownItem>
  )

  // Select all the active campaigns, and the top 5 past campaigns
  for (const campaign of campaigns) {
    campaignOptions.push(
      <MDBDropdownItem active={selectedCampaignId === campaign.id} key={campaign.id} onClick={() => { handleCampaignSelection(campaign.id, campaign.name) }}>
        <MDBBadge className='mr-2' color='success'>Active</MDBBadge> {campaign.name}
      </MDBDropdownItem>
    )
  }

  // Select the 5 most recent past campaigns too
  for (let i = 0; i < pastCampaigns.length && i < 5; i++) {
    const campaign = pastCampaigns[i]

    campaignOptions.push(
      <MDBDropdownItem active={selectedCampaignId === campaign.id} key={campaign.name} onClick={() => { handleCampaignSelection(campaign.id) }}>
        <MDBBadge className='mr-2' color='primary'>Completed</MDBBadge> {campaign.name}
      </MDBDropdownItem>
    )
  }

  return campaignOptions
}

const createDateRangeSelectors = (dateInterval, updateDateRange) => {
  const labels = Object.entries(dateIntervals)
  const mappedLabels = labels.map(entry => {
    const labelName = entry[0]
    const daysAgo = entry[1]
    const active = dateInterval === daysAgo
    const link = <a className={`p-1 ${active ? 'active-date-interval' : ''}`} key={labelName} onClick={() => { updateDateRange({ daysAgo }) }}>{labelName}</a>
    return link
  })
  return (
    <p className='mb-0'>{mappedLabels}</p>
  )
}

const CampaignSelector = ({
  campaigns, pastCampaigns, selectedCampaignId, handleCampaignSelection, startDate, endDate, dateInterval, updateDateRange, earliestDate,
  clickedTimeElapsedFilterLabel, setClickedTimeElapsedFilterLabel, setClickedTimeElapsedFilter
}) => {
  let selectedCampaign
  const campaignOptions = buildCampaignOptions(campaigns, pastCampaigns, selectedCampaignId, handleCampaignSelection)
  if (selectedCampaignId === '') {
    selectedCampaign = { name: 'All Campaigns' }
  } else {
    selectedCampaign = [...campaigns, ...pastCampaigns].find(campaign => campaign.id === selectedCampaignId)
  }

  return (
    <MDBRow className=''>
      <MDBCol size='12'>
        <MDBCol>
          <MDBRow className='d-flex align-items-center justify-content-center mt-2'>
            <DatePicker
              className='d-flex justify-content-center'
              value={startDate}
              minDate={earliestDate}
              maxDate={endDate}
              name='startDate'
              getValue={(value) => updateDateRange({ startDate: value })}
            />
            <h4 className='mb-0'><MDBIcon className='pl-2 pr-2' icon='long-arrow-alt-right' /></h4>
            <DatePicker
              className='d-flex justify-content-center'
              value={endDate}
              minDate={startDate}
              maxDate={new Date()}
              name='endDate'
              getValue={(value) => updateDateRange({ endDate: value })}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol className='d-flex justify-content-end flex-column'>
              {createDateRangeSelectors(dateInterval, updateDateRange)}
            </MDBCol>
            <MDBCol className='d-flex justify-content-end w-auto'>
              <MDBDropdown>
                <MDBDropdownToggle id='analytics-switch-campaign-toggle' caret color='primary'>
                  {selectedCampaign.name}
                </MDBDropdownToggle>
                <MDBDropdownMenu id='analytics-switch-campaign-dropdown' color='info' right basic>
                  {campaignOptions}
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBDropdown>
                <MDBDropdownToggle caret color='primary'>
                  {clickedTimeElapsedFilterLabel}
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                  <MDBDropdownItem onClick={() => { setClickedTimeElapsedFilter(0); setClickedTimeElapsedFilterLabel('Filter Click Time') }}>Reset</MDBDropdownItem>
                  <MDBDropdownItem onClick={() => { setClickedTimeElapsedFilter(30); setClickedTimeElapsedFilterLabel('30 Seconds') }}>30 Seconds</MDBDropdownItem>
                  <MDBDropdownItem onClick={() => { setClickedTimeElapsedFilter(60); setClickedTimeElapsedFilterLabel('1 Minute') }}>1 Minute</MDBDropdownItem>
                  <MDBDropdownItem onClick={() => { setClickedTimeElapsedFilter(120); setClickedTimeElapsedFilterLabel('2 Minutes') }}>2 Minutes</MDBDropdownItem>
                  <MDBDropdownItem onClick={() => { setClickedTimeElapsedFilter(300); setClickedTimeElapsedFilterLabel('5 Minutes') }}>5 Minutes</MDBDropdownItem>
                  <MDBDropdownItem onClick={() => { setClickedTimeElapsedFilter(600); setClickedTimeElapsedFilterLabel('10 Minutes') }}>10 Minutes</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBTooltip
                placement='top'
                id='description-popover'
                material
              >
                <MDBBtn
                  color='elegant'
                  className='my-0'
                  floating
                  tag='a'
                  size='sm'
                >
                  <MDBIcon icon='question' />
                </MDBBtn>
                <div>
                  Filter records by the time between send and click. Filter records that show they were clicked '30 seconds', '5 minutes', etc. after being sent.
                </div>
              </MDBTooltip>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBCol>
    </MDBRow>
  )
}

export default CampaignSelector
