import {
  MDBCardImage, MDBCardBody, MDBCardTitle, MDBCard,
  MDBCol, MDBIcon, MDBRow, MDBContainer, MDBBtn
} from 'mdbreact'

import { useHistory } from 'react-router-dom'

import NotificationWrapper from './components/notifications/notificationWrapper'

import React, { useEffect } from 'react'

import './css/training.css'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './components/Spinner'
import { getPolicyCatalogThunk } from '../store/PoliciesSlice'

function renderPolicyCard (policy, goToPolicyDistributionPage) {
  return (
    <MDBCol key={policy.id} className='d-flex justify-content-center mt-4'>
      <MDBCard style={{ width: '22rem' }}>
        <MDBCardImage
          top
          overlay={false}
          waves={false}
          src={policy.thumbnail || 'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg'}
        />
        <MDBCardBody>
          <MDBCardTitle>{policy.name}</MDBCardTitle>
        </MDBCardBody>
        <div
          onClick={() => goToPolicyDistributionPage(policy)}
          className='btn btn-lg btn-secondary m-0 training-launch-btn pt-3 rounded-bottom'
          id={`distribute-policy-${policy.name}-button`}
          aria-label='Distribute Policy Button'
        >
          <MDBRow className='d-flex justify-content-center align-content-center text-center'>
            <MDBIcon size='lg' className='pr-2 pt-1' icon='rocket' />
            Distribute Policy
          </MDBRow>
        </div>
      </MDBCard>
    </MDBCol>
  )
}

function renderPolicies (policyCatalog, openConfirmModal) {
  return policyCatalog.map(policy => renderPolicyCard(policy, openConfirmModal))
}

function CatalogPolicyPage ({ id }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { company, loaders } = useSelector((state) => state.company)
  const { isLoadingCompany } = loaders
  const { policies, policyCatalog, loaders: policyLoader } = useSelector((state) => state.policies)
  const { isLoadingPolicies, isLoadingPolicyCatalog } = policyLoader

  useEffect(() => {
    if (!policyCatalog) {
      dispatch(getPolicyCatalogThunk({ id }))
    }
  }, [])

  function goToUploadPolicyPage () {
    history.push(`/companies/${id}/policy/upload`)
  }

  function goToPolicyDistributionPage (policy) {
    history.push(`/companies/${id}/policy/distribute/${policy.id}`)
  }

  return (
    <MDBContainer>
      <NotificationWrapper />

      <MDBRow center className='d-flex justify-content-center'>
        <h1>Policy Document Catalog</h1>
      </MDBRow>
      <MDBRow className='d-flex justify-content-center'>
        <p>View our available policy document selection here as well as distribute new policy documents to your employees</p>
      </MDBRow>

      {(!company || isLoadingCompany ||
      !policies || isLoadingPolicies ||
      !policyCatalog || isLoadingPolicyCatalog) && (
        <Spinner />
      )}

      {(company && !isLoadingCompany &&
      policies && !isLoadingPolicies &&
      policyCatalog && !isLoadingPolicyCatalog) && (
        <>
          <MDBRow className='d-flex justify-content-center mb-5'>
            <MDBCol xl='4' md='4' className='d-flex justify-content-center align-items-center'>
              <MDBBtn onClick={goToUploadPolicyPage} color='primary' size='lg'>
                <MDBRow className='d-flex justify-content-center align-items-center'>
                  <MDBCol size='2' className='mr-2 pl-0'>
                    <MDBIcon size='3x' icon='plus-square' />
                  </MDBCol>
                  <MDBCol className='px-1' id='startNewCampaignBtn' size='9'>
                    Upload A Custom Policy
                  </MDBCol>
                </MDBRow>
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            {renderPolicies(policyCatalog, goToPolicyDistributionPage)}
          </MDBRow>
        </>
      )}

    </MDBContainer>
  )
}

export default CatalogPolicyPage
