import { commitStagingThunk, disconnectUserSyncThunk, syncUsersThunk } from '../../../store/UserSyncSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import '../../css/integrationsDetail.css'
import ValidationModal from '../ValidationModal'
import { useState } from 'react'
import { microsoftIntegrationStatuses } from '../../../frontendConsts.js'

export function UserSyncActionButtons ({ companyId }) {
  const dispatch = useDispatch()
  const history = useHistory()

  // Get the integration from the redux store instead
  const { integrationsMap } = useSelector((state) => state.integrations)
  const { loaders: userSyncSliceLoaders } = useSelector((state) => state.userSync)
  const { loadingUserSyncStatus } = userSyncSliceLoaders

  const [disableButtons, setDisableButtons] = useState(false)
  const [disconnectModal, setDisconnectModal] = useState(false)

  async function handleDisconnect () {
    if (disableButtons) {
      return
    }
    setDisableButtons(true)
    await dispatch(disconnectUserSyncThunk({ companyId, history }))
    setDisconnectModal(false)
    setDisableButtons(false)
  }

  async function handleSync () {
    if (disableButtons || integrationsMap.userSync.isSyncing) {
      return
    }
    setDisableButtons(true)
    await dispatch(syncUsersThunk({ companyId }))
    setDisableButtons(false)
  }

  async function handleCommit () {
    if (disableButtons || (integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.COMMITTING)) {
      return
    }
    setDisableButtons(true)
    await dispatch(commitStagingThunk({ companyId }))
    setDisableButtons(false)
  }

  return (
    <>
      <ValidationModal
        isOpen={disconnectModal}
        toggleFunction={() => setDisconnectModal(false)}
        messageJSX={<p>Are you sure you want to disconnect Azure User Sync? Users will no longer sync automatically.</p>}
        falseOptionText='Cancel'
        trueOptionText='Disconnect'
        falseOptionColor='primary'
        trueOptionColor='red'
        handlerFunction={handleDisconnect}
      />

      <div className='actionBtnGroup'>
        <div className='continueBtn disconnectActionBtn' onClick={() => setDisconnectModal(true)}>Disconnect</div>
        {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.CONNECTED && (
          <div
            id='sync-button'
            aria-aria-label='Sync Button'
            className='continueBtn syncActionBtn'
            disabled={(integrationsMap.userSync.isSyncing || loadingUserSyncStatus)}
            style={(integrationsMap.userSync.isSyncing || loadingUserSyncStatus) ? { backgroundColor: 'white', color: '#2bbbad', border: '2px solid #2bbbad' } : {}}
            onClick={handleSync}
          >{(integrationsMap.userSync.isSyncing || loadingUserSyncStatus) ? 'Syncing' : 'Sync Now'}
          </div>)}
        {integrationsMap.userSync && integrationsMap.userSync.integrationStatus === microsoftIntegrationStatuses.STAGED && (
          <div
            id='commit-button'
            aria-aria-label='Commit Button'
            disabled={loadingUserSyncStatus}
            className='continueBtn syncActionBtn'
            onClick={handleCommit}
          >
            Commit
          </div>
        )}
      </div>
    </>
  )
}
