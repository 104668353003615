import { MDBBtn, MDBIcon } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CourseDetailModal } from '../../CourseDetailModal'
import Spinner from '../../Spinner'
import './onboardingSelectorTable.scss'
import { IoLockClosed } from 'react-icons/io5'
import { TrainingTableRow } from './TrainingTableRow'
import { CoursePreviewModal } from '../../../../shared/components/CoursePreviewModal'

export const TrainingModuleSelectorTable = ({ companyId, modules, setModules, rowCount, lock }) => {
  const { courseCatalog } = useSelector((state) => state.trainings)
  const [maxPage, setMaxPage] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [courseSliceIndex, setCourseSliceIndex] = useState({ start: 0, stop: rowCount })
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [courseDetailSelected, setCourseDetailSelected] = useState()
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewCourse, setPreviewCourse] = useState()

  const translateCourseToCurriculumDataModel = (courseInfo) => {
    // note: this is currently how we store pre-built curriculum course info
    const path = (courseInfo.provider === 'self')
      ? `clients/${companyId}/course-definitions/${courseInfo.id}`
      : `course-definitions/${courseInfo.id}`

    return {
      name: courseInfo.name,
      path: path,
      id: courseInfo.id // needed for previewing and check marks in select table
    }
  }

  const handleArrayChange = (courseInfo) => {
    const found = modules.find(course => course.id === courseInfo.id)
    if (found) {
      setModules(modules.filter(course => course.id !== courseInfo.id))
    } else {
      setModules(modules.concat(translateCourseToCurriculumDataModel(courseInfo)))
    }
  }

  const changePage = (direction) => {
    if (direction === 'next') {
      const nextStart = courseSliceIndex.stop
      const nextStop = nextStart + rowCount
      setCurrentPage((prev) => prev + 1)
      setCourseSliceIndex({ start: nextStart, stop: nextStop })
    } else {
      const nextStart = courseSliceIndex.start - rowCount
      const nextStop = nextStart + rowCount
      setCurrentPage((prev) => prev - 1)
      setCourseSliceIndex({ start: nextStart, stop: nextStop })
    }
  }

  const toggleDetailModal = (course, shouldShowDetailModal) => {
    setCourseDetailSelected(course)
    setShowDetailModal(shouldShowDetailModal)
  }

  const togglePreviewModal = (course, shouldShowPreviewModal) => {
    setPreviewCourse(course)
    setShowPreviewModal(shouldShowPreviewModal)
  }

  useEffect(() => {
    if (courseCatalog) {
      setMaxPage(Math.ceil(courseCatalog.length / rowCount))
    }
  }, [courseCatalog])

  return !maxPage
    ? <Spinner />
    : (
      <>
        <div className='curriculum-select-container'>
          <table className='table'>
            <thead>
              <tr>
                {lock
                  ? (<th scope='col' style={{ paddingLeft: '2.5em' }}><IoLockClosed className='lock-icon' /></th>)
                  : (
                    <th scope='col' />
                    )}
                <th className='header' scope='col'>Provider</th>
                <th className='header' scope='col'>Course Name</th>
                <th className='header' scope='col'>Details</th>
                <th className='header' scope='col'>Preview</th>
              </tr>
            </thead>
            <tbody>
              {courseCatalog.map((course, index) => (
                <TrainingTableRow
                  handleArrayChange={handleArrayChange}
                  modules={modules}
                  togglePreviewModal={togglePreviewModal}
                  toggleDetailModal={toggleDetailModal}
                  key={course.id} courseInfo={course}
                  lock={lock}
                  stripe={(index % 2) === 0}
                />
              )).slice(courseSliceIndex.start, courseSliceIndex.stop)}
            </tbody>
          </table>
          <div className='page-table-bottom-row'>
            <div className='course-info-row'>
              <div className='course-info-col'>
                <p>Course Count:&nbsp;</p>
                <p>{modules.length}</p>
              </div>
              <div className='course-info-col'>
                <p>Estimated Completion Time:&nbsp;</p>
                {/* TODO: write a function to pull durationInMinutes from course-definitions if possible if not default to 10 mins */}
                <p>{`${modules.length * 10} Minutes`}</p>
              </div>
            </div>
            <div className='page-table-btn-row'>
              <MDBBtn size='sm' disabled={currentPage === 1} onClick={() => changePage('previous')}><MDBIcon size='lg' icon='angle-left' /></MDBBtn>
              <p>{currentPage} of {maxPage}</p>
              <MDBBtn size='sm' disabled={currentPage === maxPage} onClick={() => changePage('next')}><MDBIcon size='lg' icon='angle-right' /></MDBBtn>
            </div>
          </div>
        </div>
        <CoursePreviewModal
          companyId={companyId}
          courseDefinition={previewCourse}
          previewModalHeader={previewCourse?.name || ''}
          showPreviewModal={showPreviewModal}
          togglePreviewModal={togglePreviewModal}
        />
        <CourseDetailModal
          course={courseDetailSelected}
          detailModalHeader='Course Details'
          showDetailModal={showDetailModal}
          toggleDetailModal={toggleDetailModal}
        />
      </>
      )
}
