import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Grid, Switch } from '@mui/material'

import {
  MDBCard, MDBCardHeader, MDBCardBody, MDBRow,
  MDBCol, MDBBtn, MDBIcon, MDBNavItem, MDBNav,
  MDBInput, MDBAlert, MDBTooltip
} from 'mdbreact'

import { orgTypes, shareLevels } from '../../../frontendConsts.js'

function PublishSelector ({
  option, handleOptions, handleEmail,
  emails, emailsDisabled, handlePhones, phones,
  phonesDisabled, handleShareLevel,
  allowPreview = false, previewSuccess = false
}) {
  const { authorization } = useSelector((state) => state.auth)
  const { company } = useSelector((state) => state.auth)

  function hasAccessToParentPartner (partners) {
    return partners.includes()
  }

  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if (company && authorization) {
      const { roles } = authorization
      const partners = Object.keys(roles[orgTypes.PARTNER] || {})
      setToggle(hasAccessToParentPartner(partners))
    }
  }, [company, authorization])

  useEffect(() => {
    if (toggle) {
      handleShareLevel(shareLevels.PARTNER)
    } else {
      handleShareLevel(shareLevels.CLIENT)
    }
  }, [toggle])

  function renderContent () {
    if (option === 'preview') {
      return (
        <>
          {previewSuccess &&
            <MDBAlert className='animated fadeInDown' color='info'>
              <MDBRow>
                <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
                  <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='info-circle' />
                </MDBCol>
                <MDBCol>
                  Your preview campaign has been sent! If the emails look good then you can choose to save and launch, or hit cancel if you just wanted to preview some templates.
                </MDBCol>
              </MDBRow>
            </MDBAlert>}
          <MDBRow className='mt-2'>
            <MDBCol>
              <MDBRow className='d-flex justify-content-center text-center'>
                <MDBCol size='11'>
                  <p>Send all templates to the emails (and phones if using SMS) for viewing.
                    If they look good, you will have the option to save this preset to launch later.
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol>
              <MDBInput disabled={emailsDisabled} id='email1' outline icon='envelope' value={emails[0]} getValue={(value) => updateEmails(0, value)} label='Email Address' size='lg' />
              <MDBInput disabled={emailsDisabled} id='email2' outline icon='envelope' value={emails[1]} getValue={(value) => updateEmails(1, value)} label='Email Address' size='lg' />
              <MDBInput disabled={emailsDisabled} id='email3' outline icon='envelope' value={emails[2]} getValue={(value) => updateEmails(2, value)} label='Email Address' size='lg' />
            </MDBCol>
            <MDBCol>
              <MDBInput disabled={phonesDisabled} id='phone1' outline icon='phone' value={phones[0]} getValue={(value) => updatePhones(0, value)} label='Phone Number' size='lg' />
              <MDBInput disabled={phonesDisabled} id='phone2' outline icon='phone' value={phones[1]} getValue={(value) => updatePhones(1, value)} label='Phone Number' size='lg' />
              <MDBInput disabled={phonesDisabled} id='phone3' outline icon='phone' value={phones[2]} getValue={(value) => updatePhones(2, value)} label='Phone Number' size='lg' />
            </MDBCol>
          </MDBRow>
        </>
      )
    } else if (option === 'preset') {
      return (
        <MDBRow className='mt-2'>
          <MDBCol>
            <MDBRow className='d-flex justify-content-center text-center'>
              <MDBCol size='11'>
                <p>This campaign will be saved to your preset library so you can start a similar campaign again later.
                  It will then also schedule this campaign for your users.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow className='d-flex justify-content-center text-center'>
              {hasAccessToParentPartner && (
                <div>
                  <p>You can also share this preset with other companies:</p>
                  <Grid item xs={6}>
                    Share Preset
                    <Switch
                      id='sharePresetCampaignToggle' checked={toggle} onClick={() => { setToggle(!toggle) }}
                    />
                  </Grid>
                </div>
              )}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )
    } else if (option === 'launch') {
      return (
        <MDBRow className='mt-2'>
          <MDBCol>
            <MDBRow className='d-flex justify-content-center text-center'>
              <MDBCol size='11'>
                <p>This campaign will be scheduled for your users. However, it will not save this configuration as a preset for later use.
                  This is a good setting for one-time campaigns or special configurations.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      )
    }
  }

  function updateEmails (index, email) {
    const newEmails = [...emails]
    newEmails[index] = email
    handleEmail(newEmails)
  }

  function updatePhones (index, phone) {
    const newPhones = [...phones]
    newPhones[index] = phone
    handlePhones(newPhones)
  }

  return (
    <MDBCard className='w-100 h-100'>
      <MDBCardHeader>
        <MDBRow className='d-flex'>
          <MDBCol className='d-flex justify-content-start align-items-center'>
            <h5>Publish Options</h5>
          </MDBCol>
          <MDBCol className='d-flex justify-content-end'>
            <MDBTooltip
              placement='left'
              id='pub-popover'
              material
            >
              <MDBBtn
                color='elegant'
                className='my-0'
                floating
                tag='a'
                size='sm'
              >
                <MDBIcon icon='question' />
              </MDBBtn>
              <div>
                These are the ways we can publish this new campaign. Click on each of the options below to see more details.
                Save and Launch is the default.
              </div>
            </MDBTooltip>
          </MDBCol>
        </MDBRow>
      </MDBCardHeader>
      <MDBCardBody className='w-100 h-100'>
        <MDBNav pills color='primary' className='nav-justified'>
          <MDBNavItem>
            <a
              onClick={() => handleOptions('preset')}
              className={option === 'preset' ? 'nav-link Ripple-parent active' : 'nav-link Ripple-parent'}
              aria-current='page'
            >
              <MDBIcon size='lg' icon='archive' /> <br /> Save and Launch
            </a>
          </MDBNavItem>
          <MDBNavItem>
            <a
              onClick={() => handleOptions('launch')}
              className={option === 'launch' ? 'nav-link Ripple-parent active' : 'nav-link Ripple-parent'}
              aria-current='page'
            >
              <MDBIcon size='lg' icon='rocket' /> <br /> Launch Campaign
            </a>
          </MDBNavItem>
          {allowPreview &&
            <MDBNavItem>
              <a
                onClick={() => handleOptions('preview')}
                className={option === 'preview' ? 'nav-link Ripple-parent active' : 'nav-link Ripple-parent'}
                aria-current='page'
              >
                <MDBIcon size='lg' icon='tools' /> <br /> Run A Preview
              </a>
            </MDBNavItem>}
        </MDBNav>
        <hr />
        {renderContent()}
      </MDBCardBody>
    </MDBCard>
  )
}

export default PublishSelector
