import { useEffect, useState } from 'react'
import {
  MDBCol, MDBIcon, MDBRow, MDBAlert
} from 'mdbreact'
import { campaignTypes } from '../../../frontendConsts.js'
import { useSelector } from 'react-redux'
import ActiveTrainingCampaignCard from './ActiveTrainingCampaignCard.js'

export default function ActiveTrainingCampaigns ({
  viewTrainingCampaign,
  editTrainingCampaign,
  openCancelCampaignModal, company
}) {
  const { activeCampaigns } = useSelector((state) => state.campaigns)

  const [trainingCampaigns, setTrainingCampaigns] = useState([])

  useEffect(() => {
    if (activeCampaigns) {
      setTrainingCampaigns(activeCampaigns.filter(campaign => campaign.campaignType === campaignTypes.TRAINING || campaign.campaignType === campaignTypes.ONBOARDING))
    }
  }, [activeCampaigns])

  if (trainingCampaigns.length === 0) {
    return (
      <MDBAlert color='secondary'>
        <MDBRow>
          <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
            <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='question-circle' />
          </MDBCol>
          <br />
          <MDBCol className='d-flex align-items-center'>
            You haven't started any trainings campaings yet. To start a training campaign select the button above
          </MDBCol>
        </MDBRow>
      </MDBAlert>
    )
  } else {
    return (
      <MDBRow>
        {trainingCampaigns.map(campaign => {
          return (
            <ActiveTrainingCampaignCard
              key={campaign.id}
              campaign={campaign}
              openCancelCampaignModal={openCancelCampaignModal}
              editTrainingCampaign={editTrainingCampaign}
              viewTrainingCampaign={viewTrainingCampaign}
              company={company}
            />
          )
        }
        )}
      </MDBRow>
    )
  }
}
