import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBBtn,
  MDBRow,
  MDBIcon,
  MDBAlert,
  MDBInput,
  MDBTooltip
} from 'mdbreact'
import { learningTypes as learningTypeDef } from '../../../frontendConsts.js'
import React from 'react'

const renderTextWaring = (learningTypes) => {
  if (learningTypes.video === true) {
    return (
      <MDBAlert className='animated fadeInDown' color='warning'>
        <MDBRow>
          <MDBCol className='d-flex justify-content-center align-items-center' size='2'>
            <MDBIcon className='d-flex justify-content-center align-items-center' size='3x' icon='exclamation-triangle' />
          </MDBCol>
          <MDBCol>
            You have selected to include templates with embedded video learning. This feature is currently in beta. If there is video content
            you would like to see included reach out to us!
          </MDBCol>
        </MDBRow>
      </MDBAlert>
    )
  } else {
    return (<></>)
  }
}

const CampaignLearningTypesEditor = ({ learningTypes, toggleLearningTypes, disableUpdating = false }) => {
  return (
    <MDBCard className='mt-2 mb-2 w-100'>
      <MDBCardHeader>
        <MDBRow className='d-flex'>
          <MDBCol className='d-flex justify-content-start align-items-center'>
            <h5>Learning Types</h5>
          </MDBCol>
          <MDBCol className='d-flex justify-content-end'>
            <MDBTooltip
              placement='left'
              id='templates'
              material
            >
              <MDBBtn
                color='elegant'
                className='my-0'
                floating
                tag='a'
                size='sm'
              >
                <MDBIcon icon='question' />
              </MDBBtn>
              <div>
                Here you can filter templates in your campaign based on which learning types they are configured to use
              </div>
            </MDBTooltip>
          </MDBCol>
        </MDBRow>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBRow className='d-flex justify-content-center'>
          <MDBCol size='3' className='d-flex justify-content-center'>
            <MDBInput label='Learning Moment' disabled={disableUpdating} type='checkbox' id='lmCheckBox' checked={learningTypes.lm} onChange={() => toggleLearningTypes(learningTypeDef.LM)} />
          </MDBCol>
          <MDBCol size='3' className='d-flex justify-content-center'>
            <MDBInput label='Video' disabled={disableUpdating} type='checkbox' id='videoCheckBox' checked={learningTypes.video} onChange={() => toggleLearningTypes(learningTypeDef.VIDEO)} />
          </MDBCol>
          <MDBCol size='3' className='d-flex justify-content-center'>
            <MDBInput label='Custom URL' disabled={disableUpdating} type='checkbox' id='customCheckBox' checked={learningTypes.custom} onChange={() => toggleLearningTypes(learningTypeDef.CUSTOM)} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {renderTextWaring(learningTypes)}
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default CampaignLearningTypesEditor
