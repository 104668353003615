import React, { useState, useEffect } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { campaignTypes, audienceSelectionTypes } from '../../frontendConsts'
import { getPolicyCatalogThunk, launchPolicyThunk } from '../../store/PoliciesSlice'
import NotificationUtilities from '../components/notifications/notificationUtils'
import NotificationWrapper from '../components/notifications/notificationWrapper'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../components/Spinner'
import './UploadPolicy.css'
import CurriculumAudience from '../components/curriculums/CurriculumAudience'
import PDFObject from 'pdfobject/pdfobject.js'
import { apiRequestUtils } from '../../utils/apiRequestUtils'
import { IoArrowBackOutline, IoSendSharp } from 'react-icons/io5'
import { Button } from '@mui/material'

const DistributePolicy = ({ id }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { policyCatalog, loaders: policyLoaders } = useSelector((state) => state.policies)
  const { isLoadingPolicyCatalog } = policyLoaders

  const [policyName, setPolicyName] = useState('Loading...')
  const [policyDescription, setPolicyDescription] = useState('No description')
  const [audienceSelectionType, setAudienceSelectionType] = useState(audienceSelectionTypes.DYNAMIC)
  const [audienceFilterType, setAudienceFilterType] = useState('first')
  const [audienceFilters, setAudienceFilters] = useState([{
    field: 'first',
    type: '!=',
    value: '~~~'
  }])
  const { groups } = useSelector((state) => state.users)
  const [departmentNames, setDepartmentNames] = useState([])
  const [supervisorEmails, setSupervisorEmails] = useState([])
  const [audienceStats, setAudienceStats] = useState({})
  const [fixedAudienceList, setFixedAudienceList] = useState([])
  const initialFixedAudienceList = []
  const campaignType = campaignTypes.POLICY

  const params = useParams()

  useEffect(() => {
    async function getPolicy () {
      const policyIFrame = document.querySelector('#policy-frame')
      policyIFrame.src = apiRequestUtils.getPolicyRootPath(params.policyId, id)
      PDFObject.embed(apiRequestUtils.getPolicyRootPath(params.policyId, id), 'policy-frame')
    }
    getPolicy()
  }, [])

  useEffect(() => {
    if (!policyCatalog) {
      dispatch(getPolicyCatalogThunk({ id }))
    } else {
      const thisPolicy = policyCatalog.filter((policy) => policy.id === params.policyId)[0]
      setPolicyName(thisPolicy.name)
      setPolicyDescription(thisPolicy.description)
    }
  }, policyCatalog)

  useEffect(() => {
    configureAudienceFilters({ audienceFilterType: audienceFilterType, audienceFilters: audienceFilters })
  }, [id])

  // Update the audience stats based on audienceFilters
  useEffect(() => {
    if (audienceSelectionType === audienceSelectionTypes.DYNAMIC) {
      refreshAudienceStats(audienceFilters)
    } else {
      refreshAudienceStats([{
        field: 'first',
        type: '!=',
        value: '~~~'
      }])
    }
  }, [audienceFilters])

  const configureAudienceFilters = async ({ audienceFilterType, audienceFilters }) => {
    try {
      const response = await apiRequestUtils.post(`/api/companies/${id}/audience/stats`, { isGroup: audienceFilterType === 'group', userParameters: audienceFilters })

      if (response.status === 200) {
        const audience = await response.json()

        const departments = audience.users.filter(user => user.deptname).map((user) => user.deptname)
        const supervisors = audience.users.filter(user => user.supvemail).map((user) => user.supvemail)

        setDepartmentNames([...new Set(departments)])
        setSupervisorEmails([...new Set(supervisors)])
      } else {
        NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
    }
  }

  const refreshAudienceStats = async (audienceFilters) => {
    try {
      if (audienceFilters.length > 0) {
        const response = await apiRequestUtils.post(`/api/companies/${id}/audience/stats`, { isGroup: audienceFilterType === 'group', userParameters: audienceFilters })

        if (response.status === 200) {
          const audience = await response.json()

          setAudienceStats(audience.users)
        } else {
          NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
        }
      } else {
        setAudienceStats([])
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Failed to fetch audience. Please try again or contact Phin Support for assistance.')
    }
  }

  const distributePolicy = async () => {
    if (audienceSelectionType === audienceSelectionTypes.FIXED && fixedAudienceList.length === 0) {
      NotificationUtilities.sendErrorMessage('Please add users to the audience to distribute it.')
    } else {
      NotificationUtilities.sendInfoMessage('Launching policy...')
      dispatch(launchPolicyThunk({ id, policyId: params.policyId, audienceSelectionType, audienceStats, audienceFilters, fixedAudienceList, audienceFilterType, history }))
    }
  }

  return (

    <>
      {(isLoadingPolicyCatalog) && (<Spinner message='Loading...' />)}
      {(!isLoadingPolicyCatalog) &&
        <>
          <NotificationWrapper />
          <div className='screen-pad d-flex flex-column'>
            <div style={{ alignSelf: 'left' }}>
              <Button component={Link} sx={{ mb: 1 }} variant='text' color='primary' size='small' to={`/companies/${id}/policy/catalog`} onClick={() => null} startIcon={<IoArrowBackOutline />}>Return to Policy Catalog</Button>
              <h1 className='phin-h1'>Policy Distribution Details</h1>
              <p>Tune the audience who will need to acknowledge this policy document.</p>
            </div>

            <div className='curriculum-card-container' style={{ marginBottom: '2rem' }}>
              <div>
                <h4 className='phin-h4'>{policyName}</h4>
                <p className='description'>{policyDescription}</p>
              </div>
              <iframe id='policy-frame' className=' w-100' style={{ height: '300px', backgroundColor: 'grey' }} />
            </div>
            <CurriculumAudience
              groups={groups}
              audienceFilters={audienceFilters}
              setAudienceFilters={setAudienceFilters}
              audienceFilterType={audienceFilterType}
              setAudienceFilterType={setAudienceFilterType}
              audienceStats={audienceStats}
              departmentNames={departmentNames}
              supervisorEmails={supervisorEmails}
              audienceSelectionType={audienceSelectionType}
              setAudienceSelectionType={setAudienceSelectionType}
              campaignType={campaignType}
              fixedAudienceList={fixedAudienceList}
              setFixedAudienceList={setFixedAudienceList}
              isCampaignNew
              initialFixedAudienceList={initialFixedAudienceList}
            />
            <div style={{ width: '100%', float: 'right' }}>
              <Button
                variant='contained'
                endIcon={<IoSendSharp />}
                onClick={() => distributePolicy()}
                style={{ float: 'right', margin: '2rem' }}
              >
                Distribute Now
              </Button>
            </div>
          </div>
        </>}
    </>
  )
}

export default DistributePolicy
