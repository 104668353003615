import { MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import { TrainingCourseCard } from './TrainingCourseCard'

export const CourseDetailModal = ({ course, showDetailModal, detailModalHeader, toggleDetailModal }) => {
  return (
    <MDBModal
      isOpen={showDetailModal}
      toggle={() => toggleDetailModal(null, false)}
      closeOnEsc
      centered
      size='md'
    >
      <MDBModalHeader toggle={() => toggleDetailModal(null, false)}>{detailModalHeader}</MDBModalHeader>
      <MDBModalBody style={{ paddingBottom: '3rem' }}>
        <TrainingCourseCard course={course} showmask={false} />
      </MDBModalBody>
    </MDBModal>
  )
}
