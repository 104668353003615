import { useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { microsoftIntegrationStatuses, operationTypes, syncStatuses } from '../../../frontendConsts.js'
import { DateTime } from 'luxon'
import '../../css/integrationsDetail.css'
import '../../css/integrationCard.css'
import '../../css/integrationSyncSummary.css'

export function SyncSummary ({ stagedUsers, stagedGroups }) {
  const { integrationsMap } = useSelector((state) => state.integrations)
  const { users, groups } = useSelector((state) => state.users)
  const { loaders: userSyncSliceLoaders } = useSelector((state) => state.userSync)
  const { loadingUserSyncStatus } = userSyncSliceLoaders
  const [integration, setIntegration] = useState()
  const [usersToAdd, setUsersToAdd] = useState(0)
  const [usersToUpdate, setUsersToUpdate] = useState(0)
  const [usersToRemove, setUsersToRemove] = useState(0)
  const [groupsToAdd, setGroupsToAdd] = useState(0)
  const [groupsToUpdate, setGroupsToUpdate] = useState(0)
  const [groupsToRemove, setGroupsToRemove] = useState(0)

  useEffect(() => {
    let tempGroupsToAdd = 0
    let tempGroupsToUpdate = 0
    let tempGroupsToRemove = 0
    if (stagedGroups) {
      for (const stagedGroup of stagedGroups) {
        if (stagedGroup.operationType === operationTypes.ADD) {
          tempGroupsToAdd += 1
        } else if (stagedGroup.operationType === operationTypes.REMOVE) {
          tempGroupsToRemove += 1
        } else {
          tempGroupsToUpdate += 1
        }
      }

      setGroupsToAdd(tempGroupsToAdd)
      setGroupsToUpdate(tempGroupsToUpdate)
      setGroupsToRemove(tempGroupsToRemove)
    }
  }, [stagedGroups])

  useEffect(() => {
    let tempUsersToAdd = 0
    let tempUsersToUpdate = 0
    let tempUsersToRemove = 0
    if (stagedUsers) {
      for (const stagedUser of stagedUsers) {
        if (stagedUser.operationType === operationTypes.ADD) {
          tempUsersToAdd += 1
        } else if (stagedUser.operationType === operationTypes.REMOVE) {
          tempUsersToRemove += 1
        } else {
          tempUsersToUpdate += 1
        }
      }

      setUsersToAdd(tempUsersToAdd)
      setUsersToRemove(tempUsersToRemove)
      setUsersToUpdate(tempUsersToUpdate)
    }
  }, [stagedUsers])

  useEffect(() => {
    if (integrationsMap && integrationsMap.userSync) {
      setIntegration(integrationsMap.userSync)
    }
  }, [integrationsMap])

  const renderStatusBreadCrumbs = () => {
    const scheduledTimes = ['0', '6', '12', '18']
    const currentTime = DateTime.local({ zone: 'utc' })
    const timeCrumbArray = []
    for (const time of scheduledTimes) {
      const temp = currentTime.set({ hour: time, minute: 0, second: 0 })
      if (currentTime > temp) {
        timeCrumbArray.push(
          <>
            <span className='scheduleCrumb'>{temp.toLocaleString(DateTime.TIME_SIMPLE)} UTC</span>
            {time === '18' ? <div /> : <div>{'>'}</div>}
          </>
        )
      } else {
        timeCrumbArray.push(
          <>
            <span className='scheduleCrumb activeCrumb'>{temp.toLocaleString(DateTime.TIME_SIMPLE)} UTC</span>
          </>
        )
      }
    }
    return timeCrumbArray
  }
  return (
    <>
      {(integration && integration.integrationStatus === microsoftIntegrationStatuses.STAGED) && (
        <>
          <div className='left'>
            <div className='loadCard loadCardActive'>
              <div className='syncSummary'>
                <div className='phinCardTitle'>
                  Sync Summary
                </div>
                <br />
                <p>
                  All of your users and groups from your Azure tenant are now staged.
                  These are the changes that we will make to your Phin account if you commit these changes.
                  Users that do not have mailboxes will not be synced over.
                  You will be able to manage these users after syncing has started.
                </p>
                <br />
                <div className='dataContainer'>
                  <div>
                    <h5 className='subheading'><u>Operation Details</u></h5>
                    <div className='d-flex'>
                      <div className='d-flex flex-column'>
                        <div className='fractionColContainer flex-grow-1'>
                          <div style={{ minWidth: '120px' }} className='fraction' />
                        </div>
                        <div className='fraction flex-grow-1' style={{ minWidth: '120px' }}>
                          Users
                        </div>
                        <div className='fraction flex-grow-1' style={{ minWidth: '120px' }}>
                          Groups
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='fraction flex-grow-1' style={{ minWidth: '120px' }}>
                          Add
                        </div>
                        <div className='stagedPreviewBox flex-grow-1'>
                          <div className='fraction'>
                            {usersToAdd}
                          </div>
                        </div>
                        <div className='stagedPreviewBox flex-grow-1'>
                          <div className='fraction'>
                            {groupsToAdd}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='fraction' style={{ minWidth: '120px' }}>
                          Update
                        </div>
                        <div className='stagedPreviewBox'>
                          <div className='fraction'>
                            {usersToUpdate}
                          </div>
                        </div>
                        <div className='stagedPreviewBox'>
                          <div className='fraction'>
                            {groupsToUpdate}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-column'>
                        <div className='fraction' style={{ minWidth: '120px' }}>
                          Remove
                        </div>
                        <div className='stagedPreviewBox'>
                          <div className='fraction'>
                            {usersToRemove}
                          </div>
                        </div>
                        <div className='stagedPreviewBox'>
                          <div className='fraction'>
                            {groupsToRemove}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {(integration && integration.integrationStatus === microsoftIntegrationStatuses.CONNECTED) && (
        <>
          <div className='left'>
            <div className='loadCard loadCardActive'>
              <div className='syncSummary'>
                <div className='phinCardTitle'>
                  Sync Summary
                </div>
                <div className='dataContainer'>
                  <div className='volumeColumnContainer'>
                    <h5 className='subheading'><u>Volume</u></h5>
                    <div className='volumeRowContainer pl-2' style={{}}>
                      <div className='volumeColumnContainer'>
                        <p>users</p>
                        <div className='fractionColContainer userVolume'>
                          <div className='fraction'>
                            {users ? users.filter(user => user.status === 'active' && user.externalId).length : '-'} <span>active</span>
                          </div>
                          <div className='divide' />
                          <div className='fraction'>
                            {users ? users.filter(user => user.externalId).length : '-'} <span>total</span>
                          </div>
                        </div>
                      </div>
                      <div className='volumeColumnContainer'>
                        <p>groups</p>
                        <div className='fractionColContainer userVolume'>
                          <div className='fraction'>
                            {groups ? groups.filter(group => group.status === 'active' && group.externalId).length : '-'}<span>active</span>
                          </div>
                          <div className='divide' />
                          <div className='fraction'>
                            {groups ? groups.filter(group => group.externalId).length : '-'} <span>total</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='timingContainer w-100'>
                    <h5 className='subheading'><u>Sync Status</u></h5>
                    <div className='pl-2'>
                      <div className='volumeRowContainer align-items-center'>
                        <span className='infoKey mr-1'>Sync Times:</span>
                        {renderStatusBreadCrumbs()}
                      </div>
                    </div>
                    <div className='syncStatHeading pl-2'>
                      <span className='infoKey'>Last Sync Time: </span> {integration.lastSyncDateTime ? DateTime.fromSeconds(integration.lastSyncDateTime._seconds).toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS) : ' --'}
                    </div>
                    <div className='syncStatHeading pl-2'>
                      <span className='infoKey'>Last Successful Sync:</span> {integration.lastSyncSuccessDateTime ? DateTime.fromSeconds(integration.lastSyncSuccessDateTime._seconds).toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS) : ' --'}
                    </div>
                    <div className='syncStatHeading pl-2'>
                      <span className='infoKey'>Sync Status:</span>{(integration.isSyncing || loadingUserSyncStatus)
                        ? (
                          <>
                                  &nbsp; Syncing Now
                            <div className='spinner-grow spinner-grow-sm' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </>)
                        : ' Standby'}
                    </div>

                  </div>
                  <div className='syncErrorGroup'>
                    <h5 className='subheading'><u>Sync Errors</u></h5>
                    <div className='errorWindow' style={integration.lastSyncStatus === syncStatuses.ERROR ? { color: '#3D3F43', backgroundColor: '#FDCACA' } : {}}>
                      {integration.lastSyncStatus === syncStatuses.ERROR ? integration.syncErrorMessage : 'Nothing to display! All good!'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {(!integration || integration.integrationStatus === microsoftIntegrationStatuses.NOT_CONNECTED) && (
        <div className='loadCard loadCardInactive'>
          <div className='phinCardTitle'>
            Sync Summary
          </div>
        </div>
      )}

    </>
  )
}
