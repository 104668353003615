import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import { createGenericDownload } from '../utils/fileUtils'
import fetch from 'isomorphic-fetch'
import { getPartnerThunk, setPartnerAutomaticBillingEnabledAction } from './PartnerSlice'

export const partnerBillingSlice = createSlice({
  name: 'partnerBilling',
  initialState: {
    partnerBillingDetails: null,
    partnerBillingReports: null,
    partnerBillingInvoices: null,
    isAutomaticBillingReady: null,
    loaders: {
      isLoadingPartnerBillingDetails: false,
      isLoadingPartnerBillingReports: false,
      isLoadingPartnerBillingInvoices: false,
      isDownloadingPartnerInvoiceReport: {},
      isLoadingIsAutomaticBillingReady: false,
      isLoadingEnableAutomatedBilling: false
    }
  },
  reducers: {
    setIsLoadingPartnerBillingReports: (state, action) => {
      state.loaders.isLoadingPartnerBillingReports = action.payload
    },
    setPartnerBillingReports: (state, action) => {
      state.partnerBillingReports = action.payload
    },
    setIsLoadingPartnerBillingDetails: (state, action) => {
      state.loaders.isLoadingPartnerBillingDetails = action.payload
    },
    setPartnerBillingDetails: (state, action) => {
      state.partnerBillingDetails = action.payload
    },
    setIsLoadingPartnerBillingInvoices: (state, action) => {
      state.loaders.isLoadingPartnerBillingInvoices = action.payload
    },
    setPartnerBillingInvoices: (state, action) => {
      state.partnerBillingInvoices = action.payload
    },
    setIsDownloadingPartnerInvoiceReport: (state, action) => {
      state.loaders.isDownloadingPartnerInvoiceReport[action.payload.id] = action.payload.value
    },
    setPartnerIsAutomaticBillingReady: (state, action) => {
      state.isAutomaticBillingReady = action.payload
    },
    updateIsLoadingIsAutomaticBillingReady: (state, action) => {
      state.loaders.isLoadingIsAutomaticBillingReady = action.payload
    },
    updateIsLoadingEnableAutomatedBilling: (state, action) => {
      state.loaders.isLoadingEnableAutomatedBilling = action.payload
    }
  }
})

export const {
  setIsLoadingPartnerBillingReports: setIsLoadingPartnerBillingReportsAction,
  setPartnerBillingReports: setPartnerBillingReportsAction,
  setIsLoadingPartnerBillingDetails: setIsLoadingPartnerBillingDetailsAction,
  setPartnerBillingDetails: setPartnerBillingDetailsAction,
  setIsLoadingPartnerBillingInvoices: setIsLoadingPartnerBillingInvoicesAction,
  setPartnerBillingInvoices: setPartnerBillingInvoicesAction,
  setIsDownloadingPartnerInvoiceReport: setIsDownloadingPartnerInvoiceReportAction,
  setPartnerIsAutomaticBillingReady: setPartnerIsAutomaticBillingReadyAction,
  updateIsLoadingIsAutomaticBillingReady: updateIsLoadingIsAutomaticBillingReadyAction,
  updateIsLoadingEnableAutomatedBilling: updateIsLoadingEnableAutomatedBillingAction
} = partnerBillingSlice.actions

export default partnerBillingSlice.reducer

export const getPartnerBillingReportsThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerBillingReportsAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/reports`)

      if (res.status === 200) {
        const { partnerBillingReports } = await res.json()
        dispatch(setPartnerBillingReportsAction(partnerBillingReports))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load Billing Breakdown Reports. Please try again or contact Phin Support for assistance.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Billing Breakdown Reports. Please try again or contact Phin Support for assistance.')
      })
      console.error(err)
    }

    dispatch(setIsLoadingPartnerBillingReportsAction(false))
  }
}

export const getPartnerBillingDetailsThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerBillingDetailsAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/details`)

      if (res.status === 200) {
        const { partnerBillingDetails } = await res.json()
        dispatch(setPartnerBillingDetailsAction(partnerBillingDetails))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load Partner Billing Details. Please try again or contact Phin Support for assistance.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Partner Billing Details. Please try again or contact Phin Support for assistance.')
      })
      console.error(err)
    }

    dispatch(setIsLoadingPartnerBillingDetailsAction(false))
  }
}

export const getPartnerBillingInvoicesThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPartnerBillingInvoicesAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/invoices`)

      if (res.status === 200) {
        const { partnerBillingInvoices } = await res.json()
        await dispatch(setPartnerBillingInvoicesAction(partnerBillingInvoices))
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to load Billing Invoices. Please try again or contact Phin Support for assistance.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to load Billing Invoices. Please try again or contact Phin Support for assistance.')
      })
      console.error(err)
    }

    dispatch(setIsLoadingPartnerBillingInvoicesAction(false))
  }
}

export const getPartnerBillingInvoiceReportPDFThunk = ({ partnerId, invoice }) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(setIsDownloadingPartnerInvoiceReportAction({ id: invoice.id, value: true }))

      const res = await api.get(`/api/partners/${partnerId}/billing/invoices/${invoice.id}`)

      if (res.status === 200) {
        const { downloadURL } = await res.json()
        const downloadResponse = await fetch(downloadURL, { method: 'GET', 'Content-Type': 'application/pdf' })
        const reportBlob = await downloadResponse.blob()

        if (reportBlob.size === 0) {
          setTimeout(() => {
            NotificationUtilities.sendErrorMessage('Error downloading invoice, as it is empty, please contact Phin if problem persists.')
          })
        }

        createGenericDownload({ fileBlob: reportBlob, fileName: invoice.reportName, fileType: invoice.fileType })
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Error downloading invoice, please try again or contact Phin support for assistance')
        })
      }
    } catch (error) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Error downloading invoice, please try again or contact Phin support for assistance')
      })
    }
    dispatch(setIsDownloadingPartnerInvoiceReportAction({ id: invoice.id, value: false }))
  }
}

export const enableAutomatedBillingThunk = ({ partnerId, billingCompanyName, billingEmail }
) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingEnableAutomatedBillingAction(true))
    setTimeout(() => {
      NotificationUtilities.sendInfoMessage('Enabling Automated Billing.')
    })
    try {
      const res = await api.post(`/api/partners/${partnerId}/billing/enable-automated-billing`, {
        billingCompanyName,
        billingEmail
      })

      if (res.status === 200) {
        dispatch(setPartnerAutomaticBillingEnabledAction(true))
        dispatch(getPartnerThunk(partnerId))
        setTimeout(() => {
          NotificationUtilities.sendSuccessMessage('Successfully enabled Automated Billing.')
        })
      } else {
        setTimeout(() => {
          NotificationUtilities.sendErrorMessage('Failed to enable Automated Billing. Please try again or contact Phin Support for assistance.')
        })
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to enable Automated Billing. Please try again or contact Phin Support for assistance.')
      })
      console.error(err)
    }
    dispatch(updateIsLoadingEnableAutomatedBillingAction(true))
  }
}

export const validateAutomatedBillingReadyThunk = ({ partnerId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingIsAutomaticBillingReadyAction(true))
    try {
      const res = await api.get(`/api/partners/${partnerId}/billing/validate-automated-billing-ready`)

      if (res.status === 200) {
        const { autoPayReady } = await res.json()
        dispatch(setPartnerIsAutomaticBillingReadyAction(autoPayReady))
      } else {
        dispatch(setPartnerIsAutomaticBillingReadyAction(false))
      }
    } catch (err) {
      setTimeout(() => {
        NotificationUtilities.sendErrorMessage('Failed to validate Automated billing ready. Please try again or contact Phin Support for assistance.')
      })
      console.error(err)
    }

    dispatch(updateIsLoadingIsAutomaticBillingReadyAction(false))
  }
}
