import React, { useEffect } from 'react'
import {
  MDBRow, MDBContainer, MDBCol, MDBDatePicker, MDBTimePicker,
  MDBCardHeader, MDBCard, MDBCardBody, MDBIcon, MDBTooltip, MDBBtn
} from 'mdbreact'

function ContinuousCampaignLauncher ({
  phishingStartDate, setPhishingStartDate,
  trainingStartDate, setTrainingStartDate,
  trainingStartTimeHours, trainingStartTimeMinutes,
  setTrainingStartTimeHours, setTrainingStartTimeMinutes
}) {
  const timePickerRef = React.createRef()

  useEffect(() => {
    timePickerRef.current.handleDayTimeChange(trainingStartTimeHours >= 12 ? 'pm' : 'am')
  }, [])

  const updateStartTime = (startTimeString) => {
    let hours = +startTimeString.slice(0, 2)
    const minutes = +startTimeString.slice(3, 5)
    const dayTime = startTimeString.slice(5)
    if (dayTime === 'PM' && hours !== 12) {
      hours += 12
    }

    setTrainingStartTimeHours(hours)
    setTrainingStartTimeMinutes(minutes)
  }

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  return (
    <MDBContainer>
      <MDBRow>
        <h1 className='font-weight-bold pl-0 my-4'><strong>Company and Resource Launcher</strong></h1>
      </MDBRow>

      <MDBCard>
        <MDBCardHeader className='d-flex justify-content-between'>
          <div>
            <h5>Phishing Schedule</h5>
          </div>
          <div style={{ zIndex: 10 }}>
            <MDBTooltip
              placement='right'
              id='description-popover'
              material
            >
              <MDBBtn
                color='elegant'
                className='my-0'
                floating
                tag='a'
                size='sm'
              >
                <MDBIcon icon='question' />
              </MDBBtn>
              <div>
                When the campaign runs, it schedules phishing emails for
                every user in the audience. These emails have their send date and send time
                randomly generated to be within the next three business days.
                The campaign will then fire again based on the frequency defined:
                monthly.
                This continuous campaign will run indefinitely.
              </div>
            </MDBTooltip>
          </div>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBRow className='d-flex justify-content-center align-items-center mt-2'>
            <MDBCol size='6' className='flex-column pr-0'>
              <div className='h5'>Start Date:</div>
            </MDBCol>
            <MDBCol className='flex-column d-flex align-items-left' size='4'>
              <MDBDatePicker
                className='d-flex justify-content-center'
                valueDefault={phishingStartDate}
                disablePast
                minDate={minDate}
                name='startDate'
                getValue={setPhishingStartDate}
                format='MMMM D, YYYY'
                required
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className='d-flex justify-content-center align-items-center mt-2'>
            <MDBCol size='6' className='flex-column pr-0'>
              <div className='h5'>Frequency:</div>
            </MDBCol>
            <MDBCol className='flex-column d-flex align-items-left' size='4'>
              <div className='h6'>Monthly</div>

            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>

      <br />

      <MDBCard>
        <MDBCardHeader className='d-flex justify-content-between'>
          <div>
            <h5>Training Schedule</h5>
          </div>
          <div style={{ zIndex: 10 }}>
            <MDBTooltip
              placement='right'
              id='description-popover'
              material
            >
              <MDBBtn
                color='elegant'
                className='my-0'
                floating
                tag='a'
                size='sm'
              >
                <MDBIcon icon='question' />
              </MDBBtn>
              <div>
                When the campaign runs, it sends training emails for
                every user in the audience. These emails contain a link
                to the specific user's assigned training.
                The campaign will then fire again based on the frequency defined:
                monthly.
                This continuous campaign will run indefinitely.
              </div>
            </MDBTooltip>
          </div>
        </MDBCardHeader>
        <MDBCardBody>

          <MDBRow className='d-flex justify-content-center align-items-center mt-2'>
            <MDBCol size='6' className='flex-column pr-0'>
              <div className='h5'>Start Date:</div>
            </MDBCol>
            <MDBCol className='flex-column d-flex align-items-left' size='4'>
              <MDBDatePicker
                className='d-flex justify-content-center'
                valueDefault={trainingStartDate}
                disablePast
                minDate={minDate}
                name='startDate'
                getValue={setTrainingStartDate}
                format='MMMM D, YYYY'
                required
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className='d-flex justify-content-center align-items-center mt-2'>
            <MDBCol size='6' className='flex-column pr-0'>
              <div className='h5'>Start Time:</div>
            </MDBCol>
            <MDBCol className='flex-column d-flex align-items-left' size='4'>
              <MDBTimePicker
                ref={timePickerRef}
                hoursFormat={12}
                hours={trainingStartTimeHours % 12 === 0 ? 12 : trainingStartTimeHours % 12}
                minutes={trainingStartTimeMinutes}
                name='startTime'
                getValue={updateStartTime}
                required
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className='d-flex justify-content-center align-items-center mt-2'>
            <MDBCol size='6' className='flex-column pr-0'>
              <div className='h5'>Frequency:</div>
            </MDBCol>
            <MDBCol className='flex-column d-flex align-items-left' size='4'>
              <div className='h6'> Monthly</div>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  )
}

export default ContinuousCampaignLauncher
