import React from 'react'
import { MDBAlert } from 'mdbreact'

const ErrorPanel = ({ error, color }) => {
  if (!error) return <div />

  return <MDBAlert color={color}> {error}</MDBAlert>
}

export default ErrorPanel
