import { createSlice } from '@reduxjs/toolkit'
import NotificationUtilities from '../pages/components/notifications/notificationUtils'

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    sendRecords: null,
    userSendRecords: null,
    courseStats: null,
    phishingClicksStats: null,
    policyStats: null,
    userInteractionDataStats: null,
    loaders: {
      isLoadingSendRecords: false,
      isLoadingCourseStats: false,
      isLoadingPhishingClicksStats: false,
      isLoadingPolicyStats: false,
      isLoadingUserInteractionDataStats: false
    }
  },
  reducers: {
    getSendRecords: (state, action) => {
      state.sendRecords = action.payload
    },
    updateSendRecord: (state, action) => {
      // Only update if we've fetched the entire list of sendRecords (user analytics only pulls for user to save FB calls)
      if (state.sendRecords) {
        const updatedSendRecords = [...state.sendRecords]

        state.sendRecords = updatedSendRecords.map(sendRecord => {
          if (sendRecord.id === action.payload.id) {
            return action.payload
          } else {
            return sendRecord
          }
        })
      }

      if (state.userSendRecords) {
        const updatedUserSendRecords = [...state.userSendRecords]

        state.userSendRecords = updatedUserSendRecords.map(sendRecord => {
          if (sendRecord.id === action.payload.id) {
            return action.payload
          } else {
            return sendRecord
          }
        })
      }
    },
    getCourseStats: (state, action) => {
      state.courseStats = action.payload
    },
    setPhishingClicksStats: (state, action) => {
      state.phishingClicksStats = action.payload
    },
    getPolicyStats: (state, action) => {
      state.policyStats = action.payload
    },
    setIsLoadingSendRecords: (state, action) => {
      state.loaders.isLoadingSendRecords = action.payload
    },
    setUserSendRecords: (state, action) => {
      state.userSendRecords = action.payload
    },
    setIsLoadingCourseStats: (state, action) => {
      state.loaders.isLoadingCourseStats = action.payload
    },
    setIsLoadingPhishingClicksStats: (state, action) => {
      state.loaders.isLoadingPhishingClicksStats = action.payload
    },
    setIsLoadingPolicyStats: (state, action) => {
      state.loaders.isLoadingPolicyStats = action.payload
    },
    resetTrainingForUser: (state, action) => {
      const courseStatsUpdate = [...state.courseStats]
      const { courseId, user: userToReset } = action.payload
      courseStatsUpdate.forEach(courseStat => {
        if (courseStat.course.id === courseId) {
          courseStat.users.forEach(user => {
            if (user.id === userToReset.id) {
              if (user.lesson_status === 'passed') {
                courseStat.course.totalCompleted -= 1
                user.lesson_status = 'incomplete'
                if (user.score) {
                  delete user.score
                }
              }
            }
          })
        }
      })
      state.courseStats = courseStatsUpdate
    },
    markTrainingCompleteForUser: (state, action) => {
      const courseStatsUpdate = [...state.courseStats]

      const { courseId, user: userToReset, newScore, newDateCompleted } = action.payload

      courseStatsUpdate.forEach(courseStat => {
        if (courseStat.course.id === courseId) {
          courseStat.users.forEach(user => {
            if (user.id === userToReset.id) {
              if (user.lesson_status === 'incomplete') {
                courseStat.course.totalCompleted += 1
                user.lesson_status = 'passed'
                user.score = newScore
                user.dateCompleted = newDateCompleted
              }
            }
          })
        }
      })

      state.courseStats = courseStatsUpdate
    },
    updateIsLoadingUserInteractionDataStats: (state, action) => {
      state.loaders.isLoadingUserInteractionDataStats = action.payload
    },
    setUserInteractionDataStats: (state, action) => {
      state.userInteractionDataStats = action.payload
    },
    resetTrainingForUserInteractionDataStats: (state, action) => {
      const updatedUserInteractionDataStats = []

      for (const courseStats of state.userInteractionDataStats) {
        const newCourseStats = { ...courseStats }
        if (newCourseStats.id === action.payload.courseId) {
          newCourseStats.lesson_status = 'incomplete'
          delete newCourseStats.score
          delete newCourseStats.dateCompleted
        }

        updatedUserInteractionDataStats.push(newCourseStats)
      }

      state.userInteractionDataStats = updatedUserInteractionDataStats
    },
    markCourseCompleteForUserInteractionDataStats: (state, action) => {
      const updatedUserInteractionDataStats = []

      for (const courseStats of state.userInteractionDataStats) {
        const newCourseStats = { ...courseStats }
        if (newCourseStats.id === action.payload.courseId) {
          newCourseStats.lesson_status = 'passed'
          newCourseStats.score = action.payload.newScore
          newCourseStats.dateCompleted = action.payload.newDateCompleted
        }

        updatedUserInteractionDataStats.push(newCourseStats)
      }

      state.userInteractionDataStats = updatedUserInteractionDataStats
    }
  }
})

export const {
  getSendRecords: getSendRecordsAction,
  updateSendRecord: updateSendRecordAction,
  getCourseStats: getCourseStatsAction,
  setPhishingClicksStats: setPhishingClicksStatsAction,
  setUserSendRecords: setUserSendRecordsAction,
  getPolicyStats: getPolicyStatsAction,
  setIsLoadingSendRecords: setIsLoadingSendRecordsAction,
  setIsLoadingCourseStats: setIsLoadingCourseStatsAction,
  setIsLoadingPhishingClicksStats: setIsLoadingPhishingClicksStatsAction,
  setIsLoadingPolicyStats: setIsLoadingPolicyStatsAction,
  resetTrainingForUser: resetTrainingForUserAction,
  markTrainingCompleteForUser: markTrainingCompleteForUserAction,
  updateIsLoadingUserInteractionDataStats: updateIsLoadingUserInteractionDataStatsAction,
  setUserInteractionDataStats: setUserInteractionDataStatsAction,
  resetTrainingForUserInteractionDataStats: resetTrainingForUserInteractionDataStatsAction,
  markCourseCompleteForUserInteractionDataStats: markCourseCompleteForUserInteractionDataStatsAction
} = analyticsSlice.actions

export default analyticsSlice.reducer

export const getSendRecordsThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingSendRecordsAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/analytics/organization/sendRecords`)
      if (res.status === 200) {
        const sendRecords = await res.json()
        dispatch(getSendRecordsAction(sendRecords))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingSendRecordsAction(false))
  }
}

export const getCourseStatsThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingCourseStatsAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/analytics/courses`)
      if (res.status === 200) {
        const courseStats = await res.json()
        dispatch(getCourseStatsAction(courseStats))
        dispatch(setIsLoadingCourseStatsAction(false))
        return courseStats
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingCourseStatsAction(false))
    return false
  }
}

export const getPhishingClickStatsThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPhishingClicksStatsAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/analytics/phishing-click-stats`)
      if (res.status === 200) {
        const phishingClickStats = await res.json()
        dispatch(setPhishingClicksStatsAction(phishingClickStats.phishingClicksData))
        dispatch(setIsLoadingPhishingClicksStatsAction(false))
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(setIsLoadingPhishingClicksStatsAction(false))
    return false
  }
}

export const getUserSendRecordsThunk = ({ companyId, userId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingUserInteractionDataStatsAction(true))

    try {
      const res = await api.get(`/api/companies/${companyId}/analytics/organization/sendRecords?userId=${userId}`)
      if (res.status === 200) {
        const userSendRecords = await res.json()
        dispatch(setUserSendRecordsAction(userSendRecords))
      } else if (res.status === 500) {
        const resJson = await res.json()
        NotificationUtilities.sendErrorMessage(resJson.message)
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to load User Phishing Data. Please contact Phin Support for assistance. (https://www.phinsec.io./knowledge')
    }

    dispatch(updateIsLoadingUserInteractionDataStatsAction(false))
  }
}

export const getUserTrainingStatsThunk = ({ companyId, userId }) => {
  return async (dispatch, getState, api) => {
    dispatch(updateIsLoadingUserInteractionDataStatsAction(true))

    try {
      const res = await api.get(`/api/companies/${companyId}/analytics/user-stats/${userId}`)
      if (res.status === 200) {
        const { userTrainingData } = await res.json()
        dispatch(setUserInteractionDataStatsAction(userTrainingData))
      } else if (res.status === 500) {
        const resJson = await res.json()
        NotificationUtilities.sendErrorMessage(resJson.message)
      }
    } catch (error) {
      NotificationUtilities.sendErrorMessage('Failed to load User Training Data. Please contact Phin Support for assistance. (https://www.phinsec.io./knowledge')
    }

    dispatch(updateIsLoadingUserInteractionDataStatsAction(false))
  }
}

export const getPolicyStatsThunk = (companyId) => {
  return async (dispatch, getState, api) => {
    dispatch(setIsLoadingPolicyStatsAction(true))
    try {
      const res = await api.get(`/api/companies/${companyId}/analytics/policies`)
      if (res.status === 200) {
        const analyticsStats = await res.json()
        dispatch(getPolicyStatsAction(analyticsStats))
      }
    } catch (err) {
      console.log(err)
    }
    dispatch(setIsLoadingPolicyStatsAction(false))
  }
}

export const resetTrainingForUserThunk = ({ user, companyId, courseId, isSingleUserView }) => {
  return async (dispatch, getState, api) => {
    try {
      if (isSingleUserView) {
        dispatch(updateIsLoadingUserInteractionDataStatsAction(true))
      } else {
        dispatch(setIsLoadingCourseStatsAction(true))
      }

      const res = await api.post(`/api/companies/${companyId}/training/reset-user/${courseId}/${user.id}`)
      if (res.status === 200) {
        if (isSingleUserView) {
          dispatch(resetTrainingForUserInteractionDataStatsAction({ courseId }))
        } else {
          dispatch(resetTrainingForUserAction({ user, courseId }))
        }
        NotificationUtilities.sendSuccessMessage('Training for user reset!')

        if (isSingleUserView) {
          dispatch(updateIsLoadingUserInteractionDataStatsAction(false))
        } else {
          dispatch(setIsLoadingCourseStatsAction(false))
        }

        return true
      } else {
        NotificationUtilities.sendErrorMessage('Error resetting user training, please contact Phin if problem persists!')
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Error resetting user training, please contact Phin if problem persists!')
    }

    if (isSingleUserView) {
      dispatch(updateIsLoadingUserInteractionDataStatsAction(false))
    } else {
      dispatch(setIsLoadingCourseStatsAction(false))
    }

    return false
  }
}

export const markTrainingCompleteForUserThunk = ({ user, companyId, courseId, isSingleUserView, score, dateCompleted, overrideReason }) => {
  return async (dispatch, getState, api) => {
    try {
      if (isSingleUserView) {
        dispatch(updateIsLoadingUserInteractionDataStatsAction(true))
      } else {
        dispatch(setIsLoadingCourseStatsAction(true))
      }

      const res = await api.post(`/api/companies/${companyId}/training/mark-complete/${courseId}/${user.id}`, { score, dateCompleted, overrideReason })
      if (res.status === 200) {
        const { newScore, newDateCompleted } = await res.json()
        if (isSingleUserView) {
          dispatch(markCourseCompleteForUserInteractionDataStatsAction({ courseId, newScore, newDateCompleted }))
        } else {
          dispatch(markTrainingCompleteForUserAction({ user, courseId, newScore, newDateCompleted }))
        }

        NotificationUtilities.sendSuccessMessage('Training marked complete for user.')

        if (isSingleUserView) {
          dispatch(updateIsLoadingUserInteractionDataStatsAction(false))
        } else {
          dispatch(setIsLoadingCourseStatsAction(false))
        }

        return true
      } else {
        NotificationUtilities.sendErrorMessage('Error marking training complete for user, please try again or contact Phin Support if problem persists.')
      }
    } catch (err) {
      console.error(err)
      NotificationUtilities.sendErrorMessage('Error marking training complete for user, please try again or contact Phin Support if problem persists.')
    }

    if (isSingleUserView) {
      dispatch(updateIsLoadingUserInteractionDataStatsAction(false))
    } else {
      dispatch(setIsLoadingCourseStatsAction(false))
    }

    return false
  }
}
