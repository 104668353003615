import { MDBInput } from 'mdbreact'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { campaignTypes } from '../../../frontendConsts.js'

function CurriculumInformation ({
  name, description, setName,
  setDescription, campaignType,
  toggleCampaignType, isCampaignNew
}) {
  return (
    <div className='curriculum-card-container'>
      <div>
        <h4 className='phin-h4'>Training Campaign Info</h4>
        <p className='description'>Configure campaign metadata.</p>
      </div>
      <div className='form-group w-100'>
        <MDBInput
          style={{ borderRadius: '4px' }}
          outline value={name}
          id='training-campaign-name-field'
          aria-label='Training Campaign Name Field'
          size='lg'
          className='w-100 h-100'
          onChange={(e) => {
            setName(e.target.value)
          }} required name='trainingCampaignName' label='Training Campaign Name'
        />
        <div className='valid-feedback'>Looks good!</div>
      </div>
      <div>
        <MDBInput
          style={{ borderRadius: '4px' }}
          type='textarea'
          label='Training Campaign Description'
          rows='5'
          outline
          value={description}
          id='training-campaign-description-field'
          aria-label='Training Campaign Description Field'
          onChange={(e) => {
            setDescription(e.target.value)
          }}
          name='trainingCampaignDesc'
        />
      </div>
      {(isCampaignNew) && (
        <div>
          <FormControl>
            <FormLabel id='campaign-type-form-label'>Campaign Type</FormLabel>
            <RadioGroup
              aria-labelledby='radio-buttons-group-label'
              value={campaignType}
              name='radio-buttons-group'
              onChange={(e) => { toggleCampaignType(e.target.value) }}
              row
            >
              <FormControlLabel
                value={campaignTypes.TRAINING}
                control={<Radio />}
                label='Fixed'
                id='fixed-campaign-radio-button'
                aria-label='Fixed Campaign Radio Button'
              />
              <FormControlLabel
                value={campaignTypes.ONBOARDING}
                control={<Radio />}
                label='Onboarding'
                id='onboarding-campaign-radio-button'
                aria-label='Onboarding Campaign Radio Button'
              />
              <FormControlLabel
                value={campaignTypes.CONTINUOUS_TRAINING}
                control={<Radio />}
                label='Continuous'
                id='continuous-campaign-radio-button'
                aria-label='Continuous Campaign Radio Button'
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
    </div>
  )
}

export default CurriculumInformation
