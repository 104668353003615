import {
  MDBSideNavLink,
  MDBIcon,
  MDBSideNavNav
} from 'mdbreact'
import { IoCard } from 'react-icons/io5'
import { useSelector } from 'react-redux'

function PartnerNavRoutes ({ id }) {
  const { partner } = useSelector((state) => state.partner)
  const { authorization } = useSelector((state) => state.auth)

  const isBillingAdmin = authorization.billingAdminPartnerIds.includes(id)
  return (
    <>
      {
      (partner && isBillingAdmin)
        ? (
          <MDBSideNavNav>
            <MDBSideNavLink
              to={`/partners/${id}`}
              id='partnersPageNavLink'
            >
              <MDBIcon fixed icon='handshake' className='mr-2' />
              Companies Overview
            </MDBSideNavLink>
            <MDBSideNavLink
              to={`/partners/${id}/integrations`}
              id='partner-integrationsnav-link'
            >
              <MDBIcon fixed icon='toolbox' className='mr-2' />
              Integrations
            </MDBSideNavLink>
            <MDBSideNavLink
              to={`/partners/${id}/resources`}
              id='partner-resources-nav-link'
              className='nav-icon'
            >
              <MDBIcon
                fixed
                icon='copy'
                className='mr-2'
              />
              Resources
            </MDBSideNavLink>

            <MDBSideNavLink
              to={`/partners/${id}/branding`}
              id='partner-branding-nav-link'
              className='nav-icon'
            >
              <MDBIcon
                fixed
                icon='palette'
                className='mr-2'
              />
              Branding
            </MDBSideNavLink>

            <MDBSideNavLink
              to={`/partners/${id}/billing`}
              id='partner-billing-nav-link'
              className='nav-icon'
            >
              <span className='margin-right:-2'>
                <IoCard size='1.25em' />
              </span>
              Billing
            </MDBSideNavLink>

          </MDBSideNavNav>
          )
        : (
          <MDBSideNavNav>
            <MDBSideNavLink
              to={`/partners/${id}`}
              id='partnersPageNavLink'
            >
              <MDBIcon fixed icon='handshake' className='mr-2' />
              Companies Overview
            </MDBSideNavLink>
            <MDBSideNavLink
              to={`/partners/${id}/integrations`}
              id='partner-integrationsnav-link'
            >
              <MDBIcon fixed icon='toolbox' className='mr-2' />
              Integrations
            </MDBSideNavLink>
            <MDBSideNavLink
              to={`/partners/${id}/resources`}
              id='partner-resources-nav-link'
              className='nav-icon'
            >
              <MDBIcon
                fixed
                icon='copy'
                className='mr-2'
              />
              Resources
            </MDBSideNavLink>

            <MDBSideNavLink
              to={`/partners/${id}/branding`}
              id='partner-branding-nav-link'
              className='nav-icon'
            >
              <MDBIcon
                fixed
                icon='palette'
                className='mr-2'
              />
              Branding
            </MDBSideNavLink>
          </MDBSideNavNav>
          )
      }
    </>
  )
}

export default PartnerNavRoutes
