import NotificationUtilities from '../pages/components/notifications/notificationUtils'
import Papa from 'papaparse'
import { apiRequestUtils } from '../utils/apiRequestUtils'

/**
 * Generates a file download based off raw file data
 * @param {Json} text the JSON to convert to CSV. (2D array of rows, object specifying fields & data, etc.)
 * @param {string} fileName The name of the file (used for logging)
 */
export function createFileDownload (text, fileName, isRawCSVString) {
  const blob = new Blob([Papa.unparse(text)], { type: 'text/csv;charset=utf-8;' })

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, `${fileName}.csv`)
  } else {
    const a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob, {
      type: 'text/csv'
    })
    a.download = `${fileName}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

/**
 * Generates a generic file download based on the blob and file info passed in
 * @param {*} param0 (fileBlob, fileName, fileType)
 */
export function createGenericDownload ({ fileBlob, fileName, fileType }) {
  const blob = new Blob([fileBlob], { type: fileType })
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    const a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob, {
      type: fileType
    })
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

/**
 * Generates a CSV download with data from the passed campaign
 * @param {string} campaignId The campaign's unique campaignId
 * @param {string} campaignName The campaign's name
 * @param {string} campaignStatus Whether this is in activeCampaigns or pastCampaigns
 */
export async function downloadCSV (id, campaignId, campaignName, campaignStatus) {
  const res = await apiRequestUtils.get(`/api/companies/${id}/campaigns/${campaignId}/stats/csv?campaignStatus=${campaignStatus}`)

  if (res.status === 500) {
    NotificationUtilities.sendErrorMessage(`Error Generating stats for ${campaignName}`)
  } else {
    const csv = await res.json()
    if (csv.length === 0) {
      NotificationUtilities.sendWarningMessage('There is no data to download!')
    } else {
      createFileDownload(csv, campaignName)
    }
  }
}

export async function downloadOrgData (id, timeRange, filters = {}) {
  const [startTimestamp, endTimestamp] = timeRange
  let url = `/api/companies/${id}/analytics/organization/stats?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`
  if (filters.falsePositives) {
    url += `&falsePositives=${filters.falsePositives}`
  }
  const res = await apiRequestUtils.get(url)

  if (res.status === 500) {
    NotificationUtilities.sendErrorMessage('Error generating org data')
  } else {
    const csv = await res.json()
    if (csv.length === 0) {
      NotificationUtilities.sendWarningMessage('There is no data to download.')
    } else {
      createFileDownload(csv, `sendsFor-${startTimestamp}-${endTimestamp}`)
    }
  }
}

export async function downloadOrgDataForCampaign (id, campaignName, campaignId, filters = {}) {
  let url = `/api/companies/${id}/analytics/organization/stats/${campaignId}`
  if (filters.falsePositives) {
    url += `&falsePositives=${filters.falsePositives}`
  }
  const res = await apiRequestUtils.get(url)

  if (res.status === 500) {
    NotificationUtilities.sendErrorMessage('Error generating org data')
  } else {
    const csv = await res.json()
    if (csv.length === 0) {
      NotificationUtilities.sendWarningMessage('There is no data to download.')
    } else {
      createFileDownload(csv, `sendFor-${campaignName}-${campaignId}`)
    }
  }
}
